import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerAddStudentToGroupMutationRequest,
  StudentsControllerAddStudentToGroupMutationResponse,
} from '../../ts/StudentsDTO/StudentsControllerAddStudentToGroup'

export function getStudentsControllerAddStudentToGroupUrl() {
  return `/students` as const
}

/**
 * @summary Добавление студента в группу
 * {@link /students}
 */
export async function studentsControllerAddStudentToGroup(
  data: StudentsControllerAddStudentToGroupMutationRequest,
  config: Partial<RequestConfig<StudentsControllerAddStudentToGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    StudentsControllerAddStudentToGroupMutationResponse,
    ResponseErrorConfig<Error>,
    StudentsControllerAddStudentToGroupMutationRequest
  >({ method: 'POST', url: getStudentsControllerAddStudentToGroupUrl().toString(), data, ...requestConfig })
  return res
}