export type ENVIRONMENTS = 'development' | 'production' | 'staging' | string;

const BACKEND_URL = import.meta.env.VITE_APP_BACKEND_URL;
const FRONTEND_URL = import.meta.env.VITE_APP_FRONTEND_URL;
const MEDIA_URL = import.meta.env.VITE_APP_MEDIA_API_URL;
const PDDTEST_MEDIA_API_URL = import.meta.env.VITE_APP_PDDTEST_MEDIA_API_URL;
const PROCTORING_URL = import.meta.env.VITE_APP_PROCTORING_URL ?? 'ws://proctor.sofiasoft.kz/websocket';
const ENVIRONMENT: ENVIRONMENTS = import.meta.env.VITE_APP_MODE ?? 'development';

export const CONSTANTS = {
	BACKEND_URL,
	FRONTEND_URL,
	MEDIA_URL,
	PDDTEST_MEDIA_API_URL,
	PROCTORING_URL,
	ENVIRONMENT
};