import type { TestCategoryDto } from './testCategory.type';

export enum TestQuestionsFilterSort {
    id_asc = 'id.asc',
    id_desc = 'id.desc',
    created_at_asc = 'created_at.asc',
    created_at_desc = 'created_at.desc',
    updated_at_asc = 'updated_at.asc',
    updated_at_desc = 'updated_at.desc',
}

export interface TestQuestionsFilterDto {
    q?: string | null;
    count: number;
    offset: number;
    sort?: TestQuestionsFilterSort[];
    category_id?: number;
}

export interface TestQuestionPreviewDto {
    id: number;
    title: string;
    category: TestCategoryDto;
    task_file_id: string | null;
    category_id: number;
    created_at: Date;
    updated_at: Date;
}

export interface TestQuestionsSearchDto {
    count: number;
    sum: number;
    offset: number;
    items: TestQuestionPreviewDto[];
}