import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerTriggerRefreshTransactionQueryResponse,
  TransactionsControllerTriggerRefreshTransactionPathParams,
} from '../../ts/TransactionsDTO/TransactionsControllerTriggerRefreshTransaction'

export function getTransactionsControllerTriggerRefreshTransactionUrl({
  transactionId,
}: {
  transactionId: TransactionsControllerTriggerRefreshTransactionPathParams['transactionId']
}) {
  return `/transactions/refresh/${transactionId}` as const
}

/**
 * @summary Обновление платежа
 * {@link /transactions/refresh/:transactionId}
 */
export async function transactionsControllerTriggerRefreshTransaction(
  { transactionId }: { transactionId: TransactionsControllerTriggerRefreshTransactionPathParams['transactionId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransactionsControllerTriggerRefreshTransactionQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTransactionsControllerTriggerRefreshTransactionUrl({ transactionId }).toString(),
    ...requestConfig,
  })
  return res
}