import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerGetTransactionsByUserIdQueryResponse,
  TransactionsControllerGetTransactionsByUserIdPathParams,
  TransactionsControllerGetTransactionsByUserIdQueryParams,
} from '../../ts/TransactionsDTO/TransactionsControllerGetTransactionsByUserId'

export function getTransactionsControllerGetTransactionsByUserIdUrl({ userId }: { userId: TransactionsControllerGetTransactionsByUserIdPathParams['userId'] }) {
  return `/transactions/user/${userId}` as const
}

/**
 * @summary Получение транзакций пользователя
 * {@link /transactions/user/:userId}
 */
export async function transactionsControllerGetTransactionsByUserId(
  { userId }: { userId: TransactionsControllerGetTransactionsByUserIdPathParams['userId'] },
  params: TransactionsControllerGetTransactionsByUserIdQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransactionsControllerGetTransactionsByUserIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTransactionsControllerGetTransactionsByUserIdUrl({ userId }).toString(),
    params,
    ...requestConfig,
  })
  return res
}