import React, { type ChangeEvent, useRef, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../global/components/LoadingButton';
import { useSnackbarStore } from '@/global/store/SnackbarStore';
import type { ModalSystemProps } from '@/global/providers/ModalProvider';
import { apiCreateCabinet } from '@/modules/cabinets/services/cabinet.service';
import type { AddCabinetDtoFormData } from '@/global/api/gen/ts/AddCabinetDtoFormData';

interface Props {
	id:number,
}

type AddCabinetDto = {
	file: Blob | null
} &  Omit<AddCabinetDtoFormData, 'file'>

function AddCabinetModal({open, close,id}: ModalSystemProps<Props>) {

	const {t} = useTranslation();

	const snackbar = useSnackbarStore((state) => state);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const [cabinetDto, setCabinetDto] = useState<AddCabinetDto>({
		school_id: `${id}`, address: '', type: 'osh', file: null
	});
	const {mutate, isPending} = apiCreateCabinet();

	const handleAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setCabinetDto((prev) => {
			return {
				...prev, address: e.target.value
			};
		});
	};

	const handleFileEvent = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0];

			const maxFileSize = 5 * 1024 * 1024;

			if (file.size > maxFileSize) {
				alert(t('school_page.cabinets_page.size-alert'));
				setCabinetDto(prevState => {
					prevState.file = null;
					return {...prevState};
				});
				if (fileInputRef.current) {
					fileInputRef.current.value = '';
				}
			} else {
				setCabinetDto(prevState => {
					prevState.file = file;
					return {...prevState};
				});
			}
		} else {
			setCabinetDto(prevState => {
				prevState.file = null;
				return {...prevState};
			});
		}
	};

	const finishAppeal = () => {
		if (!cabinetDto.file) {
			snackbar.errorMessage("Прикрепите фотографию");
			return;
		}
		mutate(cabinetDto as AddCabinetDtoFormData, {
			onSuccess: () => {
				snackbar.successMessage(t('school_page.cabinets_page.success'));
			}
		});
	};

	return (
		<Dialog
			open={open}
			onClose={() => close(5)}
			fullWidth={true}
			maxWidth={'md'}
		>
			<DialogTitle>
				{t('school_page.cabinets_page.add-title')}
			</DialogTitle>
			<DialogContent>
				<TextField
					name={'address'}
					value={cabinetDto.address}
					onChange={handleAddress}
					label={t('school_page.cabinets_page.address')}
					sx={{width: '100%', my: 2}}
				/>
				<input
					ref={fileInputRef}
					type={'file'}
					onChange={handleFileEvent}
				/>
				<LoadingButton
					variant={'contained'}
					onClick={finishAppeal}
					loading={isPending}
				>
					{t('school_page.cabinets_page.send')}
				</LoadingButton>
			</DialogContent>
		</Dialog>
	);
}

export default AddCabinetModal;
