import React from 'react';
import {
	Badge, Box, CircularProgress, Divider, IconButton, List, ListItem, Popover, Stack, Tooltip, Typography
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@/global/store/UserStore';
import { apiGetNewNotificationList, apiMarkNotification } from '@/modules/notifications/services/notifications.service';

function Notifications() {
    const navigate = useNavigate();
    const currentUser = useUserStore((state) => state);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const {data, isLoading} = apiGetNewNotificationList(+(currentUser.id ?? 0), {limit:100, offset:0});
	const {mutate} = apiMarkNotification();

    const open = !!(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationMark = (notificationId: number) => {
        mutate({
            user_id: Number(currentUser.id),
            notification_id: notificationId
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton
                color="inherit"
                onClick={handleClick}
            >
                <Badge
                    badgeContent={data?.total ?? 0}
                    color="error"
                >
                    <NotificationsIcon />
                </Badge>
            </IconButton>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <List>
                    {data && data.items.map((notification) => (
                        <ListItem
                            key={notification.id}
                            onClick={() => {handleNotificationMark(+(notification.id));}}
                            sx={{ cursor: 'pointer' }}
                        >
                            <Tooltip title={'Кликните, чтобы отметить как прочитанное'}>
                                <Stack gap={1}>
                                    <Typography variant={'h6'}>
                                        {notification.title}
                                    </Typography>
                                    <Typography>
                                        {notification.text}
                                    </Typography>
                                    <Divider />
                                </Stack>
                            </Tooltip>
                        </ListItem>
                ))}
                    {(data && data.items.length === 0) && (
                    <ListItem key={'no-new-notifications'}>
                        <Typography>
                            Нет новых уведомлений
                        </Typography>
                    </ListItem>
                )}
                    {!isLoading && (
                    <ListItem
                        key={'all-notifications'}
                        onClick={() => {navigate('/notifications');}}
                        sx={{ cursor: 'pointer' }}
                    >
                        <Stack>
                            <Typography>
                                <b>
                                    Перейти ко всем уведомлениям
                                </b>
                            </Typography>
                        </Stack>
                    </ListItem>
)}
                    {(!data || isLoading) && (
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <CircularProgress />
                    </Stack>
                )}
                </List>
            </Popover>
        </Box>
    );
}

export default React.memo(Notifications);
