import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerGetStudentsByIinForQuickFeeQueryResponse,
  StudentsControllerGetStudentsByIinForQuickFeePathParams,
  StudentsControllerGetStudentsByIinForQuickFeeQueryParams,
} from '../../ts/StudentsDTO/StudentsControllerGetStudentsByIinForQuickFee'

export function getStudentsControllerGetStudentsByIinForQuickFeeUrl({ iin }: { iin: StudentsControllerGetStudentsByIinForQuickFeePathParams['iin'] }) {
  return `/students/quick-fee/${iin}` as const
}

/**
 * @summary Получение информации о студентах по IIN без оплаты
 * {@link /students/quick-fee/:iin}
 */
export async function studentsControllerGetStudentsByIinForQuickFee(
  { iin }: { iin: StudentsControllerGetStudentsByIinForQuickFeePathParams['iin'] },
  params: StudentsControllerGetStudentsByIinForQuickFeeQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerGetStudentsByIinForQuickFeeQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerGetStudentsByIinForQuickFeeUrl({ iin }).toString(),
    params,
    ...requestConfig,
  })
  return res
}