import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerChangeSchoolInfoMutationRequest,
  SchoolsControllerChangeSchoolInfoMutationResponse,
} from '../../ts/schoolsDTO/SchoolsControllerChangeSchoolInfo'

export function getSchoolsControllerChangeSchoolInfoUrl() {
  return `/schools/change-school-info` as const
}

/**
 * @summary Изменение данных автошколы
 * {@link /schools/change-school-info}
 */
export async function schoolsControllerChangeSchoolInfo(
  data: SchoolsControllerChangeSchoolInfoMutationRequest,
  config: Partial<RequestConfig<SchoolsControllerChangeSchoolInfoMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerChangeSchoolInfoMutationResponse, ResponseErrorConfig<Error>, SchoolsControllerChangeSchoolInfoMutationRequest>({
    method: 'POST',
    url: getSchoolsControllerChangeSchoolInfoUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}