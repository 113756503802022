import {Stack, Typography} from "@mui/material";
import type {ReactNode} from "react";
import type {StackOwnProps} from "@mui/material/Stack/Stack";

type Props = {
    label: ReactNode,
    value: ReactNode,
} & StackOwnProps

export function StudentIntegrationField({sx, label, value, ...props}: Props) {
    return (
        <Stack
            flexDirection={'row'}
            alignItems={'center'}
            gap={1}
            sx={{
                width: '100%',
                borderRadius: '8px',
                padding: 2,
                backgroundColor: 'rgb(245,245,245)',
                ...sx
            }}
            {...props}
        >
            <Typography
                fontWeight={'600'}
                fontSize={13}
                flexGrow={1}
            >
                {label}
            </Typography>
            <Typography
                component={'div'}
                whiteSpace={'nowrap'}
                fontSize={13}
            >
                {value}
            </Typography>
        </Stack>
    );
}