import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { StudentsControllerGetStudentInfoByIinMutationResponse } from '../../ts/StudentsDTO/StudentsControllerGetStudentInfoByIin'

export function getStudentsControllerGetStudentInfoByIinUrl() {
  return `/students/soap` as const
}

/**
 * @summary Получение информации о студенте
 * {@link /students/soap}
 */
export async function studentsControllerGetStudentInfoByIin(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerGetStudentInfoByIinMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getStudentsControllerGetStudentInfoByIinUrl().toString(),
    ...requestConfig,
  })
  return res
}