import React, { useMemo, useState } from 'react';
import { Box, InputBase, Stack, Tab, Tabs, Typography } from '@mui/material';
import '../styles/AdminPage.scss';
import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '@/global/helpers/globalstyles';
import { CreatedAppeals } from '@/modules/appeals/components/CreatedAppeals';
import { ApprovedAppeals } from '@/modules/appeals/components/ApprovedAppeals';
import { ClosedAppeals } from '@/modules/appeals/components/ClosedAppeals';
import { DeniedAppeals } from '@/modules/appeals/components/DeniedAppeals';
import { useModal } from '@/global/providers/ModalProvider';
import { AppealModal } from '@/modules/appeals/modals/AppealModal';

export enum AppealStatus {
	Created = 1, Approved = 2, Closed = 3, Denied = 10
}

interface TabInterface {
	id: number,
	label: string,
	element: JSX.Element,
}

function AdminPage() {
	const [tab, setTab] = useState<AppealStatus>(AppealStatus.Created);
	const [filter, setFilter] = useState<string>('');
	const {openModal} = useModal();

	const onRowClick = (appealId: number) => {
		openModal(AppealModal, {appealId}, `appeal_${appealId}` );
	};

	const tabs: Record<AppealStatus, TabInterface> = useMemo(() => (
		{
			1: {id: 1, label: `Новые`, element: <CreatedAppeals openAppeal={onRowClick}/>},
			2: {id: 2, label: `Ожидание подтверждения`, element: <ApprovedAppeals openAppeal={onRowClick}/>},
			3: {id: 3, label: `Зарегистрированные`, element: <ClosedAppeals openAppeal={onRowClick}/>},
			10: {id: 10, label: `Отклонено`, element: <DeniedAppeals openAppeal={onRowClick}/>}
		}
	), []);

	return (
		<Box
			className="App"
			sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
		>
			<PageHeader/>
			<Box sx={wrapperSx}>
				<Stack
					sx={{mb: 2}}
					flexDirection={'column'}
					justifyContent={'center'}
					alignItems={'flex-start'}
				>
					<Typography
						sx={{mt: 3}}
						variant={'h5'}
					>
						<b>
							Список заявок
						</b>
					</Typography>
				</Stack>

				<InputBase
					type={'text'}
					onChange={(e) => {setFilter(e.target.value);}}
					value={filter}
					placeholder={'Поиск'}
					sx={{mt: 1}}
				/>

				<Tabs
					value={tab}
					onChange={(e, newValue) => setTab(newValue)}
					variant="scrollable"
					scrollButtons={false}
					sx={{ml: 2}}
				>
					{Object.values(tabs).map(tab => (
						<Tab
							label={tab.label}
							key={tab.id}
							value={tab.id}
						/>
						))}
				</Tabs>
				{tabs[tab].element}
			</Box>
		</Box>
	);
}

export default AdminPage;