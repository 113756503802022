import {type ControlledFieldProps, TextFieldForm} from "@/global/components/FormInputs";
import type {FieldValues} from "react-hook-form";

export function StyledInput<T extends FieldValues>({sx, ...props}: ControlledFieldProps<T>) {
    return (
        <TextFieldForm
            {...props}
            sx={{
                borderRadius: '10px',
                '.MuiOutlinedInput-root': {
                    backgroundColor: '#ebf0fb',
                },
                '.MuiOutlinedInput-input': {
                    color: '#05304F',
                    padding: '18px',
                    fontSize: '16px',
                    lineHeight: '24px',
                },
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent'
                },
                ...sx
            }}
        />
    );
}

