import { registerAppealsControllerApproveAppeal } from './registerAppealsControllerApproveAppeal'
import { registerAppealsControllerChangeEmailInAppeal } from './registerAppealsControllerChangeEmailInAppeal'
import { registerAppealsControllerCheckAppealById } from './registerAppealsControllerCheckAppealById'
import { registerAppealsControllerDeleteAppealById } from './registerAppealsControllerDeleteAppealById'
import { registerAppealsControllerDeleteSchoolAndAllRelated } from './registerAppealsControllerDeleteSchoolAndAllRelated'
import { registerAppealsControllerDeleteSchoolAndUser } from './registerAppealsControllerDeleteSchoolAndUser'
import { registerAppealsControllerDenyAppeal } from './registerAppealsControllerDenyAppeal'
import { registerAppealsControllerGetAppealById } from './registerAppealsControllerGetAppealById'
import { registerAppealsControllerGetAppeals } from './registerAppealsControllerGetAppeals'
import { registerAppealsControllerGetAppealsWithStatusApproved } from './registerAppealsControllerGetAppealsWithStatusApproved'
import { registerAppealsControllerGetAppealsWithStatusClosed } from './registerAppealsControllerGetAppealsWithStatusClosed'
import { registerAppealsControllerGetAppealsWithStatusCreated } from './registerAppealsControllerGetAppealsWithStatusCreated'
import { registerAppealsControllerGetAppealsWithStatusDenied } from './registerAppealsControllerGetAppealsWithStatusDenied'
import { registerAppealsControllerResendAppealToMail } from './registerAppealsControllerResendAppealToMail'

export const registerAppealsService = {
    registerAppealsControllerGetAppeals,
    registerAppealsControllerGetAppealsWithStatusCreated,
    registerAppealsControllerGetAppealsWithStatusClosed,
    registerAppealsControllerGetAppealsWithStatusApproved,
    registerAppealsControllerGetAppealsWithStatusDenied,
    registerAppealsControllerGetAppealById,
    registerAppealsControllerCheckAppealById,
    registerAppealsControllerApproveAppeal,
    registerAppealsControllerResendAppealToMail,
    registerAppealsControllerDenyAppeal,
    registerAppealsControllerChangeEmailInAppeal,
    registerAppealsControllerDeleteSchoolAndAllRelated,
    registerAppealsControllerDeleteSchoolAndUser,
    registerAppealsControllerDeleteAppealById,
  }