import {Avatar, Button, Stack, Typography} from "@mui/material";
import {DirectionsCar} from "@mui/icons-material";
import {MiniCategoriesGrid, MiniSubCategoriesGrid} from "@/School/components/CategoriesGrid";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {apiGetSchoolPublic, apiGetSchoolsAttributes} from "@/modules/schools/services/school.service";
import {ManagerMainAttributesInfo} from "@/modules/students/components/ManagerMainAttributesInfo";

export function ManagerMainSchoolInfo() {
    const navigate = useNavigate();

    const {t} = useTranslation();

    const {data: dataSchools} = apiGetSchoolPublic({
        limit: 100,
        offset: 0
    });

    const [currentSchoolIndex, setCurrentSchoolIndex] = useState(0);

    const currentSchool = dataSchools?.items[currentSchoolIndex] ?? null;

    const schoolsList = dataSchools?.items ?? [];

    const {
        data: schoolAttributes,
        isLoading: schoolAttributesLoading
    } = apiGetSchoolsAttributes(currentSchool?.id ?? 0);

    const handleChangeSchoolIndex = (type: string) => {
        if (type === 'next') {
            if (schoolsList?.length === currentSchoolIndex + 1) {
                setCurrentSchoolIndex(0);
            } else {
                setCurrentSchoolIndex((prev) => prev + 1);
            }
        }

        if (type === 'previous') {
            if (currentSchoolIndex === 0) {
                setCurrentSchoolIndex(schoolsList?.length ? schoolsList?.length - 1 : 0);
            } else {
                setCurrentSchoolIndex((prev) => prev - 1);
            }
        }
    };

    return (
        <Stack
            flexDirection={'column'}
            sx={{
                width: {xs: 'auto', lg: '873px'},
                height: {xs: 'auto', lg: '499px'},
                p: {xs: '10px', lg: '40px'},
                background: '#fff',
                borderRadius: '20px'
            }}
        >
            <Stack
                flexDirection={{xs: 'column', lg: 'row'}}
                gap={'41px'}
            >
                <Stack
                    flexDirection={'row'}
                    sx={{
                        width: {xs: 'auto', lg: '387px'},
                        minHeight: '147px',
                        background: '#f4f7fe',
                        borderRadius: '20px',
                        p: '24px 16px 24px 24px'
                    }}
                >
                    <Stack
                        flexDirection={'column'}
                    >
                        <Typography
                            fontSize={'16px'}
                            lineHeight={'100%'}
                            color={'#1e9af3'}
                        >
                            ID
                            {' '}
                            {currentSchool?.id}
                        </Typography>

                        <Stack
                            flexDirection={'column'}
                            sx={{
                                mt: 'auto'
                            }}
                            gap={'10px'}
                        >
                            <Typography
                                fontSize={'16px'}
                                lineHeight={'100%'}
                                color={'rgba(5,48,79,0.6)'}
                            >
                                {t('main-pages.manager.organization')}
                            </Typography>
                            <Typography
                                fontSize={'22px'}
                                lineHeight={'100%'}
                                color={'#05304f'}
                            >
                                {currentSchool?.title}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        flexDirection={'column'}
                        sx={{
                            ml: 'auto'
                        }}
                    >
                        <Avatar
                            sx={{color: '#1E9AF3', bgcolor: '#fff', mt: '-9px', width: 58, height: 58}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                    </Stack>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    gap={'31px'}
                    sx={{
                        mt: '6px'
                    }}
                >
                    <Stack
                        flexDirection={'column'}
                        gap={'10px'}
                    >
                        <MiniCategoriesGrid
                            categories={currentSchool?.categories ?? []}
                        />
                    </Stack>

                    <Stack
                        flexDirection={'column'}
                        gap={'10px'}
                    >
                        <MiniSubCategoriesGrid
                            categories={currentSchool?.categories ?? []}
                        />
                    </Stack>
                </Stack>
            </Stack>

            <ManagerMainAttributesInfo
                handleChangeSchoolIndex={handleChangeSchoolIndex}
                schoolAttributes={schoolAttributes}
                schoolAttributesLoading={schoolAttributesLoading}
            />

            <Button
                variant={'outlined'}
                sx={{
                    mt: '32px',
                    color: '#000',
                    textTransform: 'none'
                }}
                disabled={!currentSchool?.id}
                onClick={() => {
                    navigate(`/schools/${currentSchool?.id}`);
                }}
            >
                {t('main-pages.manager.details')}
            </Button>
        </Stack>
    );
}