import React, {useMemo, useState} from 'react';
import {Avatar, Box, Button, Stack, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {type GridColDef} from '@mui/x-data-grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {useSchoolStore} from '../store/SchoolStore';
import {useUserStore} from '@/global/store/UserStore';
import {getName} from '@/global/helpers/text';
import RegisterWorkerDialog from '../components/RegisterWorkerDialog';
import AddWorkerDialog from '../components/AddWorkerDialog';
import {apiGetSchoolWorkers} from "@/modules/users/services/user.service";
import {ServerDataGrid} from "@/global/components/ServerDataGrid";
import type {UserDto} from "@/global/api/gen/ts/UserDto";

function SchoolWorkers() {

    const {id} = useParams();
    const schoolInfo = useSchoolStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const userStore = useUserStore((state) => state);
    const [registerDialogOpen, setRegisterDialogOpen] = useState<boolean>(false);
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25, page: 0
    });

    const {data: workersList, isLoading: workersLoading} = apiGetSchoolWorkers(+(id ?? 0), {
        offset: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize,
    });

    const columns: GridColDef<UserDto>[] = useMemo(() => [
        {field: 'empty', headerName: '', headerClassName: 'datagrid--header', maxWidth: 10, width: 10, sortable: false},
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {
            field: 'fio', headerName: 'ФИО', minWidth: 360, flex: 1, sortable: true,
            renderCell: ({row}) => {
                const name = getName(row);
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {field: 'email', headerName: 'Электронный адрес', minWidth: 250},
        {
            field: 'role',
            headerName: 'Должность',
            minWidth: 250,
            renderCell: ({row}) => row.roles?.find((el)=> el.role === 'manager') ? 'Главный специалист' : 'Специалист'
        },
    ], []);

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>

            <Box sx={wrapperSx}>
                <Stack
                    flexDirection={'column'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    sx={{mb: 2}}
                >
                    <Typography
                        sx={{mt: 3}}
                        variant={'h5'}
                    >
                        <b>
                            Сотрудники учебной организации (пользователи)
                        </b>
                    </Typography>
                    <Typography variant="subtitle1">
                        Здесь отображаются все специалисты и сотрудники учебной организации
                        {' '}
                        <i>
                            <b>
                                {schoolInfo.title}
                            </b>
                        </i>
                    </Typography>
                    {!userStore.isObserver && !isAdmin && !registerDialogOpen && (
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                setRegisterDialogOpen(true);
                            }}
                            sx={{mt: '27px'}}
                        >
                            Регистрация нового специалиста
                            {' '}
                            <KeyboardArrowDownIcon style={{marginLeft: '3rem'}}/>
                        </Button>
                    )}

                    {registerDialogOpen && (
                        <RegisterWorkerDialog
                            schoolId={+(id ?? 0)}
                            onClose={() => setRegisterDialogOpen(false)}
                        />
                    )}

                    {!userStore.isObserver && !isAdmin && !addDialogOpen && (
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                setAddDialogOpen(true);
                            }}
                            sx={{mt: '27px'}}
                        >
                            Добавить существующего пользователя
                            {' '}
                            <KeyboardArrowDownIcon style={{marginLeft: '3rem'}}/>
                        </Button>
                    )}

                    {addDialogOpen && (
                        <AddWorkerDialog
                            schoolId={+(id ?? 0)}
                            onClose={() => setAddDialogOpen(false)}
                        />
                    )}

                </Stack>

                <ServerDataGrid
                    columns={columns}
                    rows={workersList?.items ?? []}
                    rowCount={workersList?.total ?? 0}
                    loading={workersLoading}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    autoHeight={true}
                    disableRowSelectionOnClick={true}
                    style={{margin: '0'}}
                />
            </Box>
        </Box>
    );
}

export default SchoolWorkers;