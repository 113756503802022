import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerGetPracticeHoursInfoByGroupIdQueryResponse,
  GroupsControllerGetPracticeHoursInfoByGroupIdPathParams,
  GroupsControllerGetPracticeHoursInfoByGroupIdQueryParams,
} from '../../ts/GroupsDTO/GroupsControllerGetPracticeHoursInfoByGroupId'

export function getGroupsControllerGetPracticeHoursInfoByGroupIdUrl({
  groupId,
}: {
  groupId: GroupsControllerGetPracticeHoursInfoByGroupIdPathParams['groupId']
}) {
  return `/groups/practice-hours/${groupId}` as const
}

/**
 * @summary Получение student section по groupId
 * {@link /groups/practice-hours/:groupId}
 */
export async function groupsControllerGetPracticeHoursInfoByGroupId(
  { groupId }: { groupId: GroupsControllerGetPracticeHoursInfoByGroupIdPathParams['groupId'] },
  params: GroupsControllerGetPracticeHoursInfoByGroupIdQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerGetPracticeHoursInfoByGroupIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerGetPracticeHoursInfoByGroupIdUrl({ groupId }).toString(),
    params,
    ...requestConfig,
  })
  return res
}