import React, {useMemo} from 'react';
import {DataGrid, type GridColDef, type GridRenderCellParams} from '@mui/x-data-grid';
import {Avatar, Box, Button, Chip, Stack} from '@mui/material';
import {Person} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {formatDateTime, getName} from '@/global/helpers/text';
import {useUserStore} from '@/global/store/UserStore';
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import {useModal} from "@/global/providers/ModalProvider";
import CopyStudentUserInfoModal from "@/Group/components/GroupPage/CopyStudentUserInfoModal";
import {GridButtons} from "@/Group/components/GroupPage/GridButton";
import {CreateStudentUserModal} from "@/Group/components/GroupPage/CreateStudentUserModal";
import {apiGetFeatureEnabled} from "@/modules/feature-toggles/services/feature-toggles.service";

interface StudentsGridProps {
    groupInfo: GroupDataDto;
}

function StudentsGrid({groupInfo}: StudentsGridProps) {

    // TODO: добавить обновление corrections после подачи заявки

    const {t} = useTranslation();

    const userStore = useUserStore((state) => state);

    const {openModal} = useModal();

    const {data: attachmentModuleInfoResponse} = apiGetFeatureEnabled('paid_student_attachments');

    const handleCreateUserOpen = (row: StudentDto) => {
        openModal(CreateStudentUserModal, {student: row}, `create_${row.id}`);
    };

    const handleCopyStudentUserInfoOpen = (row: StudentDto) => {
        openModal(CopyStudentUserInfoModal, {student: row}, `delete_${row.id}`);
    };

    const columns: GridColDef<StudentDto>[] = useMemo(() => [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
            renderCell: (params: GridRenderCellParams) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name', headerName: t('school_page.group_page.students_grid.fio'), minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'iin',
            headerName: t('school_page.group_page.students_grid.iin'),
            width: 150,
            sortable: false,
        },
        {
            field: 'user',
            headerName: t('school_page.group_page.students_grid.personal_cabinet'),
            width: 250,
            sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);

                if (row.user_id) {
                    return (
                        <Stack
                            flexDirection={'column'}
                            onClick={() => userStore.isObserver ? toast.error('Просмотр данных личного кабинета курсанта доступен только менеджеру автошколы') : handleCopyStudentUserInfoOpen(row)}
                        >
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                            >
                                <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                >
                                    <Person/>
                                </Avatar>
                                <p>
                                    {name.fullName}
                                </p>
                                <p style={{color: 'gray', margin: '0 0 0 5px'}}>
                                    #
                                    {row.user_id}
                                </p>
                            </Stack>
                        </Stack>
                    );
                } else {
                    return (
                        <Button
                            variant={'contained'}
                            sx={{
                                height: 'auto'
                            }}
                            onClick={() => handleCreateUserOpen(row)}
                            disabled={userStore.isObserver}
                        >
                            {t('school_page.group_page.students_grid.create_personal_cabinet')}
                        </Button>
                    );
                }
            }
        },
        {
            field: 'medical_certificate_number',
            headerName: t('school_page.group_page.students_grid.medical_certificate_number'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_title',
            headerName: t('school_page.group_page.students_grid.medical_center_title'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_bin',
            headerName: t('school_page.group_page.students_grid.medical_center_bin'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_examination_date',
            headerName: t('school_page.group_page.students_grid.medical_examination_date'),
            width: 300,
            sortable: false,
        },
        {
            field: 'created_at',
            headerName: t('school_page.group_page.students_grid.created_at'),
            width: 180,
            sortable: true,
            valueGetter: ({row}) => formatDateTime(row.created_at)
        },
        {
            field: 'actions',
            headerName: t('school_page.group_page.students_grid.actions'),
            width: 500,
            sortable: false,
            renderCell: ({row}) => (
                <GridButtons
                    row={row}
                    status={groupInfo.status}
                />
            )
        }
    ], []);

    const columnsWithPaidAttachment: GridColDef<StudentDto>[] = useMemo(() => [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
            renderCell: (params) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name', headerName: t('school_page.group_page.students_grid.fio'), minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'iin',
            headerName: t('school_page.group_page.students_grid.iin'),
            width: 150,
            sortable: false,
        },
        {
            field: 'student.payment_access.paid',
            headerName: 'Пошлина',
            width: 200,
            sortable: false,
            renderCell: ({row}) => (
                row?.payment_access?.paid
                    ? (
                        <Chip
                            label={'Оплачена'}
                            color={'success'}
                        />
                    )
                    : (
                        <Chip
                            label={'Не оплачена'}
                            color={'error'}
                        />
                    )
            ),
        },
        {
            field: 'user',
            headerName: t('school_page.group_page.students_grid.personal_cabinet'),
            width: 250,
            sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);

                if (row.user_id) {
                    return (
                        <Stack
                            flexDirection={'column'}
                            onClick={() => userStore.isObserver ? toast.error('Просмотр данных личного кабинета курсанта доступен только менеджеру автошколы') : handleCopyStudentUserInfoOpen(row)}
                        >
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                            >
                                <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                >
                                    <Person/>
                                </Avatar>
                                <p>
                                    {name.fullName}
                                </p>
                                <p style={{color: 'gray', margin: '0 0 0 5px'}}>
                                    #
                                    {row.user_id}
                                </p>
                            </Stack>
                        </Stack>
                    );
                } else {
                    return (
                        <Button
                            variant={'contained'}
                            sx={{
                                height: 'auto'
                            }}
                            onClick={() => handleCreateUserOpen(row)}
                            disabled={userStore.isObserver}
                        >
                            {t('school_page.group_page.students_grid.create_personal_cabinet')}
                        </Button>
                    );
                }
            }
        },
        {
            field: 'medical_certificate_number',
            headerName: t('school_page.group_page.students_grid.medical_certificate_number'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_title',
            headerName: t('school_page.group_page.students_grid.medical_center_title'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_center_bin',
            headerName: t('school_page.group_page.students_grid.medical_center_bin'),
            width: 200,
            sortable: false,
        },
        {
            field: 'medical_examination_date',
            headerName: t('school_page.group_page.students_grid.medical_examination_date'),
            width: 300,
            sortable: false,
        },
        {
            field: 'created_at',
            headerName: t('school_page.group_page.students_grid.created_at'),
            width: 180,
            sortable: true,
            valueGetter: ({row}) => formatDateTime(row.created_at)
        },
        {
            field: 'actions',
            headerName: t('school_page.group_page.students_grid.actions'),
            width: 500,
            sortable: false,
            renderCell: ({row}) => (
                <GridButtons
                    row={row}
                    status={groupInfo.status}
                />
            )
        }
    ], []);

    return (
        <Box>
            <DataGrid
                columns={(attachmentModuleInfoResponse && attachmentModuleInfoResponse.is_enabled) ? columnsWithPaidAttachment : columns}
                rows={groupInfo.students ?? []}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30,
                        },
                    },
                }}
                pageSizeOptions={[30]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
            />
        </Box>
    );
}

export default StudentsGrid;