import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerChangeEmailInAppealMutationRequest,
  RegisterAppealsControllerChangeEmailInAppealMutationResponse,
  RegisterAppealsControllerChangeEmailInAppealPathParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerChangeEmailInAppeal'

export function getRegisterAppealsControllerChangeEmailInAppealUrl({ id }: { id: RegisterAppealsControllerChangeEmailInAppealPathParams['id'] }) {
  return `/register-appeals/change-email/${id}` as const
}

/**
 * @summary Изменить email в заявке на регистрацию по id
 * {@link /register-appeals/change-email/:id}
 */
export async function registerAppealsControllerChangeEmailInAppeal(
  { id }: { id: RegisterAppealsControllerChangeEmailInAppealPathParams['id'] },
  data: RegisterAppealsControllerChangeEmailInAppealMutationRequest,
  config: Partial<RequestConfig<RegisterAppealsControllerChangeEmailInAppealMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    RegisterAppealsControllerChangeEmailInAppealMutationResponse,
    ResponseErrorConfig<Error>,
    RegisterAppealsControllerChangeEmailInAppealMutationRequest
  >({ method: 'POST', url: getRegisterAppealsControllerChangeEmailInAppealUrl({ id }).toString(), data, ...requestConfig })
  return res
}