import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TicketControllerGetMessageHistoryQueryResponse,
  TicketControllerGetMessageHistoryPathParams,
} from '../../ts/TicketsDTO/TicketControllerGetMessageHistory'

export function getTicketControllerGetMessageHistoryUrl({ id }: { id: TicketControllerGetMessageHistoryPathParams['id'] }) {
  return `/tickets/history/${id}` as const
}

/**
 * @summary История сообщений в тикете
 * {@link /tickets/history/:id}
 */
export async function ticketControllerGetMessageHistory(
  { id }: { id: TicketControllerGetMessageHistoryPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TicketControllerGetMessageHistoryQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTicketControllerGetMessageHistoryUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}