import React, {useState} from 'react';
import {Box, Button, CircularProgress, Stack, TextField, Typography} from '@mui/material';

import SchoolInfoBlock from './SchoolInfoBlock';
import {apiGetSchoolByBin} from "@/modules/schools/services/school.service";

function SearchByBINPage() {

    const [binValue, setBinValue] = useState<string>('');

    const {data: searchResults, isLoading: searchResultsLoading, refetch: executeSearch} = apiGetSchoolByBin(binValue, {
        limit: 100,
        offset: 0,
    }, {enabled: false});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setBinValue(e.target.value);
    };

    const getStudents = async () => {
        await executeSearch();
    };

    return (
        <Box>
            <Typography
                sx={{mt: 3}}
                variant={'h5'}
            >
                <b>
                    Поиск автошкол по БИН/ИИН
                </b>
            </Typography>
            <Typography variant={'subtitle2'}>
                На данной странице возможно произвести поиск автошкол по БИН (юр. лица) или ИИН (физ. лица)
            </Typography>
            <Stack
                flexDirection={'row'}
                gap={2}
                sx={{my: 2}}
            >
                <TextField
                    name={'bin'}
                    onChange={handleChange}
                    value={binValue}
                    placeholder={'БИН/ИИН'}
                />
                <Button
                    variant={'contained'}
                    onClick={getStudents}
                >
                    Поиск
                </Button>
            </Stack>

            <Stack flexDirection={'column'}>
                {!searchResults && !searchResultsLoading && (
                    <Typography variant={'h6'}>
                        Нет результатов
                    </Typography>
                )}
                {searchResults && !searchResults.items.length && !searchResultsLoading && (
                    <Typography variant={'h6'}>
                        По данному БИН/ИИН отсутствует информация
                    </Typography>
                )}
                {searchResultsLoading && (
                    <CircularProgress/>
                )}
                {searchResults && searchResults.items.map((school, index) => {
                    return (
                        <SchoolInfoBlock
                            key={school.id}
                            schoolInfo={school}
                            index={index}
                        />
                    );
                })}
            </Stack>

        </Box>
    );
}

export default SearchByBINPage;