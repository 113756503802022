import { CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { useId, useMemo } from 'react';
import { apiStatisticUsersByCategory } from '../services/statistic.service';
import Chart from 'react-apexcharts';
import { PieChart } from '../types/statistic.type';

export function PieChartByCategory() {
	const id = useId();
	const theme = useTheme();
	const {data, isLoading} = apiStatisticUsersByCategory();

	const chart = useMemo(() => (
		PieChart(theme, id, data)
	), [data, theme, id]);

	return (
		<Paper
			sx={{
				position: 'relative',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				width: '100%',
				padding: 1
			}}
		>
			<Typography
				variant="h4"
				sx={{mb: 0.5}}
			>
				Сдавших студентов по категориям
			</Typography>
			{isLoading && <CircularProgress/>}
			<Chart
				options={chart}
				series={chart.series}
				type="pie"
			/>
		</Paper>
	);
}