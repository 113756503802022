import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerFindStudentsFromAisByIinHiddenQueryResponse,
  StudentsControllerFindStudentsFromAisByIinHiddenPathParams,
} from '../../ts/StudentsDTO/StudentsControllerFindStudentsFromAisByIinHidden'

export function getStudentsControllerFindStudentsFromAisByIinHiddenUrl({ iin }: { iin: StudentsControllerFindStudentsFromAisByIinHiddenPathParams['iin'] }) {
  return `/students/integration/hidden/${iin}` as const
}

/**
 * @summary Получение студентов по IIN из АИС для админов
 * {@link /students/integration/hidden/:iin}
 */
export async function studentsControllerFindStudentsFromAisByIinHidden(
  { iin }: { iin: StudentsControllerFindStudentsFromAisByIinHiddenPathParams['iin'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerFindStudentsFromAisByIinHiddenQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerFindStudentsFromAisByIinHiddenUrl({ iin }).toString(),
    ...requestConfig,
  })
  return res
}