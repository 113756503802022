import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerCreateMasterMutationRequest,
  EmployeeControllerCreateMasterMutationResponse,
} from '../../ts/employeesDTO/EmployeeControllerCreateMaster'

export function getEmployeeControllerCreateMasterUrl() {
  return `/employees/create/master` as const
}

/**
 * @summary Создание мастера производственного обучения
 * {@link /employees/create/master}
 */
export async function employeeControllerCreateMaster(
  data: EmployeeControllerCreateMasterMutationRequest,
  config: Partial<RequestConfig<EmployeeControllerCreateMasterMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerCreateMasterMutationResponse, ResponseErrorConfig<Error>, EmployeeControllerCreateMasterMutationRequest>({
    method: 'POST',
    url: getEmployeeControllerCreateMasterUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}