import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateApplicationControllerCreateCertificateApplicationForDeliveryMutationRequest,
  CertificateApplicationControllerCreateCertificateApplicationForDeliveryMutationResponse,
} from '../../ts/certificate-applicationDTO/CertificateApplicationControllerCreateCertificateApplicationForDelivery'

export function getCertificateApplicationControllerCreateCertificateApplicationForDeliveryUrl() {
  return `/certificate-application/internal` as const
}

/**
 * @summary Создание заявки на сертификат для доставки
 * {@link /certificate-application/internal}
 */
export async function certificateApplicationControllerCreateCertificateApplicationForDelivery(
  data: CertificateApplicationControllerCreateCertificateApplicationForDeliveryMutationRequest,
  config: Partial<RequestConfig<CertificateApplicationControllerCreateCertificateApplicationForDeliveryMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CertificateApplicationControllerCreateCertificateApplicationForDeliveryMutationResponse,
    ResponseErrorConfig<Error>,
    CertificateApplicationControllerCreateCertificateApplicationForDeliveryMutationRequest
  >({ method: 'POST', url: getCertificateApplicationControllerCreateCertificateApplicationForDeliveryUrl().toString(), data, ...requestConfig })
  return res
}