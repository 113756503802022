import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  UsersControllerAttachExistingWorkerToSchoolMutationRequest,
  UsersControllerAttachExistingWorkerToSchoolMutationResponse,
} from '../../ts/usersDTO/UsersControllerAttachExistingWorkerToSchool'

export function getUsersControllerAttachExistingWorkerToSchoolUrl() {
  return `/users/worker/attach` as const
}

/**
 * @summary Прикрепить работника к школе
 * {@link /users/worker/attach}
 */
export async function usersControllerAttachExistingWorkerToSchool(
  data: UsersControllerAttachExistingWorkerToSchoolMutationRequest,
  config: Partial<RequestConfig<UsersControllerAttachExistingWorkerToSchoolMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UsersControllerAttachExistingWorkerToSchoolMutationResponse,
    ResponseErrorConfig<Error>,
    UsersControllerAttachExistingWorkerToSchoolMutationRequest
  >({ method: 'POST', url: getUsersControllerAttachExistingWorkerToSchoolUrl().toString(), data, ...requestConfig })
  return res
}