import {Dialog, DialogContent, DialogTitle, Stack, TextField, Typography} from '@mui/material';
import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import type {ModalSystemProps} from "@/global/providers/ModalProvider";

interface ModalProps {
    student: StudentDto,
}

function CopyStudentUserInfoModal({open, close, student}: ModalSystemProps<ModalProps>) {

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={() => close(5)}
        >
            <DialogTitle>
                Данные личного кабинета пользователя
            </DialogTitle>
            <DialogContent>
                <Stack
                    flexDirection={'column'}
                    gap={'10px'}
                >
                    <Typography>
                        У данного студента есть личный кабинет пользователя на портале Avtomektep.KZ.
                    </Typography>
                    <Typography>
                        Для прохождения онлайн внутришкольных экзаменов - необходимо создать для каждого студента личный
                        кабинет на портале.
                    </Typography>
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            margin: '15px 0'
                        }}
                    >
                        <TextField
                            name={'last_name'}
                            value={student.last_name}
                            label={'Фамилия'}
                            disabled={true}
                        />
                        <TextField
                            name={'first_name'}
                            value={student.first_name}
                            label={'Имя'}
                            disabled={true}
                        />
                        <TextField
                            name={'patronymic'}
                            value={student.patronymic}
                            label={'Отчество'}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            name={'iin'}
                            value={student.iin}
                            label={'ИИН'}
                            disabled={true}
                        />
                        <TextField
                            name={'email'}
                            value={student.email}
                            label={'Электронная почта'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                        />
                        <TextField
                            name={'phone'}
                            value={student.phone}
                            label={'Номер телефона*'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                        />
                    </form>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default CopyStudentUserInfoModal;