import React from 'react';
import {Box, type Theme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {MainBanner} from "@/Landing/components/MainBanner";
import {FindStudentsBanner} from "@/Landing/components/FindStudentsBanner";
import {AboutSystemBanner} from "@/Landing/components/AboutSystemBanner";
import {AutomatizationBanner} from "@/Landing/components/AutomatizationBanner";
import {NewsSection} from "@/modules/news/components/NewsSection";
import {Footer} from "@/Landing/components/Footer";

function SchoolLandingPage() {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    return (
        <Box>
            <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{
                    py: isMobile ? '18px' : '27px',
                    px: isMobile ? '18px' : '20px',
                }}
                gap={'20px'}
            >
                <MainBanner/>
                <FindStudentsBanner/>
                <AboutSystemBanner/>
                <AutomatizationBanner/>
                <NewsSection/>
            </Box>
            <Footer/>
        </Box>
    );
}

export default SchoolLandingPage;