import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import {authService} from "@/global/api/gen";
import {UserKeys} from "@/modules/users/services/user.keys";
import type {UserCreateDto} from "@/global/api/gen/ts/UserCreateDto";

export const apiRegisterAsStudent = () => {
    return useCustomMutation({
        mutationFn: (data: UserCreateDto ) => authService.authControllerRegisterStudent(data)
            .then((res) => res.data),
        queryKey: UserKeys.all
    });
};