import { Box, FormControl, FormHelperText, MenuItem, Select, type SelectProps, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { CategoryDto } from '@/School/actions/category.type';
import { useSchoolStore } from '@/School/store/SchoolStore';
import { apiGetCategoryList } from '@/modules/categories/services/category.service';

type Props = {
	onChange: (value: CategoryValue) => void,
	value: CategoryValue,
	label?: string;
} & Omit<SelectProps, 'onChange'>

type CategoryValue = CategoryDto['id'] | null;

export default function CategorySelect({
										   onChange,
										   value,
										   label = 'Категория',
										   ...props
									   }: Props) {

	const {t} = useTranslation();

	const schoolInfo = useSchoolStore((state) => state);

	const {data} = apiGetCategoryList(schoolInfo.id ?? 0, {
		limit:999,
		offset:0
	});

	return (
		<FormControl
			fullWidth
			sx={{marginTop: '1rem'}}
		>
			<Select
				id={'new-group-select'}
				value={value || ''}
				onChange={(event) => {
					onChange(event.target.value as CategoryValue);
				}}
				{...props}
			>
				{data && data.items.length && data.items.map((category) => (
					<MenuItem
						value={category.category_id}
						key={category.category_id}
					>
						<Box>
							<Typography>
								{category.category_id}
							</Typography>
						</Box>
					</MenuItem>
				))}
			</Select>
			<FormHelperText>
				{t('school_page.group_list.category_select.required')}
			</FormHelperText>
		</FormControl>
	);
}