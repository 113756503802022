import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerGetSchoolFeatureMutationRequest,
  SchoolsControllerGetSchoolFeatureMutationResponse,
} from '../../ts/schoolsDTO/SchoolsControllerGetSchoolFeature'

export function getSchoolsControllerGetSchoolFeatureUrl() {
  return `/schools/feature-toggles` as const
}

/**
 * @summary Создание feature toggle для школы
 * {@link /schools/feature-toggles}
 */
export async function schoolsControllerGetSchoolFeature(
  data: SchoolsControllerGetSchoolFeatureMutationRequest,
  config: Partial<RequestConfig<SchoolsControllerGetSchoolFeatureMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGetSchoolFeatureMutationResponse, ResponseErrorConfig<Error>, SchoolsControllerGetSchoolFeatureMutationRequest>({
    method: 'POST',
    url: getSchoolsControllerGetSchoolFeatureUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}