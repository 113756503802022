import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputBase,
    Stack,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {ExpandMoreOutlined} from '@mui/icons-material';
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {apiGroupChangeDates} from "@/modules/groups/services/group.service";
import type {GroupChangeDatesDto} from "@/global/api/gen/ts/GroupChangeDatesDto";

interface AdminChangeDatesProps {
    groupInfo: GroupDataDto;
}

function AdminChangeDates({groupInfo}: AdminChangeDatesProps) {

    const [dto, setDto] = useState<GroupChangeDatesDto>({
        group_id: 500000,
        start_date: '',
        end_date: ''
    });

    const {mutate, isPending: loading} = apiGroupChangeDates();

    useEffect(() => {
        setDto((prev) => {
            return {
                ...prev,
                group_id: Number(groupInfo.id),
                start_date: groupInfo.start_date,
                end_date: groupInfo.end_date
            };
        });
    }, [groupInfo]);

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDto((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleSubmit = () => {
        mutate(dto);
    };

    return (
        <Accordion
            sx={{width: '100%'}}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
            >
                <Typography>
                    Изменить даты обучения группы
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={1}>
                    <FormControl>
                        <InputBase 
                            type='date'
                            value={dto.start_date}
                            name='start_date'
                            onChange={handleDateChange}
                        />
                        <FormHelperText>
                            Дата начала обучения
                        </FormHelperText>
                    </FormControl>
                    <FormControl>
                        <InputBase 
                            type='date'
                            name='end_date'
                            value={dto.end_date}
                            onChange={handleDateChange}
                        />
                        <FormHelperText>
                            Дата окончания обучения
                        </FormHelperText>
                    </FormControl>

                    {!loading && (
                    <Button
                        variant='contained'
                        onClick={() => {handleSubmit();}}
                    >
                        Сохранить
                    </Button>
)}
                    {loading && (
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <CircularProgress/>
                    </Stack>
)}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export default AdminChangeDates;