import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { SchoolsControllerGetSchoolByIdQueryResponse, SchoolsControllerGetSchoolByIdPathParams } from '../../ts/schoolsDTO/SchoolsControllerGetSchoolById'

export function getSchoolsControllerGetSchoolByIdUrl({ id }: { id: SchoolsControllerGetSchoolByIdPathParams['id'] }) {
  return `/schools/${id}` as const
}

/**
 * @summary Получение информации о школе по id
 * {@link /schools/:id}
 */
export async function schoolsControllerGetSchoolById(
  { id }: { id: SchoolsControllerGetSchoolByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGetSchoolByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerGetSchoolByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}