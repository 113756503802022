import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    CircularProgress,
    Stack,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {ExpandMoreOutlined} from '@mui/icons-material';

import CategorySelect from '../GroupsList/CategorySelect';
import type {CategoryDto} from '@/School/actions/category.type';
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {apiGroupChangeCategory} from "@/modules/groups/services/group.service";
import type {GroupChangeCategoryDto} from "@/global/api/gen/ts/GroupChangeCategoryDto";

interface AdminChangeCategoryProps {
    groupInfo: GroupDataDto;
}

type CategoryValue = CategoryDto['id'] | null

function AdminChangeCategory({groupInfo}: AdminChangeCategoryProps) {

    const [dto, setDto] = useState<GroupChangeCategoryDto>({
        group_id: 500000,
        category_id: groupInfo.category_id
    });

    const {mutate, isPending: loading} = apiGroupChangeCategory();

    useEffect(() => {
        setDto((prev) => {
            return {
                ...prev,
                group_id: groupInfo.id,
                category_id: groupInfo.category_id
            };
        });
    }, [groupInfo]);

    const handleCategory = (value: CategoryValue) => {
        setDto((prev) => {
            return {
                ...prev,
                category_id: value ?? ''
            };
        });
    };

    const handleSubmit = () => {
        mutate(dto);
    };

    return (
        <Accordion
            sx={{width: '100%'}}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined/>}
            >
                <Typography>
                    Изменить категорию
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={1}>

                    <Alert severity="info">
                        Внимание: при смене категории - дата окончания обучения группы будет изменена в соответствии с
                        новой категорией.
                    </Alert>

                    <CategorySelect
                        fullWidth
                        value={dto.category_id}
                        onChange={(value) => handleCategory(value)}
                    />

                    {!loading && (
                        <Button
                            variant='contained'
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Сохранить
                        </Button>
                    )}
                    {loading && (
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            <CircularProgress/>
                        </Stack>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export default AdminChangeCategory;