import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { NewsControllerGetNewsByIdQueryResponse, NewsControllerGetNewsByIdPathParams } from '../../ts/NewsDTO/NewsControllerGetNewsById'

export function getNewsControllerGetNewsByIdUrl({ newsId }: { newsId: NewsControllerGetNewsByIdPathParams['newsId'] }) {
  return `/news/${newsId}` as const
}

/**
 * @summary Получение новости по id
 * {@link /news/:newsId}
 */
export async function newsControllerGetNewsById(
  { newsId }: { newsId: NewsControllerGetNewsByIdPathParams['newsId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<NewsControllerGetNewsByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getNewsControllerGetNewsByIdUrl({ newsId }).toString(),
    ...requestConfig,
  })
  return res
}