import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { GroupsControllerGetGroupByIdQueryResponse, GroupsControllerGetGroupByIdPathParams } from '../../ts/GroupsDTO/GroupsControllerGetGroupById'

export function getGroupsControllerGetGroupByIdUrl({ id }: { id: GroupsControllerGetGroupByIdPathParams['id'] }) {
  return `/groups/${id}` as const
}

/**
 * @summary Получение группы по id
 * {@link /groups/:id}
 */
export async function groupsControllerGetGroupById(
  { id }: { id: GroupsControllerGetGroupByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerGetGroupByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerGetGroupByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}