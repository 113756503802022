import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import type {CertificateApplicationDto} from "@/global/api/gen/ts/CertificateApplicationDto";
import {Controller, type SubmitHandler, useForm} from "react-hook-form";
import {apiChangeCertificate} from "@/modules/certificates/services/certificates.service";
import {z} from "zod";
import {zodRequiredString} from "@/global/types";
import {TextFieldForm} from "@/global/components/FormInputs";
import {LoadingButton} from "@mui/lab";
import {toast} from "react-toastify";
import React from "react";
import dayjs from "dayjs";

interface Props {
    application: CertificateApplicationDto,
}

const zodSchema = z.object({
    school_title: zodRequiredString,
    school_bin: zodRequiredString,
    group_title: zodRequiredString,
    category: zodRequiredString,
    end_date: zodRequiredString,
    start_date: zodRequiredString,
    birthday: zodRequiredString,
    living_place: zodRequiredString,
    maintenance: zodRequiredString,
    rules: zodRequiredString,
    practice: zodRequiredString
});

type zodType = z.infer<typeof zodSchema>;

const Categories = ['A', 'A1', 'B1', 'B', 'C1', 'C', 'D1', 'D', 'BE', 'C1E', 'CE', 'DE', 'D1E', 'Tm', 'Tb'];

export function ChangeInfoApplicationModal({open, close, application}: ModalSystemProps<Props>) {
    const {control, handleSubmit} = useForm<zodType>({
        defaultValues: {
            ...application.certificate_data,
            end_date: dayjs(application.certificate_data.end_date).format('YYYY-MM-DD'),
            start_date: dayjs(application.certificate_data.start_date).format('YYYY-MM-DD'),
            living_place: application.certificate_data.living_place ?? '',
            birthday: application.certificate_data.birthday ?? ''
        }
    });

    const {mutate, isPending} = apiChangeCertificate();

    const onSubmit: SubmitHandler<zodType> = (data) => {
        mutate({
            ...data,
            group_status: application.certificate_data.group_status,
            certificate_application_id: application.id
        }, {
            onSuccess: () => {
                toast.success(`Информация о сертификате по заявке № ${application.id} изменена`);
                close(0);
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(5)}
        >
            <DialogTitle>
                Изменение информации о заявке №
                {' '}
                {application.id}
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack gap={2}>
                        <TextFieldForm
                            control={control}
                            name={'school_title'}
                            label={'Название школы'}
                        />
                        <TextFieldForm
                            control={control}
                            name={'school_bin'}
                            label={'БИН школы'}
                        />
                        <TextFieldForm
                            control={control}
                            name={'group_title'}
                            label={'Название группы'}
                        />
                        <Stack>
                            <Typography>
                                Категория
                            </Typography>
                            <Controller
                                control={control}
                                name={'category'}
                                render={({field: {value, onChange}}) => (
                                    <FormControl
                                        fullWidth
                                    >
                                        <Select
                                            id={'new-group-select'}
                                            value={value || ''}
                                            onChange={(event) => {
                                                onChange(event.target.value);
                                            }}
                                        >
                                            {Categories.map((category) => (
                                                <MenuItem
                                                    value={category}
                                                    key={category}
                                                >
                                                    <Box>
                                                        <Typography>
                                                            {category}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Stack>
                        <TextFieldForm
                            control={control}
                            name={'living_place'}
                            label={'Место жительства'}
                        />
                        <TextFieldForm
                            control={control}
                            name={'birthday'}
                            label={'День рождения'}
                            type={'date'}
                        />
                        <TextFieldForm
                            control={control}
                            name={'start_date'}
                            label={'Дата начала группы'}
                            type={'date'}
                        />
                        <TextFieldForm
                            control={control}
                            name={'end_date'}
                            label={'Дата окончания группы'}
                            type={'date'}
                        />
                        <TextFieldForm
                            control={control}
                            name={'maintenance'}
                            valuePreprocess={(e) => e.target.value.match(/\d/)?.[0] ?? ''}
                            label={'Оценка устройство и техническое обслуживание'}
                        />
                        <TextFieldForm
                            control={control}
                            name={'rules'}
                            valuePreprocess={(e) => e.target.value.match(/\d/)?.[0] ?? ''}
                            label={'Оценка за теорию'}
                        />
                        <TextFieldForm
                            control={control}
                            name={'practice'}
                            valuePreprocess={(e) => e.target.value.match(/\d/)?.[0] ?? ''}
                            label={'Оценка практическое вождение'}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack flexDirection={{sm: 'row', xs: 'column'}}>
                        <Button
                            color={'info'}
                            onClick={() => close(5)}
                            sx={{
                                minWidth: '150px'
                            }}
                        >
                            Закрыть
                        </Button>
                        <LoadingButton
                            loading={isPending}
                            type={'submit'}
                            variant={'contained'}
                        >
                            Изменить информацию о сертификате
                        </LoadingButton>
                    </Stack>
                </DialogActions>
            </form>
        </Dialog>
    );
}