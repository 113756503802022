import type { SxProps, Theme } from '@mui/material';

const testStyles: { [key: string]: SxProps<Theme> } = {
    questionsList: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        width: '100%',
        whiteSpace: 'nowrap',
        listStyleType: 'none',
        p: 0,
        mb: {xs: 0.3, sm: 3, md: 5},
        '::-webkit-scrollbar': {
            display: 'none'
        },
        '& .text': {
            fontSize: 14,
            fontWeight: 600,
            mt: 1,
        },
        '& li': {
            display: 'inline-block',
            cursor: 'default',
            width: 40,
            height: 40,
            border: 'none',
            borderRadius: 2.8,
            mr: 1.65,
            textAlign: 'center',
            lineHeight: 2.6,
            ':first-child': {
                ml: 2
            },
            '&.clickable': {
                cursor: 'pointer'
            },
            '&.active': {
                color: 'white',
                bgcolor: '#458EF8'
            },
            '&.question_disabled': {
                color: 'white',
                bgcolor: '#828282'
                // border: '2px solid #828282'
            },
            '&.question_fail': {
                color: 'white',
                bgcolor: '#CF5035'
                // border: '2px solid #ec5050'
            },
            '&.question_success': {
                color: 'white',
                bgcolor: '#1BA875'
                // border: '2px solid #4eb03f'
            }
        }
    },
    selectedQuestionItem: {
        color: 'white',
        bgcolor: '#2b53b3'
    },
    clickable: {
        cursor: 'pointer'
    },
    testContent: {
        minHeight: '60vh',
        '& .side': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        '& .right': {
            alignItems: 'center'
        },
        '& .question': {
            maxWidth: 900,
            flexDirection: {xs: 'column-reverse', sm: 'column'},
            '& .question_heading': {
                mb: 1, fontSize: {xs: 14, sm: 18}, display: {xs: 'none', sm: 'block'}
            },
            '& .question_title': {
                mt: {xs: 1.5, md: 0},
                mb: 0.7, mr: '16px', ml: '16px', fontSize: {xs: 20, sm: 25, md: 27},
                textAlign: {xs: 'left', sm: 'center'},
                width: {xs: 'calc(100% - 32px)', sm: 'auto'}
            }
        },
        '& .answers': {
            mt: {xs: 0, md: 2}, mb: 3, mr: '16px', ml: '16px',
            width: {xs: 'calc(100% - 32px)', sm: 'auto'}
        },
        '& .align_center': {},
        '& .explanation': {
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .collapsed': {
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .video': {
            position: 'relative',
            width: '100%',
            mt: 2,
            mb: 2,
            '& .safe_area': {
                position: 'absolute',
                display: 'block',
                opacity: 0,
                zIndex: 100,
                backgroundColor: 'white',
                margin: '0px',
                width: '100%',
                height: '100%'
            },
            '& .watermark': {
                position: 'absolute',
                zIndex: 80,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                '& *': {
                    color: 'rgba(121,164,239,0.4)',
                    fontWeight: 600
                },
                '& h1': {
                    fontSize: '3em',
                    lineHeight: '1.5em',
                    margin: '5px 0 5px 0',
                },
                '& p': {
                    fontSize: '2.5em',
                    lineHeight: '1em',
                    margin: 0,
                }
            },
            '@media screen and (min-width: 1px)': {
                '& .watermark': {
                    fontSize: '12px',
                }
            },
            '@media screen and (min-width: 600px)': {
                '& .watermark': {
                    fontSize: '15px',
                }
            },
            '@media screen and (min-width: 800px)': {
                '& .watermark': {
                    fontSize: '18px',
                }
            },
            '@media screen and (min-width: 1500px)': {
                '& .watermark': {
                    fontSize: '20px',
                }
            },
            '& .video_loader': {
                position: 'absolute',
                zIndex: 100,
                backgroundColor: 'white',
                margin: '-10px',
                width: '105%',
                height: '110%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            '& .progress': {
                width: '100%',
                height: 5,
                position: 'absolute',
                bottom: 3,
                '& .current_progress': {
                    width: 0,
                    height: '100%',
                    backgroundColor: '#458EF8',
                    transition: 'width .5s linear'
                }
            },
            '& #video_replay': {
                zIndex: 200,
                position: 'absolute',
                height: 40,
                width: 40,
                bottom: 20,
                right: 20
            }
        },
        '& video': {
            width: '100%'
            // maxWidth: '95vw'
            // width: '100%',
            // maxWidth: 600,
        }
    },
    answerButton: {}
};

export const testButtonSx: SxProps<Theme> = {
    background: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'row',
    p: 2,
    mb: 2,
    borderRadius: 5,
    cursor: 'pointer',
    width: '100%',
    maxWidth: '700px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
    userSelect: 'none',
    transitionProperty: 'box-shadow, transform',
    transition: '0.2s ease',
    '& .test_button_icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(151.09deg, #569BF5 6.29%, #4688E8 96.44%, #366AB7 96.44%)',
        borderRadius: '12px',
        mr: 2,
        width: 46,
        height: 46
    },
    '& .test_button_loading': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.54)',
        borderRadius: 5,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    '&:hover': {
        backgroundColor: '#fcfcff',
        boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 12px 24px -4px',
        transform: 'scale(1.01)'
    },
    '&:active': {
        backgroundColor: '#fcfcff',
        boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 5px 18px -4px',
        transform: 'scale(1.00)'
    }
};
export default testStyles;
