import React from 'react';
import Button, { type ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface LoadingButtonProps extends ButtonProps {
    loading?: boolean;
}

function LoadingButton ({ loading, children, disabled, ...props }: LoadingButtonProps) {
    return (
        <Button
            {...props}
            disabled={loading || disabled}
        >
            {loading ? (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                >
                    <CircularProgress size={24} />
                </Box>
            ) : (
                children
            )}
        </Button>
    );
}

export default LoadingButton;
