import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CabinetsControllerGetSchoolCabinetsQueryResponse,
  CabinetsControllerGetSchoolCabinetsPathParams,
  CabinetsControllerGetSchoolCabinetsQueryParams,
} from '../../ts/CabinetsDTO/CabinetsControllerGetSchoolCabinets'

export function getCabinetsControllerGetSchoolCabinetsUrl({ schoolId }: { schoolId: CabinetsControllerGetSchoolCabinetsPathParams['schoolId'] }) {
  return `/cabinets/school/${schoolId}` as const
}

/**
 * @summary Получить помещения школы
 * {@link /cabinets/school/:schoolId}
 */
export async function cabinetsControllerGetSchoolCabinets(
  { schoolId }: { schoolId: CabinetsControllerGetSchoolCabinetsPathParams['schoolId'] },
  params: CabinetsControllerGetSchoolCabinetsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CabinetsControllerGetSchoolCabinetsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCabinetsControllerGetSchoolCabinetsUrl({ schoolId }).toString(),
    params,
    ...requestConfig,
  })
  return res
}