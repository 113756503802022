import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { GroupsControllerGetAllGroupsQueryResponse, GroupsControllerGetAllGroupsQueryParams } from '../../ts/GroupsDTO/GroupsControllerGetAllGroups'

export function getGroupsControllerGetAllGroupsUrl() {
  return `/groups/all` as const
}

/**
 * @summary Получение всех групп
 * {@link /groups/all}
 */
export async function groupsControllerGetAllGroups(
  params: GroupsControllerGetAllGroupsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerGetAllGroupsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerGetAllGroupsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}