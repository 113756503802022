import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { RegionControllerGetRegionsQueryResponse, RegionControllerGetRegionsQueryParams } from '../../ts/RegionDTO/RegionControllerGetRegions'

export function getRegionControllerGetRegionsUrl() {
  return `/region` as const
}

/**
 * @summary Получить все регионы
 * {@link /region}
 */
export async function regionControllerGetRegions(
  params: RegionControllerGetRegionsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegionControllerGetRegionsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getRegionControllerGetRegionsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}