import {Stack, Typography} from '@mui/material';
import React from 'react';
import {apiStatisticOverview} from '../services/statistic.service';

export function OverviewInfo() {
    const {data} = apiStatisticOverview();

    return (
        <Stack
            gap={1}
            sx={{
                mb: 2
            }}
        >
            <Typography variant={'h4'}>
                Общая информация
            </Typography>
            <Typography>
                Всего пользователей -&nbsp;
                {data?.total_users}
                <br/>
                Всего автошкол -&nbsp;
                {data?.total_schools}
                <br/>
                Всего групп -&nbsp;
                {data?.total_groups}
                <br/>
                Всего сертификатов -&nbsp;
                {data?.total_certificates}
                <br/>
                Всего учатся -&nbsp;
                {data?.total_students}
                <br/>
                Всего закончили обучение -&nbsp;
                {data?.total_passed_students}
            </Typography>
        </Stack>
    );
}