import React, {type FormEvent, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {useParams} from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import PageHeader from '../../global/components/PageHeader';
import TransportTable from '../components/TransportTable';
import {useUserStore} from '@/global/store/UserStore';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {apiCreateTransport} from '@/modules/transports/services/transport.service';
import {LoadingButton} from '@mui/lab';
import type {
    TransportControllerCreateTransportMutationRequest
} from '@/global/api/gen/ts/TransportDTO/TransportControllerCreateTransport';
import {useSchoolStore} from '@/School/store/SchoolStore';

function TransportPage() {

    const {id} = useParams();

    const {t} = useTranslation();

	const schoolInfo = useSchoolStore((state) => state);
	const userStore = useUserStore((state) => state);

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [transportForm, setTransportForm] = useState<TransportControllerCreateTransportMutationRequest>({
        plate_number: '',
        description: '',
        school_id: +(id ?? 0)
    });
	const {mutate, isPending} = apiCreateTransport();

    const transportText = {
        title: t('school_page.transport_page.title'),
        subtitle: t('school_page.transport_page.subtitle'),
        button: t('school_page.transport_page.button'),
        newTransport: t('school_page.transport_page.new_transport')
    };

    const cleanForm = () => {
        setTransportForm({
            plate_number: '',
            description: '',
            school_id: +(id ?? 0)
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTransportForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleSelectChange = (e: SelectChangeEvent) => {
        setTransportForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
		if (transportForm.school_id === 0 ) {
			toast.error('Непредвиденная ошибка. Обновите страницу.');
		}
		mutate(transportForm, {
			onSuccess: () => {
				toast.success(t('school_page.transport_page.messages.car_added'));
				cleanForm();
			}
		});

    };

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Box>
                    <Stack
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                    >
                        <Typography
                            sx={{mt: 3}}
                            variant={'h5'}
                        >
                            <b>
                                {transportText.title}
                            </b>
                        </Typography>
                        <Typography variant="subtitle2">
                            {transportText.subtitle} 
                            <i>
                                <b>
                                    {schoolInfo.title}
                                </b>
                            </i>
                        </Typography>
                    </Stack>
                    {!userStore.isObserver && !dialogOpen && (
                        <Button
                            variant={'contained'}
                            onClick={() => {setDialogOpen(true);}}
                            sx={{mt: '27px'}}
                        >
                            {transportText.button} 
                            <KeyboardArrowDownIcon style={{marginLeft: '3rem'}}/>
                        </Button>
                    )}

                    {dialogOpen && (
                    <Box sx={{width: '773px', height: 'auto', p: '32px', background: '#fff', borderRadius: '16px', mt: '17px'}}>
                        <form
                            style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px'}}
                            onSubmit={handleSubmit}
                        >
                            <p
                                onClick={() => {setDialogOpen(false);}}
                            >
                                <Typography
                                    variant={'h5'}
                                    sx={{
                                        mb: '14px',
                                        alignItems: 'center',
                                        display: 'flex'
                                    }}
                                >
                                    {transportText.newTransport} 
                                    <KeyboardArrowUpIcon style={{marginLeft: 'auto'}}/>
                                </Typography>
                            </p>

                            <TextField
                                value={transportForm.plate_number}
                                onChange={handleInputChange}
                                name={'plate_number'}
                                label={t('school_page.transport_page.labels.plate_number')}
                                required={true}
                                helperText={t('school_page.transport_page.helpers.plate_number')}
                            />
                            <FormControl>
                                <InputLabel id={'description-label'}>
                                    {t('school_page.transport_page.labels.transmission')}
                                </InputLabel>
                                <Select
									variant={'outlined'}
                                    labelId={'description-label'}
                                    onChange={handleSelectChange}
                                    name={'description'}
                                    label={t('school_page.transport_page.labels.transmission')}
                                    value={transportForm.description ? transportForm.description : ''}
                                >
                                    <MenuItem
                                        value={'АКПП'}
                                    >
                                        АКПП
                                    </MenuItem>
                                    <MenuItem
                                        value={'МКПП'}
                                    >
                                        МКПП
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <LoadingButton
								loading={isPending}
                                variant={'contained'}
                                type={'submit'}
                                sx={{width: '227px', mt: '14px'}}
                            >
                                {t('school_page.transport_page.add')}
                            </LoadingButton>
                        </form>
                    </Box>
					)}
                </Box>
				{id && <TransportTable id={+(id)}/>}
            </Box>
        </Box>
    );
}

export default TransportPage;