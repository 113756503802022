import type {GridColDef} from "@mui/x-data-grid";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Avatar, Stack, Switch, Tooltip, Typography} from "@mui/material";
import {DirectionsCar} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {ServerDataGrid} from "@/global/components/ServerDataGrid";
import {useTranslation} from "react-i18next";
import {apiGetSchoolListFeatureToggles} from "@/modules/schools/services/school.service";
import type {SchoolDto} from "@/global/api/gen/ts/SchoolDto";

export function SchoolsFeatureTogglesTable() {
    const {t} = useTranslation();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25, page: 0
    });

    const {data, isLoading} = apiGetSchoolListFeatureToggles({
        offset: paginationModel.page * paginationModel.pageSize, limit: paginationModel.pageSize
    });

    const columns: GridColDef<SchoolDto>[] = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'datagrid--header',
            width: 90,
            sortable: true,
        },
        {
            field: 'bin',
            headerName: t('register.school_list_component.grid.bin'),
            headerClassName: 'datagrid--header',
            width: 200,
            sortable: false,
        },
        {
            field: 'title',
            headerName: t('register.school_list_component.grid.title'),
            headerClassName: 'datagrid--header',
            width: 400,
            sortable: false,
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        <Link
                            to={`/schools/${params.row.id}`}
                            style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Avatar
                                sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
                            >
                                <DirectionsCar fontSize="small"/>
                            </Avatar>
                            {params.value}
                        </Link>
                    </React.Fragment>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Информация о модулях',
            width: 350,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Stack
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        sx={{
                            width: '100%',
                            py: 1
                        }}
                        gap={1}
                    >
                        {(!params.row.features || params.row.features?.length === 0)&& (
                            <Typography fontSize={'14px'}>
                                Нет данных
                            </Typography>
                        )}
                        {params.row.features && params.row.features.length > 0 && params.row.features?.map((feature) => (
                            <Stack
                                key={feature.id}
                                flexDirection={'column'}
                                gap={1}
                                alignItems={'center'}
                            >
                                <Typography fontSize={'14px'}>
                                    {feature.is_enabled}
                                </Typography>
                                <Stack
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                >
                                    <Typography fontSize={'14px'}>
                                        Откл.
                                    </Typography>
                                    <Switch
                                        checked={feature.is_enabled}
                                    />
                                    <Typography fontSize={'14px'}>
                                        Вкл.
                                    </Typography>
                                </Stack>
                            </Stack>
                        ))}
                        <Tooltip
                            title={'Для включения/выключения модуля для определенной школы необходимо перейти на страницу автошколы'}
                            sx={{ml: 2}}
                            color={'gray'}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Stack>
                );
            }
        }
    ];

    return (
        <ServerDataGrid
            autoHeight={true}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rows={data?.items ?? []}
            columns={columns}
            loading={isLoading}
            rowCount={data?.total ?? 0}
            disableRowSelectionOnClick
            disableColumnMenu={true}
            disableColumnFilter={true}
        />
    );

}