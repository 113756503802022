import axios, { type AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

import { CONSTANTS } from '../constants';
import { apiConfigAuthorized, apiConfigRefreshToken, BACKEND_URL, FRONTEND_URL } from '../actions/apiVariables';
import { useUserStore } from '../store/UserStore';

export type ResponseErrorConfig<TError = unknown> = AxiosError<TError>;

export type RequestConfig<TData = unknown> = {
    url?: string;
    method: 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE';
    params?: unknown;
    data?: TData | FormData;
    responseType?: | 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
    signal?: AbortSignal;
    headers?: AxiosRequestConfig['headers'];
};

export type ResponseConfig<TData = unknown> = {
    data: TData; status: number; statusText: string; headers?: AxiosResponse['headers'];
};

export const axiosInstance = axios.create({
    withCredentials: false,
    baseURL: CONSTANTS.BACKEND_URL,
    timeout: 30000,
    paramsSerializer: {
        indexes: null
    }
});

axiosInstance.interceptors.request.use((request) => {
    if (Cookies.get('accessToken')) {
        const accessToken = Cookies.get('accessToken');
        request.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return request;
});

axiosInstance.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    if ('response' in error && error.response.status === 401) {
        try {
            const newTokens = await refreshAccessToken();
            originalRequest.headers['Authorization'] = 'Bearer ' + newTokens.accessToken;
            return axiosInstance(originalRequest);
        } catch (e) {
            logoutToAuth();
        }
    }
    return Promise.reject(error);
});

export async function refreshAccessToken(isBadRequest = false) {
    const refreshToken = Cookies.get('refreshToken');

    if (!refreshToken) {
        useUserStore.getState().logOut();
        window.location.assign(`${FRONTEND_URL}/auth`);
    }

    try {
        const response = await axios.get(`${BACKEND_URL}/auth/refresh`, apiConfigRefreshToken());
        setTokens(response.data);
        return response.data;
    } catch (e) {
        if (isBadRequest) {
            logoutToAuth();
        } else {
            logoutToAuth();
        }
    }
}

export const setTokens = ({ accessToken, refreshToken }: any) => {
    Cookies.set('accessToken', accessToken);
    Cookies.set('refreshToken', refreshToken);
};

export const clearTokens = () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
};

export const logoutFromSystem = () => {
    axiosInstance.post(`${BACKEND_URL}/auth/logout`, {}, apiConfigAuthorized())
        .then(() => {
            logoutToAuth();
        })
        .catch(() => {
            logoutToAuth();
        })
        .finally(() => {
            clearTokens();
        });
};

export const logoutToAuth = () => {
    useUserStore.getState().logOut();
    window.location.replace(`${FRONTEND_URL}/auth`);
};

export const axiosClient = async <TData, TError = unknown, TVariables = unknown>(config: RequestConfig<TVariables>): Promise<ResponseConfig<TData>> => {
    return axiosInstance
        .request<TVariables, ResponseConfig<TData>>({ ...config })
        .catch((e: AxiosError<TError>) => {
            throw e;
        });
};

export default axiosClient;
