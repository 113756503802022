import React, { useMemo, useState } from 'react';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { type GridColDef } from '@mui/x-data-grid';

import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '@/global/helpers/globalstyles';
import { useSchoolStore } from '../store/SchoolStore';
import { useUserStore } from '@/global/store/UserStore';
import { getName } from '@/global/helpers/text';
import { apiGetKursantList } from '@/modules/students/services/student.service';
import { ServerDataGrid } from '@/global/components/ServerDataGrid';
import type { StudentDto } from '@/global/api/gen/ts/StudentDto';

function SchoolKursants() {

	const {id} = useParams();
	const navigate = useNavigate();

	const schoolInfo = useSchoolStore((state) => state);
	const userStore = useUserStore((state) => state);

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25, page: 0
	});

	const {data, isLoading} = apiGetKursantList(+(id ?? 0),{
		offset: paginationModel.page * paginationModel.pageSize, limit: paginationModel.pageSize
	});

	const columns: GridColDef<StudentDto>[] = useMemo(() => [{
		field: 'empty',
		headerName: '',
		headerClassName: 'datagrid--header',
		maxWidth: 10,
		width: 10,
		sortable: false
	}, {field: 'id', headerName: 'ID', width: 110, sortable: true}, {
		field: 'fio', headerName: 'ФИО', width: 360, sortable: true, renderCell: ({row}) => {
			const name = getName(row);
			return (
				<React.Fragment>
					<Avatar
						sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
					>
						{name.partialName}
					</Avatar>
					{name.fullName}
				</React.Fragment>
			);
		}
	}, {
		field: 'group.title', headerName: 'Учебная группа', width: 300, sortable: false, renderCell: ({row}) => {
			if (!row.group) {
				return "Не указана группа";
			}

			return (
				<React.Fragment>
					<Link
						to={`/groups/${row.group.id}`}
						style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
						target="_blank"
						rel="noopener noreferrer"
					>
						{row.group.title}
					</Link>
				</React.Fragment>
			);
		}
	}, {
		field: 'actions', headerName: 'Действия', width: 300, sortable: false, renderCell: ({row}) => {
			return (
				<Button
					onClick={() => {navigate(`/schools/${id}/kursants/${row.id}`);}}
					sx={{height: 'auto'}}
					disabled={userStore.isObserver}
				>
					Перейти к документации
				</Button>
			);
		}
	}], []);

	return (
		<Box
			className="App"
			sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
		>
			<PageHeader/>

			<Box sx={{...wrapperSx}}>
				<Stack
					flexDirection={'column'}
					justifyContent={'flex-start'}
					alignItems={'flex-start'}
					sx={{mb: 2}}
				>
					<Typography
						sx={{mt: 3}}
						variant={'h5'}
					>
						<b>
							Курсанты учебной организации (студенты)
						</b>
					</Typography>
					<Typography variant="subtitle1">
						Здесь отображаются все курсанты учебной организации
						<i>
							<b>
								{schoolInfo.title}
							</b>
						</i>
					</Typography>
				</Stack>

				<ServerDataGrid
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					columns={columns}
					rows={data?.items ?? []}
					loading={isLoading}
					autoHeight={true}
					pageSizeOptions={[20]}
					disableRowSelectionOnClick={true}
					style={{margin: '0'}}
				/>
			</Box>
		</Box>
	);
}

export default SchoolKursants;