import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerCreateDriverMutationRequest,
  EmployeeControllerCreateDriverMutationResponse,
} from '../../ts/employeesDTO/EmployeeControllerCreateDriver'

export function getEmployeeControllerCreateDriverUrl() {
  return `/employees/create/driver` as const
}

/**
 * @summary Создание водителя
 * {@link /employees/create/driver}
 */
export async function employeeControllerCreateDriver(
  data: EmployeeControllerCreateDriverMutationRequest,
  config: Partial<RequestConfig<EmployeeControllerCreateDriverMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerCreateDriverMutationResponse, ResponseErrorConfig<Error>, EmployeeControllerCreateDriverMutationRequest>({
    method: 'POST',
    url: getEmployeeControllerCreateDriverUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}