import { MEDIA_URL } from '@/global/actions/apiVariables';
import axiosInstance from '../../global/actions/main.api';

export async function uploadMediaFile(file: File, onUploadProgress: (percent: number) => void) {
    const formData = new FormData();
    formData.append('media', file);
    return await axiosInstance.post(`${MEDIA_URL}/upload`, formData, {
        onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.total;

            // If totalLength is not provided by the event, it's not possible to compute progress
            if (!totalLength) {
                console.log('Total upload length is not computable.');
                return;
            }

            // Calculate the progress as an integer percentage
            const currentProgress = Math.round((progressEvent.loaded * 100) / totalLength);
            console.log('Progress', currentProgress);

            // Assuming onUploadProgress callback is expecting a number for the percentage
            onUploadProgress(currentProgress);
        }
    });
}

export async function getMediaUploadStatus(id: any) {
    return await axiosInstance.get(`${MEDIA_URL}/check/${id}`);
}
export async function getMedia(id: any) {
    return await axiosInstance.get(`${MEDIA_URL}/get/${id}`);
}