import {useCustomQuery} from '@/global/hooks/useCustomQuery';
import type {AxiosError} from 'axios';
import {schoolsService} from '@/global/api/gen';
import type {SchoolSearchResponseDto} from '@/global/api/gen/ts/SchoolSearchResponseDto';
import type {
	SchoolsControllerGetAdminSchoolsQueryParams
} from '@/global/api/gen/ts/schoolsDTO/SchoolsControllerGetAdminSchools';
import type {SchoolSearchAdminResponseDto} from '@/global/api/gen/ts/SchoolSearchAdminResponseDto';
import type {
	SchoolsControllerGetUserSchoolsQueryParams
} from '@/global/api/gen/ts/schoolsDTO/SchoolsControllerGetUserSchools';
import {SchoolKeys} from '@/modules/schools/services/school.keys';
import type {SchoolAttributesCountDto} from "@/global/api/gen/ts/SchoolAttributesCountDto";
import type {
	SchoolsControllerGetSchoolsWithFeatureTogglesQueryParams
} from "@/global/api/gen/ts/schoolsDTO/SchoolsControllerGetSchoolsWithFeatureToggles";
import type {
	SchoolsControllerGetSchoolInfoByBinQueryParams
} from "@/global/api/gen/ts/schoolsDTO/SchoolsControllerGetSchoolInfoByBin";
import type {OmmitedUseQueryOptions} from "@/global/types";
import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import type {SchoolUpdateDto} from "@/global/api/gen/ts/SchoolUpdateDto";
import type {ChangeSchoolFeatureEnableDto} from "@/global/api/gen/ts/ChangeSchoolFeatureEnableDto";
import type {AttachNewSchoolDto} from "@/global/api/gen/ts/AttachNewSchoolDto";
import type {SchoolChangeInfoUpdateDto} from "@/global/api/gen/ts/SchoolChangeInfoUpdateDto";
import type {
	SchoolsControllerGetSchoolsByUserIdQueryParams
} from "@/global/api/gen/ts/schoolsDTO/SchoolsControllerGetSchoolsByUserId";

export const apiGetSchoolList = (params: SchoolsControllerGetAdminSchoolsQueryParams) => {
	return useCustomQuery<SchoolSearchAdminResponseDto, AxiosError>({
		queryFn: () => schoolsService.schoolsControllerGetAdminSchools(params)
			.then((res) => res.data), queryKey: SchoolKeys.schoolAdmin(params)
	});
};

export const apiGetSchoolPublic  = (params: SchoolsControllerGetUserSchoolsQueryParams) => {
	return useCustomQuery<SchoolSearchResponseDto, AxiosError>({
		queryFn: () => schoolsService.schoolsControllerGetUserSchools(params)
			.then((res) => res.data), queryKey:  SchoolKeys.schoolPublic(params)
	});
};

export const apiGetSchoolsAttributes = (id:number) => {
	return useCustomQuery<SchoolAttributesCountDto, AxiosError>({
		enabled:!!id,
		queryFn: () => schoolsService.schoolsControllerGetSchoolAttributesCount({id})
			.then((res) => res.data),
		queryKey:  SchoolKeys.attributes(id)
	});
};

export const apiGetSchoolById = (id:number) => {
	return useCustomQuery({
		enabled: !!id && !isNaN(id),
		queryFn: () => schoolsService.schoolsControllerGetSchoolById({id})
			.then((res) => res.data),
		queryKey: SchoolKeys.detail(id)
	});
};

export const apiGetSchoolListFeatureToggles = (params:SchoolsControllerGetSchoolsWithFeatureTogglesQueryParams) => {
	return useCustomQuery({
		queryFn: () => schoolsService.schoolsControllerGetSchoolsWithFeatureToggles(params)
			.then((res) => res.data),
		queryKey: SchoolKeys.featureToggles(params)
	});
};

export const apiChangeFeatureToggleSchool = () => {
	return useCustomMutation({
		mutationFn: (data:ChangeSchoolFeatureEnableDto) => schoolsService.schoolsControllerGetSchoolFeature(data)
			.then((res) => res.data),
		queryKey: () => SchoolKeys.all,
	});
};

export const apiGetSchoolByBin = (bin:string, params:SchoolsControllerGetSchoolInfoByBinQueryParams, option?: OmmitedUseQueryOptions<SchoolSearchResponseDto>) => {
	return useCustomQuery({
		queryFn: () => schoolsService.schoolsControllerGetSchoolInfoByBin({bin}, params)
			.then((res) => res.data),
		queryKey: SchoolKeys.detailByBin(bin, params),
		...option
	});
};

export const apiUpdateSchoolByAdmin = () => {
	return useCustomMutation({
		mutationFn: (data:SchoolUpdateDto) => schoolsService.schoolsControllerChangeSchoolByAdmin(data)
			.then((res) => res.data),
		queryKey: () => SchoolKeys.all,
	});
};

export const apiUpdateSchool = () => {
	return useCustomMutation({
		mutationFn: (data:SchoolChangeInfoUpdateDto) => schoolsService.schoolsControllerChangeSchoolInfo(data)
			.then((res) => res.data),
		queryKey: () => SchoolKeys.all,
	});
};

export const apiAttachSchoolToUser = () => {
	return useCustomMutation({
		mutationFn: (data:AttachNewSchoolDto) => schoolsService.schoolsControllerAddSchoolToUser(data)
			.then((res) => res.data),
		queryKey: () => SchoolKeys.all,
	});
};

export const apiSchoolsByUser = (id:number, params: SchoolsControllerGetSchoolsByUserIdQueryParams, option?: OmmitedUseQueryOptions<SchoolSearchResponseDto>) => {
	return useCustomQuery({
		queryFn: () => schoolsService.schoolsControllerGetSchoolsByUserId({id}, params)
			.then((res) => res.data),
		queryKey: SchoolKeys.byUser(id, params),
		...option
	});
};

