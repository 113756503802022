import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegionControllerGetLocalitiesByRegionIdQueryResponse,
  RegionControllerGetLocalitiesByRegionIdPathParams,
  RegionControllerGetLocalitiesByRegionIdQueryParams,
} from '../../ts/RegionDTO/RegionControllerGetLocalitiesByRegionId'

export function getRegionControllerGetLocalitiesByRegionIdUrl({ id }: { id: RegionControllerGetLocalitiesByRegionIdPathParams['id'] }) {
  return `/region/${id}` as const
}

/**
 * @summary Получить все locality
 * {@link /region/:id}
 */
export async function regionControllerGetLocalitiesByRegionId(
  { id }: { id: RegionControllerGetLocalitiesByRegionIdPathParams['id'] },
  params: RegionControllerGetLocalitiesByRegionIdQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegionControllerGetLocalitiesByRegionIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getRegionControllerGetLocalitiesByRegionIdUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}