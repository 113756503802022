import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  NotificationsControllerGetNewUserNotificationsQueryResponse,
  NotificationsControllerGetNewUserNotificationsPathParams,
  NotificationsControllerGetNewUserNotificationsQueryParams,
} from '../../ts/NotificationsDTO/NotificationsControllerGetNewUserNotifications'

export function getNotificationsControllerGetNewUserNotificationsUrl({ id }: { id: NotificationsControllerGetNewUserNotificationsPathParams['id'] }) {
  return `/notifications/unmarked/${id}` as const
}

/**
 * @summary Получение новых уведомлений
 * {@link /notifications/unmarked/:id}
 */
export async function notificationsControllerGetNewUserNotifications(
  { id }: { id: NotificationsControllerGetNewUserNotificationsPathParams['id'] },
  params: NotificationsControllerGetNewUserNotificationsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<NotificationsControllerGetNewUserNotificationsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getNotificationsControllerGetNewUserNotificationsUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}