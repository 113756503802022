import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerChangeSchoolByAdminMutationRequest,
  SchoolsControllerChangeSchoolByAdminMutationResponse,
} from '../../ts/schoolsDTO/SchoolsControllerChangeSchoolByAdmin'

export function getSchoolsControllerChangeSchoolByAdminUrl() {
  return `/schools/update-school` as const
}

/**
 * {@link /schools/update-school}
 */
export async function schoolsControllerChangeSchoolByAdmin(
  data: SchoolsControllerChangeSchoolByAdminMutationRequest,
  config: Partial<RequestConfig<SchoolsControllerChangeSchoolByAdminMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    SchoolsControllerChangeSchoolByAdminMutationResponse,
    ResponseErrorConfig<Error>,
    SchoolsControllerChangeSchoolByAdminMutationRequest
  >({ method: 'POST', url: getSchoolsControllerChangeSchoolByAdminUrl().toString(), data, ...requestConfig })
  return res
}