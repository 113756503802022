import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerAttachDriverToGroupMutationRequest,
  GroupsControllerAttachDriverToGroupMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerAttachDriverToGroup'

export function getGroupsControllerAttachDriverToGroupUrl() {
  return `/groups/attach/driver` as const
}

/**
 * @summary Добавление водителя в группу
 * {@link /groups/attach/driver}
 */
export async function groupsControllerAttachDriverToGroup(
  data: GroupsControllerAttachDriverToGroupMutationRequest,
  config: Partial<RequestConfig<GroupsControllerAttachDriverToGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerAttachDriverToGroupMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerAttachDriverToGroupMutationRequest
  >({ method: 'POST', url: getGroupsControllerAttachDriverToGroupUrl().toString(), data, ...requestConfig })
  return res
}