import {useState} from 'react';

interface Options {
    pageSize: number,
    page: number
}

export const usePagination = (props?: Options) => {
    const { page = 0, pageSize = 25 } = props || {};
    const [paginationModel, setPaginationModel] = useState({
        pageSize,
        page
    });

    return {
        api: {
            limit: paginationModel.pageSize,
            offset: paginationModel.page * paginationModel.pageSize
        },
        grid: {
            paginationModel,
            onPaginationModelChange: setPaginationModel
        }
    };
};