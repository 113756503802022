import type { ModalSystemProps } from '@/global/providers/ModalProvider';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import LoadingButton from '@/global/components/LoadingButton';
import React, { useState } from 'react';
import type { StudentDocSaveBodyDto } from '@/global/api/gen/ts/StudentDocSaveBodyDto';
import type { StudentDto } from '@/global/api/gen/ts/StudentDto';
import { useSnackbarStore } from '@/global/store/SnackbarStore';
import { apiUpdateKursantDocs } from '@/modules/students/services/student.service';

interface Props {
	kursantInfo: StudentDto;
}

export function KursantInfoForm({open, close, kursantInfo}: ModalSystemProps<Props>) {
	const snackbar = useSnackbarStore((state) => state);

	const {mutate, isPending} = apiUpdateKursantDocs();

	const [docsInfo, setDocsInfo] = useState<StudentDocSaveBodyDto>({
		date_of_birth: '',
		polyclinic: '',
		doc_number: '',
		identification_number: '',
		identification_date_of_issue: '',
		price: '',
		study_period: 0,
		initial_number: ''
	});

	const handleDocsInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = e.target.value;
		const name = e.target.name;

		setDocsInfo((prev) => {
			return {
				...prev, [name]: value
			};
		});
	};

	const handleSubmit = () => {

		if (!docsInfo.date_of_birth || !docsInfo.polyclinic || !docsInfo.doc_number || !docsInfo.identification_number || !docsInfo.identification_date_of_issue || !docsInfo.price || !docsInfo.study_period || !docsInfo.initial_number) {
			snackbar.errorMessage('Необходимо заполнить все дополнительные данные');
			return;
		}

		mutate({
			...docsInfo,
			studentId: +(
				kursantInfo.id
			)
		}, {
			onSuccess: () => {
				close(0);
			}
		});
	};

	return (
		<Dialog
			open={open}
			fullWidth
			onClose={() => close(5)}
		>
			<DialogTitle>
				Внесение дополнительных данных о курсанте
				{kursantInfo.last_name}
				{kursantInfo.first_name}
			</DialogTitle>
			<DialogContent>
				<Stack gap={2}>
					<Stack gap={2}>
						<Typography variant={'h6'}>
							Данные для экзаменационной карточки
						</Typography>
						<TextField
							onChange={handleDocsInput}
							value={docsInfo.date_of_birth}
							InputLabelProps={{shrink: true}}
							name={'date_of_birth'}
							type={'date'}
							label={'Дата рождения'}
						/>
						<TextField
							onChange={handleDocsInput}
							value={docsInfo.polyclinic}
							InputLabelProps={{shrink: true}}
							name={'polyclinic'}
							type={'text'}
							label={'Название мед.центра'}
						/>
						<TextField
							onChange={handleDocsInput}
							value={docsInfo.doc_number}
							InputLabelProps={{shrink: true}}
							name={'doc_number'}
							type={'text'}
							label={'Номер документа'}
						/>
					</Stack>

					<Stack gap={2}>
						<Typography variant={'h6'}>
							Данные для договора
						</Typography>
						<TextField
							onChange={handleDocsInput}
							value={docsInfo.identification_number}
							InputLabelProps={{shrink: true}}
							name={'identification_number'}
							type={'text'}
							label={'Номер удостоверения личности'}
						/>
						<TextField
							onChange={handleDocsInput}
							value={docsInfo.identification_date_of_issue}
							InputLabelProps={{shrink: true}}
							name={'identification_date_of_issue'}
							type={'date'}
							label={'Дата выдачи удостоверения личности'}
						/>
						<TextField
							onChange={handleDocsInput}
							value={docsInfo.price}
							InputLabelProps={{shrink: true}}
							name={'price'}
							type={'text'}
							label={'Сумма договора'}
						/>
						<TextField
							onChange={handleDocsInput}
							value={docsInfo.study_period}
							InputLabelProps={{shrink: true}}
							name={'study_period'}
							type={'number'}
							label={'Период обучения (в днях)'}
						/>
					</Stack>

					<Stack gap={2}>
						<Typography variant={'h6'}>
							Данные для подтверждения об обучении
						</Typography>
						<TextField
							onChange={handleDocsInput}
							value={docsInfo.initial_number}
							InputLabelProps={{shrink: true}}
							name={'initial_number'}
							type={'text'}
							label={'Номер документа'}
						/>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					variant={'contained'}
					color={'error'}
					onClick={() => close(5)}
					loading={isPending}
				>
					Отмена
				</LoadingButton>

				<LoadingButton
					variant={'contained'}
					color={'primary'}
					onClick={() => handleSubmit()}
					loading={isPending}
				>
					Сохранить данные
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}