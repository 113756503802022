import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerGetAllStudentsQueryResponse,
  StudentsControllerGetAllStudentsQueryParams,
} from '../../ts/StudentsDTO/StudentsControllerGetAllStudents'

export function getStudentsControllerGetAllStudentsUrl() {
  return `/students` as const
}

/**
 * @summary Получение всех студентов
 * {@link /students}
 */
export async function studentsControllerGetAllStudents(
  params: StudentsControllerGetAllStudentsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerGetAllStudentsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerGetAllStudentsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}