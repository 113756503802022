import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerGetAppealsWithStatusClosedQueryResponse,
  RegisterAppealsControllerGetAppealsWithStatusClosedQueryParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerGetAppealsWithStatusClosed'

export function getRegisterAppealsControllerGetAppealsWithStatusClosedUrl() {
  return `/register-appeals/closed` as const
}

/**
 * @summary Получить все заявки на регистрацию со статусом closed
 * {@link /register-appeals/closed}
 */
export async function registerAppealsControllerGetAppealsWithStatusClosed(
  params: RegisterAppealsControllerGetAppealsWithStatusClosedQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerGetAppealsWithStatusClosedQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getRegisterAppealsControllerGetAppealsWithStatusClosedUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}