import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerAddSchoolToUserMutationRequest,
  SchoolsControllerAddSchoolToUserMutationResponse,
} from '../../ts/schoolsDTO/SchoolsControllerAddSchoolToUser'

export function getSchoolsControllerAddSchoolToUserUrl() {
  return `/schools/new-school` as const
}

/**
 * {@link /schools/new-school}
 */
export async function schoolsControllerAddSchoolToUser(
  data: SchoolsControllerAddSchoolToUserMutationRequest,
  config: Partial<RequestConfig<SchoolsControllerAddSchoolToUserMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerAddSchoolToUserMutationResponse, ResponseErrorConfig<Error>, SchoolsControllerAddSchoolToUserMutationRequest>({
    method: 'POST',
    url: getSchoolsControllerAddSchoolToUserUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}