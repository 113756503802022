import {useCustomQuery} from "@/global/hooks/useCustomQuery";
import {ticketsService} from "@/global/api/gen";
import type {
    TicketControllerGetTicketsByUserIdQueryParams
} from "@/global/api/gen/ts/TicketsDTO/TicketControllerGetTicketsByUserId";
import {TicketsKeys} from "@/modules/tickets/services/tickets.keys";
import type {
    TicketControllerGetAllTicketsQueryParams
} from "@/global/api/gen/ts/TicketsDTO/TicketControllerGetAllTickets";
import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import type {TicketMessageSendDto} from "@/global/api/gen/ts/TicketMessageSendDto";
import type {TicketCreateDto} from "@/global/api/gen/ts/TicketCreateDto";

export const apiGetMyTickets = (params: TicketControllerGetTicketsByUserIdQueryParams) => {
    return useCustomQuery({
        queryFn: () => ticketsService.ticketControllerGetTicketsByUserId(params)
            .then((res) => res.data),
        queryKey: TicketsKeys.myTickets(params)
    });
};

export const apiGetAllTickets = (params: TicketControllerGetAllTicketsQueryParams) => {
    return useCustomQuery({
        queryFn: () => ticketsService.ticketControllerGetAllTickets(params)
            .then((res) => res.data),
        queryKey: TicketsKeys.search(params)
    });
};

export const apiGetTicketInfo = (id: number) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => ticketsService.ticketControllerGetTicketById({id})
            .then((res) => res.data),
        queryKey: TicketsKeys.detail(id)
    });
};

export const apiGetTicketMessages = (id: number) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => ticketsService.ticketControllerGetMessageHistory({id})
            .then((res) => res.data),
        queryKey: TicketsKeys.history(id)
    });
};

export const apiTicketSendMessage = () => {
    return useCustomMutation({
        mutationFn: ({id, ...data}: TicketMessageSendDto & {
            id: number
        }) => ticketsService.ticketControllerSendMessage({id}, data)
            .then((res) => res.data),
        queryKey: (data) => TicketsKeys.id(data.ticket_id),
    });
};

export const apiCloseTicket = () => {
    return useCustomMutation({
        mutationFn: (data: { id: number }) => ticketsService.ticketControllerChangeTicketStatusClosed(data)
            .then((res) => res.data),
        queryKey: (data) => TicketsKeys.id(data.id),
    });
};

export const apiCreateTicket = () => {
    return useCustomMutation({
        mutationFn: (data: TicketCreateDto) => ticketsService.ticketControllerCreateTicket(data),
        queryKey: TicketsKeys.all,
    });
};