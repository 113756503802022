import {Box, Chip, CircularProgress, Stack, Switch, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {apiChangeFeatureToggleSchool} from "@/modules/schools/services/school.service";
import {apiChangeSchoolFeatureEnable} from "@/modules/feature-toggles/services/feature-toggles.service";
import {useParams} from "react-router-dom";
import {useUserStore} from "@/global/store/UserStore";

export function SchoolPageFeaturesSection() {
    const {id} = useParams();

    const isSuperAdmin = useUserStore((state) => state.isSuperAdmin);

    const {
        mutate: fetchTheoryExamsFeature,
        data: theoryExamsFeature,
        isPending: theoryExamsFeatureLoading
    } = apiChangeFeatureToggleSchool();
    const {
        mutate: fetchAutoFillFeature,
        data: autoFillFeature,
        isPending: autoFillFeatureLoading
    } = apiChangeFeatureToggleSchool();

    const {
        mutate: requestChangeFeature,
        data: changeFeature,
        isPending: changeFeatureLoading,
    } = apiChangeSchoolFeatureEnable();

    useEffect(() => {
        if (isSuperAdmin && id && !isNaN(+id)) {
            fetchTheoryExamsFeature({
                school_id: +id,
                module_name: 'theory_exams',
                is_enabled: false
            });

            fetchAutoFillFeature({
                school_id: +id,
                module_name: 'auto_fill',
                is_enabled: false
            });
        }
    }, []);

    const handleTheoryExamsChangeEnabled = (checked: boolean) => {
        if (!id || isNaN(+id)) {
            return;
        }
        requestChangeFeature({
            is_enabled: checked,
            module_name: 'theory_exams',
            school_id: +id
        });
    };

    const handleAutoFillChangeEnabled = (checked: boolean) => {
        if (!id || isNaN(+id)) {
            return;
        }
        requestChangeFeature({
            is_enabled: checked,
            module_name: 'auto_fill',
            school_id: +id
        });
    };

    useEffect(() => {
        if (!id || isNaN(+id) || !changeFeature) {
            return;
        }
        fetchTheoryExamsFeature({
            school_id: +id,
            module_name: 'theory_exams',
            is_enabled: false
        });
        fetchAutoFillFeature({
            school_id: +id,
            module_name: 'auto_fill',
            is_enabled: false
        });
    }, [changeFeature]);

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            sx={{mt: 2}}
        >
            <Typography
                variant="h4"
                sx={{ml: 1}}
            >
                Управление модулями системы
            </Typography>

            <Stack
                flexDirection={'row'}
                gap={1}
            >
                <Stack
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                        width: '300px',
                        height: 'auto',
                        minHeight: '150px',
                        borderRadius: '16px',
                        backgroundColor: '#FFFFFF',
                        py: '20px',
                        px: '16px',
                        gap: '16px'
                    }}
                    key={theoryExamsFeature?.id}
                >
                    <Typography
                        fontSize={'14px'}
                        color={'gray'}
                    >
                        #
                        {theoryExamsFeature?.module_name}
                    </Typography>
                    <Typography
                        fontSize={'14px'}
                        fontWeight={'bold'}
                    >
                        Состояние:
                    </Typography>
                    <Typography
                        fontSize={'14px'}
                        sx={{
                            mt: '-16px'
                        }}
                    >
                        <Chip
                            label={theoryExamsFeature?.is_enabled ? 'Включен' : 'Отключен'}
                            color={theoryExamsFeature?.is_enabled ? 'success' : 'error'}
                            sx={{
                                mt: 1
                            }}
                        />
                    </Typography>
                    <Typography
                        fontSize={'14px'}
                        fontWeight={'bold'}
                    >
                        Описание модуля:
                    </Typography>
                    <Typography
                        fontSize={'14px'}
                        sx={{
                            mt: '-16px'
                        }}
                    >
                        {theoryExamsFeature?.description}
                    </Typography>
                    {(theoryExamsFeatureLoading || changeFeatureLoading) && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                        >
                            <CircularProgress/>
                        </Stack>
                    )}
                    {theoryExamsFeature && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                        >

                            <Typography>
                                Откл.
                            </Typography>
                            <Switch
                                checked={theoryExamsFeature?.is_enabled}
                                onChange={(e) => handleTheoryExamsChangeEnabled(e.target.checked)}
                            />
                            <Typography>
                                Вкл.
                            </Typography>
                        </Stack>
                    )}
                </Stack>

                <Stack
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                        width: '300px',
                        height: 'auto',
                        minHeight: '150px',
                        borderRadius: '16px',
                        backgroundColor: '#FFFFFF',
                        py: '20px',
                        px: '16px',
                        gap: '16px'
                    }}
                    key={autoFillFeature?.id}
                >
                    <Typography
                        fontSize={'14px'}
                        color={'gray'}
                    >
                        #
                        {autoFillFeature?.module_name}
                    </Typography>
                    <Typography
                        fontSize={'14px'}
                        fontWeight={'bold'}
                    >
                        Состояние:
                    </Typography>
                    <Typography
                        fontSize={'14px'}
                        sx={{
                            mt: '-16px'
                        }}
                    >
                        <Chip
                            label={autoFillFeature?.is_enabled ? 'Включен' : 'Отключен'}
                            color={autoFillFeature?.is_enabled ? 'success' : 'error'}
                            sx={{
                                mt: 1
                            }}
                        />
                    </Typography>
                    <Typography
                        fontSize={'14px'}
                        fontWeight={'bold'}
                    >
                        Описание модуля:
                    </Typography>
                    <Typography
                        fontSize={'14px'}
                        sx={{
                            mt: '-16px'
                        }}
                    >
                        {autoFillFeature?.description}
                    </Typography>
                    {(autoFillFeatureLoading || changeFeatureLoading) && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                        >
                            <CircularProgress/>
                        </Stack>
                    )}
                    {autoFillFeature && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                        >

                            <Typography>
                                Откл.
                            </Typography>
                            <Switch
                                checked={autoFillFeature?.is_enabled}
                                onChange={(e) => handleAutoFillChangeEnabled(e.target.checked)}
                            />
                            <Typography>
                                Вкл.
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
}