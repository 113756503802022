import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerGetSchoolGroupsCountQueryResponse,
  GroupsControllerGetSchoolGroupsCountPathParams,
} from '../../ts/GroupsDTO/GroupsControllerGetSchoolGroupsCount'

export function getGroupsControllerGetSchoolGroupsCountUrl({ id }: { id: GroupsControllerGetSchoolGroupsCountPathParams['id'] }) {
  return `/groups/all-groups/count/${id}` as const
}

/**
 * {@link /groups/all-groups/count/:id}
 */
export async function groupsControllerGetSchoolGroupsCount(
  { id }: { id: GroupsControllerGetSchoolGroupsCountPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerGetSchoolGroupsCountQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerGetSchoolGroupsCountUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}