import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerChangeGroupTitleMutationRequest,
  GroupsControllerChangeGroupTitleMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerChangeGroupTitle'

export function getGroupsControllerChangeGroupTitleUrl() {
  return `/groups/change-title` as const
}

/**
 * @summary Изменение названия группы
 * {@link /groups/change-title}
 */
export async function groupsControllerChangeGroupTitle(
  data: GroupsControllerChangeGroupTitleMutationRequest,
  config: Partial<RequestConfig<GroupsControllerChangeGroupTitleMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerChangeGroupTitleMutationResponse, ResponseErrorConfig<Error>, GroupsControllerChangeGroupTitleMutationRequest>({
    method: 'POST',
    url: getGroupsControllerChangeGroupTitleUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}