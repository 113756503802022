import {Stack, Typography} from "@mui/material";
import {formatDate} from "@/global/helpers/text";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface NewsCardProps {
    id: number
    title_ru: string;
    title_kz: string;
    description_ru?: string;
    description_kz?: string;
    created_at: Date | string;
    authorized: boolean;
    thumbnail: string | null;
}

export function NewsCard({
                             id,
                             title_ru,
                             title_kz,
                             description_ru,
                             description_kz,
                             created_at,
                             authorized,
                             thumbnail
                         }: NewsCardProps) {
    const navigate = useNavigate();

    const {i18n} = useTranslation();

    return (
        <Stack
            display={'flex'}
            flexDirection={'column'}
            sx={{
                width: {xs: '353px', lg: '420px'},
                minHeight: {xs: '346px', lg: '442px'},
                background: authorized ? '#fff' : '#f5f5f5',
                borderRadius: '20px',
                overflow: 'hidden',
                pb: '28px',
                cursor: 'pointer',
            }}
            onClick={() => {
                navigate(`/news/${id}`);
            }}
        >
            <img
                src={thumbnail ?? undefined}
                alt={title_ru}
                style={{
                    width: '100%',
                    height: '268px',
                    borderRadius: '20px 20px 0 0',
                    objectFit: 'cover', // Сохраняет пропорции и обрезает изображение, если оно превышает размеры
                    objectPosition: 'center', // Центрирует изображение в рамке
                }}
            />

            <Stack
                flexDirection={'column'}
                sx={{
                    px: '30px',
                    mt: {xs: '20px', lg: '30px'},
                    flex: 1
                }}
            >
                <Typography
                    fontSize={'24px'}
                    color={'#000'}
                >
                    {i18n.language === 'ru' ? title_ru : title_kz}
                </Typography>

                <Typography
                    sx={{
                        mt: {xs: '7px', lg: '9px'},
                        mb: {xs: '35px', lg: '32px'}
                    }}
                    fontSize={'16px'}
                    color={'rgba(0,0,0,0.5)'}
                >
                    {i18n.language === 'ru' ? description_ru : description_kz}
                </Typography>

                <Typography
                    sx={{
                        mt: 'auto'
                    }}
                    fontSize={'16px'}
                    color={'#1E9AF3'}
                >
                    {formatDate((created_at))}
                </Typography>
            </Stack>
        </Stack>
    );
}