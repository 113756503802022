import {Box, Button, InputBase, Typography} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, {type FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    apiAddDriverToSchool,
    apiAddMasterToSchool,
    apiAddTeacherToSchool
} from "@/modules/employees/services/employees.service";
import {LoadingButton} from "@mui/lab";
import type {EmployeeCreateDto} from "@/global/api/gen/ts/EmployeeCreateDto";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useUserStore} from "@/global/store/UserStore";

interface Props {
    id: number;
    employee: "teachers" | "drivers" | "masters";
}

export function NewEmployeeDialog({id, employee}: Props) {
    const {t} = useTranslation();

    const {mutate: addTeacher, isPending: isPendingTeacher} = apiAddTeacherToSchool();
    const {mutate: addMaster, isPending: isPendingMaster} = apiAddMasterToSchool();
    const {mutate: addDriver, isPending: isPendingDriver} = apiAddDriverToSchool();

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const userStore = useUserStore((state) => state);

    const addLoading = isPendingDriver || isPendingTeacher || isPendingMaster;

    const [employeeForm, setEmployeeForm] = useState<EmployeeCreateDto>({
        first_name: '',
        last_name: '',
        patronymic: '',
        birth_date: null,
        school_id: id,
        qualification_certificate: '',
        iin: '',
    });

    const cleanInputs = () => {
        setEmployeeForm({
            first_name: '',
            last_name: '',
            patronymic: '',
            birth_date: null,
            school_id: id,
            qualification_certificate: '',
            iin: '',
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmployeeForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (employee === 'teachers') {
            addTeacher(employeeForm, {
                onSuccess: () => {
                    cleanInputs();
                }
            });
        }

        if (employee === 'drivers') {
            addDriver(employeeForm, {
                onSuccess: () => {
                    cleanInputs();
                }
            });
        }

        if (employee === 'masters') {
            addMaster(employeeForm, {
                onSuccess: () => {
                    cleanInputs();
                }
            });
        }
    };

    return (
        <>
            {!dialogOpen && !userStore.isObserver && (
                <Button
                    variant={'contained'}
                    onClick={() => {
                        setDialogOpen(true);
                    }}
                    sx={{mt: '27px'}}
                >
                    {t(`school_page.employees_page.${employee}.button`)}
                    <KeyboardArrowDownIcon style={{marginLeft: '3rem'}}/>
                </Button>
            )}
            {dialogOpen && (
                <Box
                    sx={{
                        width: '773px',
                        height: 'auto',
                        p: '32px',
                        background: '#fff',
                        borderRadius: '16px',
                        mt: '17px'
                    }}
                >
                    <form
                        style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px'}}
                        onSubmit={handleSubmit}
                    >
                        <p
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setDialogOpen(false);
                            }}
                        >
                            <Typography
                                variant={'h5'}
                                sx={{
                                    mb: '14px',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}
                            >
                                {t(`school_page.employees_page.${employee}.newEmployee`)}
                                <KeyboardArrowUpIcon style={{marginLeft: 'auto'}}/>
                            </Typography>
                        </p>
                        <InputBase
                            value={employeeForm.qualification_certificate}
                            placeholder={t('school_page.employees_page.qualification_certificate')}
                            name={'qualification_certificate'}
                            sx={{pl: 2}}
                            onChange={handleInputChange}
                            required={true}
                        />
                        <InputBase
                            value={employeeForm.iin}
                            placeholder={t('school_page.employees_page.iin')}
                            name={'iin'}
                            sx={{pl: 2}}
                            onChange={handleInputChange}
                            required={true}
                        />
                        <LoadingButton
                            loading={addLoading}
                            variant={'contained'}
                            type={'submit'}
                            sx={{width: '227px', mt: '14px'}}
                        >
                            {t('school_page.employees_page.add_btn')}
                        </LoadingButton>
                    </form>
                </Box>
            )}
        </>
    );
}