import { certificateApplicationControllerCreateCertificateApplication } from './certificateApplicationControllerCreateCertificateApplication'
import { certificateApplicationControllerCreateCertificateApplicationForDelivery } from './certificateApplicationControllerCreateCertificateApplicationForDelivery'
import { certificateApplicationControllerGetCertificateApplicationById } from './certificateApplicationControllerGetCertificateApplicationById'
import { certificateApplicationControllerSearchCertificateApplication } from './certificateApplicationControllerSearchCertificateApplication'
import { certificateApplicationControllerUpdateCertificateData } from './certificateApplicationControllerUpdateCertificateData'
import { certificateApplicationControllerUpdateTrackNumber } from './certificateApplicationControllerUpdateTrackNumber'

export const certificateApplicationService = {
    certificateApplicationControllerCreateCertificateApplication,
    certificateApplicationControllerCreateCertificateApplicationForDelivery,
    certificateApplicationControllerSearchCertificateApplication,
    certificateApplicationControllerGetCertificateApplicationById,
    certificateApplicationControllerUpdateTrackNumber,
    certificateApplicationControllerUpdateCertificateData,
  }