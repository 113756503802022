import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import PageHeader from '../../global/components/PageHeader';
import TicketsAdminGrid from '../components/TicketsAdminGrid';
import {wrapperSx} from '@/global/helpers/globalstyles';

function TicketsAdminPage() {
    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader />
            <Box sx={wrapperSx}>

                <Stack
                    flexDirection={'row'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <Typography
                        sx={{mt: 3}}
                        variant={'h5'}
                    >
                        <b>
                            Служба поддержки
                        </b>
                    </Typography>
                </Stack>

                <TicketsAdminGrid/>
            </Box>
        </Box>
    );
}

export default TicketsAdminPage;