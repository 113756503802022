import React from 'react';
import {Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography} from '@mui/material';
import {toast} from "react-toastify";
import LoadingButton from '../../../global/components/LoadingButton';
import {apiGetPracticeHours, apiGetTheoryHours, apiGroupAutoSubmit} from "@/modules/groups/services/group.service";
import type {GroupAutoFillClassesDtoTypeEnum} from "@/global/api/gen/ts/GroupAutoFillClassesDto";
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";

interface AutoFillClassesModalProps {
    groupInfo: GroupDataDto,

}

function AutoFillClassesModal({groupInfo, open, close}: ModalSystemProps<AutoFillClassesModalProps>) {

    const {data: theoryList, isLoading: theoryLoading} = apiGetTheoryHours(groupInfo.id, {
        limit:100,
        offset:0
    });
    const {data: practiceList, isLoading: practiceLoading} = apiGetPracticeHours(groupInfo.id, {
        limit:100,
        offset:0
    });

    const {mutate: autoFill, isPending:autoFillLoading} = apiGroupAutoSubmit();

    const updateClasses = async () => {
        toast.success('Занятие успешно выставлено');
        close(0);
    };

    const handleAutoFill = (type: GroupAutoFillClassesDtoTypeEnum, sectionId: string | number, studentId: number | null) => {
        autoFill({
            school_id: groupInfo.school_id,
            group_id: groupInfo.id,
            type: type,
            student_id: studentId,
            section_id: +(sectionId ?? 0)
        }, {
            onSuccess: async () => {
                await updateClasses();
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(0)}
            maxWidth={'xl'}
            fullWidth={true}
        >
            <DialogTitle>
                Авто-заполнение занятий
            </DialogTitle>
            <DialogContent>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                >
                    {(theoryLoading || practiceLoading) && (
                        <CircularProgress/>
                    )}

                    <Typography
                        variant={'h6'}
                    >
                        Теоретические занятия:
                    </Typography>

                    <Stack
                        flexDirection={'row'}
                        flexWrap={'wrap'}
                        gap={1}
                    >
                        {theoryList?.items.map((theory) => {
                            return (
                                <Stack
                                    flexDirection={'column'}
                                    sx={{
                                        background: '#f5f5f5',
                                        p: 2,
                                        borderRadius: '16px',
                                        width: 'fit-content'
                                    }}
                                    key={theory.id}
                                    gap={2}
                                >
                                    <Stack
                                        flexDirection={'row'}
                                        gap={1}
                                    >
                                        <Typography
                                            fontWeight={'bold'}
                                            sx={{
                                                width: '150px'
                                            }}
                                        >
                                            Предмет:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                width: '300px'
                                            }}
                                        >
                                            {theory.section?.title_ru}
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        flexDirection={'row'}
                                        gap={1}
                                    >
                                        <Typography
                                            fontWeight={'bold'}
                                            sx={{
                                                width: '150px'
                                            }}
                                        >
                                            Часы:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                width: '300px'
                                            }}
                                        >
                                            {theory.current_hours}
                                            /
                                            {theory.max_hours}
                                            {' '}
                                            академических часов
                                        </Typography>
                                    </Stack>

                                    {(theory.current_hours !== theory.max_hours) ? (
                                        <LoadingButton
                                            loading={autoFillLoading}
                                            variant={'contained'}
                                            sx={{
                                                mt: 'auto'
                                            }}
                                            onClick={() => {
                                                handleAutoFill('theory', theory.section?.id ?? 0, null);
                                            }}
                                        >
                                            Запустить авто-заполнение по данному предмету
                                        </LoadingButton>
                                    ) : (
                                        <LoadingButton
                                            loading={autoFillLoading}
                                            variant={'contained'}
                                            sx={{
                                                mt: 'auto'
                                            }}
                                            disabled={true}
                                        >
                                            Занятия запланированы по всем темам
                                        </LoadingButton>
                                    )}
                                </Stack>
                            );
                        })}
                    </Stack>

                    <Divider/>

                    <Typography
                        variant={'h6'}
                    >
                        Практические занятия:
                    </Typography>

                    <Stack
                        flexDirection={'row'}
                        gap={1}
                        flexWrap={'wrap'}
                    >
                        {practiceList?.items.map((practice) => {
                            return (
                                <Stack
                                    flexDirection={'column'}
                                    sx={{
                                        background: '#f5f5f5',
                                        p: 2,
                                        borderRadius: '16px',
                                        width: 'fit-content',
                                    }}
                                    key={practice.id}
                                    gap={2}
                                >
                                    <Stack
                                        flexDirection={'row'}
                                        gap={1}
                                        flexWrap={'wrap'}
                                    >
                                        <Typography
                                            fontWeight={'bold'}
                                            sx={{
                                                width: '150px'
                                            }}
                                        >
                                            Студент:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                width: '300px'
                                            }}
                                        >
                                            {practice.student?.last_name}
                                            {' '}
                                            {practice.student?.first_name}
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        flexDirection={'row'}
                                        gap={1}
                                    >
                                        <Typography
                                            fontWeight={'bold'}
                                            sx={{
                                                width: '150px'
                                            }}
                                        >
                                            Часы:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                width: '300px'
                                            }}
                                        >
                                            {practice.current_hours}
                                            /
                                            {practice.max_hours}
                                            {' '}
                                            часов
                                        </Typography>
                                    </Stack>

                                    {(practice.current_hours !== practice.max_hours) ? (
                                        <LoadingButton
                                            loading={autoFillLoading}
                                            variant={'contained'}
                                            sx={{
                                                mt: 'auto'
                                            }}
                                            onClick={() => {
                                                handleAutoFill('practice', practice.section_id, practice.student_id);
                                            }}
                                        >
                                            Запустить авто-заполнение по данному студенту
                                        </LoadingButton>
                                    ) : (
                                        <LoadingButton
                                            loading={autoFillLoading}
                                            variant={'contained'}
                                            sx={{
                                                mt: 'auto'
                                            }}
                                            disabled={true}
                                        >
                                            Все занятия запланированы
                                        </LoadingButton>
                                    )}
                                </Stack>
                            );
                        })}
                    </Stack>

                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default AutoFillClassesModal;
