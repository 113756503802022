import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerGetSchoolInfoByBinQueryResponse,
  SchoolsControllerGetSchoolInfoByBinPathParams,
  SchoolsControllerGetSchoolInfoByBinQueryParams,
} from '../../ts/schoolsDTO/SchoolsControllerGetSchoolInfoByBin'

export function getSchoolsControllerGetSchoolInfoByBinUrl({ bin }: { bin: SchoolsControllerGetSchoolInfoByBinPathParams['bin'] }) {
  return `/schools/bin/${bin}` as const
}

/**
 * @summary Получение информации о школе по BIN
 * {@link /schools/bin/:bin}
 */
export async function schoolsControllerGetSchoolInfoByBin(
  { bin }: { bin: SchoolsControllerGetSchoolInfoByBinPathParams['bin'] },
  params: SchoolsControllerGetSchoolInfoByBinQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGetSchoolInfoByBinQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerGetSchoolInfoByBinUrl({ bin }).toString(),
    params,
    ...requestConfig,
  })
  return res
}