import type {
    CategoriesControllerGetSchoolCategoriesQueryParams
} from '@/global/api/gen/ts/CategoriesDTO/CategoriesControllerGetSchoolCategories';
import type {
    StudentsControllerGetStudentInfoQueryParams
} from '@/global/api/gen/ts/StudentsDTO/StudentsControllerGetStudentInfo';
import type {
    StudentsControllerFindStudentsByIINQueryParams
} from '@/global/api/gen/ts/StudentsDTO/StudentsControllerFindStudentsByIIN';

export const StudentKeys = {
    all: ['students'] as const,
    search: () => [...StudentKeys.all, 'search'] as const,
    searchBySchool: (id: number, params: CategoriesControllerGetSchoolCategoriesQueryParams) => [...StudentKeys.search(), `${id}`, params] as const,
    detail: (id: number) => [...StudentKeys.all, 'detail', `${id}`] as const,
    detailWithSchool: (id: number, params: StudentsControllerGetStudentInfoQueryParams) => [...StudentKeys.detail(id), `${params.schoolId}`] as const,
    searchByIin: (iin: string, params: StudentsControllerFindStudentsByIINQueryParams) => [...StudentKeys.search(), 'iin', iin, params] as const,
    studentFromAis: (iin: string) => [...StudentKeys.all, 'ais', iin] as const,
    fee: (id: number) => [...StudentKeys.all, 'fee', `${id}`] as const,
    studentFromAisForAdmin: (iin: string) => [...StudentKeys.all, 'ais-admin', iin] as const
};