import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerSoapAutoSubmitGroupClassMutationRequest,
  GroupsControllerSoapAutoSubmitGroupClassMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerSoapAutoSubmitGroupClass'

export function getGroupsControllerSoapAutoSubmitGroupClassUrl() {
  return `/groups/auto-submit` as const
}

/**
 * {@link /groups/auto-submit}
 */
export async function groupsControllerSoapAutoSubmitGroupClass(
  data: GroupsControllerSoapAutoSubmitGroupClassMutationRequest,
  config: Partial<RequestConfig<GroupsControllerSoapAutoSubmitGroupClassMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerSoapAutoSubmitGroupClassMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerSoapAutoSubmitGroupClassMutationRequest
  >({ method: 'POST', url: getGroupsControllerSoapAutoSubmitGroupClassUrl().toString(), data, ...requestConfig })
  return res
}