import React, {useMemo, useState} from 'react';
import {Avatar, Box, Typography} from '@mui/material';
import {type GridColDef} from '@mui/x-data-grid';
import {DirectionsCar} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import {useUserStore} from '@/global/store/UserStore';
import {ServerDataGrid} from "@/global/components/ServerDataGrid";
import type {UserDto} from "@/global/api/gen/ts/UserDto";
import {apiSchoolsByUser} from "@/modules/schools/services/school.service";

interface UsersSchoolsProps {
    userId: number
}

function UsersSchools({userId} : UsersSchoolsProps) {

    const currentUser = useUserStore((state) => state);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25, page: 0
    });

    const {data, isLoading: loading} = apiSchoolsByUser(userId, {
        offset: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize
    }, {
        enabled: currentUser.isAdmin || (!!currentUser.id && +(currentUser.id) === +(userId))
    });

    const columns: GridColDef<UserDto>[] = useMemo(() => [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
        },
        {
            field: 'bin',
            headerName: 'БИН учебной организации',
            width: 200,
            sortable: false,
        },
        {
            field: 'title',
            headerName: 'Название учебной организации',
            width: 500,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        <Link
                            to={`/schools/${params.row.id}`}
                            style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Avatar
                                sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
                            >
                                <DirectionsCar fontSize="small"/>
                            </Avatar>
                            {params.value}
                        </Link>
                    </React.Fragment>
                );
            }
        },
    ], []);

    return (
        <Box sx={{mt: 3}}>
            <Typography
                variant="h5"
                color="dimgray"
            >
                Закрепленные за пользователем организации
            </Typography>
            <ServerDataGrid
                sx={{mt: 2}}
                columns={columns}
                rows={data?.items ?? []}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                autoHeight={true}
                disableRowSelectionOnClick
                loading={loading}
            />
        </Box>
    );
}

export default UsersSchools;
