import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerDetachTransportToGroupMutationRequest,
  GroupsControllerDetachTransportToGroupMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerDetachTransportToGroup'

export function getGroupsControllerDetachTransportToGroupUrl() {
  return `/groups/detach/transport` as const
}

/**
 * @summary Удаление транспорта из группы
 * {@link /groups/detach/transport}
 */
export async function groupsControllerDetachTransportToGroup(
  data: GroupsControllerDetachTransportToGroupMutationRequest,
  config: Partial<RequestConfig<GroupsControllerDetachTransportToGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerDetachTransportToGroupMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerDetachTransportToGroupMutationRequest
  >({ method: 'POST', url: getGroupsControllerDetachTransportToGroupUrl().toString(), data, ...requestConfig })
  return res
}