import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerDetachMasterFromGroupMutationRequest,
  GroupsControllerDetachMasterFromGroupMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerDetachMasterFromGroup'

export function getGroupsControllerDetachMasterFromGroupUrl() {
  return `/groups/detach/master` as const
}

/**
 * @summary Удаление мастера из группы
 * {@link /groups/detach/master}
 */
export async function groupsControllerDetachMasterFromGroup(
  data: GroupsControllerDetachMasterFromGroupMutationRequest,
  config: Partial<RequestConfig<GroupsControllerDetachMasterFromGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerDetachMasterFromGroupMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerDetachMasterFromGroupMutationRequest
  >({ method: 'POST', url: getGroupsControllerDetachMasterFromGroupUrl().toString(), data, ...requestConfig })
  return res
}