import React, {useState} from 'react';
import {Avatar, Box, Tab, Tabs} from '@mui/material';
import {type GridColDef, type GridEventListener} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';

import {getName} from '@/global/helpers/text';
import {
    type TicketControllerGetAllTicketsQueryParamsStatusEnum
} from "@/global/api/gen/ts/TicketsDTO/TicketControllerGetAllTickets";
import {apiGetAllTickets} from "@/modules/tickets/services/tickets.service";
import type {TicketDto} from "@/global/api/gen/ts/TicketDto";
import {ServerDataGrid} from "@/global/components/ServerDataGrid";

export enum TicketStatus {
    "Новое" = 1,
    "Администратор" = 2,
    "Пользователь" = 3,
    "Закрытое" = 10
}

const tabs = {
    all: {id: 0, label: `Все`},
    created: {id: 1, label: `Новые`},
    answeredByAdmin: {id: 2, label: `Администратор`},
    answeredByUser: {id: 3, label: `Клиент`},
    closed: {id: 10, label: `Закрытые`}
};

function TicketAdminGrid() {

    const navigate = useNavigate();

    const [tab, setTab] = useState<TicketControllerGetAllTicketsQueryParamsStatusEnum | 0>(0);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25, page: 0
    });

    const {data, isLoading} = apiGetAllTickets({
        offset: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize,
        status: tab === 0 ? undefined : tab,
    });

    const columns: GridColDef<TicketDto>[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 150
        },
        {
            field: 'title',
            headerName: 'Тема обращения',
            width: 300
        },
        {
            field: 'user',
            headerName: 'Пользователь',
            width: 300,
            renderCell: ({row}) => {
                const name = getName(row.user ?? {});
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'manager',
            headerName: 'Администратор',
            width: 300,
            renderCell: ({row}) => {
                if (row.manager) {
                    const name = getName(row.manager);
                    return (
                        <React.Fragment>
                            <Avatar
                                sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                            >
                                {name.partialName}
                            </Avatar>
                            {name.fullName}
                        </React.Fragment>
                    );
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'status',
            headerName: 'Статус',
            width: 200,
            renderCell: ({row}) => {
                return TicketStatus[row.status];
            }
        },
    ];

    const handleRowClick: GridEventListener<'rowClick'> = (
        params,
    ) => {
        navigate('/tickets/' + params.row.id);
    };

    return (
        <Box className={'appeals'}>
            <Tabs
                value={tab}
                onChange={(_e, newValue) => setTab(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{mt: 2, ml: 2}}
            >
                {Object.values(tabs).map(tab => (
                    <Tab
                        label={tab.label}
                        key={tab.id}
                        value={tab.id}
                    />
                ))}
            </Tabs>
            <ServerDataGrid
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                className={'appeals-grid'}
                rows={data?.items ?? []}
                onRowClick={handleRowClick}
                columns={columns}
                loading={isLoading}
                rowCount={data?.total ?? 0}
                autoHeight={true}
                disableRowSelectionOnClick
            />
        </Box>
    );
}

export default TicketAdminGrid;