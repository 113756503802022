import {useCustomQuery} from '@/global/hooks/useCustomQuery';
import {registerAppealsService} from '@/global/api/gen';
import {AppealKeys} from '@/modules/appeals/services/appeal.keys';
import type {
    RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams
} from '@/global/api/gen/ts/register-appealsDTO/RegisterAppealsControllerGetAppealsWithStatusCreated';
import type {AxiosError} from 'axios';
import type {RegisterAppealDto} from '@/global/api/gen/ts/RegisterAppealDto';
import {useCustomMutation} from '@/global/hooks/useCustomMutation';
import type {
    RegisterAppealsSchoolInfoSearchResponseDto
} from '@/global/api/gen/ts/RegisterAppealsSchoolInfoSearchResponseDto';
import {SchoolKeys} from "@/modules/schools/services/school.keys";

export const apiGetCreatedAppeals = (params: RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams) => {
    return useCustomQuery<RegisterAppealsSchoolInfoSearchResponseDto, AxiosError>({
        queryFn: () => registerAppealsService.registerAppealsControllerGetAppealsWithStatusCreated(params)
            .then((res) => res.data), queryKey: AppealKeys.createdAppeals(params)
    });
};

export const apiGetApprovedAppeals = (params: RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams) => {
    return useCustomQuery<RegisterAppealsSchoolInfoSearchResponseDto, AxiosError>({
        queryFn: () => registerAppealsService.registerAppealsControllerGetAppealsWithStatusApproved(params)
            .then((res) => res.data), queryKey: AppealKeys.approvedAppeals(params)
    });
};

export const apiGetDeniedAppeals = (params: RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams) => {
    return useCustomQuery<RegisterAppealsSchoolInfoSearchResponseDto, AxiosError>({
        queryFn: () => registerAppealsService.registerAppealsControllerGetAppealsWithStatusDenied(params)
            .then((res) => res.data), queryKey: AppealKeys.deniedAppeals(params)
    });
};

export const apiGetClosedAppeals = (params: RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams) => {
    return useCustomQuery<RegisterAppealsSchoolInfoSearchResponseDto, AxiosError>({
        queryFn: () => registerAppealsService.registerAppealsControllerGetAppealsWithStatusClosed(params)
            .then((res) => res.data), queryKey: AppealKeys.closedAppeals(params)
    });
};

export const apiGetAppealById = (id: number) => {
    return useCustomQuery<RegisterAppealDto, AxiosError>({
        queryFn: () => registerAppealsService.registerAppealsControllerGetAppealById({id})
            .then((res) => res.data), queryKey: AppealKeys.appeal(id)
    });
};

export const apiApproveAppeal = () => {
    return useCustomMutation({
        mutationFn: ({id}: { id: number }) => registerAppealsService.registerAppealsControllerApproveAppeal({id}),
        queryKey: AppealKeys.all,
    });
};

export const apiDenyAppeal = () => {
    return useCustomMutation({
        mutationFn: ({id}: { id: number }) => registerAppealsService.registerAppealsControllerDenyAppeal({id}),
        queryKey: AppealKeys.all,
    });
};

export const apiAppealResendEmail = () => {
    return useCustomMutation({
        mutationFn: ({email}: {
            email: string
        }) => registerAppealsService.registerAppealsControllerResendAppealToMail({email}),
        queryKey: AppealKeys.all,
    });
};

export const apiAppealChangeEmail = () => {
    return useCustomMutation({
        mutationFn: ({id, email}: {
            email: string,
            id: number
        }) => registerAppealsService.registerAppealsControllerChangeEmailInAppeal({id}, {email}),
        queryKey: AppealKeys.all,
    });
};

export const apiDeleteSchoolCascade = () => {
    return useCustomMutation({
        mutationFn: (data: {
            id: number
        }) => registerAppealsService.registerAppealsControllerDeleteSchoolAndAllRelated(data),
        queryKey: [...AppealKeys.all, ...SchoolKeys.all],
    });
};