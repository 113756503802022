import React, {
	type ComponentPropsWithRef,
	type ComponentType,
	createContext,
	type ElementType,
	type ReactNode,
	useContext,
	useRef,
	useState
} from 'react';

type ModalObject<Element extends ElementType> = {
	e: Element, id: string, props: ComponentPropsWithRef<Element>, open: boolean
	timeout?: ReturnType<typeof setTimeout> | null,
};

type ModalSystemPropsName = 'close' | 'open';
export type ModalSystemProps<T extends object = {}> = {
	close: (unmountDelay?: number) => void, open: boolean,
} & T;

export type ModalComponent<Props = object, SystemProps = ModalSystemProps> = ComponentType<Props & SystemProps>;

interface IModalContext {
	openModal: <C extends ElementType>(e: C, props: Omit<ComponentPropsWithRef<C>, ModalSystemPropsName>, modalId?: string) => void,
	closeModal: (id: string | number, unmountDelay?: number) => void,
	modals: ModalObject<ElementType>[]
}

export const ModalContext = createContext<IModalContext>({
	openModal: () => {
	}, closeModal: () => {
	}, modals: []
});

export const useModal = () => useContext(ModalContext);

export const useModalAPI = (): IModalContext => {
	const [modals, setModals] = useState<IModalContext['modals']>([]);
	const modalsRef = useRef(modals);
	modalsRef.current = modals;

	const openModal: IModalContext['openModal'] = (e, props, modalId = '') => {
		const eFunc = e as () => void;
		const id = modalId ? `${String(eFunc.name)}__${modalId}` : String(eFunc.name);

		const existsModal = modals.filter((modal) => modal.id === id)[0];
		if (existsModal) {
			setModals(modals.map((modal) => {
				if (modal.id === id) {
					modal.open = true;
					if (modal.timeout) clearTimeout(modal.timeout);
				}
				return modal;
			}));
			return;
		}

		const newModals = [...modals, {
			e, id, props, open: true
		}];
		setModals(newModals);
	};

	const unmountModal = (id: string | number) => {
		setModals(modalsRef.current.filter((modal) => {
			if (modal.id === id) {
				return modal.open;

			}
			return true;
		}));
	};

	const closeModal = (id: string | number, unmountDelay: number = 5) => {
		setModals(modals.map((modal) => {
			if (modal.id === id) {
				modal.open = false;
				if (unmountDelay >= 0) {
					modal.timeout = setTimeout(() => unmountModal(id), unmountDelay === 0 ? 200 : unmountDelay * 1000);
				}
			}
			return modal;
		}));

	};

	return {
		openModal, closeModal, modals
	};
};

function Modal() {
	const {
		closeModal, modals
	} = useModal();

	return (
    <>
        {modals.map(({
										 e: Element, id, props, open
									 }) => (
    <Element
        key={id}
        close={(unmountDelay = 5) => closeModal(id, unmountDelay)}
        open={open}
        {...props}
				/>
			))}
    </>
	);
}

export function ModalProvider({children}: { children: ReactNode }) {
	const {
		openModal, closeModal, modals
	} = useModalAPI();

	return (

    <ModalContext.Provider
        value={{
				openModal, closeModal, modals
			}}
    >
        <Modal/>
        {children}
    </ModalContext.Provider>
	);
}
