import {Box, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface CategoriesGridProps {
    categories: any[];
    className?: string;
}

export function MiniCategoriesGrid({categories, className}: CategoriesGridProps) {

    const [hasActiveItems, setHasActiveItems] = useState<boolean>(false);

    const { t } = useTranslation();

    const categoriesGrid = [
        ['A', 'B', 'BE', 'C'],
        ['CE', 'D', 'DE', 'Tm'],
        ['Tb']
    ];

    useEffect(() => {
        const hasActiveItems = categoriesGrid.some((row) =>
          row.some((item) => categories.some((cat) => cat.category_id === item))
        );

        setHasActiveItems(hasActiveItems);
    }, [categories]);

    function Item({ value, isLast }: { value: string; isLast: boolean }) {
        const active = !!categories.filter((i) => i.category_id === value)[0];
        return (
            <Stack
                flexDirection="row"
                alignItems="center"
            >
                {active && (
                <Typography
                    fontSize={'22px'}
                    lineHeight={'100%'}
                    color={'#05304f'}
                    sx={{
                      mr: '5px'
                  }}
                >
                    {value}
                    {!isLast && ','}
                </Typography>
              )}
            </Stack>
        );
    }

    return (
        <Stack
            flexDirection={'column'}
            gap={2}
            className={className && `${className}`}
            sx={{
            width: '100%', // Растянуть по ширине
            alignSelf: 'stretch', // Растянуть по высоте строки
        }}
        >
            <Box>
                <Typography
                    fontSize={'16px'}
                    lineHeight={'100%'}
                    color={'rgba(5,48,79,0.6)'}
                >
                    {t('school_page.categories_grid.categories_title')}
                </Typography>
                <Stack
                    sx={{
                    mt: '10px',
                }}
                >
                    {!hasActiveItems && (
                    <Typography
                        fontSize={'22px'}
                        lineHeight={'100%'}
                        color={'#05304f'}
                        sx={{
                          mr: '5px'
                      }}
                    >
                        Н/Д
                    </Typography>
                  )}
                    {categoriesGrid.map((row, i) => {
                      const activeItems = row.filter((item) =>
                        categories.some((cat) => cat.category_id === item)
                      );
                      return (
                          <Stack
                              key={i}
                              flexDirection="row"
                          >
                              {row.map((item, index) => (
                                  <Item
                                      key={item}
                                      value={item}
                                      isLast={activeItems[activeItems.length - 1] === item}
                                  />
                            ))}
                          </Stack>
                      );
                  })}
                </Stack>
            </Box>
        </Stack>
    );
}

export function MiniSubCategoriesGrid({categories, className}: CategoriesGridProps) {

    const [hasActiveItems, setHasActiveItems] = useState<boolean>(false);

    const { t } = useTranslation();

    const subCategoriesGrid = [
        ['A1', 'B1', 'C1', 'C1E'],
        ['D1', 'D1E'],
    ];

    useEffect(() => {
        const hasActiveItems = subCategoriesGrid.some((row) =>
          row.some((item) => categories.some((cat) => cat.category_id === item))
        );

        setHasActiveItems(hasActiveItems);
    }, [categories]);

    function Item({ value, isLast }: { value: string; isLast: boolean }) {
        const active = !!categories.filter((i) => i.category_id === value)[0];
        return (
            <Stack
                flexDirection="row"
                alignItems="center"
            >
                {active && (
                <Typography
                    fontSize={'22px'}
                    lineHeight={'100%'}
                    color={'#05304f'}
                    sx={{
                      mr: '5px'
                  }}
                >
                    {value}
                    {!isLast && ','}
                </Typography>
              )}
            </Stack>
        );
    }

    return (
        <Stack
            flexDirection={'column'}
            gap={2}
            className={className && `${className}`}
            sx={{
            width: '100%', // Растянуть по ширине
            alignSelf: 'stretch', // Растянуть по высоте строки
        }}
        >
            <Box>
                <Typography
                    fontSize={'16px'}
                    lineHeight={'100%'}
                    color={'rgba(5,48,79,0.6)'}
                >
                    {t('school_page.categories_grid.subcategories_title')}
                </Typography>
                <Stack
                    sx={{
                    mt: '10px',
                }}
                >
                    {!hasActiveItems && (
                    <Typography
                        fontSize={'22px'}
                        lineHeight={'100%'}
                        color={'#05304f'}
                        sx={{
                          mr: '5px'
                      }}
                    >
                        Н/Д
                    </Typography>
                  )}
                    {subCategoriesGrid.map((row, i) => {
                      const activeItems = row.filter((item) =>
                        categories.some((cat) => cat.category_id === item)
                      );
                      return (
                          <Stack
                              key={i}
                              flexDirection="row"
                          >
                              {row.map((item, index) => (
                                  <Item
                                      key={item}
                                      value={item}
                                      isLast={activeItems[activeItems.length - 1] === item}
                                  />
                            ))}
                          </Stack>
                      );
                  })}
                </Stack>
            </Box>
        </Stack>
    );
}

export function CategoriesGrid({categories, className}: CategoriesGridProps) {

    const { t } = useTranslation();

    const categoriesGrid = [
        ['A', 'B', 'BE', 'C'],
        ['CE', 'D', 'DE', 'Tm'],
        ['Tb']
    ];

    const subCategoriesGrid = [
        ['A1', 'B1', 'C1', 'C1E'],
        ['D1', 'D1E'],
    ];

    function Item({value}: { value: string }) {
        const active = !!categories.filter(i => i.category_id === value)[0];
        return (
            <Stack
                flexDirection="row"
                alignItems="center"
                sx={{width: 70}}
            >
                {active ?
                    (
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V14C3 14.7956 3.31607 15.5587 3.87868 16.1213C4.44129 16.6839 5.20435 17 6 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V6C17 5.20435 16.6839 4.44129 16.1213 3.87868C15.5587 3.31607 14.7956 3 14 3H6ZM4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H6C5.46957 16 4.96086 15.7893 4.58579 15.4142C4.21071 15.0391 4 14.5304 4 14V6ZM13.854 7.854C13.9479 7.76011 14.0006 7.63278 14.0006 7.5C14.0006 7.36722 13.9479 7.23989 13.854 7.146C13.7601 7.05211 13.6328 6.99937 13.5 6.99937C13.3672 6.99937 13.2399 7.05211 13.146 7.146L8.5 11.793L6.854 10.146C6.76011 10.0521 6.63278 9.99937 6.5 9.99937C6.36722 9.99937 6.23989 10.0521 6.146 10.146C6.05211 10.2399 5.99937 10.3672 5.99937 10.5C5.99937 10.6328 6.05211 10.7601 6.146 10.854L8.146 12.854C8.19245 12.9006 8.24762 12.9375 8.30837 12.9627C8.36911 12.9879 8.43423 13.0009 8.5 13.0009C8.56577 13.0009 8.63089 12.9879 8.69163 12.9627C8.75238 12.9375 8.80755 12.9006 8.854 12.854L13.854 7.854Z"
                                fill="#1E9AF3"
                            />
                        </svg>
)
                    : (
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V14C3 14.7956 3.31607 15.5587 3.87868 16.1213C4.44129 16.6839 5.20435 17 6 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V6C17 5.20435 16.6839 4.44129 16.1213 3.87868C15.5587 3.31607 14.7956 3 14 3H6ZM4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H6C5.46957 16 4.96086 15.7893 4.58579 15.4142C4.21071 15.0391 4 14.5304 4 14V6Z"
                                fill="#05304F"
                                opacity={0.4}
                            />
                        </svg>
)}

                <Typography
                    sx={{ml: '5px'}}
                    color={'#05304F'}
                    fontWeight={600}
                >
                    {value}
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack
            flexDirection={'column'}
            gap={2}
            className={className && `${className}`}
            sx={{
              width: '100%', // Растянуть по ширине
              alignSelf: 'stretch', // Растянуть по высоте строки
          }}
        >
            <Box>
                <Typography className={'info-header'}>
                    <b>
                        {t('school_page.categories_grid.categories_title')}
                    </b>
                </Typography>
                <Stack className={'info-categories'}>
                    {categoriesGrid.map((row, i) => (
                        <Stack
                            key={i}
                            flexDirection="row"
                            sx={{mt: 1}}
                        >
                            {row.map((item) => (
                                <Item
                                    key={item}
                                    value={item}
                                />
))}
                        </Stack>
                    )
                    )}
                </Stack>
            </Box>
        </Stack>
    );
}

export function SubCategoriesGrid({categories, className}: CategoriesGridProps) {

    const { t } = useTranslation();

    const categoriesGrid = [
        ['A', 'B', 'BE', 'C'],
        ['CE', 'D', 'DE', 'Tm'],
        ['Tb']
    ];

    const subCategoriesGrid = [
        ['A1', 'B1', 'C1', 'C1E'],
        ['D1', 'D1E'],
    ];

    function Item({value}: { value: string }) {
        const active = !!categories.filter(i => i.category_id === value)[0];
        return (
            <Stack
                flexDirection="row"
                alignItems="center"
                sx={{width: 70}}
            >
                {active ?
                (
                    <svg
                        width="25"
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V14C3 14.7956 3.31607 15.5587 3.87868 16.1213C4.44129 16.6839 5.20435 17 6 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V6C17 5.20435 16.6839 4.44129 16.1213 3.87868C15.5587 3.31607 14.7956 3 14 3H6ZM4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H6C5.46957 16 4.96086 15.7893 4.58579 15.4142C4.21071 15.0391 4 14.5304 4 14V6ZM13.854 7.854C13.9479 7.76011 14.0006 7.63278 14.0006 7.5C14.0006 7.36722 13.9479 7.23989 13.854 7.146C13.7601 7.05211 13.6328 6.99937 13.5 6.99937C13.3672 6.99937 13.2399 7.05211 13.146 7.146L8.5 11.793L6.854 10.146C6.76011 10.0521 6.63278 9.99937 6.5 9.99937C6.36722 9.99937 6.23989 10.0521 6.146 10.146C6.05211 10.2399 5.99937 10.3672 5.99937 10.5C5.99937 10.6328 6.05211 10.7601 6.146 10.854L8.146 12.854C8.19245 12.9006 8.24762 12.9375 8.30837 12.9627C8.36911 12.9879 8.43423 13.0009 8.5 13.0009C8.56577 13.0009 8.63089 12.9879 8.69163 12.9627C8.75238 12.9375 8.80755 12.9006 8.854 12.854L13.854 7.854Z"
                            fill="#1E9AF3"
                        />
                    </svg>
)
                : (
                    <svg
                        width="25"
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V14C3 14.7956 3.31607 15.5587 3.87868 16.1213C4.44129 16.6839 5.20435 17 6 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V6C17 5.20435 16.6839 4.44129 16.1213 3.87868C15.5587 3.31607 14.7956 3 14 3H6ZM4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H6C5.46957 16 4.96086 15.7893 4.58579 15.4142C4.21071 15.0391 4 14.5304 4 14V6Z"
                            fill="#05304F"
                            opacity={0.4}
                        />
                    </svg>
)}

                <Typography
                    sx={{ml: '5px'}}
                    color={'#05304F'}
                    fontWeight={600}
                >
                    {value}
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack
            flexDirection={'column'}
            gap={2}
            className={className && `${className}`}
            sx={{
            width: '100%', // Растянуть по ширине
            alignSelf: 'stretch', // Растянуть по высоте строки
        }}
        >
            <Box>
                <Typography className={'info-header'}>
                    <b>
                        {t('school_page.categories_grid.subcategories_title')}
                    </b>
                </Typography>
                <Stack className={'info-categories'}>
                    {subCategoriesGrid.map((row, i) => (
                        <Stack
                            key={i}
                            flexDirection="row"
                            sx={{mt: 1}}
                        >
                            {row.map((item) => (
                                <Item
                                    key={item}
                                    value={item}
                                />
))}
                        </Stack>
                  )
                  )}
                </Stack>
            </Box>
        </Stack>
    );
}