import { Fragment, useEffect, useId, useMemo, useState } from 'react';
import { FormControl, IconButton, InputAdornment, MenuItem, Select, type SxProps, type Theme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

interface Props {
    items: object;
    value?: string | null;
    label?: string;
    sx?: SxProps<Theme>;
    onChange?: (newValue: string | null) => void;
}

const Formatters = {
    id: 'По ID',
    created_at: 'По дате создания',
    updated_at: 'По дате изменения'
};

const supportedIds = Object.keys(Formatters);

export default function SortSelect(
    {items, value: initialValue, label = 'Сортировка', sx = {}, onChange}: Props) {
    const id = useId();
    const [value, setValue] = useState<string | null>(initialValue?.split('.')[0] || null);
    const [order, setOrder] = useState<boolean>(initialValue?.split('.')[1] === 'asc');

    const available = useMemo<{ id: string, label: string }[]>(() =>
            Object.values(items).reduce<{ id: string, label: string }[]>((prev, curr, i, arr) => {
                const [x, y] = curr.split('.');
                const exists = prev.filter(i => i.id === x)[0];
                if (!exists && supportedIds.includes(x)) {
                    // @ts-ignore
                    return [...prev, {id: x, label: Formatters[x]}];
                }
                return prev;
            }, []),
        [items]);

    useEffect(() => {
        onChange && onChange(value ? `${value}.${order ? 'asc' : 'desc'}` : null);
    }, [value, order]);

    useEffect(() => {
        const currentValue = value ? `${value}.${order ? 'asc' : 'desc'}` : null;
        const newValue = initialValue || null;
        console.log('currentValue', currentValue);
        console.log('newValue', newValue);

        if (currentValue !== newValue) {
            const [x, y] = initialValue?.split('.') || [];
            if (!x) return setValue(x);

            setValue(x);
            setOrder(y === 'asc');
        }
    }, [initialValue]);

    return (
        <Fragment>
            <FormControl
                fullWidth
                sx={{...sx,
            '& .MuiInputBase-root': {
                border:'none',
                backgroundColor: '#F8F9FB', // Or any color that matches your UI
                '& .MuiInputAdornment-root MuiIconButton-root': {
                    color: 'rgba(0, 0, 0, 0.54)' // Adjust the color as needed
                }
            }}}
            >
                <Select
                    id={id}
                    value={value || ''}
                    onChange={(e) => setValue(e.target.value || null)}
                    startAdornment={(
                        <InputAdornment position="start" >
                            <IconButton onClick={() => setOrder(!order)}>
                                <FilterListIcon sx={{transform: order ? 'rotate(180deg)' : undefined}}/>
                            </IconButton>
                        </InputAdornment>
)}
                    variant="standard"
                    placeholder="Сортировка"
                >
                    <MenuItem value="">
                        Без сортировки
                    </MenuItem>
                    {available?.map(i => (
                        <MenuItem
                            key={i.id}
                            value={i.id}
                        >
                            {i.label}
                        </MenuItem>
                  )
                )}
                </Select>
            </FormControl>
        </Fragment>
    );
}