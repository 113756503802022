import {CircularProgress, Paper, Typography, useTheme} from '@mui/material';
import {useId, useMemo} from 'react';
import {apiStatisticSchools} from '../services/statistic.service';
import {type DateRangeParams, LineChart, PeriodType} from '../types/statistic.type';
import Chart from 'react-apexcharts';
import {formatDate} from "@/global/helpers/text";

export function ChartSchoolCountByPeriod({start_date, end_date, period_type}: DateRangeParams) {
    const id = useId();
    const theme = useTheme();
    const {data, isLoading} = apiStatisticSchools({end_date, start_date, period_type});

    const chart = useMemo(() => (
        LineChart(theme, id, data)
    ), [data, theme, id]);

    return (
        <Paper
            sx={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                padding: 1
            }}
        >
            <Typography
                variant="h4"
                sx={{mb: 0.5}}
            >
                Автошкол в системе
            </Typography>
            <Typography sx={{mb: 1}}>
                Общее кол-во зарегистрированных автошкол (по
                {' '}
                {period_type === PeriodType.Year ? 'годам' : 'месяцам'}
                ).
                Данные
                собраны
                c
                {' '}
                {formatDate(start_date)}
                {' '}
                по
                {' '}
                {formatDate(end_date)}
            </Typography>
            {isLoading && <CircularProgress/>}
            <Chart
                options={chart}
                series={chart.series}
                type="line"
            />
        </Paper>
    );
}