import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerGetTransactionStatusQueryResponse,
  TransactionsControllerGetTransactionStatusPathParams,
} from '../../ts/TransactionsDTO/TransactionsControllerGetTransactionStatus'

export function getTransactionsControllerGetTransactionStatusUrl({
  invoice_id,
}: {
  invoice_id: TransactionsControllerGetTransactionStatusPathParams['invoice_id']
}) {
  return `/transactions/get-status/${invoice_id}` as const
}

/**
 * @summary Получение статуса платежа
 * {@link /transactions/get-status/:invoice_id}
 */
export async function transactionsControllerGetTransactionStatus(
  { invoice_id }: { invoice_id: TransactionsControllerGetTransactionStatusPathParams['invoice_id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransactionsControllerGetTransactionStatusQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTransactionsControllerGetTransactionStatusUrl({ invoice_id }).toString(),
    ...requestConfig,
  })
  return res
}