import type {UseQueryOptions} from '@tanstack/react-query';
import {z} from "zod";

export type OmmitedUseQueryOptions<T> = Omit<
	UseQueryOptions<T>,
	'queryKey' | 'queryFn'
>;

export enum UserGender {
	unknown = "0", male = "1", female = "2"
}

export const UserGenderText = {
	0: 'Не указан', 1: 'Мужской', 2: 'Женский'
};

export const zodRequiredString = z.string().min(1, 'Поле не может быть пустым');