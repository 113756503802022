import { type FC, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { Stack, Typography } from '@mui/material';

interface TimerProps {
    began_at: Date;
    current_time: Date;
    onExpire: () => void;
}

const TimerComponent: FC<TimerProps> = ({ began_at, current_time, onExpire }) => {
    const [expiryTimestamp, setExpiryTimestamp] = useState<Date>(current_time);
    const { seconds, minutes, isRunning, restart } = useTimer({
        expiryTimestamp: expiryTimestamp,
        onExpire,
    });

    useEffect(() => {
        // Вычисляем разницу во времени между began_at и current_time
        const timeDifference = current_time.getTime() - began_at.getTime();

        // 40 минут в миллисекундах
        const fortyMinutesInMilliseconds = 40 * 60 * 1000;

        // Вычисляем оставшееся время
        const remainingTime = fortyMinutesInMilliseconds - timeDifference;

        // Получаем новый expiryTimestamp
        const newExpiryTimestamp = new Date(current_time.getTime() + remainingTime);

        // Устанавливаем новый expiryTimestamp
        setExpiryTimestamp(newExpiryTimestamp);
    }, [began_at, current_time]);

    useEffect(() => {
        // Перезапускаем таймер только тогда, когда expiryTimestamp изменился
        restart(expiryTimestamp);
    }, [expiryTimestamp, restart]);

    const radius = (125 - 10)/ 2;

    return (
        <Stack>
            <div style={{position: 'relative', width: 125, height: 125}}>
                <svg
                    height={125}
                    width={125}
                >
                    <circle
                        stroke={'#d6d6d6'}
                        strokeWidth={10}
                        fill="transparent"
                        r={radius}
                        cx={125 / 2}
                        cy={125 / 2}
                    />
                    <circle
                        stroke={'#ff6a00'}
                        strokeWidth={10}
                        fill="transparent"
                        r={radius}
                        cx={125 / 2}
                        cy={125 / 2}
                        strokeDasharray={2 * Math.PI * radius}
                        style={{transition: 'stroke-dashoffset 1s linear'}}
                    />
                </svg>
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // Центрирование
                        textAlign: 'center',
                    }}
                >
                    <Typography fontSize={'12px'}>
                        {isRunning ? 'Осталось:' : 'Время закончилось'}
                    </Typography>
                    <Typography variant={'h6'}>
                        {minutes}
                        :
                        {seconds.toString().length > 1 ? seconds : `0${seconds}`}
                    </Typography>
                </div>
            </div>
        </Stack>
    );
};

export default TimerComponent;
