import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerGenerateStudyConfirmationQueryResponse,
  CertificateControllerGenerateStudyConfirmationPathParams,
} from '../../ts/CertificateDTO/CertificateControllerGenerateStudyConfirmation'

export function getCertificateControllerGenerateStudyConfirmationUrl({
  studentId,
}: {
  studentId: CertificateControllerGenerateStudyConfirmationPathParams['studentId']
}) {
  return `/certificate/doc/confirmation/${studentId}` as const
}

/**
 * {@link /certificate/doc/confirmation/:studentId}
 */
export async function certificateControllerGenerateStudyConfirmation(
  { studentId }: { studentId: CertificateControllerGenerateStudyConfirmationPathParams['studentId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerGenerateStudyConfirmationQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerGenerateStudyConfirmationUrl({ studentId }).toString(),
    ...requestConfig,
  })
  return res
}