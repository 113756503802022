import {BackLink} from "@/global/components/BackLink";
import {Box, Stack} from "@mui/material";
import type {CertificateApplicationDto} from "@/global/api/gen/ts/CertificateApplicationDto";

interface Props {
    returnFirstStep: () => void;
    dataCertificateApplication: CertificateApplicationDto | undefined,
}

export function CertificateTransactionStep({returnFirstStep, dataCertificateApplication}: Props) {

    return (
        <Stack
            width={'100%'}
            gap={4}
        >
            <BackLink
                sx={{
                    alignSelf: 'flex-start',
                    fontSize: '18px',
                }}
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
            />
            <Box
                flexShrink={0}
                flexGrow={1}
                flexBasis={1}
                sx={{
                    backgroundColor: 'white',
                    margin: '0 auto',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    minHeight: '800px',
                    width: {xs: '100%', md: '60%'}
                }}
            >
                {
                    dataCertificateApplication?.transaction?.payment_url && (
                        <iframe
                            width={'100%'}
                            height={'800px'}
                            src={dataCertificateApplication.transaction.payment_url}
                            style={{
                                border: 'none',
                            }}
                        />
                    )}
            </Box>
        </Stack>
    );
}