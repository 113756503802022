import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { CertificateControllerFixExistingCertificatesQueryResponse } from '../../ts/CertificateDTO/CertificateControllerFixExistingCertificates'

export function getCertificateControllerFixExistingCertificatesUrl() {
  return `/certificate/fix-certificates` as const
}

/**
 * {@link /certificate/fix-certificates}
 */
export async function certificateControllerFixExistingCertificates(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerFixExistingCertificatesQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerFixExistingCertificatesUrl().toString(),
    ...requestConfig,
  })
  return res
}