import { studentsControllerAddStudentToGroup } from './studentsControllerAddStudentToGroup'
import { studentsControllerArchiveStudents } from './studentsControllerArchiveStudents'
import { studentsControllerAttachUserForStudent } from './studentsControllerAttachUserForStudent'
import { studentsControllerClearGeneratedPasswordByUserId } from './studentsControllerClearGeneratedPasswordByUserId'
import { studentsControllerCreateUserForStudent } from './studentsControllerCreateUserForStudent'
import { studentsControllerDeleteStudentFromGroup } from './studentsControllerDeleteStudentFromGroup'
import { studentsControllerFindStudentsByIIN } from './studentsControllerFindStudentsByIIN'
import { studentsControllerFindStudentsFromAisByIin } from './studentsControllerFindStudentsFromAisByIin'
import { studentsControllerFindStudentsFromAisByIinHidden } from './studentsControllerFindStudentsFromAisByIinHidden'
import { studentsControllerGetAllStudents } from './studentsControllerGetAllStudents'
import { studentsControllerGetFeeStudentInfo } from './studentsControllerGetFeeStudentInfo'
import { studentsControllerGetSchoolStudents } from './studentsControllerGetSchoolStudents'
import { studentsControllerGetSchoolStudentsWithGroups } from './studentsControllerGetSchoolStudentsWithGroups'
import { studentsControllerGetStudentInfo } from './studentsControllerGetStudentInfo'
import { studentsControllerGetStudentInfoByIin } from './studentsControllerGetStudentInfoByIin'
import { studentsControllerGetStudentsByIinForQuickFee } from './studentsControllerGetStudentsByIinForQuickFee'
import { studentsControllerGiveCertificateToStudent } from './studentsControllerGiveCertificateToStudent'
import { studentsControllerLoginAsStudent } from './studentsControllerLoginAsStudent'
import { studentsControllerSaveDocsInfoByStudentId } from './studentsControllerSaveDocsInfoByStudentId'
import { studentsControllerSetPassedFalseForStudent } from './studentsControllerSetPassedFalseForStudent'
import { studentsControllerSetPassedTrueForStudent } from './studentsControllerSetPassedTrueForStudent'
import { studentsControllerSubmitGradeByStudentId } from './studentsControllerSubmitGradeByStudentId'
import { studentsControllerUpdateMedicalInfoByStudentId } from './studentsControllerUpdateMedicalInfoByStudentId'

export const studentsService = {
    studentsControllerFindStudentsFromAisByIin,
    studentsControllerFindStudentsFromAisByIinHidden,
    studentsControllerFindStudentsByIIN,
    studentsControllerAttachUserForStudent,
    studentsControllerCreateUserForStudent,
    studentsControllerSaveDocsInfoByStudentId,
    studentsControllerGetSchoolStudentsWithGroups,
    studentsControllerGetFeeStudentInfo,
    studentsControllerGetStudentsByIinForQuickFee,
    studentsControllerGetStudentInfo,
    studentsControllerGetAllStudents,
    studentsControllerAddStudentToGroup,
    studentsControllerDeleteStudentFromGroup,
    studentsControllerSetPassedTrueForStudent,
    studentsControllerGiveCertificateToStudent,
    studentsControllerSetPassedFalseForStudent,
    studentsControllerGetSchoolStudents,
    studentsControllerGetStudentInfoByIin,
    studentsControllerLoginAsStudent,
    studentsControllerSubmitGradeByStudentId,
    studentsControllerUpdateMedicalInfoByStudentId,
    studentsControllerArchiveStudents,
    studentsControllerClearGeneratedPasswordByUserId,
  }