import { useState } from 'react';

import { AppealsGrid } from '@/modules/appeals/components/AppealsGrid';
import { apiGetCreatedAppeals } from '@/modules/appeals/services/appeal.service';

interface CreatedAppealsProps {
	openAppeal: (id: number) => void,
}

export function CreatedAppeals({openAppeal}: CreatedAppealsProps) {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25,
		page: 0
	});

	const {data, isLoading} = apiGetCreatedAppeals({
		offset: paginationModel.page * paginationModel.pageSize,
		limit: paginationModel.pageSize
	});

	return (
		<AppealsGrid
			openAppeal={openAppeal}
			rows={data?.items ?? []}
			loading={isLoading}
			rowCount={data?.total ?? 0}
			paginationModel={paginationModel}
			setPaginationModel={(model) => setPaginationModel(model)}
		/>
	);
}