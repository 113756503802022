import { CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { useId, useMemo } from 'react';
import { apiStatisticDemographicsRegion } from '../services/statistic.service';
import Chart from 'react-apexcharts';
import { BarChart } from '../types/statistic.type';

export function ChartBarDemographicsRegion() {
	const id = useId();
	const theme = useTheme();
	const {data, isLoading} = apiStatisticDemographicsRegion();

	const chart = useMemo(() => (
		BarChart(theme, id, data)
	), [data, theme, id]);

	return (
		<Paper
			sx={{
				position: 'relative',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				width: '100%',
				padding: 1
			}}
		>
			<Typography
				variant="h4"
				sx={{mb: 0.5}}
			>
				Пользователей в системе по региону
			</Typography>
			<Typography sx={{mb: 1}}>
				Общее кол-во зарегистрированных пользователей (по региону).
			</Typography>
			{isLoading && <CircularProgress/>}
			<Chart
				options={chart}
				series={chart.series}
				type="bar"
			/>
		</Paper>
	);
}