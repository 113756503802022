import {CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, Typography, useTheme} from '@mui/material';
import {useId, useMemo, useState} from 'react';
import {apiStatisticRegisterTime} from '../services/statistic.service';
import Chart from 'react-apexcharts';
import {BarChart, RegisterPeriodType} from '../types/statistic.type';

export function ChartBarRegisterUsers() {
    const id = useId();
    const theme = useTheme();

    const [selectedType, setSelectedType] = useState<RegisterPeriodType>(RegisterPeriodType.time);

    const {
        data, isLoading
    } = apiStatisticRegisterTime({period_type: selectedType});

    const chart = useMemo(() => (
        BarChart(theme, id, data)
    ), [data, theme, id]);

    return (
        <Paper
            sx={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                padding: 1
            }}
        >
            <Typography
                variant="h4"
                sx={{mb: 0.5}}
            >
                Активность регистрации учеников
            </Typography>
            <FormControl
                sx={{'& .MuiInputBase-root': {border: 'none !important'}}}
            >
                <InputLabel>
                    Рассматриваемое время
                </InputLabel>
                <Select
                    value={selectedType}
                    label={'Рассматриваемое время'}
                    sx={{mb: 1}}
                    onChange={(event) => setSelectedType(event.target.value as RegisterPeriodType)}
                >
                    <MenuItem value="time">
                        По времени суток
                    </MenuItem>
                    <MenuItem value="day_of_week">
                        По дням недели
                    </MenuItem>
                </Select>
            </FormControl>
            {isLoading && <CircularProgress/>}
            <Chart
                options={chart}
                series={chart.series}
                type="bar"
            />
        </Paper>
    );
}