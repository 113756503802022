import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import {Person} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {Avatar, Paper, Stack, Typography} from "@mui/material";
import {toast} from "react-toastify";
import {apiAttachStudentToUser} from "@/modules/students/services/student.service";
import type {UserDto} from "@/global/api/gen/ts/UserDto";

interface Props {
    student:StudentDto,
    user: UserDto,
}

export function HasMatchUser({student, user}:Props) {

    const {mutate:attachStudentToUser, isPending:attachLoading} =apiAttachStudentToUser();

    const handleAttach = () => {
        attachStudentToUser({
            student_id: student.id,
            user_id: user.id
        }, {
            onSuccess: (data) => {
                toast.success(data.message);
            }
        });
    };

    return (
        <Stack
            flexDirection={'column'}
            gap={'16px'}
        >
            <Typography>
                Внимание! По ИИН данного студента (
                {student.last_name}
                {' '}
                {student.first_name}
                {' '}
                -
                {' '}
                {student.iin}
                ) - уже существует пользователь на портале Avtomektep.KZ.
            </Typography>
            <Typography>
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        p: '24px',
                        width: 'fit-content'
                    }}
                >
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        <Person />
                    </Avatar>
                    <Stack>
                        <Typography>
                            #
                            {user.id}
                            .
                            {user.last_name}
                            {' '}
                            {user.first_name}
                        </Typography>
                        <Typography fontSize={'12px'}>
                            ИИН:
                            {user.iin}
                        </Typography>
                    </Stack>
                </Paper>
            </Typography>
            <Stack>
                <LoadingButton
                    sx={{
                        width: 'fit-content',
                    }}
                    variant={'contained'}
                    loading={attachLoading}
                    onClick={handleAttach}
                >
                    Прикрепить пользователя к студенту
                </LoadingButton>
            </Stack>
        </Stack>
    );
}