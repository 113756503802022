import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerGenerateExamResultsQueryResponse,
  CertificateControllerGenerateExamResultsPathParams,
} from '../../ts/CertificateDTO/CertificateControllerGenerateExamResults'

export function getCertificateControllerGenerateExamResultsUrl({ studentId }: { studentId: CertificateControllerGenerateExamResultsPathParams['studentId'] }) {
  return `/certificate/doc/results/${studentId}` as const
}

/**
 * {@link /certificate/doc/results/:studentId}
 */
export async function certificateControllerGenerateExamResults(
  { studentId }: { studentId: CertificateControllerGenerateExamResultsPathParams['studentId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerGenerateExamResultsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerGenerateExamResultsUrl({ studentId }).toString(),
    ...requestConfig,
  })
  return res
}