import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  UsersControllerGetUserAuthJournalQueryResponse,
  UsersControllerGetUserAuthJournalQueryParams,
} from '../../ts/usersDTO/UsersControllerGetUserAuthJournal'

export function getUsersControllerGetUserAuthJournalUrl() {
  return `/users/journal` as const
}

/**
 * @summary Получить журнал авторизаций
 * {@link /users/journal}
 */
export async function usersControllerGetUserAuthJournal(
  params: UsersControllerGetUserAuthJournalQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerGetUserAuthJournalQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getUsersControllerGetUserAuthJournalUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}