import { featureTogglesControllerChangeEnabledForFeatureToggle } from './featureTogglesControllerChangeEnabledForFeatureToggle'
import { featureTogglesControllerChangeEnabledForSchoolFeatureToggle } from './featureTogglesControllerChangeEnabledForSchoolFeatureToggle'
import { featureTogglesControllerGetAllFeatureToggles } from './featureTogglesControllerGetAllFeatureToggles'
import { featureTogglesControllerGetFeatureToggleByModuleName } from './featureTogglesControllerGetFeatureToggleByModuleName'

export const featureTogglesService = {
    featureTogglesControllerGetFeatureToggleByModuleName,
    featureTogglesControllerGetAllFeatureToggles,
    featureTogglesControllerChangeEnabledForFeatureToggle,
    featureTogglesControllerChangeEnabledForSchoolFeatureToggle,
  }