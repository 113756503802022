import {BACKEND_URL} from '@/global/actions/apiVariables';
import axiosInstance from '../../global/actions/main.api';

export const getSchoolInfo = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/schools/${id}`);
};

export const getSchoolBalance = (schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/balance/${schoolId}`);
};

export const getSchoolTransactions = (schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/${schoolId}`);
};

// Методы для получения документов по курсанту
export const getExamCard = (kursantId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/doc/exam/${kursantId}`, { responseType: 'arraybuffer' });
};

export const getKursantContract = (kursantId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/doc/contract/${kursantId}`, { responseType: 'arraybuffer' });
};

export const getStudyingConfirmation = (kursantId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/doc/confirmation/${kursantId}`, { responseType: 'arraybuffer' });
};

export const getExamResults = (kursantId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/doc/results/${kursantId}`, { responseType: 'arraybuffer' });
};

