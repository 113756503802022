import type {
	UsersControllerGetSchoolUsersQueryParams
} from '@/global/api/gen/ts/usersDTO/UsersControllerGetSchoolUsers';
import type {
	UsersControllerGetUsersBySchoolIdQueryParams
} from "@/global/api/gen/ts/usersDTO/UsersControllerGetUsersBySchoolId";

export const UserKeys = {
	all: ['users'] as const,
	iin: (iin: string) => [...UserKeys.all, 'detail', iin] as const,
	detail: (id: number) => [...UserKeys.all, 'detail', `${id}`] as const,
	workers: (workerId: number) => [...UserKeys.all, 'workers', `${workerId}`] as const,
	workersWithParams: (workerId: number, params:UsersControllerGetUsersBySchoolIdQueryParams) => [...UserKeys.workers(workerId), params] as const,
	search: () => [...UserKeys.all, 'search'] as const,
	schoolSearch: (params:UsersControllerGetSchoolUsersQueryParams) => [...UserKeys.all, 'search', 'school', params] as const
	// search:
};