import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  UsersControllerChangePasswordForUserByIdMutationRequest,
  UsersControllerChangePasswordForUserByIdMutationResponse,
  UsersControllerChangePasswordForUserByIdPathParams,
} from '../../ts/usersDTO/UsersControllerChangePasswordForUserById'

export function getUsersControllerChangePasswordForUserByIdUrl({ id }: { id: UsersControllerChangePasswordForUserByIdPathParams['id'] }) {
  return `/users/change-password/${id}` as const
}

/**
 * @summary Сменить пароль пользователя
 * {@link /users/change-password/:id}
 */
export async function usersControllerChangePasswordForUserById(
  { id }: { id: UsersControllerChangePasswordForUserByIdPathParams['id'] },
  data: UsersControllerChangePasswordForUserByIdMutationRequest,
  config: Partial<RequestConfig<UsersControllerChangePasswordForUserByIdMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UsersControllerChangePasswordForUserByIdMutationResponse,
    ResponseErrorConfig<Error>,
    UsersControllerChangePasswordForUserByIdMutationRequest
  >({ method: 'POST', url: getUsersControllerChangePasswordForUserByIdUrl({ id }).toString(), data, ...requestConfig })
  return res
}