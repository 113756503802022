import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { TicketControllerGetAllTicketsQueryResponse, TicketControllerGetAllTicketsQueryParams } from '../../ts/TicketsDTO/TicketControllerGetAllTickets'

export function getTicketControllerGetAllTicketsUrl() {
  return `/tickets/get/all` as const
}

/**
 * @summary Получение всех тикетов
 * {@link /tickets/get/all}
 */
export async function ticketControllerGetAllTickets(
  params: TicketControllerGetAllTicketsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TicketControllerGetAllTicketsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTicketControllerGetAllTicketsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}