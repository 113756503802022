import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';

import { confirmRegistration } from '../actions/api';
import { useSnackbarStore } from '@/global/store/SnackbarStore';

function ConfirmPage() {

    const {id} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const snackbar = useSnackbarStore((store) => store);

    useEffect(() => {
        setLoading(true);
        if (id) {
            confirmRegistration(id)
                .then((res) => {
                    snackbar.successMessage(res.data.message);
                    setLoading(false);
                    setTimeout(() => {
                        navigate('/auth');
                    }, 1500);
                })
                .catch((err) => {
                    if (err.response.status === 417) {
                        snackbar.errorMessage(err.response.data.message);
                        navigate('/auth');
                    } else {
                        snackbar.errorMessage(err.response.data.message);
                    }
                });
        }
    }, []);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {!loading && (
            <>
                <Typography
                    variant={'h4'}
                    sx={{textAlign: 'center', mb: 2}}
                >
                    Вы успешно зарегистрировались в автоматизированной информационной системе "Курсант"!
                </Typography>
                <Typography
                    variant={'h4'}
                    sx={{textAlign: 'center'}}
                >
                    Переход на страницу авторизации...
                </Typography>
            </>
)}
            {loading && (
                <>
                    <Typography
                        variant={'h4'}
                        sx={{textAlign: 'center', mb: 2}}
                    >
                        Подтверждение регистрации...
                    </Typography>
                    <Typography
                        variant={'h4'}
                        sx={{textAlign: 'center', mb: 2}}
                    >
                        Не закрывайте вкладку.
                    </Typography>
                    <CircularProgress />
                </>
            )}
        </Box>
    );
}

export default ConfirmPage;
