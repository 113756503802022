import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from '@/global/actions/apiVariables';

export const apiRequestExam = (dto: CreateTheoryExamDto) => {
    return axiosInstance.post(`${BACKEND_URL}/theory-exams/create`, dto);
};

export const apiGetTheoryExam = (theoryExamId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/theory-exams/${theoryExamId}`);
};

export const apiStartExam = (theoryExamId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/theory-exams/start/${theoryExamId}`);
};

export const apiSubmitAnswer = (dto: SubmitTheoryExamAnswerDto) => {
    return axiosInstance.post(`${BACKEND_URL}/theory-exams/submit-answer`, dto);
};

export const apiFinishExam = (theoryExamId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/theory-exams/finish/${theoryExamId}`);
};

export interface CreateTheoryExamDto {
    student_id: number;
    category_id: string;
}

export interface CreateTheoryExamResponse {
    message: string,
    theory_exam_id: number
}

export interface SubmitTheoryExamAnswerDto {
    question_id: number,
    answer_id: number
}

export interface StartTheoryExamResponseDto {
    message: string,
    began_at: Date,
    current_time: Date
}

// -----------

export const apiGetUserTrialTries = (userId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/users/trial/tries/${userId}`);
};

export const apiRequestTrialExam = (dto: CreateTrialTheoryExamDto) => {
    return axiosInstance.post(`${BACKEND_URL}/theory-exams/trial/create`, dto);
};

export const apiGetTrialExam = (trialExamId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/theory-exams/trial/${trialExamId}`);
};

export const apiStartTrialExam = (trialExamId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/theory-exams/trial/start/${trialExamId}`);
};

export const apiSubmitTrialAnswer = (dto: SubmitTrialExamAnswerDto) => {
    return axiosInstance.post(`${BACKEND_URL}/theory-exams/trial/submit-answer`, dto);
};

export const apiFinishTrialExam = (trialExamId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/theory-exams/trial/finish/${trialExamId}`);
};

export interface CreateTrialTheoryExamDto {
    category_id: string;
}

export interface CreateTrialExamResponse {
    message: string,
    trial_exam_id: number
}

export interface StartTrialExamResponseDto extends StartTheoryExamResponseDto {}

export interface SubmitTrialExamAnswerDto extends SubmitTheoryExamAnswerDto {}
