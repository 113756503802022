import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useIdleTimer} from 'react-idle-timer';
import {I18nextProvider} from 'react-i18next';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Helmet} from 'react-helmet';
import {ToastContainer} from 'react-toastify';

import {useSnackbarStore} from './global/store/SnackbarStore';
import {useUserStore} from './global/store/UserStore';
import {logoutFromSystem} from './global/actions/main.api';
import i18n from './i18n';
import SnackbarHOC from './global/HOC/SnackbarHOC';
import {RoutesElement} from './global/routes/routes';
import {MiddlewareRoute} from './global/components/MiddlewareRoute';
import {ModalProvider} from '@/global/providers/ModalProvider';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 2,
		}
	}
});

const theme = createTheme({
	palette: {
		primary: {
			light: '#1E9AF3', main: '#1E9AF3', dark: '#1a8bda', contrastText: '#fff'
		}, secondary: {
			light: '#05304F', main: '#05304F', dark: '#04263c', contrastText: '#fff'
		}, info: {
			light: '#000000', main: '#000000', dark: '#000000', contrastText: '#fff'
		}
	}, components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '41px', height: '56px', textTransform: 'uppercase', fontWeight: 'bold'
				}
			}
		}, MuiInputBase: {
			styleOverrides: {
				root: {
					border: '1px solid #99999F', borderRadius: '16px', backgroundColor: '#FFFFFF', WebkitBoxShadow: 'none'
				}, input: {
					borderRadius: '16px',
					backgroundColor: '#FFFFFF !important',
					height: '56px',
					padding: '0 10px',
					WebkitBackgroundClip: 'text',
					transition: 'background-color 5000s ease-in-out 0s',
					boxShadow: 'none'
				}
			}
		}, MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiInputBase-root': {
						border: '0px !important'
					}, '& .MuiInputBase-input': {
						height: 'auto'
					}
				}
			}
		}
	}
});

function App() {

	const snackbar = useSnackbarStore((state) => state);
	const currentUser = useUserStore((state) => state);

	const onIdle = () => {
		if (currentUser.iin) {
			window.location.replace('/auth');
			snackbar.infoMessage(`Сессия была прекращена в связи с бездействием.`);
			logoutFromSystem();
		}
	};

	useIdleTimer({
		onIdle, timeout: 60000 * 15, throttle: 500
	});

	return (
    <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18n}>
                <ModalProvider>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ru"
                    >
                        <SnackbarHOC>
                            <Helmet>
                                <meta
                                    name="mailru-domain"
                                    content="CWH8CeLkeUIy2vTk"
                                />
                            </Helmet>
                            <BrowserRouter>
                                <Routes>
                                    {RoutesElement.map((el) => (
                                        <Route
                                            key={el.path}
                                            path={el.path}
                                            Component={() => MiddlewareRoute(el)}
                                        />
																		))}
                                </Routes>
                            </BrowserRouter>
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                        </SnackbarHOC>
                    </LocalizationProvider>
                </ModalProvider>
            </I18nextProvider>
        </QueryClientProvider>
    </ThemeProvider>
	);
}

export default App;
