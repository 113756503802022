import React, { useMemo, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import type { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import { PeopleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/global/helpers/text';

import { GroupStatusChip } from './GroupStatusChip';
import { ServerDataGrid } from '@/global/components/ServerDataGrid';
import { apiGetGroupList } from '@/modules/groups/services/group.service';

interface GroupsTableProps {
    filter:string,
	status: number | null,
	id: number
}

function GroupsTable({filter, status, id}: GroupsTableProps) {

    const navigate = useNavigate();

    const {t} = useTranslation();

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25, page: 0
	});

	const {data, isLoading} = apiGetGroupList(id, {
		offset: paginationModel.page * paginationModel.pageSize,
		limit: paginationModel.pageSize,
		q:filter,
		status:status
	});

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'empty',
            headerName: '',
            headerClassName: 'datagrid--header',
            maxWidth: 10,
            width: 10,
            sortable: false,
        },
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'datagrid--header',
            maxWidth: 10,
            width: 10,
            sortable: false,
            renderCell: ((row) => {
                return (
                    <Typography
                        fontSize={11}
                        color={'gray'}
                        sx={{
                    verticalAlign: 'center',
                }}
                    >
                        #
                        {row.id}
                    </Typography>
                );
            })
        },
        {field: 'title', headerName: t('school_page.group_list.group_table.grid.title'), minWidth: 200, sortable: true},
        {field: 'category_id', headerName: t('school_page.group_list.group_table.grid.category'), width: 120, sortable: false},
        {
            field: 'stats', headerName: t('school_page.group_list.group_table.grid.stats'), width: 150, sortable: false,
            renderCell: ({row}) => {
                return (
                    <Stack flexDirection="row">
                        <PeopleOutline
                            fontSize="small"
                            sx={{color: 'gray', mr: 1}}
                        />
                        <Typography
                            sx={{width: 35}}
                            variant="body2"
                        >
                            {row.students?.length}
                        </Typography>
                    </Stack>
                );
            }
        },
        {
            field: 'status', headerName: t('school_page.group_list.group_table.grid.status'), width: 200, sortable: false,
            renderCell: ({value}) => <GroupStatusChip status={value}/>
        },
        {
            field: 'start_date', headerName: t('school_page.group_list.group_table.grid.start_date'), width: 140, sortable: false,
            valueFormatter: ({value}) => formatDate(value)
        },
        {
            field: 'end_date', headerName: t('school_page.group_list.group_table.grid.end_date'), width: 140, sortable: false,
            valueFormatter: ({value}) => formatDate(value)
        },
        {
            field: 'new_tab', headerName: t('school_page.group_list.group_table.grid.action'), width: 300, sortable: false,
            renderCell: ({row}) => {
                return (
                    <Link
                        to={`/groups/${row.id}`}
                        target={'_blank'}
                        onClick={(e) => {e.stopPropagation();}}
                    >
                        <Button
                            sx={{height: 'auto'}}
                            variant={'contained'}
                        >
                            {t('school_page.group_list.group_table.new-tab')}
                        </Button>
                    </Link>
                );
            }
		}
    ], []);

    const handleRowClick = (params: GridRowParams) => {
        navigate(`/groups/${params.row.id}`);
    };

    return (
        <Box>
            <ServerDataGrid
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				rows={data?.items ?? []}
				onRowClick={handleRowClick}
				columns={columns}
				loading={isLoading}
				rowCount={data?.total ?? 0}
				disableRowSelectionOnClick
				disableColumnMenu={true}
				disableColumnFilter={true}
            />
        </Box>
    );
}

export default GroupsTable;