import {Stack, type Theme, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useUserStore} from "@/global/store/UserStore";

export function StudentMainPageIntroSection() {
    const {t} = useTranslation();

    const currentUser = useUserStore((state) => state);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    return (
        <>
            {isMobile && (
                <Stack>
                    <a
                        href={'https://pddtest.kz/'}
                        style={{
                            display: 'block',
                            width: '100%',
                            padding: '0 5px'
                        }}
                    >
                        <img
                            src={'/images/banner-mobile.png'}
                            style={{width: '100%'}}
                            alt={''}
                        />
                    </a>
                </Stack>
            )}

            {!isMobile && (
                <Stack>
                    <a
                        href={'https://pddtest.kz/'}
                        style={{
                            display: 'block',
                            width: '100%'
                        }}
                    >
                        <img
                            src={'/images/banner-pc.png'}
                            style={{width: '100%'}}
                            alt={''}
                        />
                    </a>
                </Stack>
            )}

            <Typography
                fontSize={'24px'}
                lineHeight={'120%'}
                color={'#707EAE'}
                sx={{
                    mt: '20px'
                }}
            >
                {t('main-pages.manager.hello')}
                &nbsp;
                {currentUser.first_name}
            </Typography>
            <Typography
                fontSize={{xs: '32px', lg: '40px'}}
                lineHeight={'100%'}
                color={'#2b3674'}
                fontWeight={'bold'}
                sx={{
                    mt: '11px'
                }}
            >
                {t('main-pages.manager.welcome')}
            </Typography>
        </>
    );
}