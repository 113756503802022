import {useCustomQuery} from "@/global/hooks/useCustomQuery";
import {transactionsService} from "@/global/api/gen";
import type {
    TransactionsControllerGetProductsPricesQueryParams
} from "@/global/api/gen/ts/TransactionsDTO/TransactionsControllerGetProductsPrices";
import {TransactionsKeys} from "@/modules/transactions/services/transactions.keys";
import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import type {TransactionCreateDto} from "@/global/api/gen/ts/TransactionCreateDto";
import type {OmmitedUseQueryOptions} from "@/global/types";
import type {TransactionDto} from "@/global/api/gen/ts/TransactionDto";

export const apiGetProductsPrice = (params: TransactionsControllerGetProductsPricesQueryParams) => {
    return useCustomQuery({
        queryFn: () => transactionsService.transactionsControllerGetProductsPrices(params)
            .then((res) => res.data),
        queryKey: TransactionsKeys.search(params)
    });
};

export const apiCreateTransaction = () => {
    return useCustomMutation({
        mutationFn: (data: TransactionCreateDto) => transactionsService.transactionsControllerCreateTransaction(data)
            .then((res) => res.data),
        queryKey: TransactionsKeys.all
    });
};

export const apiCreateQuickTransaction = () => {
    return useCustomMutation({
        mutationFn: (data: TransactionCreateDto) => transactionsService.transactionsControllerCreateQuickTransaction(data)
            .then((res) => res.data),
        queryKey: TransactionsKeys.all
    });
};

export const apiGetTransaction = (transactionId: number) => {
    return useCustomQuery({
        enabled: !!transactionId && !isNaN(transactionId),
        queryFn: () => transactionsService.transactionsControllerGetTransactionById({transactionId})
            .then((res) => res.data),
        queryKey: TransactionsKeys.detail(transactionId)
    });
};

export const apiRefreshStatusTransaction = (transactionId: number, option?: OmmitedUseQueryOptions<TransactionDto>) => {
    return useCustomQuery({
        enabled: !!transactionId && !isNaN(transactionId),
        queryFn: () => transactionsService.transactionsControllerTriggerRefreshTransaction({transactionId})
            .then((res) => res.data),
        queryKey: TransactionsKeys.refresh(transactionId),
        ...option
    });
};

export const apiGetQuickTransaction = (transactionId: number) => {
    return useCustomQuery({
        enabled: !!transactionId && !isNaN(transactionId),
        queryFn: () => transactionsService.transactionsControllerGetQuickTransactionById({transactionId})
            .then((res) => res.data),
        queryKey: TransactionsKeys.quickDetail(transactionId)
    });
};

export const apiRefreshStatusQuickTransaction = (transactionId: number, option?: OmmitedUseQueryOptions<TransactionDto>) => {
    return useCustomQuery({
        enabled: !!transactionId && !isNaN(transactionId),
        queryFn: () => transactionsService.transactionsControllerTriggerRefreshQuickTransaction({transactionId})
            .then((res) => res.data),
        queryKey: TransactionsKeys.quickRefresh(transactionId),
        ...option
    });
};

export const apiGetProductPriceById = (productId: string) => {
    return useCustomQuery({
        queryFn: () => transactionsService.transactionsControllerGetProductPriceByProductId({productId})
            .then((res) => res.data),
        queryKey: TransactionsKeys.product(productId)
    });
};

export const apiTransactionFreedomWebhook = (invoice_id: string | undefined | null, stop?: boolean) => {
    return useCustomQuery({
        enabled: !!invoice_id && !stop,
        queryFn: () => transactionsService.transactionsControllerGetTransactionStatus({invoice_id: invoice_id!})
            .then((res) => res.data),
        queryKey: TransactionsKeys.detail(invoice_id),
        refetchInterval: 10000,
    });
};