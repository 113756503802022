import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import React, {type Dispatch, type SetStateAction, useEffect, useState} from "react";
import {refreshAccessToken} from "@/global/actions/main.api";
import {apiGetLocalityBySlug, apiGetRegionList} from "@/modules/regions/services/regions.service";
import type {LocalityDto} from "@/global/api/gen/ts/LocalityDto";
import {apiAttachSchoolToUser} from "@/modules/schools/services/school.service";
import {toast} from "react-toastify";
import type {AttachNewSchoolDto} from "@/global/api/gen/ts/AttachNewSchoolDto";
import {useUserStore} from "@/global/store/UserStore";

interface Props {
    newSchoolInfo: AttachNewSchoolDto,
    setNewSchoolInfo: Dispatch<SetStateAction<AttachNewSchoolDto>>,
    clearForm: () => void,
    userInfo: { id: number, fullName: string, partialName: string },
    setCurrentStep: Dispatch<SetStateAction<number>>
}

const inputStyle = {WebkitBoxShadow: "0 0 0 1000px white inset"};

export function NewSchoolFirstStep({newSchoolInfo, setCurrentStep, userInfo, clearForm, setNewSchoolInfo}: Props) {

    const isAdmin = useUserStore((state) => state.isAdmin);

    const [localitiesArray, setLocalitiesArray] = useState<LocalityDto[]>([]);
    const [registerLoading, setRegisterLoading] = useState<boolean>(false);

    const {data: regionsArray} = apiGetRegionList({limit: 100, offset: 0});
    const {data: localities} = apiGetLocalityBySlug(newSchoolInfo.region, {
        limit: 100,
        offset: 0,
    });

    const {mutate} = apiAttachSchoolToUser();

    useEffect(() => {
        if (!localities || !regionsArray) {
            return;
        }
        regionsArray.items.map((region) => {
            if (region.name_ru === newSchoolInfo.region) {
                const regionId = region.id;
                if (regionId === 1 || regionId === 2 || regionId === 3) {
                    setLocalitiesArray(localities.items);
                } else {
                    setLocalitiesArray([
                        ...localities.items,
                        {
                            id: 50000,
                            region_id: regionId,
                            name_kz: 'Другое (вписать в адрес)',
                            name_ru: 'Другое (вписать в адрес)'
                        }
                    ]);
                }
            }
        });
    }, [localities]);

    const handleSchoolChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setNewSchoolInfo((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleRegionChange = (e: SelectChangeEvent) => {
        setRegion(e.target.value);
    };

    const handleLocalitiesChange = (e: SelectChangeEvent) => {
        setLocality(e.target.value);
    };

    const setRegion = (chosenRegion: string) => {
        setNewSchoolInfo((prev) => {
            return {
                ...prev,
                region: chosenRegion
            };
        });
    };

    const setLocality = (chosenLocality: string) => {

        if (chosenLocality === 'Другое (вписать в адрес)') {
            setNewSchoolInfo((prev) => {
                return {
                    ...prev,
                    locality: 'Другое (вписать в адрес)'
                };
            });
            return;
        }

        setNewSchoolInfo((prev) => {
            return {
                ...prev,
                locality: chosenLocality
            };
        });
    };

    const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = newSchoolInfo.categories.concat(name);

            setNewSchoolInfo((prev) => {
                return {
                    ...prev,
                    categories: newCategories
                };
            });
        }

        if (!checked) {
            const isIncluded = newSchoolInfo.categories.includes(name);

            if (isIncluded) {
                const newCategories: string[] = [];

                for (let i = 0; i < newSchoolInfo.categories.length; i++) {
                    const element = newSchoolInfo.categories[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setNewSchoolInfo((prev) => {
                    return {
                        ...prev,
                        categories: newCategories
                    };
                });
            }
        }
    };

    const checkSchoolForm = () => {

        if (!(newSchoolInfo.region === 'Алматы') && !(newSchoolInfo.region === 'Астана') && !(newSchoolInfo.region === 'Шымкент')) {
            if (!newSchoolInfo.locality) {
                return false;
            }
        }

        return !(!newSchoolInfo.bin || !newSchoolInfo.phone1 || !newSchoolInfo.title || !newSchoolInfo.director || newSchoolInfo.categories.length < 1 || !newSchoolInfo.region || !newSchoolInfo.address);
    };

    const finishAdd = () => {

        let schoolInfoDto = {
            ...newSchoolInfo
        };

        if (newSchoolInfo.locality === 'Другое (вписать в адрес)') {
            schoolInfoDto = {
                ...newSchoolInfo,
                locality: ''
            };
        }

        if (checkSchoolForm()) {
            setRegisterLoading(true);
            mutate(schoolInfoDto, {
                onSuccess: () => {
                    toast.success('Успешно добавлена школа');
                    clearForm();
                    refreshAccessToken()
                        .then(() => {
                            setRegisterLoading(false);
                        });
                }
            });
        } else {
            toast.error('Необходимо заполнить все поля');
        }
    };

    return (
        <Box
            className={'register-form'}
            sx={{p: 5}}
        >
            <Box className={'register-form-box'}>

                <Typography variant={'h6'}>
                    Выбранный пользователь:
                </Typography>

                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {userInfo.partialName ? userInfo.partialName : ''}
                    </Avatar>
                    <Typography>
                        {userInfo.fullName ? userInfo.fullName : ''}
                        {' '}
                        #
                        {userInfo.id}
                    </Typography>
                </Stack>

                <Typography variant={'h5'}>
                    Данные учебной организации
                </Typography>
                <InputBase
                    type={'text'}
                    placeholder={'БИН*'}
                    value={newSchoolInfo.bin}
                    name={'bin'}
                    onChange={handleSchoolChange}
                    inputProps={{style: inputStyle}}
                    required={true}
                    disabled={registerLoading}
                />
                <InputBase
                    type={'text'}
                    placeholder={'Полное наименование организации*'}
                    value={newSchoolInfo.title}
                    name={'title'}
                    onChange={handleSchoolChange}
                    inputProps={{style: inputStyle}}
                    required={true}
                    disabled={registerLoading}
                />

                <InputBase
                    type={'text'}
                    placeholder={'Ф.И.О. Директора*'}
                    value={newSchoolInfo.director}
                    name={'director'}
                    onChange={handleSchoolChange}
                    inputProps={{style: inputStyle}}
                    required={true}
                    disabled={registerLoading}
                />

                <InputBase
                    type={'text'}
                    placeholder={'Основной номер телефона*'}
                    value={newSchoolInfo.phone1}
                    name={'phone1'}
                    onChange={handleSchoolChange}
                    inputProps={{style: inputStyle}}
                    required={true}
                    disabled={registerLoading}
                />

                <InputBase
                    type={'text'}
                    placeholder={'Дополнительный номер телефона'}
                    value={newSchoolInfo.phone2}
                    name={'phone2'}
                    onChange={handleSchoolChange}
                    inputProps={{style: inputStyle}}
                    required={false}
                    disabled={registerLoading}
                />

                <FormControl sx={{mt: 1}}>
                    <InputLabel id={'region-label'}>
                        Регион
                    </InputLabel>
                    <Select
                        labelId={'region-label'}
                        onChange={handleRegionChange}
                        name={'region'}
                        label={'Регион'}
                        sx={{
                            ".MuiOutlinedInput-notchedOutline": {
                                borderWidth: 0,
                                boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
                            }
                        }}
                        value={newSchoolInfo.region}
                        required={true}
                    >
                        {regionsArray?.items.map((region) => {
                            return (
                                <MenuItem
                                    value={region.name_ru}
                                    key={region.name_ru}
                                >
                                    {region.name_ru}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <FormControl sx={{mt: 1}}>
                    <InputLabel id={'locality-label'}>
                        Населенный пункт
                    </InputLabel>
                    <Select
                        labelId={'locality-label'}
                        onChange={handleLocalitiesChange}
                        name={'locality'}
                        label={'Населенный пункт'}
                        sx={{
                            ".MuiOutlinedInput-notchedOutline": {
                                borderWidth: 0,
                                boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
                            }
                        }}
                        value={newSchoolInfo.locality ? newSchoolInfo.locality : ''}
                        required={true}
                        disabled={localitiesArray.length === 0}
                    >
                        {localitiesArray.map((locality) => {
                            return (
                                <MenuItem
                                    value={locality.name_ru}
                                    key={locality.name_ru}
                                >
                                    {locality.name_ru}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <InputBase
                    type={'text'}
                    placeholder={'Адрес учебной организации'}
                    value={newSchoolInfo.address}
                    name={'address'}
                    onChange={handleSchoolChange}
                    inputProps={{style: inputStyle}}
                    required={false}
                    disabled={registerLoading}
                />

                <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '10px', maxWidth: '400px'}}>
                    <Typography variant={'h6'}>
                        Категории учебной организации, по которым разрешено проводить обучение:
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('A')}
                                disabled={registerLoading}
                                name={'A'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'А'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('B')}
                                disabled={registerLoading}
                                name={'B'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'B'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('BE')}
                                disabled={registerLoading}
                                name={'BE'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'BE'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('C')}
                                disabled={registerLoading}
                                name={'C'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'C'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('CE')}
                                disabled={registerLoading}
                                name={'CE'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'CE'}
                    />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('D')}
                                disabled={registerLoading}
                                name={'D'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'D'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('DE')}
                                disabled={registerLoading}
                                name={'DE'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'DE'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('Tb')}
                                disabled={registerLoading}
                                name={'Tb'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'Tb'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('Tm')}
                                disabled={registerLoading}
                                name={'Tm'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'Tm'}
                    />
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '10px', maxWidth: '400px'}}>
                    <Typography variant={'h6'}>
                        Подкатегории учебной организации, по которым разрешено проводить обучение:
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('A1')}
                                disabled={registerLoading}
                                name={'A1'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'A1'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('B1')}
                                disabled={registerLoading}
                                name={'B1'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'B1'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('C1')}
                                disabled={registerLoading}
                                name={'C1'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'C1'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('C1E')}
                                disabled={registerLoading}
                                name={'C1E'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'C1E'}
                    />
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('D1')}
                                disabled={registerLoading}
                                name={'D1'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'D1'}
                    />
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <FormControlLabel
                        className={'category-label'}
                        control={(
                            <Checkbox
                                checked={newSchoolInfo.categories.includes('D1E')}
                                disabled={registerLoading}
                                name={'D1E'}
                                onChange={handleCategoriesChange}
                                className={'register-checkbox'}
                            />
                        )}
                        label={'D1E'}
                    />
                </Box>

                {!registerLoading && (
                    <Stack gap={2}>

                        <Button
                            onClick={() => {
                                finishAdd();
                            }}
                            variant={'contained'}
                            disabled={registerLoading}
                        >
                            Добавить
                        </Button>

                        {isAdmin && (
                            <Button
                                onClick={() => {
                                    setCurrentStep(0);
                                }}
                                variant={'contained'}
                                color={'error'}
                                disabled={registerLoading}
                            >
                                Назад
                            </Button>
                        )}

                    </Stack>
                )}

                {registerLoading && <CircularProgress/>}
            </Box>
        </Box>
    );
}