import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {Alert, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {apiPrecalculateDate} from "@/modules/groups/services/group.service";
import {useMemo} from "react";
import {getMessageFromError} from "@/global/helpers/getMessageFromError";

interface Props {
    group_id: number
}

export function DateEndGroupModal({open, group_id, close}: ModalSystemProps<Props>) {
    const {data, isLoading, error} = apiPrecalculateDate(group_id);

    const text = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.message.split('|').map((el) => el.trim());
    }, [data]);

    return (
        <Dialog
            open={open}
            onClose={() => close(0)}
        >
            <DialogTitle>
                Предварительная дата окончания
            </DialogTitle>
            <DialogContent>
                {
                    isLoading && (
                        <Stack
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <CircularProgress/>
                        </Stack>
                    )
                }
                {
                    data && (
                        <Alert severity={'info'}>
                            {text.map((el, index) => (
                                <Typography key={index}>
                                    {el}
                                </Typography>
                            ))}
                        </Alert>
                    )
                }
                {
                    !!error && (
                        <Alert severity={'error'}>
                            {getMessageFromError(error)}
                        </Alert>
                    )
                }
            </DialogContent>
        </Dialog>
    );
}