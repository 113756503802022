import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import type {CertificateApplicationDto} from "@/global/api/gen/ts/CertificateApplicationDto";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField} from "@mui/material";
import {useState} from "react";
import {apiUpdateTrackNumber} from "@/modules/certificates/services/certificates.service";
import {LoadingButton} from "@mui/lab";
import {toast} from "react-toastify";

interface Props {
    application: CertificateApplicationDto,
}

export function AddTrackNumberModal({open, close, application}: ModalSystemProps<Props>) {
    const [trackNumber, setTrackNumber] = useState(application.tracking_number ?? '');

    const {mutate, isPending} = apiUpdateTrackNumber();

    const onSubmit = () => {
        mutate({certificate_application_id: application.id, tracking_number: trackNumber}, {
            onSuccess: () => {
                toast.success('Трек номер изменен');
                close(0);
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(5)}
        >
            <DialogTitle>
                Изменение трек-номера для заявки №
                {' '}
                {application.id}
                {' '}
                c ИИН
                {' '}
                {application.iin}
            </DialogTitle>
            <DialogContent>
                <Stack
                    sx={{
                        paddingY: 2,
                    }}
                >
                    <TextField
                        label={'Трек-номер'}
                        value={trackNumber}
                        onChange={(e) => setTrackNumber(e.target.value)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack flexDirection={{sm: 'row', xs: 'column'}}>
                    <Button
                        color={'info'}
                        onClick={() => close(5)}
                        sx={{
                            minWidth: '150px'
                        }}
                    >
                        Закрыть
                    </Button>
                    <LoadingButton
                        loading={isPending}
                        onClick={onSubmit}
                        variant={'contained'}
                    >
                        Добавить трек номер
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}