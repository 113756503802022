import {DataGrid as BaseGrid, type DataGridProps, type GridCallbackDetails, type GridSortModel} from '@mui/x-data-grid';
import {useEffect, useMemo, useState} from 'react';
import {ruRU} from '@mui/x-data-grid/locales';
import type {SxProps} from '@mui/system';
import type {Theme} from '@mui/material';
import NoRows from "@/global/components/NoRows";
import {CustomPagination} from "@/global/components/CustomPagination";

interface Props<T extends string> extends DataGridProps {
	sortEnum?: T;
	setSort?: (sort: T[]) => void;
	sort?: T[];
}

export function ServerDataGrid<T extends string>({
													 loading,
													 rowCount,
													 sortModel,
													 onSortModelChange,
													 setSort,
													 sortEnum,
													 sx,
													 ...props
												 }: Props<T>) {
	const [count, setCount] = useState(0);

	useEffect(() => {
		if (!rowCount || rowCount === count || loading) return;
		setCount(rowCount);
	}, [rowCount]);

	const onSortModelChangeCustom = (
		model: GridSortModel,
		details: GridCallbackDetails
	) => {
		if (onSortModelChange) {
			onSortModelChange(model, details);
		}
		if (!setSort) {
			return;
		}
		if (!sortEnum) {
			setSort(model.map((el) => `${el.field}.${el.sort}`) as T[]);
			return;
		}
		const available = Object.values(sortEnum);
		const result = [];

		for (const el of model) {
			const value = `${el.field}.${el.sort}` as T;
			if (available.includes(value as T)) {
				result.push(value);
			}
		}

		setSort(result);
	};

	const dataGridsx: SxProps<Theme> = useMemo(
		() => ({
			border: 0,
			boxShadow:
				'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
			backgroundColor: '#FFFFFF',
			borderRadius: '15px',
			color: 'rgba(0,0,0,.85)',
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"'
			].join(','),
			WebkitFontSmoothing: 'auto',
			letterSpacing: 'normal',
			'& .MuiDataGrid-columnsContainer': {
				backgroundColor: '#FFFFFF'
			},
			'& .MuiDataGrid-iconSeparator': {
				display: 'none'
			},
			'& .MuiDataGrid-main': {
				borderTopLeftRadius: 20,
				borderTopRightRadius: 20
			},
			'& .MuiDataGrid-row': {
				cursor:
					typeof props.onRowClick === 'function'
					? 'pointer'
					: undefined
			},
			'& .MuiDataGrid-row:nth-of-type(odd)': {
				background: 'rgba(40, 75, 156, 0.03)'
			},
			'& .MuiDataGrid-row:nth-of-type(odd):hover': {
				background: 'rgba(40, 75, 156, 0.1)'
			},
			'& .MuiDataGrid-columnHeaders': {
				backgroundColor: '#FFFFFF',
				border: 0,
				position: 'relative',
				color: 'rgba(11, 18, 49, 0.48)',
				borderBottom: '1px solid rgba(11, 18, 49, 0.1);'
			},
			'& .MuiDataGrid-virtualScroller': {
				overflowY: 'hidden',
				minHeight: '50px'
			},
			'& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
				borderBottom: `1px solid #FFFFFF`
			},
			'& .MuiDataGrid-cell': {
				color: 'rgba(0,0,0,.85)'
			},
			'& .MuiDataGrid-cell:nth-of-type(1)': {
				paddingLeft: 7.5
			},
			'& .MuiPaginationItem-root': {
				borderRadius: 0
			},
			...(sx ? sx : {})
		}),
		[sx]
	);

	return (
		<BaseGrid
			sortingMode={'server'}
			rowHeight={74}
			loading={loading}
			rowCount={count}
			paginationMode={'server'}
			pageSizeOptions={[25]}
			sortModel={sortModel}
			onSortModelChange={onSortModelChangeCustom}
			rowSelection={false}
			localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
			{...props}
			slots={{
				noRowsOverlay: NoRows,
				pagination: CustomPagination,
			}}
			sx={dataGridsx}
		/>
	);
}
