import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerSoapDeleteGroupClassMutationRequest,
  GroupsControllerSoapDeleteGroupClassMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerSoapDeleteGroupClass'

export function getGroupsControllerSoapDeleteGroupClassUrl() {
  return `/groups/delete-class` as const
}

/**
 * {@link /groups/delete-class}
 */
export async function groupsControllerSoapDeleteGroupClass(
  data: GroupsControllerSoapDeleteGroupClassMutationRequest,
  config: Partial<RequestConfig<GroupsControllerSoapDeleteGroupClassMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerSoapDeleteGroupClassMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerSoapDeleteGroupClassMutationRequest
  >({ method: 'POST', url: getGroupsControllerSoapDeleteGroupClassUrl().toString(), data, ...requestConfig })
  return res
}