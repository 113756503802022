import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerDetachEmployeeFromSchoolMutationRequest,
  EmployeeControllerDetachEmployeeFromSchoolMutationResponse,
} from '../../ts/employeesDTO/EmployeeControllerDetachEmployeeFromSchool'

export function getEmployeeControllerDetachEmployeeFromSchoolUrl() {
  return `/employees/detach` as const
}

/**
 * @summary Открепления сотрудника от школы
 * {@link /employees/detach}
 */
export async function employeeControllerDetachEmployeeFromSchool(
  data: EmployeeControllerDetachEmployeeFromSchoolMutationRequest,
  config: Partial<RequestConfig<EmployeeControllerDetachEmployeeFromSchoolMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    EmployeeControllerDetachEmployeeFromSchoolMutationResponse,
    ResponseErrorConfig<Error>,
    EmployeeControllerDetachEmployeeFromSchoolMutationRequest
  >({ method: 'POST', url: getEmployeeControllerDetachEmployeeFromSchoolUrl().toString(), data, ...requestConfig })
  return res
}