import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { GroupsControllerCreateGroupMutationRequest, GroupsControllerCreateGroupMutationResponse } from '../../ts/GroupsDTO/GroupsControllerCreateGroup'

export function getGroupsControllerCreateGroupUrl() {
  return `/groups` as const
}

/**
 * @summary Создание группы
 * {@link /groups}
 */
export async function groupsControllerCreateGroup(
  data: GroupsControllerCreateGroupMutationRequest,
  config: Partial<RequestConfig<GroupsControllerCreateGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerCreateGroupMutationResponse, ResponseErrorConfig<Error>, GroupsControllerCreateGroupMutationRequest>({
    method: 'POST',
    url: getGroupsControllerCreateGroupUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}