import React from 'react';
import {Alert, Button, Grid, Paper, Stack, type SxProps, type Theme, Tooltip, Typography} from '@mui/material';
import {DeleteOutline} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import {useTranslation} from 'react-i18next';

import {useUserStore} from '@/global/store/UserStore';
import {FRONTEND_URL} from '@/global/actions/apiVariables';

import GroupStepper from './GroupStepper';
import AdminChangeDates from './AdminChangeDates';
import AdminChangeTitle from './AdminChangeTitle';
import AdminChangeCategory from './AdminChangeCategory';
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {useModal} from "@/global/providers/ModalProvider";
import {ConfirmDialog} from "@/Group/components/GroupPage/ConfirmDialog";

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface GroupMainPageProps {
    groupInfo: GroupDataDto;
    addStudent: () => void;
    formGroup: () => void;
}

function GroupMainPage({groupInfo, addStudent, formGroup}: GroupMainPageProps) {
    const {t} = useTranslation();

    const currentUser = useUserStore((state) => state);
    const {openModal} = useModal();

    const openConfirmDialog = () => {
        openModal(ConfirmDialog, {groupInfo}, `confirm_${groupInfo.id}`);
    };

    return (
        <Grid
            container
            spacing={5}
        >
            <Grid
                item
                xs={12}
                lg={8}
                xl={8}
            >
                <Paper sx={{...paperSx}}>
                    <Typography variant="h4">
                        Статус группы
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1.5}}
                        color="gray"
                    >
                        {t('school_page.group_page.groups_main_page.follow_steps')}
                    </Typography>
                    <GroupStepper
                        groupInfo={groupInfo}
                        addStudent={addStudent}
                        formGroup={formGroup}
                    />
                </Paper>
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                xl={4}
            >
                <Paper sx={{...paperSx, display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <Typography
                        variant="h4"
                        sx={{mb: 1.5}}
                    >
                        {t('school_page.group_page.groups_main_page.actions')}
                    </Typography>

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <Button
                            startIcon={<DeleteOutline/>}
                            color="error"
                            onClick={openConfirmDialog}
                            disabled={true}
                            variant={'contained'}
                            sx={{maxWidth: '300px', minWidth: '200px'}}
                        >
                            {t('school_page.group_page.groups_main_page.delete_group')}
                        </Button>

                        <Tooltip
                            title={t('school_page.group_page.groups_main_page.start_study.before')}
                            sx={{ml: 2}}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Stack>

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <Button
                            color="primary"
                            href={`${FRONTEND_URL}/enrollment/${groupInfo.id}`}
                            target={'_blank'}
                            disabled={groupInfo.status < 2}
                            variant={'contained'}
                            sx={{maxWidth: '300px', minWidth: '200px'}}
                        >
                            {t('school_page.group_page.groups_main_page.documentation-1')}
                        </Button>

                        <Tooltip
                            title={t('school_page.group_page.groups_main_page.start_study.after')}
                            sx={{ml: 2}}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Stack>

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <Button
                            color="primary"
                            href={`${FRONTEND_URL}/exams-protocol/${groupInfo.id}`}
                            target={'_blank'}
                            disabled={groupInfo.status !== 4}
                            variant={'contained'}
                            sx={{maxWidth: '300px', minWidth: '200px'}}
                        >
                            {t('school_page.group_page.groups_main_page.documentation-2')}
                        </Button>

                        <Tooltip
                            title={`Доступно только ПОСЛЕ завершения обучения`}
                            sx={{ml: 2}}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Stack>

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <Button
                            color="primary"
                            href={`${FRONTEND_URL}/graduation/${groupInfo.id}`}
                            target={'_blank'}
                            disabled={groupInfo.status !== 4}
                            variant={'contained'}
                            sx={{maxWidth: '300px', minWidth: '200px'}}
                        >
                            {t('school_page.group_page.groups_main_page.documentation-3')}
                        </Button>

                        <Tooltip
                            title={`Доступно только ПОСЛЕ завершения обучения`}
                            sx={{ml: 2}}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Stack>
                </Paper>
            </Grid>

            {currentUser.isAdmin && (currentUser.iin === '970812350705' || currentUser.iin === '111122223333') && (
                <Grid
                    item
                    xs={12}
                    lg={4}
                    xl={4}
                >
                    <Paper sx={{...paperSx, mb: 5, display: 'flex', flexDirection: 'column', gap: 3}}>
                        <Typography
                            variant="h4"
                            sx={{mb: 1.5}}
                        >
                            Действия супер-администратора
                        </Typography>

                        {currentUser.isAdmin && (
                            <Alert severity="info">
                                Осторожно: у вашего аккаунта есть доступ к редактированию всех групп в системе.
                                Поэтому вы можете изменять даже сформированные группы.
                            </Alert>
                        )}

                        <AdminChangeCategory
                            groupInfo={groupInfo}
                        />

                        {groupInfo.status >= 2 && (
                            <AdminChangeDates
                                groupInfo={groupInfo}
                            />
                        )}

                        <AdminChangeTitle
                            groupInfo={groupInfo}
                        />

                        <Button
                            onClick={addStudent}
                            variant={'contained'}
                        >
                            Добавить курсанта
                        </Button>
                    </Paper>
                </Grid>
            )}

        </Grid>
    );
}

export default GroupMainPage;