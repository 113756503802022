import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateApplicationControllerSearchCertificateApplicationQueryResponse,
  CertificateApplicationControllerSearchCertificateApplicationQueryParams,
} from '../../ts/certificate-applicationDTO/CertificateApplicationControllerSearchCertificateApplication'

export function getCertificateApplicationControllerSearchCertificateApplicationUrl() {
  return `/certificate-application/search` as const
}

/**
 * @summary Поиск заявок на сертификат
 * {@link /certificate-application/search}
 */
export async function certificateApplicationControllerSearchCertificateApplication(
  params: CertificateApplicationControllerSearchCertificateApplicationQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateApplicationControllerSearchCertificateApplicationQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateApplicationControllerSearchCertificateApplicationUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}