import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerApproveAppealMutationResponse,
  RegisterAppealsControllerApproveAppealPathParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerApproveAppeal'

export function getRegisterAppealsControllerApproveAppealUrl({ id }: { id: RegisterAppealsControllerApproveAppealPathParams['id'] }) {
  return `/register-appeals/approve/${id}` as const
}

/**
 * @summary Подтвердить заявку на регистрацию по id
 * {@link /register-appeals/approve/:id}
 */
export async function registerAppealsControllerApproveAppeal(
  { id }: { id: RegisterAppealsControllerApproveAppealPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerApproveAppealMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getRegisterAppealsControllerApproveAppealUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}