import {BACKEND_URL} from '@/global/actions/apiVariables';
import axiosInstance from '../../global/actions/main.api';

import {type TestQuestionsFilterDto} from './testQuestionsSearch.type';

export const getCategories = () => {
    return axiosInstance.get(`${BACKEND_URL}/tests/questions/categories`);
};

export const getQuestionsSearch = (query: TestQuestionsFilterDto) => {
    const queryString = !query.category_id ? `?count=${query.count}&offset=${query.offset}&sort=${query.sort}` :
                                            `?count=${query.count}&offset=${query.offset}&sort=${query.sort}&category_id=${query.category_id}`;
    const queryString2 = !query.q ? `${queryString}` :
                                        `${queryString}&q=${query.q}`;
    return axiosInstance.get(`${BACKEND_URL}/tests/questions/search${queryString2}`);
};

export const getQuestionById = (id: number | null | undefined) => {
    console.log('ID    ' + id);
    return axiosInstance.get(`${BACKEND_URL}/tests/questions/${id}`);
};
export const createQuestion = (questionData: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions`, questionData);
};
export const updateQuestion = (questionId: string | number, questionData: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions/update/${questionId}`, questionData);
};
export const reportQuestion = (questionId: string | number, questionData: any) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions/report/${questionId}`, questionData);
};

export const deleteQuestionById = (id: number | null | undefined) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions/delete/${id}`);
};
export const updateQuestionCategory = (questionId: number, categoryId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/tests/questions/${questionId}/category/${categoryId}`);
};