import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export function AutomatizationBanner() {
    const {t} = useTranslation();

    return (
        <Box
            flexDirection={'column'}
            sx={{
                p: {xs: '0', lg: '50px'},
                background: '#fff',
                borderRadius: '30px',
                mt: {xs: '44px', lg: '100px'},
            }}
        >
            <Typography
                fontSize={{xs: '35px', lg: '59px'}}
                lineHeight={'98%'}
                color={'#05304F'}
                fontWeight={'bold'}
                sx={{
                    maxWidth: '800px'
                }}
            >
                {t('landing.schools-landing.about-title')}
            </Typography>
            <Typography
                fontSize={{xs: '16px', lg: '17px'}}
                lineHeight={{xs: '130%', lg: '150%'}}
                color={'#05304F'}
                sx={{
                    mt: {xs: '28px', lg: '50px'},
                    maxWidth: '758px'
                }}
            >
                {t('landing.schools-landing.about-content-1')}
                {' '}
                {t('landing.schools-landing.about-content-2')}
            </Typography>

            <Stack
                flexDirection={{xs:'column', lg: 'row'}}
                gap={'20px'}
                sx={{
                    mt: {xs: '42px', lg: '100px'}
                }}
            >
                <Stack
                    flexDirection={'column'}
                    sx={{
                        p: '30px',
                        background: '#f5f5f5',
                        borderRadius: '20px',
                        height: '202px',
                        width: '310px'
                    }}
                >
                    <svg
                        width="20"
                        height="25"
                        viewBox="0 0 20 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.88874 8.88874V0H2.22218C0.994905 0 0 0.994905 0 2.22218V22.2218C0 23.4492 0.994905 24.444 2.22218 24.444H17.7775C19.0048 24.444 19.9997 23.4492 19.9997 22.2218V11.1109H11.1109C9.88361 11.1109 8.88874 10.1161 8.88874 8.88874Z"
                            fill="#1E9AF3"
                        />
                        <path
                            d="M11.1113 0V8.88874H20.0001L11.1113 0Z"
                            fill="#1E9AF3"
                        />
                    </svg>

                    <Typography
                        fontSize={'19px'}
                        lineHeight={'140%'}
                        letterSpacing={'-0.02'}
                        color={'#05304F'}
                        sx={{
                            mt: 'auto'
                        }}
                    >
                        {t('landing.schools-landing.documentation-1')}
                    </Typography>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        p: '30px',
                        background: '#f5f5f5',
                        borderRadius: '20px',
                        height: '202px',
                        width: '310px'
                    }}
                >
                    <svg
                        width="20"
                        height="25"
                        viewBox="0 0 20 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.88874 8.88874V0H2.22218C0.994905 0 0 0.994905 0 2.22218V22.2218C0 23.4492 0.994905 24.444 2.22218 24.444H17.7775C19.0048 24.444 19.9997 23.4492 19.9997 22.2218V11.1109H11.1109C9.88361 11.1109 8.88874 10.1161 8.88874 8.88874Z"
                            fill="#1E9AF3"
                        />
                        <path
                            d="M11.1113 0V8.88874H20.0001L11.1113 0Z"
                            fill="#1E9AF3"
                        />
                    </svg>

                    <Typography
                        fontSize={'19px'}
                        lineHeight={'140%'}
                        letterSpacing={'-0.02'}
                        color={'#05304F'}
                        sx={{
                            mt: 'auto'
                        }}
                    >
                        {t('landing.schools-landing.documentation-2')}
                    </Typography>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        p: '30px',
                        background: '#f5f5f5',
                        borderRadius: '20px',
                        height: '202px',
                        width: '310px'
                    }}
                >
                    <svg
                        width="20"
                        height="25"
                        viewBox="0 0 20 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.88874 8.88874V0H2.22218C0.994905 0 0 0.994905 0 2.22218V22.2218C0 23.4492 0.994905 24.444 2.22218 24.444H17.7775C19.0048 24.444 19.9997 23.4492 19.9997 22.2218V11.1109H11.1109C9.88361 11.1109 8.88874 10.1161 8.88874 8.88874Z"
                            fill="#1E9AF3"
                        />
                        <path
                            d="M11.1113 0V8.88874H20.0001L11.1113 0Z"
                            fill="#1E9AF3"
                        />
                    </svg>

                    <Typography
                        fontSize={'19px'}
                        lineHeight={'140%'}
                        letterSpacing={'-0.02'}
                        color={'#05304F'}
                        sx={{
                            mt: 'auto'
                        }}
                    >
                        {t('landing.schools-landing.documentation-3')}
                    </Typography>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        p: '30px',
                        background: '#f5f5f5',
                        borderRadius: '20px',
                        height: '202px',
                        width: '310px'
                    }}
                >
                    <svg
                        width="20"
                        height="25"
                        viewBox="0 0 20 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.88874 8.88874V0H2.22218C0.994905 0 0 0.994905 0 2.22218V22.2218C0 23.4492 0.994905 24.444 2.22218 24.444H17.7775C19.0048 24.444 19.9997 23.4492 19.9997 22.2218V11.1109H11.1109C9.88361 11.1109 8.88874 10.1161 8.88874 8.88874Z"
                            fill="#1E9AF3"
                        />
                        <path
                            d="M11.1113 0V8.88874H20.0001L11.1113 0Z"
                            fill="#1E9AF3"
                        />
                    </svg>

                    <Typography
                        fontSize={'19px'}
                        lineHeight={'140%'}
                        letterSpacing={'-0.02'}
                        color={'#05304F'}
                        sx={{
                            mt: 'auto'
                        }}
                    >
                        {t('landing.schools-landing.documentation-4')}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    );
}