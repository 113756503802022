import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CategoriesControllerGetAllCategoriesQueryResponse,
  CategoriesControllerGetAllCategoriesQueryParams,
} from '../../ts/CategoriesDTO/CategoriesControllerGetAllCategories'

export function getCategoriesControllerGetAllCategoriesUrl() {
  return `/categories` as const
}

/**
 * @summary Получение всех категорий
 * {@link /categories}
 */
export async function categoriesControllerGetAllCategories(
  params: CategoriesControllerGetAllCategoriesQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CategoriesControllerGetAllCategoriesQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCategoriesControllerGetAllCategoriesUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}