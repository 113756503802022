import type {
    TransactionsControllerGetProductsPricesQueryParams
} from "@/global/api/gen/ts/TransactionsDTO/TransactionsControllerGetProductsPrices";

export const TransactionsKeys = {
    all: ['transactions'] as const,
    search: (params: TransactionsControllerGetProductsPricesQueryParams) => [...TransactionsKeys.all, 'search', params] as const,
    detail: (id:number | string | undefined | null) => [...TransactionsKeys.all, 'detail', id] as const,
    quickDetail: (id:number) => [...TransactionsKeys.detail(id), 'quick'] as const,
    refresh: (id:number) => [...TransactionsKeys.all, 'refresh', id] as const,
    quickRefresh: (id:number) => [...TransactionsKeys.refresh(id), 'quick'] as const,
    product: (id:string) => [...TransactionsKeys.all, 'product', id] as const,
};