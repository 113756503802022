import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerGetAllSchoolMastersQueryResponse,
  EmployeeControllerGetAllSchoolMastersPathParams,
  EmployeeControllerGetAllSchoolMastersQueryParams,
} from '../../ts/employeesDTO/EmployeeControllerGetAllSchoolMasters'

export function getEmployeeControllerGetAllSchoolMastersUrl({ id }: { id: EmployeeControllerGetAllSchoolMastersPathParams['id'] }) {
  return `/employees/school/master/${id}` as const
}

/**
 * @summary Получение всех мастеров производственного обучения
 * {@link /employees/school/master/:id}
 */
export async function employeeControllerGetAllSchoolMasters(
  { id }: { id: EmployeeControllerGetAllSchoolMastersPathParams['id'] },
  params: EmployeeControllerGetAllSchoolMastersQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerGetAllSchoolMastersQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getEmployeeControllerGetAllSchoolMastersUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}