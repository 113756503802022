import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useSchoolStore} from "@/School/store/SchoolStore";
import {useSnackbarStore} from "@/global/store/SnackbarStore";
import {apiSearchActiveDrivers} from "@/modules/employees/services/employees.service";
import {apiAttachDriverToGroup} from "@/modules/groups/services/group.service";
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";

interface DriversModalProps {
    groupInfo: GroupDataDto;
}

export function DriversModal({groupInfo, open, close} : ModalSystemProps<DriversModalProps>) {

    const {t} = useTranslation();

    const [driverId, setDriverId] = useState<string>('');
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);

    const {data: driversList} = apiSearchActiveDrivers(+(schoolInfo.id ?? 0), {
        limit: 100,
        offset: 0,
    });

    const {mutate} = apiAttachDriverToGroup();

    const handleSelectChange = (e: SelectChangeEvent) => {
        setDriverId(e.target.value);
    };

    const handleAttach = () => {

        if (!groupInfo.id || !driverId) {
            snackbar.errorMessage(t('school_page.group_page.attributes_window.modal.required.driver'));
            return;
        }

        const driverDto = {
            group_id: groupInfo.id,
            driver_id: Number(driverId)
        };

        mutate(driverDto, {
            onSuccess: () => {
                close(0);
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(5)}
        >
            <DialogTitle>
                {t('school_page.group_page.attributes_window.modal.add_dialog.driver')}
                {' '}
                <i>
                    <b>
                        {groupInfo.title}
                    </b>
                </i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={driverId}
                    >
                        {driversList?.items.map((teacher) => {
                            return (
                                <MenuItem
                                    value={teacher.id}
                                    key={teacher.id}
                                >
                                    <Stack
                                        sx={{width: '100%'}}
                                        flexDirection="row"
                                        alignItems={'center'}
                                    >
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {teacher.last_name[0]}
                                            {teacher.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{width: '100%'}}
                                            >
                                                <Typography>
                                                    {teacher.last_name}
                                                    {' '}
                                                    {teacher.first_name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="gray"
                                                >
                                                    #
                                                    {teacher.id}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Typography
                        variant="body2"
                        sx={{mt: 1}}
                    >
                        {t('school_page.group_page.attributes_window.modal.reminder.driver')}
                    </Typography>

                    <Button onClick={handleAttach}>
                        Прикрепить мастера обучения вождению
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}