import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransportControllerGetAllSchoolTransportsQueryResponse,
  TransportControllerGetAllSchoolTransportsPathParams,
  TransportControllerGetAllSchoolTransportsQueryParams,
} from '../../ts/TransportDTO/TransportControllerGetAllSchoolTransports'

export function getTransportControllerGetAllSchoolTransportsUrl({ id }: { id: TransportControllerGetAllSchoolTransportsPathParams['id'] }) {
  return `/transport/school/${id}` as const
}

/**
 * @summary Получение всех транспортов
 * {@link /transport/school/:id}
 */
export async function transportControllerGetAllSchoolTransports(
  { id }: { id: TransportControllerGetAllSchoolTransportsPathParams['id'] },
  params: TransportControllerGetAllSchoolTransportsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransportControllerGetAllSchoolTransportsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTransportControllerGetAllSchoolTransportsUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}