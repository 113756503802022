import React, {type ChangeEvent, useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogContent, DialogTitle, Stack, Tab, Tabs, Typography} from '@mui/material';

import '../styles/RegisterPage.scss';
import {Link, useNavigate} from 'react-router-dom';
import {Looks3, Looks4, LooksOne, LooksTwo} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import type {IRegisterAttributes, IRegisterSchool, IRegisterUser} from '../dto/AuthDto';
import {getLocalitiesByRegionId, register, registerByAdmin} from '../actions/api';
import PageHeader from '../../global/components/PageHeader';
import {FirstStep} from '../components/FirstStep';
import {ThirdStep} from '../components/ThirdStep';
import {useUserStore} from '@/global/store/UserStore';
import FourthStep from '../components/FourthStep';
import SecondStep from '../components/SecondStep';
import {apiGetRegionList} from "@/modules/regions/services/regions.service";
import {getMessageFromError} from "@/global/helpers/getMessageFromError";

function RegisterPage() {

    const navigate = useNavigate();
    const currentUser = useUserStore((state) => state);

    const {t} = useTranslation();

    const [schoolForm, setSchoolForm] = useState<IRegisterSchool>({
        bin: '',
        title: '',
        director: '',
        director_iin: '',
        director_qualification_certificate_number: '',
        director_qualification_certificate_issuer: '',
        director_qualification_certificate_issue_date: '',
        director_qualification_certificate_end_date: '',
        email: '',
        region: '',
        locality: '',
        address: '',
        phone1: '',
        phone2: '',
        type: 'legal',
        categories: [],
        is_gov: false
    });
    const [userForm, setUserForm] = useState<IRegisterUser>({
        iin: '',
        gender: 0,
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        phone: '',
        address: '',
        password: ''
    });
    const [attributesForm, setAttributesForm] = useState<IRegisterAttributes>({
        teachers: [{
            iin: '',
            qualification_certificate: '',
            qualification_certificate_issuer: '',
            qualification_certificate_issue_date: '',
            qualification_certificate_end_date: ''
        }],
        drivers: [{
            iin: '',
            qualification_certificate: '',
            qualification_certificate_issuer: '',
            qualification_certificate_issue_date: '',
            qualification_certificate_end_date: ''
        }],
        masters: [{
            iin: '',
            qualification_certificate: '',
            qualification_certificate_issuer: '',
            qualification_certificate_issue_date: '',
            qualification_certificate_end_date: ''
        }],
        transports: [{plate_number: '', description: ''}],
    });
    const [uploadedImages, setUploadedImages] = useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [registerLoading, setRegisterLoading] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const {data: regionsArray} = apiGetRegionList({
        limit: 100,
        offset: 0
    });
    const [localitiesArray, setLocalitiesArray] = useState<any[]>([]);
    const [registerFinished, setRegisterFinished] = useState<boolean>(false);
    const [signString, setSignString] = useState<string>('');

    const handleSchoolChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setSchoolForm((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setUserForm((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = schoolForm.categories.concat(name);

            setSchoolForm((prev) => {
                return {
                    ...prev,
                    categories: newCategories
                };
            });
        }

        if (!checked) {
            const isIncluded = schoolForm.categories.includes(name);

            if (isIncluded) {
                const newCategories: string[] = [];

                for (let i = 0; i < schoolForm.categories.length; i++) {
                    const element = schoolForm.categories[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setSchoolForm((prev) => {
                    return {
                        ...prev,
                        categories: newCategories
                    };
                });
            }
        }
    };

    const handleIsGovChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;

        setSchoolForm((prev) => {
            return {
                ...prev,
                is_gov: checked
            };
        });
    };

    const handleAttachedFiles = (files: File[]) => {
        setUploadedFiles(files);
    };

    const handleImageEvent = (e: ChangeEvent<HTMLInputElement>) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleAttachedImages(chosenFiles);
    };

    const handleAttachedImages = (files: File[]) => {
        const uploaded = [...uploadedImages];

        if ((files.length + uploaded.length) > 5) {
            toast.error('Максимально допустимое количество файлов - 5');
            return;
        }
        files.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
        });
        setUploadedImages(uploaded);
    };

    const finishReg = () => {

        if (registerLoading) {
            return;
        }

        const formData = new FormData();

        const schoolInfo = {
            ...schoolForm,
        };

        const userInfo = {
            ...userForm,
            patronymic: userForm.patronymic ?? null,
        };
        const attributesInfo = {
            ...attributesForm
        };

        if (schoolInfo.locality === 'Другое (вписать в адрес)') {
            formData.set('school', JSON.stringify({...schoolInfo, locality: 'another_locality'}));
        } else {
            formData.set('school', JSON.stringify(schoolInfo));
        }
        formData.set('user', JSON.stringify(userInfo));
        formData.set('attributes', JSON.stringify(attributesInfo));
        formData.set('signData', signString);

        if (uploadedImages.length > 0) {
            for (let i = 0; i < uploadedImages.length; i++) {
                const image = uploadedImages[i];
                formData.append('img', image);
            }
        }

        if (uploadedFiles.length > 0) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i];
                formData.append('file', file);
            }
        }

        if (currentUser.iin && currentUser.isAdmin) {
            setRegisterLoading(true);
            registerByAdmin(formData)
                .then(() => {
                    toast.info(`Автошкола ${schoolInfo.title} зарегистрирована. Аккаунт активен.`);
                    setCurrentStep(0);
                    cleanInputs();
                    setRegisterFinished(true);
                })
                .catch((e) => {
                    toast.error(getMessageFromError(e));
                })
                .finally(() => {
                    setRegisterLoading(false);
                    return;
                });
        } else {
            setRegisterLoading(true);
            register(formData)
                .then(() => {
                    toast.info(t('auth.register-page.success-message'));
                    setCurrentStep(0);
                    cleanInputs();
                    setRegisterFinished(true);
                })
                .catch((e) => {
                    toast.error(getMessageFromError(e));
                })
                .finally(() => {
                    setRegisterLoading(false);
                });
        }
    };

    const cleanInputs = () => {
        setSchoolForm({
            bin: '',
            title: '',
            director: '',
            director_iin: '',
            director_qualification_certificate_number: '',
            director_qualification_certificate_issuer: '',
            director_qualification_certificate_issue_date: '',
            director_qualification_certificate_end_date: '',
            email: '',
            region: '',
            locality: '',
            address: '',
            phone1: '',
            phone2: '',
            type: 'legal',
            categories: [],
            is_gov: false
        });

        setUserForm({
            iin: '',
            gender: 0,
            first_name: '',
            last_name: '',
            patronymic: '',
            email: '',
            phone: '',
            address: '',
            password: ''
        });

        setUploadedImages([]);
        setUploadedFiles([]);
    };

    const checkSchoolForm = () => {

        if (schoolForm.region === 'Алматы' || schoolForm.region === 'Астана' || schoolForm.region === 'Шымкент') {

        } else {
            if (!schoolForm.locality) {
                return false;
            }
        }

        return !(!schoolForm.bin || !schoolForm.phone1 || !schoolForm.title || !schoolForm.director || !schoolForm.director || !schoolForm.director_iin ||
            !schoolForm.director_qualification_certificate_number || !schoolForm.director_qualification_certificate_issue_date || !schoolForm.director_qualification_certificate_end_date || !schoolForm.director_qualification_certificate_issuer
            || !schoolForm.region || !schoolForm.address);
    };

    const checkUserForm = () => {
        return !(!userForm.iin || !userForm.email || !userForm.phone || !userForm.last_name || !userForm.first_name);
    };

    const nextStep = (step: number) => {

        if (step === 1) {
            const isSchoolValid = checkSchoolForm();
            if (!isSchoolValid) {
                toast.error(t('auth.register-page.required-fields'));
                return;
            }
        }

        if (step === 2) {
            const isUserValid = checkUserForm();
            if (!isUserValid) {
                toast.error(t('auth.register-page.required-fields'));
                return;
            }
        }

        setCurrentStep(step);
    };

    const setRegion = (chosenRegion: any) => {
        setSchoolForm((prev) => {
            return {
                ...prev,
                region: chosenRegion
            };
        });

        let regionId: number;

        regionsArray?.items.map((region) => {
            if (region.name_ru === chosenRegion) {
                regionId = region.id;
                getLocalitiesByRegionId(regionId, {limit: 100, offset:0})
                    .then((res) => {
                        if (regionId === 1 || regionId === 2 || regionId === 3) {
                            setLocalitiesArray(res.data.items);
                        } else {
                            setLocalitiesArray([
                                ...res.data.items,
                                {
                                    id: 50000,
                                    name_ru: 'Другое (вписать в адрес)'
                                }
                            ]);
                        }
                    });
            }
        });
    };

    const setLocality = (chosenLocality: any) => {

        if (chosenLocality === 'Другое (вписать в адрес)') {
            setSchoolForm((prev) => {
                return {
                    ...prev,
                    locality: 'Другое (вписать в адрес)'
                };
            });
            return;
        }

        setSchoolForm((prev) => {
            return {
                ...prev,
                locality: chosenLocality
            };
        });
    };

    const setType = (chosenType: any) => {
        setSchoolForm((prev) => {
            return {
                ...prev,
                type: chosenType
            };
        });
    };

    const detach = (type: string, index: number) => {
        if (type === 'image') {
            const filteredImages = uploadedImages.filter((image, arrIndex) => {
                return index !== arrIndex;
            });
            setUploadedImages(filteredImages);
        }

        if (type === 'file') {
            const filteredFiles = uploadedFiles.filter((file, arrIndex) => {
                return index !== arrIndex;
            });
            setUploadedFiles(filteredFiles);
        }
    };

    const handleSign = (signStr: string) => {
        setSignString(signStr);
    };

    useEffect(() => {
        if (schoolForm.is_gov) {
            setAttributesForm({
                teachers: [{
                    iin: '',
                    qualification_certificate: '',
                    qualification_certificate_issuer: '',
                    qualification_certificate_issue_date: '',
                    qualification_certificate_end_date: ''
                }],
                drivers: [{
                    iin: '',
                    qualification_certificate: '',
                    qualification_certificate_issuer: '',
                    qualification_certificate_issue_date: '',
                    qualification_certificate_end_date: ''
                }],
                masters: [],
                transports: [{plate_number: '', description: ''}],
            });
        } else {
            setAttributesForm({
                teachers: [{
                    iin: '',
                    qualification_certificate: '',
                    qualification_certificate_issuer: '',
                    qualification_certificate_issue_date: '',
                    qualification_certificate_end_date: ''
                }],
                drivers: [{
                    iin: '',
                    qualification_certificate: '',
                    qualification_certificate_issuer: '',
                    qualification_certificate_issue_date: '',
                    qualification_certificate_end_date: ''
                }],
                masters: [{
                    iin: '',
                    qualification_certificate: '',
                    qualification_certificate_issuer: '',
                    qualification_certificate_issue_date: '',
                    qualification_certificate_end_date: ''
                }],
                transports: [{plate_number: '', description: ''}],
            });
        }
    }, [schoolForm.is_gov]);

    return (
        <Box
            className={'register App'}
            id={'register'}
            sx={{p: 5}}
        >
            <PageHeader/>
            <Stack
                flexDirection={'row'}
                justifyContent={'center'}
                sx={{mt: '21px'}}
            >
                {currentStep === 0 && (
                    <Typography variant={'h6'}>
                        {t('auth.register-page.register-organization')}
                    </Typography>
                )}
                {currentStep === 1 && (
                    <Typography variant={'h6'}>
                        {t('auth.register-page.register-employees')}
                    </Typography>
                )}
                {currentStep === 2 && (
                    <Typography variant={'h6'}>
                        {t('auth.register-page.register-user')}
                    </Typography>
                )}
                {currentStep === 3 && (
                    <Typography variant={'h6'}>
                        {t('auth.register-page.register-sign')}
                    </Typography>
                )}
            </Stack>

            <Stack>
                <Link
                    to={'/landing'}
                    style={{width: 'fit-content', color: 'grey'}}
                >
                    {t('auth.register-page.back')}
                </Link>
            </Stack>
            <Tabs
                sx={{width: '100%'}}
                variant={'fullWidth'}
                value={currentStep}
            >
                <Tab
                    label={<LooksOne color={'primary'}/>}
                    value={0}
                    sx={{width: '50%'}}
                    disabled={true}
                />
                <Tab
                    label={currentStep === 1 || currentStep === 2 || currentStep === 3 ? <LooksTwo color={'primary'}/> :
                        <LooksTwo/>}
                    value={1}
                    sx={{width: '50%'}}
                    disabled={true}
                />
                <Tab
                    label={currentStep === 2 || currentStep === 3 ? <Looks3 color={'primary'}/> : <Looks3/>}
                    value={2}
                    sx={{width: '50%'}}
                    disabled={true}
                />
                <Tab
                    label={currentStep === 3 ? <Looks4 color={'primary'}/> : <Looks4/>}
                    value={3}
                    sx={{width: '25%'}}
                    disabled={true}
                />
            </Tabs>
            {currentStep === 0 && (
                <FirstStep
                    schoolForm={schoolForm}
                    handleSchoolChange={handleSchoolChange}
                    registerLoading={registerLoading}
                    uploadedImages={uploadedImages}
                    uploadedFiles={uploadedFiles}
                    handleImageEvent={handleImageEvent}
                    handleFileEvent={handleAttachedFiles}
                    handleCategoriesChange={handleCategoriesChange}
                    handleIsGovChange={handleIsGovChange}
                    nextStep={nextStep}
                    detach={detach}
                    regionsArray={regionsArray?.items ?? []}
                    setRegion={setRegion}
                    localitiesArray={localitiesArray}
                    setLocality={setLocality}
                    typesArray={[{name: 'Физическое лицо', value: 'natural'}, {
                        name: 'Юридическое лицо',
                        value: 'legal'
                    }]}
                    setType={setType}
                />
            )}
            {currentStep === 1 && (
                <SecondStep
                    schoolForm={schoolForm}
                    attributesForm={attributesForm}
                    setAttributesForm={setAttributesForm}
                    nextStep={setCurrentStep}
                />
            )}
            {currentStep === 2 && (
                <ThirdStep
                    userForm={userForm}
                    handleUserChange={handleUserChange}
                    registerLoading={registerLoading}
                    nextStep={nextStep}
                />
            )}
            {currentStep === 3 && (
                <FourthStep
                    signString={signString}
                    setSignString={handleSign}
                    registerLoading={registerLoading}
                    finishReg={finishReg}
                    nextStep={nextStep}
                    registerFinished={registerFinished}
                    userForm={userForm}
                    schoolForm={schoolForm}
                    attributesForm={attributesForm}
                />
            )}
            <Dialog
                open={registerFinished}
            >
                <DialogTitle>
                    <Typography>
                        <b>
                            {t('auth.register-page.dialog-title')}
                        </b>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack gap={'10px'}>
                        <Typography>
                            {t('auth.register-page.dialog-content-1')}
                        </Typography>

                        <Typography>
                            {t('auth.register-page.dialog-content-2')}
                        </Typography>

                        <Typography>
                            {t('auth.register-page.dialog-content-3')}
                        </Typography>

                        <Button
                            onClick={() => {
                                navigate('/');
                            }}
                            variant={'outlined'}
                            color={'info'}
                        >
                            {t('auth.register-page.return')}
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default RegisterPage;