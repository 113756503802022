import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerSearchCertificateQueryRequest,
  CertificateControllerSearchCertificateQueryResponse,
} from '../../ts/CertificateDTO/CertificateControllerSearchCertificate'

export function getCertificateControllerSearchCertificateUrl() {
  return `/certificate/search` as const
}

/**
 * @summary Поиск сертификата по номеру
 * {@link /certificate/search}
 */
export async function certificateControllerSearchCertificate(
  data: CertificateControllerSearchCertificateQueryRequest,
  config: Partial<RequestConfig<CertificateControllerSearchCertificateQueryRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CertificateControllerSearchCertificateQueryResponse,
    ResponseErrorConfig<Error>,
    CertificateControllerSearchCertificateQueryRequest
  >({ method: 'GET', url: getCertificateControllerSearchCertificateUrl().toString(), data, ...requestConfig })
  return res
}