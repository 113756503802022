import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerCertificatePDFForEnrollmentQueryResponse,
  CertificateControllerCertificatePDFForEnrollmentPathParams,
} from '../../ts/CertificateDTO/CertificateControllerCertificatePDFForEnrollment'

export function getCertificateControllerCertificatePDFForEnrollmentUrl({
  number,
}: {
  number: CertificateControllerCertificatePDFForEnrollmentPathParams['number']
}) {
  return `/certificate/pdf/enrollment/${number}` as const
}

/**
 * {@link /certificate/pdf/enrollment/:number}
 */
export async function certificateControllerCertificatePDFForEnrollment(
  { number }: { number: CertificateControllerCertificatePDFForEnrollmentPathParams['number'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerCertificatePDFForEnrollmentQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerCertificatePDFForEnrollmentUrl({ number }).toString(),
    ...requestConfig,
  })
  return res
}