import React, { useEffect, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useSnackbarStore } from '@/global/store/SnackbarStore';
import { TrialExamStatus, type TrialExamType } from '@/global/actions/types.api';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { apiStartTrialExam, type StartTrialExamResponseDto } from '../actions/api';
import LoadingButton from '../../global/components/LoadingButton';

interface handleStartProps {
    began_at: Date,
    current_time: Date
}

interface ModalProps {
    examInfo: TrialExamType | null,
    open: boolean,
    onClose: () => void,
    handleStartExam: (props: handleStartProps) => void,
}

function TrialExamBeginningModal({examInfo, open, onClose, handleStartExam} : ModalProps) {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);

    const [startResponse, startLoading, startError, startExam] = useLoadingHook<StartTrialExamResponseDto>(apiStartTrialExam);

    const videoRefs = useRef<{ [key: string]: HTMLVideoElement | null }>({});

    const handleClick = () => {
        console.log(`Старт экзамена`);
        startExam(examInfo?.id);
    };

    useEffect(() => {
        if (startResponse) {
            snackbar.successMessage(startResponse.message);
            handleStartExam({
                current_time: startResponse.current_time,
                began_at: startResponse.began_at,
            });
            onClose();
        }

        if (startError) {
            snackbar.errorMessage(startError.response.data.message ? startError.response.data.message : 'Произошла непредвиденная ошибка');
        }
    }, [startResponse, startError]);

    function CreatedExamComponent() {
        return (
            <Stack
                flexDirection={'column'}
                gap={'16px'}
            >
                <Typography>
                    Ваш экзамен еще не запущен.
                </Typography>
                <Typography>
                    На прохождение экзамена вам дается 40 минут. По истечении времени таймера, расположенного сверху страницы экзаменов - экзамен автоматически завершится.
                </Typography>
                <LoadingButton
                    variant={'contained'}
                    onClick={handleClick}
                    sx={{
                        width: 'fit-content'
                    }}
                    loading={startLoading}
                >
                    Начать прохождение экзамена
                </LoadingButton>
            </Stack>
        );
    }

    function InProgressExamComponent() {
        return (
            <Stack
                flexDirection={'column'}
                gap={'16px'}
            >
                <Typography>
                    Ваш экзамен уже запущен.
                </Typography>
                <Typography>
                    На прохождение экзамена вам дается 40 минут. По истечении времени таймера, расположенного сверху страницы экзаменов - экзамен автоматически завершится.
                </Typography>
                <LoadingButton
                    variant={'contained'}
                    onClick={handleClick}
                    sx={{
                        width: 'fit-content'
                    }}
                    loading={startLoading}
                >
                    Возобновить прохождение экзамена
                </LoadingButton>
            </Stack>
        );
    }

    function NotReadyDevicesComponent() {
        return (
            <Stack>
                <Typography>
                    Для дальнейшего прохождения экзамена, необходимо подключить к вашему устройству - видеокамеру и микрофон, а также разрешить в браузере доступ к данным медиа-устройствам.
                </Typography>
            </Stack>
        );
    }

    if (examInfo) {
        return (
            <Dialog
                maxWidth={'xl'}
                fullWidth={true}
                open={open}
                onClose={onClose}
            >
                <DialogTitle>
                    <Typography
                        fontWeight={'bold'}
                    >
                        Информация об экзаменe
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {examInfo.status === TrialExamStatus.Created && (<CreatedExamComponent />)}
                    {examInfo.status === TrialExamStatus.InProgress &&  (<InProgressExamComponent />)}
                </DialogContent>
            </Dialog>
        );
    } else {
        return (
            <></>
        );
    }
}

export default TrialExamBeginningModal;