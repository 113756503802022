import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerGetAppealsWithStatusCreatedQueryResponse,
  RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerGetAppealsWithStatusCreated'

export function getRegisterAppealsControllerGetAppealsWithStatusCreatedUrl() {
  return `/register-appeals/created` as const
}

/**
 * @summary Получить все заявки на регистрацию со статусом created
 * {@link /register-appeals/created}
 */
export async function registerAppealsControllerGetAppealsWithStatusCreated(
  params: RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerGetAppealsWithStatusCreatedQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getRegisterAppealsControllerGetAppealsWithStatusCreatedUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}