import React, {type ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, InputBase, Stack, Tab, Tabs, Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useSchoolStore} from '@/School/store/SchoolStore';
import GroupsTable from '../components/GroupsList/GroupsTable';
import NewGroupDialog from '../components/GroupsList/NewGroupDialog';
import PageHeader from '../../global/components/PageHeader';
import {useUserStore} from '@/global/store/UserStore';
import {wrapperSx} from '@/global/helpers/globalstyles';
import InfoIcon from '@mui/icons-material/Info';
import {useModal} from '@/global/providers/ModalProvider';

export interface PaginationArgs {
    page: number,
    limit: number,
    offset: number,
    count: number
}

function GroupsList() {

    const schoolInfo = useSchoolStore((state) => state);
    const userStore = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const navigate = useNavigate();
	const {openModal} = useModal();

    const {t} = useTranslation();

    const userExceptions = ['ber1q@mail.ru', 'aliya-zhumagulova@inbox.ru', 'administrator4@kursant.kz', 'sup.autoonline@gmail.com'];
    const emailCheck = isAdmin && !userExceptions.includes('d.dayana@kursant.kz');

    const [filter, setFilter] = useState<string>('');
    const [status, setStatus] = useState<number>(0);

    useEffect(() => {
        if (schoolInfo.id === null) {
            navigate('/schools');
        }
    }, []);

    const handleFilterChange = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilter(e.target.value);
    };

	const openNewGroupModal = () => {
		openModal(NewGroupDialog,{}, `new_group`);
	};

	const tabs = {
		all: {id: 0, label: t('school_page.group_list.group_table.tabs.all')},
		created: {id: 1, label: t('school_page.group_list.group_table.tabs.created')},
		study: {id: 2, label: t('school_page.group_list.group_table.tabs.active')},
		attestation: {id: 3, label: t('school_page.group_list.group_table.tabs.exam')},
		finished: {id: 4, label: t('school_page.group_list.group_table.tabs.finished')}
	};

	if (!schoolInfo.id) {
		return null;
	}

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader />
            <Box sx={wrapperSx}>
                <Box>
                    <Stack
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                    >
                        <Typography
                            sx={{mt: 3}}
                            variant={'h5'}
                        >
                            <b>
                                {t('school_page.group_page.groups')}
                            </b>
                        </Typography>
                    </Stack>
                    <Typography variant="subtitle1">
                        {t('school_page.group_page.groups_educ_process')} 
                        <i>
                            <b>
                                {schoolInfo.title}
                            </b>
                        </i>
                    </Typography>
                    {!userStore.isObserver && (
						<Button
							sx={{mt: 2, pr: 5, pl: 5}}
							variant={'contained'}
							// disabled={emailCheck}
							onClick={openNewGroupModal}
						>
							{t('school_page.group_page.add_group')}
						</Button>
					)}
                </Box>
				<Stack
					flexDirection={'row'}
					justifyContent={'flex-end'}
					alignItems={'center'}
					sx={{mt: 2}}
					gap={1}
				>
					<Tooltip title={t('school_page.group_list.group_table.title')}>
						<InfoIcon sx={{color: 'gray'}}/>
					</Tooltip>
					<InputBase
						value={filter}
						onChange={handleFilterChange}
						placeholder={t('school_page.group_list.group_table.input')}
						sx={{width: '400px'}}
					/>
				</Stack>

				<Tabs
					value={status}
					onChange={(e, newValue) => setStatus(newValue)}
					variant="scrollable"
					scrollButtons={false}
					sx={{ml: 2}}
				>
					{Object.values(tabs).map(tab => (
						<Tab
							label={tab.label}
							key={tab.id}
							value={tab.id}
						/>
					))}
				</Tabs>

				<GroupsTable
					id={schoolInfo.id}
					filter={filter}
					status={status}
				/>
            </Box>
        </Box>
    );
}

export default GroupsList;