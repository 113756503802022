import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import LoadingButton from "@/global/components/LoadingButton";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    handleFinish: () => void;
    registerLoading: boolean;
}

export function OfferDialog({open, handleFinish, registerLoading, close}: ModalSystemProps<Props>) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={() => close(0)}
            maxWidth={'xl'}
        >
            <DialogTitle>
                <b>
                    Подтвердите регистрацию в качестве студента
                </b>
            </DialogTitle>
            <DialogActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 2}}>
                <Button
                    onClick={() => close(0)}
                    variant={'contained'}
                    color={'error'}
                    disabled={registerLoading}
                >
                    {t('auth.register-student.refuse')}
                </Button>

                <LoadingButton
                    onClick={() => {
                        handleFinish();
                        close(0);
                    }}
                    variant={'contained'}
                    loading={registerLoading}
                >
                    {t('auth.register-student.accept')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}