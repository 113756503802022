import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerChangeAppointmentMutationResponse,
  CertificateControllerChangeAppointmentPathParams,
} from '../../ts/CertificateDTO/CertificateControllerChangeAppointment'

export function getCertificateControllerChangeAppointmentUrl({ id }: { id: CertificateControllerChangeAppointmentPathParams['id'] }) {
  return `/certificate/create/all/${id}` as const
}

/**
 * {@link /certificate/create/all/:id}
 */
export async function certificateControllerChangeAppointment(
  { id }: { id: CertificateControllerChangeAppointmentPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerChangeAppointmentMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getCertificateControllerChangeAppointmentUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}