import {Button, Stack, Typography} from "@mui/material";
import React from "react";
import {useUserStore} from "@/global/store/UserStore";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export function UserInfo() {
    const currentUser = useUserStore((state) => state);

    const {t} = useTranslation();

    const navigate = useNavigate();

    return (
        <Stack
            flexDirection={'column'}
            alignItems={'center'}
            width={{xs:'auto', lg:'427px'}}
            height={'499px'}
            sx={{
                background: '#1e9af3',
                borderRadius: '20px',
                py: '40px',
                px: {xs: '10px', lg: '0'}
            }}
        >
            <Stack>
                <img
                    src={'/images/new-design/avatar.png'}
                    alt={''}
                />
            </Stack>
            <Typography
                fontSize={'26px'}
                lineHeight={'100%'}
                color={'#fff'}
                sx={{
                    mt: '18px'
                }}
            >
                {currentUser?.last_name}
                &nbsp;
                {currentUser?.first_name}
            </Typography>
            <Typography
                fontSize={'18px'}
                lineHeight={'100%'}
                color={'rgba(255,255,255,0.5)'}
                sx={{
                    mt: '14px'
                }}
            >
                {currentUser?.iin}
            </Typography>
            <Typography
                fontSize={'24px'}
                lineHeight={'100%'}
                color={'#fff'}
                sx={{
                    mt: '42px'
                }}
            >
                {currentUser.phone ? currentUser.phone : t('main-pages.manager.no-phone')}
            </Typography>
            <Typography
                fontSize={'18px'}
                lineHeight={'100%'}
                color={'rgba(255,255,255,0.5)'}
                sx={{
                    mt: '10px'
                }}
            >
                {currentUser?.email}
            </Typography>
            <Button
                variant={'outlined'}
                onClick={() => {
                    navigate(`/change-password/${currentUser.id}`);
                }}
                color={'success'}
                sx={{
                    width: '347px',
                    height: '54px',
                    border: '0px !important',
                    mt: 'auto',
                    background: 'rgba(255,255,255,0.16)',
                    textTransform: 'none',
                    color: '#fff',
                    fontWeight: 'normal',
                    ':hover': {
                        background: 'rgba(255,255,255,0.36)'
                    }
                }}
            >
                {t('main-pages.manager.change-password')}
            </Button>
        </Stack>
    );
}