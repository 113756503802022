import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerDeleteSchoolAndAllRelatedMutationResponse,
  RegisterAppealsControllerDeleteSchoolAndAllRelatedPathParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerDeleteSchoolAndAllRelated'

export function getRegisterAppealsControllerDeleteSchoolAndAllRelatedUrl({ id }: { id: RegisterAppealsControllerDeleteSchoolAndAllRelatedPathParams['id'] }) {
  return `/register-appeals/school/delete/${id}` as const
}

/**
 * @summary Удалить школу и все связанные с ней данные
 * {@link /register-appeals/school/delete/:id}
 */
export async function registerAppealsControllerDeleteSchoolAndAllRelated(
  { id }: { id: RegisterAppealsControllerDeleteSchoolAndAllRelatedPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerDeleteSchoolAndAllRelatedMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getRegisterAppealsControllerDeleteSchoolAndAllRelatedUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}