import {Box, Checkbox, FormControlLabel} from "@mui/material";
import type {ChangeEvent} from "react";
import type {EmployeeChangeBodyDto} from "@/global/api/gen/ts/EmployeeChangeBodyDto";

interface Props {
    handleCategoriesChange: (e: ChangeEvent<HTMLInputElement>) => void;
    newEmployeeInfo: EmployeeChangeBodyDto;
}

export function CategoryCheckboxes({handleCategoriesChange, newEmployeeInfo}:Props) {

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('A1')}
                            disabled={true}
                            name={'A1'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'A1'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('A')}
                            disabled={true}
                            name={'A'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'А'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('B1')}
                            disabled={true}
                            name={'B1'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'B1'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('B')}
                            disabled={true}
                            name={'B'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'B'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('C1')}
                            disabled={true}
                            name={'C1'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'C1'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('C')}
                            disabled={true}
                            name={'C'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'C'}
                />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('D1')}
                            disabled={true}
                            name={'D1'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'D1'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('D')}
                            disabled={true}
                            name={'D'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'D'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('BE')}
                            disabled={true}
                            name={'BE'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'BE'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('C1E')}
                            disabled={true}
                            name={'C1E'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'C1E'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('CE')}
                            disabled={true}
                            name={'CE'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'CE'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('DE')}
                            disabled={true}
                            name={'DE'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'DE'}
                />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={newEmployeeInfo?.driver_license_categories?.includes('D1E')}
                            disabled={true}
                            name={'D1E'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'D1E'}
                />
            </Box>
        </>
    );
}