import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerGetAppealsWithStatusApprovedQueryResponse,
  RegisterAppealsControllerGetAppealsWithStatusApprovedQueryParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerGetAppealsWithStatusApproved'

export function getRegisterAppealsControllerGetAppealsWithStatusApprovedUrl() {
  return `/register-appeals/approved` as const
}

/**
 * @summary Получить все заявки на регистрацию со статусом approved
 * {@link /register-appeals/approved}
 */
export async function registerAppealsControllerGetAppealsWithStatusApproved(
  params: RegisterAppealsControllerGetAppealsWithStatusApprovedQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerGetAppealsWithStatusApprovedQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getRegisterAppealsControllerGetAppealsWithStatusApprovedUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}