import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerSetPassedFalseForStudentMutationResponse,
  StudentsControllerSetPassedFalseForStudentPathParams,
} from '../../ts/StudentsDTO/StudentsControllerSetPassedFalseForStudent'

export function getStudentsControllerSetPassedFalseForStudentUrl({ id }: { id: StudentsControllerSetPassedFalseForStudentPathParams['id'] }) {
  return `/students/exam/failure/${id}` as const
}

/**
 * @summary Отказ в выдаче сертификата студенту
 * {@link /students/exam/failure/:id}
 */
export async function studentsControllerSetPassedFalseForStudent(
  { id }: { id: StudentsControllerSetPassedFalseForStudentPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerSetPassedFalseForStudentMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getStudentsControllerSetPassedFalseForStudentUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}