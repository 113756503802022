import React from 'react';
import {useParams} from 'react-router-dom';
import {Box, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useSchoolStore} from '@/School/store/SchoolStore';
import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {DriverPage} from "@/modules/employees/components/DriverPage";
import {NewEmployeeDialog} from "@/modules/employees/components/NewEmployeeDialog";
import {TeachersPage} from "@/modules/employees/components/TeachersPage";
import {MastersPage} from "@/modules/employees/components/MastersPage";
import type {EmployeeType} from "@/global/helpers/others";

interface EmployeesPageProps {
    employee: EmployeeType
}

function EmployeesPage({employee}: EmployeesPageProps) {
    const {id} = useParams();

    const {t} = useTranslation();

    const schoolInfo = useSchoolStore((state) => state);

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Box sx={wrapperSx}>
                <Box>
                    <Stack
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                    >
                        <Typography
                            sx={{mt: 3}}
                            variant={'h5'}
                        >
                            <b>
                                {t(`school_page.employees_page.${employee}.title`)}
                            </b>
                        </Typography>
                        <Typography variant="subtitle2">
                            {t(`school_page.employees_page.${employee}.subtitle`)}
                            <i>
                                <b>
                                    {schoolInfo.title}
                                </b>
                            </i>
                        </Typography>
                    </Stack>
                    <NewEmployeeDialog
                        id={+(id ?? 0)}
                        employee={employee}
                    />
                </Box>

                {employee === 'drivers' && <DriverPage id={+(id ?? 0)}/>}
                {employee === 'teachers' && <TeachersPage id={+(id ?? 0)}/>}
                {employee === 'masters' && <MastersPage id={+(id ?? 0)}/>}

            </Box>
        </Box>
    );
}

export default EmployeesPage;