import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import React, { type ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSchoolStore } from '@/School/store/SchoolStore';
import type { CategoryDto } from '@/School/actions/category.type';
import { useSnackbarStore } from '@/global/store/SnackbarStore';

import CategorySelect from './CategorySelect';
import type { ModalSystemProps } from '@/global/providers/ModalProvider';
import { apiCreateGroup } from '@/modules/groups/services/group.service';
import { LoadingButton } from '@mui/lab';

interface GroupForm {
    title: string | null;
    category: CategoryValue;
}

type CategoryValue = CategoryDto['id'] | null

function NewGroupDialog({open, close}: ModalSystemProps) {

    const {t} = useTranslation();

    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
	const {mutate, isPending} = apiCreateGroup();
    const [groupForm, setGroupForm] = useState<GroupForm>({
        title: '',
        category: '',
    });

    const changeCategory = (value: CategoryValue) => {
        setGroupForm((prev) => {
            return {
                ...prev,
                category: value
            };
        });
    };

    const changeTitle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setGroupForm((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleSubmit = () => {
        if (!groupForm.category) {
            snackbar.errorMessage(t('school_page.group_list.new_group_dialog.message'));
            return;
        }
		const schoolId = schoolInfo.id;

		if (!schoolId) {
			snackbar.errorMessage('Непревиденная ошибка');
			return;
		}

		const formData = {
			title: groupForm.title ? groupForm.title : "",
			category_id: groupForm.category,
			school_id: schoolId
		};

		mutate(formData, {
			onSuccess: () => {
				close(0);
			}
		});
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => close(5)}
            >
                <DialogTitle>
                    Создание группы
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{mb: 1.5}}>
                        {t('school_page.group_list.new_group_dialog.create_text')} 
                        <i>
                            <b>
                                {schoolInfo.title || '...'}
                            </b>
                        </i>
                    </Typography>
                    <TextField
                        fullWidth
                        name={'title'}
                        value={groupForm.title}
                        onChange={(e) => changeTitle(e)}
                        label={t('school_page.group_list.new_group_dialog.label')}
                    />
                    <CategorySelect
                        fullWidth
                        value={groupForm.category}
                        onChange={(value) => changeCategory(value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => close(5)}>
                        {t('school_page.group_list.new_group_dialog.close_btn')}
                    </Button>
                    <LoadingButton
						loading={isPending}
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        {t('school_page.group_list.new_group_dialog.create_btn')}
                    </LoadingButton>

                </DialogActions>
            </Dialog>
        </>
    );
}

export default NewGroupDialog;