import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { GroupsControllerCorrectGroupsEndDatesQueryResponse } from '../../ts/GroupsDTO/GroupsControllerCorrectGroupsEndDates'

export function getGroupsControllerCorrectGroupsEndDatesUrl() {
  return `/groups/correct` as const
}

/**
 * @summary Корректировка дат групп
 * {@link /groups/correct}
 */
export async function groupsControllerCorrectGroupsEndDates(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerCorrectGroupsEndDatesQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerCorrectGroupsEndDatesUrl().toString(),
    ...requestConfig,
  })
  return res
}