import React, { useState } from 'react';
import { Avatar, Box } from '@mui/material';
import { type GridColDef } from '@mui/x-data-grid';

import PageHeader from '../../global/components/PageHeader';
import { getName } from '@/global/helpers/text';
import { wrapperSx } from '@/global/helpers/globalstyles';
import { apiGetJournalList } from '@/modules/journal/services/journal.service';
import { ServerDataGrid } from '@/global/components/ServerDataGrid';
import type { UserJournalDto } from '@/global/api/gen/ts/UserJournalDto';

export function JournalPage() {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25,
		page: 0
	});

    const {data: dataJournal, isLoading:isLoadingJournal} = apiGetJournalList({
		offset: paginationModel.page * paginationModel.pageSize,
		limit: paginationModel.pageSize,
	});

    const columns: GridColDef<UserJournalDto>[] = [
        {
            field: 'empty',
            headerName: '',
            headerClassName: 'datagrid--header',
            maxWidth: 10,
            width: 10,
            sortable: false,
        },
        {
            field: 'timestamp',
            headerName: 'Дата, время',
            width: 300,
        },
        {
            field: 'type',
            headerName: 'Действие',
            width: 300,
            renderCell: ({row}) => {
                if (row.type === 'login') {
                    return 'Вход в систему';
                } else if (row.type === 'logout') {
                    return 'Выход из системы';
                }
            }
        },
        {
            field: 'user_id',
            headerName: 'ID пользователя',
            width: 300,
        },
        {
            field: 'user',
            headerName: 'Пользователь',
            width: 300,
            renderCell: ({row}) => {
                const name = getName(row.user);
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'ip',
            headerName: 'IP-адрес',
            width: 300,
        },
    ];

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <ServerDataGrid
                    columns={columns}
                    rows={dataJournal?.items ?? []}
                    disableRowSelectionOnClick={true}
                    autoHeight={true}
					rowCount={dataJournal?.total ?? 0}
					paginationModel={paginationModel}
					onPaginationModelChange={(model)=> setPaginationModel(model)}
                    pageSizeOptions={[20]}
                    loading={isLoadingJournal}
                />
            </Box>
        </Box>
    );
}