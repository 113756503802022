import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerGetSchoolsByUserIdQueryResponse,
  SchoolsControllerGetSchoolsByUserIdPathParams,
  SchoolsControllerGetSchoolsByUserIdQueryParams,
} from '../../ts/schoolsDTO/SchoolsControllerGetSchoolsByUserId'

export function getSchoolsControllerGetSchoolsByUserIdUrl({ id }: { id: SchoolsControllerGetSchoolsByUserIdPathParams['id'] }) {
  return `/schools/users/${id}` as const
}

/**
 * {@link /schools/users/:id}
 */
export async function schoolsControllerGetSchoolsByUserId(
  { id }: { id: SchoolsControllerGetSchoolsByUserIdPathParams['id'] },
  params: SchoolsControllerGetSchoolsByUserIdQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGetSchoolsByUserIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerGetSchoolsByUserIdUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}