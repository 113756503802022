import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerAttachTeacherToGroupMutationRequest,
  GroupsControllerAttachTeacherToGroupMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerAttachTeacherToGroup'

export function getGroupsControllerAttachTeacherToGroupUrl() {
  return `/groups/attach/teacher` as const
}

/**
 * @summary Добавление учителя в группу
 * {@link /groups/attach/teacher}
 */
export async function groupsControllerAttachTeacherToGroup(
  data: GroupsControllerAttachTeacherToGroupMutationRequest,
  config: Partial<RequestConfig<GroupsControllerAttachTeacherToGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerAttachTeacherToGroupMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerAttachTeacherToGroupMutationRequest
  >({ method: 'POST', url: getGroupsControllerAttachTeacherToGroupUrl().toString(), data, ...requestConfig })
  return res
}