import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerGetPdfCertificateQueryResponse,
  CertificateControllerGetPdfCertificatePathParams,
} from '../../ts/CertificateDTO/CertificateControllerGetPdfCertificate'

export function getCertificateControllerGetPdfCertificateUrl({
  iin,
  certificate_number,
}: {
  iin: CertificateControllerGetPdfCertificatePathParams['iin']
  certificate_number: CertificateControllerGetPdfCertificatePathParams['certificate_number']
}) {
  return `/certificate/ais-generate/by-iin/${iin}/${certificate_number}` as const
}

/**
 * @summary Генерации сертификата для выдачи
 * {@link /certificate/ais-generate/by-iin/:iin/:certificate_number}
 */
export async function certificateControllerGetPdfCertificate(
  {
    iin,
    certificate_number,
  }: {
    iin: CertificateControllerGetPdfCertificatePathParams['iin']
    certificate_number: CertificateControllerGetPdfCertificatePathParams['certificate_number']
  },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerGetPdfCertificateQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerGetPdfCertificateUrl({ iin, certificate_number }).toString(),
    ...requestConfig,
  })
  return res
}