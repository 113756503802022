import { transactionsControllerCreateQuickTransaction } from './transactionsControllerCreateQuickTransaction'
import { transactionsControllerCreateTransaction } from './transactionsControllerCreateTransaction'
import { transactionsControllerGetProductPriceByProductId } from './transactionsControllerGetProductPriceByProductId'
import { transactionsControllerGetProductsPrices } from './transactionsControllerGetProductsPrices'
import { transactionsControllerGetQuickTransactionById } from './transactionsControllerGetQuickTransactionById'
import { transactionsControllerGetStatusFromFreedom } from './transactionsControllerGetStatusFromFreedom'
import { transactionsControllerGetStatusFromPDDPaymentsModule } from './transactionsControllerGetStatusFromPDDPaymentsModule'
import { transactionsControllerGetTransactionById } from './transactionsControllerGetTransactionById'
import { transactionsControllerGetTransactionsByUserId } from './transactionsControllerGetTransactionsByUserId'
import { transactionsControllerGetTransactionStatus } from './transactionsControllerGetTransactionStatus'
import { transactionsControllerTriggerRefreshQuickTransaction } from './transactionsControllerTriggerRefreshQuickTransaction'
import { transactionsControllerTriggerRefreshTransaction } from './transactionsControllerTriggerRefreshTransaction'

export const transactionsService = {
    transactionsControllerTriggerRefreshQuickTransaction,
    transactionsControllerGetQuickTransactionById,
    transactionsControllerCreateQuickTransaction,
    transactionsControllerCreateTransaction,
    transactionsControllerGetTransactionStatus,
    transactionsControllerGetStatusFromFreedom,
    transactionsControllerGetStatusFromPDDPaymentsModule,
    transactionsControllerTriggerRefreshTransaction,
    transactionsControllerGetTransactionsByUserId,
    transactionsControllerGetProductPriceByProductId,
    transactionsControllerGetProductsPrices,
    transactionsControllerGetTransactionById,
  }