import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerGroupPrecalculateDateQueryResponse,
  GroupsControllerGroupPrecalculateDatePathParams,
} from '../../ts/GroupsDTO/GroupsControllerGroupPrecalculateDate'

export function getGroupsControllerGroupPrecalculateDateUrl({ group_id }: { group_id: GroupsControllerGroupPrecalculateDatePathParams['group_id'] }) {
  return `/groups/${group_id}/precalculate-date` as const
}

/**
 * @summary Предварительная дата окончания
 * {@link /groups/:group_id/precalculate-date}
 */
export async function groupsControllerGroupPrecalculateDate(
  { group_id }: { group_id: GroupsControllerGroupPrecalculateDatePathParams['group_id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerGroupPrecalculateDateQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerGroupPrecalculateDateUrl({ group_id }).toString(),
    ...requestConfig,
  })
  return res
}