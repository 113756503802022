import {useCustomQuery} from "@/global/hooks/useCustomQuery";
import {balanceService} from "@/global/api/gen";
import {BalanceKeys} from "@/modules/balance/services/balance.keys";
import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import type {TopUpBalanceDto} from "@/global/api/gen/ts/TopUpBalanceDto";

export const apiGetSchoolBalance = (id:number) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => balanceService.balanceControllerGetBalanceBySchoolId({id})
            .then((res)=> res.data),
        queryKey: BalanceKeys.school(id)
    });
};

export const apiUpdateSchoolBalance = () => {
    return useCustomMutation({
        mutationFn: (data:TopUpBalanceDto) => balanceService.balanceControllerTopUpBalance(data)
            .then((res) => res.data),
        queryKey: (data) => BalanceKeys.school(data.school_id)
    });
};