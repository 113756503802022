import React from 'react';
import {Box, Button, Stack, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {AddCircleOutlined} from '@mui/icons-material';
import PageHeader from '../../global/components/PageHeader';
import TicketsUserGrid from '../components/TicketsUserGrid';
import {wrapperSx} from '@/global/helpers/globalstyles';

function TicketsUserPage() {

    const navigate = useNavigate();

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader />
            <Box sx={wrapperSx}>

                <Stack
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                >
                    <Typography
                        sx={{mt: 3}}
                        variant={'h5'}
                    >
                        <b>
                            Служба поддержки
                        </b>
                    </Typography>
                </Stack>

                <Button
                    variant={'contained'}
                    sx={{width: '400px', height: '50px', mt: 2}}
                    onClick={() => {navigate('/tickets/new');}}
                >
                    <AddCircleOutlined
                        fontSize={'small'}
                        sx={{mr: 1}}
                    />
                    Новое обращение
                </Button>

                <TicketsUserGrid/>
            </Box>
        </Box>
    );
}

export default TicketsUserPage;