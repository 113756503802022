import type {
	RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams
} from '@/global/api/gen/ts/register-appealsDTO/RegisterAppealsControllerGetAppealsWithStatusCreated';

export const AppealKeys = {
	all: ['appeals'] as const,
	appeal: (id: number) => [...AppealKeys.all, id] as const,
	createdAppeals: (params: RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams) => [...AppealKeys.all, 'created', params] as const,
	deniedAppeals: (params: RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams) => [...AppealKeys.all, 'denied', params] as const,
	approvedAppeals: (params: RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams) => [...AppealKeys.all, 'approved', params] as const,
	closedAppeals: (params: RegisterAppealsControllerGetAppealsWithStatusCreatedQueryParams) => [...AppealKeys.all, 'closed', params] as const
};