import React, { useCallback, useMemo } from 'react';
import {
	type GridCallbackDetails, type GridColDef, type GridEventListener, type GridPaginationModel
} from '@mui/x-data-grid';
import { ServerDataGrid } from '@/global/components/ServerDataGrid';
import type { SchoolRegisterAppealDto } from '@/global/api/gen/ts/SchoolRegisterAppealDto';

interface AppealsGridProps {
	openAppeal: (id: number) => void,
	rows: SchoolRegisterAppealDto[],
	loading: boolean,
	rowCount: number,
	paginationModel: GridPaginationModel,
	setPaginationModel: (model:GridPaginationModel, details: GridCallbackDetails) => void
}

export function AppealsGrid({openAppeal, rows,rowCount, loading, setPaginationModel, paginationModel }: AppealsGridProps) {

	const columns: GridColDef<SchoolRegisterAppealDto>[] = useMemo(() => [
		{
			field: 'empty',
			headerName: '',
			headerClassName: 'datagrid--header',
			maxWidth: 10,
			width: 10,
			sortable: false,
		},
		{
			field: 'id',
			headerName: 'ID',
			width: 90
		},
		{
			field: 'bin',
			headerName: 'БИН учебной организации',
			width: 200
		},
		{
			field: 'title',
			headerName: 'Название учебной организации',
			width: 500
		},
		{
			field: 'phone1',
			headerName: 'Телефон учебной организации',
			width: 300
		},
		{
			field: 'iin',
			headerName: 'ИИН пользователя',
			width: 200
		},
		{
			field: 'fio',
			headerName: 'ФИО пользователя',
			width: 300
		},
		{
			field: 'phone',
			headerName: 'Телефон пользователя',
			width: 300
		},
	],[]);

	const handleRowClick: GridEventListener<'rowClick'> = useCallback((params) => {
		openAppeal(params.row.id);
	},[]);

	return (
		<ServerDataGrid
			paginationModel={paginationModel}
			onPaginationModelChange={setPaginationModel}
			rows={rows}
			onRowClick={handleRowClick}
			columns={columns}
			loading={loading}
			rowCount={rowCount}
			disableRowSelectionOnClick
			disableColumnMenu={true}
			disableColumnFilter={true}
		/>
	);
}
