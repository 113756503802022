import React from 'react';
import {useParams} from 'react-router-dom';
import {Box, Divider, Stack, Typography} from '@mui/material';

import {useSnackbarStore} from '@/global/store/SnackbarStore';
import {useUserStore} from '@/global/store/UserStore';
import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import LoadingButton from '../../global/components/LoadingButton';
import VisaIcon from '../../global/assets/images/VisaIcon';
import MasterCardIcon from '../../global/assets/images/MasterCardIcon';
import StudentGroupCard from '../../Main/components/StudentGroupCard';
import {apiGetFeeStudent} from "@/modules/students/services/student.service";
import {apiCreateTransaction, apiGetProductPriceById} from "@/modules/transactions/services/transactions.services";

function FeePage() {

    const {studentId} = useParams();

    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);

    const {data:studentInfoResponse, isLoading:studentInfoLoading } = apiGetFeeStudent(+(studentId ?? 0));
    const {data:attachmentPriceResponse, isLoading:attachmentPriceLoading } = apiGetProductPriceById('attachment');

    const {mutate:requestIokaTransaction, isPending:iokaTransactionLoading} = apiCreateTransaction();

    const handleIOKAClick = () => {
        if (currentUser.id && studentInfoResponse && attachmentPriceResponse) {
            requestIokaTransaction({
                user_id: +(currentUser.id),
                recipient_id: currentUser.id,
                school_id: null,
                type: 'payment_access',
                amount: 1,
                price: attachmentPriceResponse.price,
                group_id: studentInfoResponse.group_id,
                payment_system: 'freedom'
            }, {
                onSuccess: (response) => {
                    if (response.payment_url) {
                        window.location.href = response.payment_url;
                    }
                }
            });
        } else {
            snackbar.errorMessage('Отсутствует currentUser.id || studentInfoResponse || Информация о сумме');
        }
    };

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>

            <Box sx={wrapperSx}>
                <Stack
                    direction={'column'}
                    width={'100%'}
                    sx={{
                        mt: '38px',
                        p: '52px',
                        borderRadius: '24px',
                        background: '#F5F5F5',
                        gap: '16px'
                    }}
                >
                    <Typography
                        variant={'h6'}
                    >
                        Оплата пошлины за хранение учебных данных
                    </Typography>

                    {studentInfoResponse && (
                        <StudentGroupCard
                            studentInfo={studentInfoResponse}
                            handleStartExam={() => {
                            }}
                            feePage={true}
                        />
                    )}

                    <Typography fontWeight={'bold'}>
                        Стоимость пошлины -
                        {' '}
                        {attachmentPriceResponse?.price}
                        {' '}
                        ₸
                    </Typography>

                    <Divider/>

                    <Stack>
                        <Typography>
                            Сумма к оплате:
                        </Typography>
                        <Typography variant={'h6'}>
                            {attachmentPriceResponse?.price}
                            {' '}
                            ₸
                        </Typography>
                    </Stack>

                    <Divider/>

                    <Typography>
                        Выберите способ оплаты:
                    </Typography>

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                    >
                        <LoadingButton
                            variant={'contained'}
                            sx={{
                                width: '300px',
                                gap: '16px'
                            }}
                            onClick={() => {
                                handleIOKAClick();
                            }}
                            loading={studentInfoLoading || attachmentPriceLoading || iokaTransactionLoading}
                        >
                            <VisaIcon
                                width={'50px'}
                                height={'50px'}
                            />
                            <MasterCardIcon
                                width={'50px'}
                                height={'50px'}
                            />
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}

export default FeePage;
