import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TicketControllerCreateTicketMutationRequest,
  TicketControllerCreateTicketMutationResponse,
} from '../../ts/TicketsDTO/TicketControllerCreateTicket'

export function getTicketControllerCreateTicketUrl() {
  return `/tickets/create` as const
}

/**
 * @summary Создание тикета
 * {@link /tickets/create}
 */
export async function ticketControllerCreateTicket(
  data: TicketControllerCreateTicketMutationRequest,
  config: Partial<RequestConfig<TicketControllerCreateTicketMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TicketControllerCreateTicketMutationResponse, ResponseErrorConfig<Error>, TicketControllerCreateTicketMutationRequest>({
    method: 'POST',
    url: getTicketControllerCreateTicketUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}