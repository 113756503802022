import {Box, Checkbox, FormControlLabel} from "@mui/material";
import type {ChangeEvent} from "react";

interface Props {
    categoriesArray: string[],
    changing: boolean,
    handleCategoriesChange: (e: ChangeEvent<HTMLInputElement>) => void,
}

export function ChangeSchoolCategoryCheckboxes({categoriesArray, changing, handleCategoriesChange}: Props) {
    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('A1')}
                            disabled={!changing}
                            name={'A1'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'A1'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('B1')}
                            disabled={!changing}
                            name={'B1'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'B1'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('C1')}
                            disabled={!changing}
                            name={'C1'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'C1'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('C1E')}
                            disabled={!changing}
                            name={'C1E'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'C1E'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('D1')}
                            disabled={!changing}
                            name={'D1'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'D1'}
                />
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('BC1')}
                            disabled={!changing}
                            name={'BC1'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'BC1'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('D1E')}
                            disabled={!changing}
                            name={'D1E'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'D1E'}
                />
            </Box>
        </>
    );
}