import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerGetProductsPricesQueryResponse,
  TransactionsControllerGetProductsPricesQueryParams,
} from '../../ts/TransactionsDTO/TransactionsControllerGetProductsPrices'

export function getTransactionsControllerGetProductsPricesUrl() {
  return `/transactions/products-prices` as const
}

/**
 * @summary Получение всех цен услуг
 * {@link /transactions/products-prices}
 */
export async function transactionsControllerGetProductsPrices(
  params: TransactionsControllerGetProductsPricesQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransactionsControllerGetProductsPricesQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTransactionsControllerGetProductsPricesUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}