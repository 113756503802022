import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TicketControllerSendMessageMutationRequest,
  TicketControllerSendMessageMutationResponse,
  TicketControllerSendMessagePathParams,
} from '../../ts/TicketsDTO/TicketControllerSendMessage'

export function getTicketControllerSendMessageUrl({ id }: { id: TicketControllerSendMessagePathParams['id'] }) {
  return `/tickets/message/${id}` as const
}

/**
 * @summary Отправка сообщения в тикет
 * {@link /tickets/message/:id}
 */
export async function ticketControllerSendMessage(
  { id }: { id: TicketControllerSendMessagePathParams['id'] },
  data: TicketControllerSendMessageMutationRequest,
  config: Partial<RequestConfig<TicketControllerSendMessageMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TicketControllerSendMessageMutationResponse, ResponseErrorConfig<Error>, TicketControllerSendMessageMutationRequest>({
    method: 'POST',
    url: getTicketControllerSendMessageUrl({ id }).toString(),
    data,
    ...requestConfig,
  })
  return res
}