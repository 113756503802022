import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useSchoolStore} from "@/School/store/SchoolStore";
import {useSnackbarStore} from "@/global/store/SnackbarStore";
import {apiSearchActiveTeachers} from "@/modules/employees/services/employees.service";
import {apiAttachTeacherToGroup} from "@/modules/groups/services/group.service";
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import type {ModalSystemProps} from "@/global/providers/ModalProvider";

interface TeachersModalProps {
    groupInfo: GroupDataDto;
}

export function TeachersModal({groupInfo, open, close} : ModalSystemProps<TeachersModalProps>) {

    const {t} = useTranslation();

    const [teacherId, setTeacherId] = useState<string>('');
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);

    const {data: teachersList} = apiSearchActiveTeachers(+(schoolInfo.id ?? 0), {
        limit: 100,
        offset: 0,
    });

    const {mutate} = apiAttachTeacherToGroup();

    const handleSelectChange = (e: SelectChangeEvent) => {
        setTeacherId(e.target.value);
    };

    const handleAttach = () => {

        if (!groupInfo.id || !teacherId) {
            snackbar.errorMessage(t('school_page.group_page.attributes_window.modal.required.teacher'));
            return;
        }

        const teacherDto = {
            group_id: groupInfo.id,
            teacher_id: +(teacherId)
        };

        mutate(teacherDto, {
            onSuccess: () => close(0)
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(5)}
        >
            <DialogTitle>
                {t('school_page.group_page.attributes_window.modal.add_dialog.teacher')}
                <i>
                    <b>
                        {groupInfo.title}
                    </b>
                </i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={teacherId}
                    >
                        {teachersList?.items.map((teacher) => {
                            return (
                                <MenuItem
                                    value={teacher.id}
                                    key={teacher.id}
                                >
                                    <Stack
                                        sx={{width: '100%'}}
                                        flexDirection="row"
                                        alignItems={'center'}
                                    >
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {teacher.last_name[0]}
                                            {teacher.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{width: '100%'}}
                                            >
                                                <Typography>
                                                    {teacher.last_name}
                                                    {' '}
                                                    {teacher.first_name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="gray"
                                                >
                                                    #
                                                    {teacher.id}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Typography
                        variant="body2"
                        sx={{mt: 1}}
                    >
                        {t('school_page.group_page.attributes_window.modal.reminder.teacher')}
                    </Typography>

                    <Button onClick={handleAttach}>
                        {t('school_page.group_page.attributes_window.modal.add_dialog.teacher')}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}