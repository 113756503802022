import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerDetachTeacherToGroupMutationRequest,
  GroupsControllerDetachTeacherToGroupMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerDetachTeacherToGroup'

export function getGroupsControllerDetachTeacherToGroupUrl() {
  return `/groups/detach/teacher` as const
}

/**
 * @summary Удаление учителя из группы
 * {@link /groups/detach/teacher}
 */
export async function groupsControllerDetachTeacherToGroup(
  data: GroupsControllerDetachTeacherToGroupMutationRequest,
  config: Partial<RequestConfig<GroupsControllerDetachTeacherToGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerDetachTeacherToGroupMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerDetachTeacherToGroupMutationRequest
  >({ method: 'POST', url: getGroupsControllerDetachTeacherToGroupUrl().toString(), data, ...requestConfig })
  return res
}