import React, {useEffect, useState} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import PageHeader from '../../global/components/PageHeader';
import {useUserStore} from '@/global/store/UserStore';
import UsersList from '../../Users/components/UsersList';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {getName} from "@/global/helpers/text";
import type {AttachNewSchoolDto} from "@/global/api/gen/ts/AttachNewSchoolDto";
import type {UserDto} from "@/global/api/gen/ts/UserDto";
import {NewSchoolFirstStep} from "@/School/components/NewSchoolFirstStep";

function NewSchoolAdmin() {

    const navigate = useNavigate();
    const isAdmin = useUserStore((state) => state.isAdmin);
    const [currentStep, setCurrentStep] = useState<number>(0);

    const userState = useUserStore((state) => state);

    const [userInfo, setUserInfo] = useState<{ fullName: string, id: number, partialName: string }>({
        id: 0,
        fullName: '',
        partialName: ''
    });

    const [newSchoolInfo, setNewSchoolInfo] = useState<AttachNewSchoolDto>({
        user_id: +(userState.id ?? 0),
        bin: '',
        title: '',
        director: '',
        address: '',
        phone1: '',
        phone2: '',
        region: '',
        locality: '',
        categories: []
    });

    const clearForm = () => {
        setNewSchoolInfo({
            user_id: 0,
            bin: '',
            title: '',
            director: '',
            address: '',
            phone1: '',
            phone2: '',
            region: '',
            locality: '',
            categories: []
        });

        if (!isAdmin) {
            navigate('/');
        } else {
            setCurrentStep(0);
        }
    };

    const chooseUser = (user: UserDto) => {
        const name = getName(user);

        setUserInfo({
            id: user.id,
            partialName: name.partialName,
            fullName: name.fullName
        });

        setNewSchoolInfo((prev) => {
            return {
                ...prev,
                user_id: user.id
            };
        });

        setCurrentStep(1);
    };

    useEffect(() => {
        if (!isAdmin) {
            setCurrentStep(1);
        }

    }, []);

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Box sx={wrapperSx}>
                {currentStep === 0 && (
                    <>
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                        >
                            <Typography
                                sx={{mt: 3}}
                                variant={'h5'}
                            >
                                <b>
                                    Добавление новой учебной организации к существующему специалисту
                                </b>
                            </Typography>
                        </Stack>

                        <Box>
                            <Typography variant="h6">
                                Специалисты (пользователи)
                            </Typography>
                            <Typography variant="subtitle2">
                                Здесь отображаются все специалисты
                                {' '}
                                <b>
                                    всех учебных организаций
                                </b>
                            </Typography>
                        </Box>

                        <UsersList
                            setUser={chooseUser}
                        />
                    </>
                )}

                {currentStep === 1 && (
                    <NewSchoolFirstStep
                        setNewSchoolInfo={setNewSchoolInfo}
                        setCurrentStep={setCurrentStep}
                        newSchoolInfo={newSchoolInfo}
                        userInfo={userInfo}
                        clearForm={clearForm}
                    />
                )}
            </Box>
        </Box>
    );
}

export default NewSchoolAdmin;