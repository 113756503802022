import React from "react";
import {BackLink} from "@/global/components/BackLink";
import {Button, Stack, Typography} from "@mui/material";
import {UserIcon} from "@/global/icons/UserIcon";
import {HeaderInfo} from "@/modules/students/components/StudentsByIin/HeaderInfo";
import {StudentIntegrationField} from "@/modules/students/components/StudentsByIin/StudentIntegrationField";
import type {CertificateInfoDto} from "@/global/api/gen/ts/CertificateInfoDto";

interface Props {
    studentsByIIN: CertificateInfoDto,
    returnFirstStep: () => void;
    handleStepStartTransaction: () => void;
}

export function CheckCertificateStep({returnFirstStep, handleStepStartTransaction, studentsByIIN}: Props) {
    return (
        <>
            <BackLink
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
                sx={{
                    alignSelf: 'flex-start'
                }}
            />
            <Stack
                display={'flex'}
                flexDirection={'column'}
                sx={{
                    width: {lg: '60%', xs:'100%'},
                    borderRadius: '26px',
                    backgroundColor: '#FFFFFF',
                    padding: 3,
                    gap: '16px'
                }}
            >
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={2}
                >
                    <UserIcon size={61}/>
                    <Typography fontSize={'34px'}>
                        {`${studentsByIIN.last_name} ${studentsByIIN.first_name[0]}. ${studentsByIIN.patronymic?.[0] || ''}.`}
                    </Typography>
                </Stack>
                <HeaderInfo>
                    Данные курсанта
                </HeaderInfo>
                <Stack
                    flexDirection={'column'}
                    gap={1}
                >
                    <StudentIntegrationField
                        value={(
                            <>
                                {studentsByIIN.last_name}
                                {' '}
                                {studentsByIIN.first_name}
                                {' '}
                                {studentsByIIN.patronymic ?? ''}
                            </>
                        )}
                        label={'ФИО:'}
                    />
                    <StudentIntegrationField
                        label={'ИИН курсанта:'}
                        value={studentsByIIN.iin}
                    />
                    <StudentIntegrationField
                        label={'Автошкола:'}
                        value={studentsByIIN.school_title}
                    />
                    <StudentIntegrationField
                        label={'Номер свидетельства:'}
                        value={studentsByIIN.certificate_number}
                    />
                </Stack>
                <Button
                    variant={'contained'}
                    onClick={handleStepStartTransaction}
                >
                    Получить
                </Button>
            </Stack>
        </>
    );
}