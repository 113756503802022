import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  BalanceControllerGetBalanceBySchoolIdQueryResponse,
  BalanceControllerGetBalanceBySchoolIdPathParams,
} from '../../ts/BalanceDTO/BalanceControllerGetBalanceBySchoolId'

export function getBalanceControllerGetBalanceBySchoolIdUrl({ id }: { id: BalanceControllerGetBalanceBySchoolIdPathParams['id'] }) {
  return `/balance/${id}` as const
}

/**
 * @summary Получить баланс школы
 * {@link /balance/:id}
 */
export async function balanceControllerGetBalanceBySchoolId(
  { id }: { id: BalanceControllerGetBalanceBySchoolIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<BalanceControllerGetBalanceBySchoolIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getBalanceControllerGetBalanceBySchoolIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}