import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  FeatureTogglesControllerGetFeatureToggleByModuleNameQueryResponse,
  FeatureTogglesControllerGetFeatureToggleByModuleNamePathParams,
} from '../../ts/Feature TogglesDTO/FeatureTogglesControllerGetFeatureToggleByModuleName'

export function getFeatureTogglesControllerGetFeatureToggleByModuleNameUrl({
  moduleName,
}: {
  moduleName: FeatureTogglesControllerGetFeatureToggleByModuleNamePathParams['moduleName']
}) {
  return `/feature-toggles/${moduleName}` as const
}

/**
 * @summary Получить Feature Toggle по moduleName
 * {@link /feature-toggles/:moduleName}
 */
export async function featureTogglesControllerGetFeatureToggleByModuleName(
  { moduleName }: { moduleName: FeatureTogglesControllerGetFeatureToggleByModuleNamePathParams['moduleName'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<FeatureTogglesControllerGetFeatureToggleByModuleNameQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getFeatureTogglesControllerGetFeatureToggleByModuleNameUrl({ moduleName }).toString(),
    ...requestConfig,
  })
  return res
}