import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerAttachUserForStudentMutationRequest,
  StudentsControllerAttachUserForStudentMutationResponse,
} from '../../ts/StudentsDTO/StudentsControllerAttachUserForStudent'

export function getStudentsControllerAttachUserForStudentUrl() {
  return `/students/attach-user` as const
}

/**
 * @summary Прикрепление пользователя к студенту
 * {@link /students/attach-user}
 */
export async function studentsControllerAttachUserForStudent(
  data: StudentsControllerAttachUserForStudentMutationRequest,
  config: Partial<RequestConfig<StudentsControllerAttachUserForStudentMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    StudentsControllerAttachUserForStudentMutationResponse,
    ResponseErrorConfig<Error>,
    StudentsControllerAttachUserForStudentMutationRequest
  >({ method: 'POST', url: getStudentsControllerAttachUserForStudentUrl().toString(), data, ...requestConfig })
  return res
}