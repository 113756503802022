export function NextIcon() {
    return (
        <svg
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="52"
                y="52"
                width="52"
                height="52"
                rx="16"
                transform="rotate(-180 52 52)"
                fill="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5 20L33.4142 26L27.5 32L24.9143 32L29.5858 27L18 27L18 25L29.5858 25L24.9143 20L27.5 20Z"
                fill="#05304F"
            />
        </svg>
    );
}