import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerDenyAppealMutationResponse,
  RegisterAppealsControllerDenyAppealPathParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerDenyAppeal'

export function getRegisterAppealsControllerDenyAppealUrl({ id }: { id: RegisterAppealsControllerDenyAppealPathParams['id'] }) {
  return `/register-appeals/deny/${id}` as const
}

/**
 * @summary Отказать в заявке на регистрацию по id
 * {@link /register-appeals/deny/:id}
 */
export async function registerAppealsControllerDenyAppeal(
  { id }: { id: RegisterAppealsControllerDenyAppealPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerDenyAppealMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getRegisterAppealsControllerDenyAppealUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}