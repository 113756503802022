import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { StudentsControllerArchiveStudentsMutationResponse } from '../../ts/StudentsDTO/StudentsControllerArchiveStudents'

export function getStudentsControllerArchiveStudentsUrl() {
  return `/students/archive` as const
}

/**
 * {@link /students/archive}
 */
export async function studentsControllerArchiveStudents(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerArchiveStudentsMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getStudentsControllerArchiveStudentsUrl().toString(),
    ...requestConfig,
  })
  return res
}