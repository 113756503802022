import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerGetStudentInfoQueryResponse,
  StudentsControllerGetStudentInfoPathParams,
  StudentsControllerGetStudentInfoQueryParams,
} from '../../ts/StudentsDTO/StudentsControllerGetStudentInfo'

export function getStudentsControllerGetStudentInfoUrl({ studentId }: { studentId: StudentsControllerGetStudentInfoPathParams['studentId'] }) {
  return `/students/studentInfo/${studentId}` as const
}

/**
 * @summary Получение информации о студенте
 * {@link /students/studentInfo/:studentId}
 */
export async function studentsControllerGetStudentInfo(
  { studentId }: { studentId: StudentsControllerGetStudentInfoPathParams['studentId'] },
  params: StudentsControllerGetStudentInfoQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerGetStudentInfoQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerGetStudentInfoUrl({ studentId }).toString(),
    params,
    ...requestConfig,
  })
  return res
}