import React, {type ChangeEvent, useState} from 'react';
import {Avatar, Box, Button, Dialog, DialogContent, DialogTitle, InputBase, Stack, Typography} from '@mui/material';
import {useSnackbarStore} from '@/global/store/SnackbarStore';
import LoadingButton from '../../../global/components/LoadingButton';
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {apiSearchSectionAndTopics} from "@/modules/categories/services/category.service";
import {apiGetCabinetsListBySchool} from "@/modules/cabinets/services/cabinet.service";
import {apiSubmitClass} from "@/modules/groups/services/group.service";
import type {CategorySectionDto} from "@/global/api/gen/ts/CategorySectionDto";
import type {CategoryTopicDto} from "@/global/api/gen/ts/CategoryTopicDto";
import {type GroupClassSubmitDto, groupClassSubmitDtoTypeEnum} from "@/global/api/gen/ts/GroupClassSubmitDto";
import type {GroupTeacherDto} from "@/global/api/gen/ts/GroupTeacherDto";
import type {GroupDriverDto} from "@/global/api/gen/ts/GroupDriverDto";
import type {GroupMasterDto} from "@/global/api/gen/ts/GroupMasterDto";
import type {GroupTransportDto} from "@/global/api/gen/ts/GroupTransportDto";
import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import type {CabinetDto} from "@/global/api/gen/ts/CabinetDto";

interface SubmitClassModalProps {
    groupInfo: GroupDataDto,
}

function SubmitClassModal({groupInfo, open, close}: ModalSystemProps<SubmitClassModalProps>) {

    const snackbar = useSnackbarStore((state) => state);

    const {data: sectopsList} = apiSearchSectionAndTopics(groupInfo.category_id);
    const {data: cabinetsList} = apiGetCabinetsListBySchool(groupInfo.school_id, {
        approved: true,
        limit: 100,
        offset: 0
    });

    const {mutate: submit, isPending: submitLoading} = apiSubmitClass();

    const [topics, setTopics] = useState<CategoryTopicDto[]>([]);
    const [classDto, setClassDto] = useState<GroupClassSubmitDto & {start_date_time: string | Date} >({
        group_id: groupInfo.id,
        school_id: groupInfo.school_id,
        type: 'theory',
        teacher_id: null,
        driver_id: null,
        master_id: null,
        car_id: null,
        cabinet_id: 0,
        student_id: null,
        start_date_time: '',
        duration: 1,
        section_id: 0,
        topic_id: null
    });

    const handleSection = (section: CategorySectionDto) => {
        setClassDto((prev) => {
            return {
                ...prev,
                section_id: section.id,
                topic_id: null,
                type: section.type ?? groupClassSubmitDtoTypeEnum.exam,
                teacher_id: null,
                driver_id: null,
                master_id: null,
                car_id: null,
                cabinet_id: null,
                student_id: null,
                start_date_time: ''
            };
        });
        setTopics(section.topics ?? []);
    };

    const handleTopic = (topic: CategoryTopicDto) => {
        setClassDto((prev) => {
            return {
                ...prev,
                topic_id: topic.id
            };
        });
    };

    const handleCabinet = (cabinet: CabinetDto) => {
        setClassDto((prev) => {
            return {
                ...prev,
                cabinet_id: cabinet.id
            };
        });
    };

    const handleTeacher = (teacher: GroupTeacherDto) => {
        setClassDto((prev) => {
            return {
                ...prev,
                teacher_id: teacher.teacher_id
            };
        });
    };

    const handleDriver = (driver:  GroupDriverDto) => {
        setClassDto((prev) => {
            return {
                ...prev,
                driver_id: driver.driver_id
            };
        });
    };

    const handleMaster = (master: GroupMasterDto) => {
        setClassDto((prev) => {
            return {
                ...prev,
                master_id: master.master_id
            };
        });
    };

    const handleTransport = (transport: GroupTransportDto) => {
        setClassDto((prev) => {
            return {
                ...prev,
                car_id: transport.car.id
            };
        });
    };

    const handleStudent = (student: StudentDto) => {
        setClassDto((prev) => {
            return {
                ...prev,
                student_id: student.id
            };
        });
    };

    const handleDateTime = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setClassDto((prev) => {
            return {
                ...prev,
                start_date_time: e.target.value
            };
        });
    };

    const handleDuration = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (+(e.target.value) < 1) {
            snackbar.errorMessage('Длительность занятия должна быть не менее 1 академического часа');
            return;
        }

        if (+(e.target.value) > 2) {
            snackbar.errorMessage('Длительность занятия не должна превышать 2 часа без перерыва');
            return;
        }

        setClassDto((prev) => {
            return {
                ...prev,
                duration: +(e.target.value)
            };
        });
    };

    function convertDate(input: string): string {
        const date = new Date(input);

        // Форматируем дату в нужный вид
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    }

    const onSubmitClass = () => {
        submit({
            ...classDto,
            start_date_time: convertDate(classDto.start_date_time)
        });
        snackbar.successMessage('Занятие успешно выставлено');
        close(0);
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(5)}
            maxWidth={'xl'}
            fullWidth={true}
        >
            <DialogTitle>
                Планирование нового занятия
            </DialogTitle>
            <DialogContent>
                <Box flexDirection={'column'}>
                    <Stack flexDirection={'column'}>
                        <Typography variant={'h6'}>
                            Выберите учебный раздел (обязательно)
                        </Typography>

                        <Stack
                            flexDirection={'row'}
                            gap={3}
                            sx={{p: 2, flexWrap: 'wrap'}}
                        >
                            {sectopsList?.map((section, index: number) => {
                                return (
                                    <Button
                                        variant={'outlined'}
                                        color={'info'}
                                        onClick={() => {
                                            handleSection(section);
                                        }}
                                        disabled={classDto.section_id === section.id}
                                        key={section.id}
                                    >
                                        {`${index + 1} ${section.title_ru}`}
                                    </Button>
                                );
                            })}
                        </Stack>
                    </Stack>

                    {topics.length > 0 && (
                        <Stack flexDirection="column">
                            <Typography variant={'h6'}>
                                Выберите учебную тему (обязательно)
                            </Typography>

                            <Stack
                                flexDirection={'row'}
                                gap={3}
                                sx={{p: 2, flexWrap: 'wrap'}}
                            >
                                {topics?.map((topic, index: number) => {
                                    return (
                                        <Button
                                            variant={'outlined'}
                                            color={'info'}
                                            onClick={() => {
                                                handleTopic(topic);
                                            }}
                                            disabled={classDto.topic_id === topic.id}
                                            key={topic.id}
                                        >
                                            {`${index + 1}. ${topic.title_ru} | ${topic.hours} ч.`}
                                        </Button>
                                    );
                                })}
                            </Stack>
                        </Stack>
                    )}

                    {/* ----- ТЕОРЕТИЧЕСКИЕ ЗАНЯТИЯ ----- НАЧАЛО */}
                    {!!classDto.section_id && (!!classDto.topic_id || topics.length === 0) && (classDto.type === 'theory') && (
                        <Stack flexDirection="column">
                            <Typography variant={'h6'}>
                                Выберите кабинет и преподавателя (обязательно)
                            </Typography>

                            <Stack
                                flexDirection={'row'}
                                gap={3}
                                sx={{p: 2, flexWrap: 'wrap'}}
                            >
                                {cabinetsList?.items.map((cabinet, index: number) => {
                                    return (
                                        <Button
                                            variant={'outlined'}
                                            color={'info'}
                                            onClick={() => {
                                                handleCabinet(cabinet);
                                            }}
                                            disabled={classDto.cabinet_id === cabinet.id}
                                            key={cabinet.id}
                                        >
                                            {`${index + 1}. ${cabinet.address}`}
                                        </Button>
                                    );
                                })}
                            </Stack>

                            <Stack
                                flexDirection={'row'}
                                gap={3}
                                sx={{p: 2, flexWrap: 'wrap'}}
                            >
                                {groupInfo.teacher?.map((teacher) => {
                                    return (
                                        <Button
                                            variant={'outlined'}
                                            color={'info'}
                                            onClick={() => {
                                                handleTeacher(teacher);
                                            }}
                                            disabled={classDto.teacher_id === teacher.teacher.id}
                                            sx={{py: 5}}
                                            key={teacher.teacher.id}
                                        >
                                            <Stack
                                                flexDirection={'row'}
                                                alignItems={'center'}
                                            >
                                                <Avatar
                                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                                >
                                                    {teacher.teacher.last_name[0]}
                                                    {teacher.teacher.first_name[0]}
                                                </Avatar>
                                                <Typography>
                                                    {`${teacher.teacher.last_name} ${teacher.teacher.first_name}`}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    );
                                })}
                            </Stack>
                        </Stack>
                    )}

                    {!!classDto.section_id && (classDto.topic_id || topics.length === 0) && classDto.type === 'theory' && (
                        <Stack flexDirection="column">
                            <Typography variant={'h6'}>
                                Выберите мастера производственного обучения (необязательно)
                            </Typography>

                            <Stack
                                flexDirection={'row'}
                                gap={3}
                                sx={{p: 2, flexWrap: 'wrap'}}
                            >
                                {groupInfo.master?.map((master) => {
                                    return (
                                        <Button
                                            variant={'outlined'}
                                            color={'info'}
                                            onClick={() => {
                                                handleMaster(master);
                                            }}
                                            disabled={classDto.master_id === master.master?.id}
                                            sx={{py: 5}}
                                            key={master.master?.id}
                                        >
                                            <Stack
                                                flexDirection={'row'}
                                                alignItems={'center'}
                                            >
                                                <Avatar
                                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                                >
                                                    {master.master?.last_name[0]}
                                                    {master.master?.first_name[0]}
                                                </Avatar>
                                                <Typography>
                                                    {`${master.master?.last_name} ${master.master?.first_name}`}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    );
                                })}
                            </Stack>
                        </Stack>
                    )}
                    {!!classDto.section_id && (classDto.topic_id || topics.length === 0) && classDto.type === 'practice' && (
                        <Stack flexDirection="column">
                            <Typography variant={'h6'}>
                                Выберите кабинет и преподавателя (обязательно)
                            </Typography>

                            <Stack
                                flexDirection={'row'}
                                gap={3}
                                sx={{p: 2, flexWrap: 'wrap'}}
                            >
                                {groupInfo.transport?.map((transport, index: number) => {
                                    return (
                                        <Button
                                            variant={'outlined'}
                                            color={'info'}
                                            onClick={() => {
                                                handleTransport(transport);
                                            }}
                                            disabled={classDto.car_id === transport.car.id}
                                            key={transport.car.id}
                                        >
                                            {`${index + 1}. ${transport.car.model} ${transport.car.plate_number}`}
                                        </Button>
                                    );
                                })}
                            </Stack>

                            <Stack
                                flexDirection={'row'}
                                gap={3}
                                sx={{p: 2, flexWrap: 'wrap'}}
                            >
                                {groupInfo.driver.map((driver) => {
                                    return (
                                        <Button
                                            variant={'outlined'}
                                            color={'info'}
                                            onClick={() => {
                                                handleDriver(driver);
                                            }}
                                            disabled={classDto.driver_id === driver.driver.id}
                                            sx={{py: 5}}
                                            key={driver.driver.id}
                                        >
                                            <Stack
                                                flexDirection={'row'}
                                                alignItems={'center'}
                                            >
                                                <Avatar
                                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                                >
                                                    {driver.driver.last_name[0]}
                                                    {driver.driver.first_name[0]}
                                                </Avatar>
                                                <Typography>
                                                    {`${driver.driver.last_name} ${driver.driver.first_name}`}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    );
                                })}
                            </Stack>
                        </Stack>
                    )}

                    {!!classDto.section_id && (classDto.topic_id || topics.length === 0) && classDto.type === 'practice' &&
                        classDto.car_id && classDto.driver_id && (
                            <Stack flexDirection="column">
                                <Typography variant={'h6'}>
                                    Выберите студента (обязательно)
                                </Typography>

                                <Stack
                                    flexDirection={'row'}
                                    gap={3}
                                    sx={{p: 2, flexWrap: 'wrap'}}
                                >
                                    {groupInfo.students?.map((student, index: number) => {
                                        return (
                                            <Button
                                                variant={'outlined'}
                                                color={'info'}
                                                onClick={() => {
                                                    handleStudent(student);
                                                }}
                                                disabled={classDto.student_id === student.id}
                                                key={student.id}
                                            >
                                                {`${index + 1}. ${student.last_name} ${student.first_name}`}
                                            </Button>
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        )}
                    {!!classDto.section_id &&
                        (classDto.topic_id || topics.length === 0) &&
                        ((classDto.type === 'theory' && classDto.cabinet_id && classDto.teacher_id) || (classDto.type === 'practice' && classDto.car_id && classDto.driver_id && classDto.student_id)) &&
                        (
                            <Stack flexDirection="column">
                                <Typography variant={'h6'}>
                                    Выберите дату, время и длительность проведения занятия
                                </Typography>

                                <Stack
                                    flexDirection={'row'}
                                    gap={3}
                                    sx={{p: 2, flexWrap: 'wrap'}}
                                >
                                    <InputBase
                                        onChange={handleDateTime}
                                        type={'datetime-local'}
                                        value={classDto.start_date_time}
                                    />
                                </Stack>

                                <Stack
                                    flexDirection={'row'}
                                    gap={3}
                                    sx={{p: 2, flexWrap: 'wrap'}}
                                >
                                    <InputBase
                                        onChange={handleDuration}
                                        type={'number'}
                                        value={classDto.duration}
                                    />
                                </Stack>
                            </Stack>
                        )
                    }

                    {!!classDto.section_id &&
                        (classDto.topic_id || topics.length === 0) &&
                        ((classDto.type === 'theory' && classDto.cabinet_id && classDto.teacher_id) || (classDto.type === 'practice' && classDto.car_id && classDto.driver_id && classDto.student_id)) &&
                        classDto.start_date_time &&
                        (
                            <Stack flexDirection="column">
                                <Stack
                                    flexDirection={'row'}
                                    gap={3}
                                    sx={{p: 2, flexWrap: 'wrap'}}
                                >
                                    <LoadingButton
                                        variant={'contained'}
                                        loading={submitLoading}
                                        onClick={onSubmitClass}
                                    >
                                        Запланировать занятие
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        )
                    }
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default SubmitClassModal;
