import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { UsersControllerResetPasswordMutationResponse, UsersControllerResetPasswordPathParams } from '../../ts/usersDTO/UsersControllerResetPassword'

export function getUsersControllerResetPasswordUrl({ id }: { id: UsersControllerResetPasswordPathParams['id'] }) {
  return `/users/reset/${id}` as const
}

/**
 * @summary Сбросить пароль
 * {@link /users/reset/:id}
 */
export async function usersControllerResetPassword(
  { id }: { id: UsersControllerResetPasswordPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerResetPasswordMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getUsersControllerResetPasswordUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}