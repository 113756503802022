import React, {type ChangeEvent, useState} from 'react';
import {Box, Button, CircularProgress, Stack, TextField, Typography} from '@mui/material';
import StudentInfoBlock from '../components/StudentInfoBlock';
import {apiSearchStudentByIin} from "@/modules/students/services/student.service";

function SearchByIINPage() {

    const [iinValue, setIinValue] = useState<string>('');
    const {data: searchResults, isLoading: searchResultsLoading, refetch} = apiSearchStudentByIin(iinValue, {
        limit: 100,
        offset: 0,
    }, { enabled: false });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIinValue(e.target.value);
    };

    const getStudents = async () => {
        await refetch();
    };

    return (
        <Box>
            <Typography
                sx={{mt: 3}}
                variant={'h5'}
            >
                <b>
                    Поиск студентов по ИИН
                </b>
            </Typography>
            <Typography variant={'subtitle2'}>
                На данной странице возможно произвести поиск студентов по ИИН
            </Typography>
            <Stack
                flexDirection={'row'}
                gap={2}
                sx={{my: 2}}
            >
                <TextField
                    name={'iin'}
                    onChange={handleChange}
                    value={iinValue}
                    placeholder={'ИИН'}
                />
                <Button
                    variant={'contained'}
                    onClick={getStudents}
                >
                    Поиск
                </Button>
            </Stack>

            <Stack flexDirection={'column'}>
                {!searchResults && !searchResultsLoading && (
                    <Typography variant={'h6'}>
                        Нет результатов
                    </Typography>
                )}
                {searchResults && !searchResults.items.length && !searchResultsLoading && (
                    <Typography variant={'h6'}>
                        По данному ИИН отсутствует информация
                    </Typography>
                )}
                {searchResultsLoading && (
                    <CircularProgress/>
                )}
                {searchResults && searchResults.items.map((student, index) => {
                    return (
                        <StudentInfoBlock
                            key={student.id}
                            studentInfo={student}
                            index={index}
                        />
                    );
                })}
            </Stack>

        </Box>
    );
}

export default SearchByIINPage;