import { transportControllerCreateTransport } from './transportControllerCreateTransport'
import { transportControllerDetachTransportFromGroup } from './transportControllerDetachTransportFromGroup'
import { transportControllerGetAllSchoolTransports } from './transportControllerGetAllSchoolTransports'
import { transportControllerGetTransportById } from './transportControllerGetTransportById'

export const transportService = {
    transportControllerCreateTransport,
    transportControllerGetAllSchoolTransports,
    transportControllerGetTransportById,
    transportControllerDetachTransportFromGroup,
  }