import React, {useEffect, useMemo, useState} from 'react';
import type {GridColDef} from '@mui/x-data-grid';
import {Avatar, Box, Button, CircularProgress, Stack, Typography} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import {Delete, DirectionsCar, Person} from '@mui/icons-material';
import {toast} from 'react-toastify';

import {getName} from '@/global/helpers/text';
import {useSnackbarStore} from '@/global/store/SnackbarStore';
import {useUserStore} from '@/global/store/UserStore';
import {getDayDateTime} from '@/global/helpers/formatters';
import DeleteClassModal from './DeleteClassModal';
import AutoFillClassesModal from './AutoFillClassesModal';
import {useModal} from "@/global/providers/ModalProvider";
import {apiGetPracticeHours, apiGetTheoryHours, apiSearchGroupClasses} from "@/modules/groups/services/group.service";
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {ServerDataGrid} from "@/global/components/ServerDataGrid";
import {apiChangeFeatureToggleSchool} from "@/modules/schools/services/school.service";
import type {GroupClassDto} from "@/global/api/gen/ts/GroupClassDto";
import SubmitClassModal from "@/Group/components/GroupPage/SubmitClassModal";

interface ClassesGridProps {
    groupInfo: GroupDataDto,
}

function ClassesGrid({groupInfo}: ClassesGridProps) {

    const snackbar = useSnackbarStore((state) => state);
    const userStore = useUserStore((state) => state);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25, page: 0
    });
    const {data: classesList, isLoading: classesLoading} = apiSearchGroupClasses(groupInfo.id, {
        offset: paginationModel.page * paginationModel.pageSize, limit: paginationModel.pageSize
    });
    const {data: theoryList, isLoading: theoryLoading} = apiGetTheoryHours(groupInfo.id, {
        limit: 100,
        offset: 0
    });
    const {data: practiceList, isLoading: practiceLoading} = apiGetPracticeHours(groupInfo.id, {
        limit: 100,
        offset: 0
    });

    const {mutate: fetchAutoFillFeature, data: autoFillFeature} = apiChangeFeatureToggleSchool();

    const {openModal} = useModal();

    useEffect(() => {
        if (groupInfo.id) {
            fetchAutoFillFeature({
                school_id: groupInfo.school_id,
                module_name: 'auto_fill',
                is_enabled: false
            });
        } else {
            snackbar.errorMessage('Отсутствует информация о группе');
        }
    }, [groupInfo]);

    const handleSubmit = () => {
        openModal(SubmitClassModal, {groupInfo}, `submit_class_${groupInfo.id}` );
    };

    const handleDeleteDialogOpen = (row: GroupClassDto) => {
        openModal(DeleteClassModal, {classInfo: row}, `delete_class_${row.id}`);
    };

    const handleOpenAutoFillDialog = () => {
        openModal(AutoFillClassesModal, {groupInfo}, `auto_fill_${groupInfo.id}`);
    };

    const columns: GridColDef<GroupClassDto>[] = useMemo(() => [
        {field: 'empty', headerName: '', headerClassName: 'datagrid--header', maxWidth: 10, width: 10, sortable: false},
        {
            field: 'delete',
            headerName: 'Удаление',
            width: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Button
                            onClick={() => userStore.isObserver ? toast.error('Удаление занятий доступно только менеджерам автошколы') : handleDeleteDialogOpen(params.row)}
                            disabled={groupInfo.status !== 2}
                        >
                            <Delete color={'error'}/>
                        </Button>
                    </Stack>
                );
            }
        },
        {
            field: 'start_date_time',
            headerName: 'Дата занятия',
            width: 170,
            sortable: true,
            renderCell: ({row}) => {
                console.log(getDayDateTime(new Date(row.start_date_time)));
                return getDayDateTime(new Date(row.start_date_time)).date;
            },
        },
        {
            field: 'day',
            headerName: 'День недели',
            width: 150,
            sortable: true,
            renderCell: ({row}) => getDayDateTime(new Date(row.start_date_time)).day,
        },
        {
            field: 'time',
            headerName: 'Время занятия',
            width: 150,
            sortable: true,
            renderCell: ({row}) => `${getDayDateTime(new Date(row.start_date_time)).time} - ${ row.end_date_time ? getDayDateTime(new Date(row.end_date_time)).time : 'Не указано'}`,
        },
        {
            field: 'type',
            headerName: 'Тип занятия',
            width: 170,
            sortable: true,
            renderCell: ({row}) => row.type === 'theory' ? 'Теория' : 'Практика',
        },
        {
            field: 'section',
            headerName: 'Раздел обучения',
            width: 250,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                        overflow: 'hidden',
                        padding: '10px 0'
                    }}
                >
                    {params.row.section?.title_ru}
                </div>
            ),
        },
        {
            field: 'topic',
            headerName: 'Тема обучения',
            width: 250,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center', // Вертикальное центрирование
                        height: '100%',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                        overflow: 'hidden',
                        padding: '10px 0'
                    }}
                >
                    {params.row?.topic?.title_ru ? params.row.topic.title_ru : 'Отсутствует'}
                </div>
            ),
        },
        {
            field: 'teacher', headerName: 'Преподаватель', minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                if (!row.teacher) {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                whiteSpace: 'normal',
                                lineHeight: 'normal',
                                overflow: 'hidden',
                                padding: '10px 0'
                            }}
                        >
                            {'Отсутствует'}
                        </div>
                    );
                }

                const name = getName(row.teacher);
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'master', headerName: 'Мастер пр. об.', minWidth: 350, sortable: false,
            renderCell: ({row}) => {

                if (!row.master) {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center', // Вертикальное центрирование
                                height: '100%',
                                whiteSpace: 'normal',
                                lineHeight: 'normal',
                                overflow: 'hidden',
                                padding: '10px 0'
                            }}
                        >
                            {'Отсутствует'}
                        </div>
                    );
                }

                const name = getName(row.master);
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'driver', headerName: 'Мастер об. вожд.', minWidth: 350, sortable: false,
            renderCell: ({row}) => {

                if (!row.driver) {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center', // Вертикальное центрирование
                                height: '100%',
                                whiteSpace: 'normal',
                                lineHeight: 'normal',
                                overflow: 'hidden',
                                padding: '10px 0'
                            }}
                        >
                            {'Отсутствует'}
                        </div>
                    );
                }

                const name = getName(row.driver);
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'cabinet', headerName: 'ID кабинета', minWidth: 350, sortable: false,
            renderCell: ({row}) => {

                if (!row.cabinet) {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                whiteSpace: 'normal',
                                lineHeight: 'normal',
                                overflow: 'hidden',
                                padding: '10px 0'
                            }}
                        >
                            {'Отсутствует'}
                        </div>
                    );
                }

                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            <SchoolIcon/>
                        </Avatar>
                        #
                        {row.cabinet.id}
                        ,
                        {row.cabinet.address}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'car', headerName: 'Транспортное средство', minWidth: 250, sortable: false,
            renderCell: ({row}) => {

                if (!row.car) {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center', // Вертикальное центрирование
                                height: '100%',
                                whiteSpace: 'normal',
                                lineHeight: 'normal',
                                overflow: 'hidden',
                                padding: '10px 0'
                            }}
                        >
                            {'Отсутствует'}
                        </div>
                    );
                }

                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            <DirectionsCar/>
                        </Avatar>
                        {row.car.model}
                        {' '}
                        {row.car.plate_number}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'student', headerName: 'Студент', minWidth: 350, sortable: false,
            renderCell: ({row}) => {

                if (!row.student) {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center', // Вертикальное центрирование
                                height: '100%',
                                whiteSpace: 'normal',
                                lineHeight: 'normal',
                                overflow: 'hidden',
                                padding: '10px 0'
                            }}
                        >
                            {'Отсутствует'}
                        </div>
                    );
                }

                const name = getName(row.student);
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
    ], []);

    if (classesLoading || theoryLoading || practiceLoading) {
        return (
            <Stack
                flexDirection={'row'}
                justifyContent={'center'}
            >
                <CircularProgress/>
            </Stack>
        );
    }

    if (groupInfo.status === 1) {
        return (
            <Box>
                <Typography variant={"h4"}>
                    Группа должна начать обучение
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            flexDirection={'column'}
            display={'flex'}
            gap={1}
        >

            <Stack
                flexDirection={'row'}
                justifyContent={'center'}
                sx={{m: 1}}
                gap={2}
            >
                {!userStore.isObserver && (
                    <Button
                        variant={'contained'}
                        onClick={handleSubmit}
                    >
                        Запланировать новое занятие
                    </Button>
                )}

                {!userStore.isObserver && autoFillFeature?.is_enabled && (
                    <Button
                        variant={'contained'}
                        color={'secondary'}
                        onClick={handleOpenAutoFillDialog}
                    >
                        Произвести авто-заполнение
                    </Button>
                )}
            </Stack>

            <Typography variant={'h6'}>
                Информация по проведенным учебным часам
            </Typography>

            <Box>
                {theoryList?.items.map((theorySection) => {
                    return (
                        <Stack
                            sx={{
                                bgcolor: 'white',
                                p: 3,
                                borderRadius: '12px',
                                border: `1px solid ${theorySection.current_hours === theorySection.max_hours ? 'green' : 'red'}`,
                                display: 'flex',
                                mb: 1
                            }}
                            key={theorySection.section?.id}
                        >
                            <Typography>
                                <b>
                                    {theorySection.section?.title_ru}
                                    :
                                </b>
                                {' '}
                                {theorySection.current_hours}
                                /
                                {theorySection.max_hours}
                                {' '}
                                академических часов
                            </Typography>
                        </Stack>
                    );
                })}
            </Box>

            <Box>
                {practiceList?.items.map((studentInfo) => {
                    return (
                        <Stack
                            sx={{
                                bgcolor: 'white',
                                p: 3,
                                borderRadius: '12px',
                                border: `1px solid ${studentInfo.current_hours === studentInfo.max_hours ? 'green' : 'red'}`,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                mb: 1
                            }}
                            key={studentInfo.id}
                        >
                            <Avatar
                                sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                            >
                                <Person/>
                            </Avatar>
                            <Typography>
                                <b>
                                    {studentInfo.student?.last_name}
                                    {' '}
                                    {studentInfo.student?.first_name}
                                    :
                                    {' '}
                                </b>
                                {studentInfo.current_hours}
                                /
                                {studentInfo.max_hours}
                                {' '}
                                часов практического вождения
                            </Typography>
                        </Stack>
                    );
                })}
            </Box>

            <Typography variant={'h6'}>
                Информация по проведенным и планируемым занятиям
            </Typography>

            <ServerDataGrid
                columns={columns}
                rows={classesList?.items ?? []}
                rowCount={classesList?.total ?? 0}
                loading={classesLoading}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                disableRowSelectionOnClick={true}
                getRowHeight={() => 'auto'}
                autoHeight={true}
                disableColumnMenu={true}
                disableColumnFilter={true}
            />
        </Box>
    );
}

export default ClassesGrid;