import React, { useRef, useState } from 'react';
import {Box, Button, Stack, Typography} from "@mui/material";
import {Replay} from "@mui/icons-material";

import {useUserStore} from '@/global/store/UserStore';

interface VideoPlayerProps {
    src: string;
    width?: string;
}

function VideoPlayer({src, width = '750px'} : VideoPlayerProps) {

    const currentUser = useUserStore((state) => state);

    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [played, setPlayed] = useState(0);

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            const progress = videoRef.current.currentTime / videoRef.current.duration;
            setPlayed(progress);
        }
    };

    const handleReplay = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    const handleEnded = () => {
        setIsPlaying(false);
    };

    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    opacity: 0.2,
                    background: 'black',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pointerEvents: 'none',
                    zIndex: 9999,
                }}
            >
                <Typography
                    variant={'h3'}
                    fontWeight={'bold'}
                    sx={{
                        color: 'white'
                    }}
                >
                    Kursant.KZ
                </Typography>
                <Typography
                    variant={'h3'}
                    fontWeight={'bold'}
                    sx={{
                        color: 'white'
                    }}
                >
                    #
                    {currentUser.id}
                </Typography>
            </Box>
            <video
                ref={videoRef}
                src={src}
                width={width}
                controls={false}
                autoPlay
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleEnded}
                preload={"auto"}
                playsInline={true}
                style={{
                    zIndex: 1,
                    pointerEvents: 'none',
                }}
            />
            <Stack
                sx={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    height: '10px'
                }}
            >
                <progress
                    max={1}
                    value={played}
                    className="video-custom-progress"
                />
            </Stack>
            {!isPlaying && (
                <Stack
                    sx={{
                        position: 'absolute',
                        bottom: '20px',
                        right: '10px'
                    }}
                >
                    <Button
                        onClick={handleReplay}
                    >
                        <Replay
                            sx={{color: '#ff6a00'}}
                            fontSize="large"
                        />
                    </Button>
                </Stack>
            )}
        </Box>
    );
}

export default VideoPlayer;
