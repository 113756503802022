import { useCustomQuery } from '@/global/hooks/useCustomQuery';
import { usersService } from '@/global/api/gen';
import type {
	UsersControllerGetUserAuthJournalQueryParams
} from '@/global/api/gen/ts/usersDTO/UsersControllerGetUserAuthJournal';
import { JournalKeys } from '@/modules/journal/services/journal.keys';

export const apiGetJournalList = (params: UsersControllerGetUserAuthJournalQueryParams) => {
	return useCustomQuery({
		queryFn: () => usersService.usersControllerGetUserAuthJournal(params)
			.then((res)=> res.data),
		queryKey: JournalKeys.search(params)
	});
};