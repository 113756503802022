import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerLoginAsStudentMutationRequest,
  StudentsControllerLoginAsStudentMutationResponse,
} from '../../ts/StudentsDTO/StudentsControllerLoginAsStudent'

export function getStudentsControllerLoginAsStudentUrl() {
  return `/students/get-info` as const
}

/**
 * {@link /students/get-info}
 */
export async function studentsControllerLoginAsStudent(
  data: StudentsControllerLoginAsStudentMutationRequest,
  config: Partial<RequestConfig<StudentsControllerLoginAsStudentMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerLoginAsStudentMutationResponse, ResponseErrorConfig<Error>, StudentsControllerLoginAsStudentMutationRequest>({
    method: 'POST',
    url: getStudentsControllerLoginAsStudentUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}