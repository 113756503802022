import {Box, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';

export type TestLanguages = 'ru' | 'kz' | 'en'

interface Props {
    value?: TestLanguages,
    onChange?: (newValue: TestLanguages) => void
    previewOnly?: boolean
}

export default function TestLanguageSelector({value, onChange, previewOnly}: Props) {
    const [selectedLang, setSelectedLang] = useState(value || 'ru');

    useEffect(() => {
        if (value && value !== selectedLang) setSelectedLang(value);
    }, [value]);

    const handleChange = (newLanguage: TestLanguages) => {
        setSelectedLang(newLanguage);
        onChange && onChange(newLanguage);
    };

    const languages: { [key in TestLanguages]: { id: key, label: string } } = {
        ru: {id: 'ru', label: 'RU'},
        kz: {id: 'kz', label: 'KZ'},
        en: {id: 'en', label: 'EN'},
    };
    const theme = useTheme();

    return (
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                {Object.values(languages).filter((lang) => previewOnly).map(lang => {
                    return (
                        <Box
                            key={lang.id}
                            sx={{
                            cursor: selectedLang === lang.id ? 'default' : 'pointer',
                            color: selectedLang === lang.id ? theme.palette.mode === 'light' ? 'black' : 'white' : '#248AE8'
                        }}
                            onClick={() => handleChange(lang.id)}
                        >
                            <Typography sx={{fontSize: 18, fontWeight: 500,}}>
                                {lang.label}
                            </Typography>
                        </Box>
                    );
                }
                // @ts-ignore
            ).reduce((prev, curr) => [prev, <Box
                key={curr.key}
                sx={{mr: 0.5, ml: 0.5, fontSize: 20, fontWeight: 600}}
            >
&nbsp;/&nbsp;
            </Box>, curr])}
            </Box>
        </Box>
    );
}
