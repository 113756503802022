import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useSchoolStore} from "@/School/store/SchoolStore";
import {useSnackbarStore} from "@/global/store/SnackbarStore";
import {useUserStore} from "@/global/store/UserStore";
import {apiSearchActiveMasters} from "@/modules/employees/services/employees.service";
import {apiAttachMasterToGroup} from "@/modules/groups/services/group.service";
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";

interface MasterModalProps {
    groupInfo: GroupDataDto;
}

export function MastersModal({groupInfo, open, close} : ModalSystemProps<MasterModalProps>) {

    const {t} = useTranslation();

    const [masterId, setMasterId] = useState<string>('');

    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const userStore = useUserStore((state) => state);

    const {data: mastersList} = apiSearchActiveMasters(+(schoolInfo.id ?? 0), {
        limit: 100,
        offset: 0,
    });

    const {mutate} = apiAttachMasterToGroup();

    const handleSelectChange = (e: SelectChangeEvent) => {
        setMasterId(e.target.value);
    };

    const handleAttach = () => {

        if (!groupInfo.id || !masterId) {
            snackbar.errorMessage(t('school_page.group_page.attributes_window.modal.required.master'));
            return;
        }

        const masterDto = {
            group_id: groupInfo.id,
            master_id: +(masterId)
        };

        mutate(masterDto, {
            onSuccess: () => {
                close(0);
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(5)}
        >
            <DialogTitle>
                {t('school_page.group_page.attributes_window.modal.add_dialog.master')}
                {' '}
                <i>
                    <b>
                        {groupInfo.title}
                    </b>
                </i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={masterId}
                    >
                        {mastersList?.items.map((master) => {
                            return (
                                <MenuItem
                                    value={master.id}
                                    key={master.id}
                                >
                                    <Stack
                                        sx={{width: '100%'}}
                                        flexDirection="row"
                                        alignItems={'center'}
                                    >
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {master.last_name[0]}
                                            {master.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{width: '100%'}}
                                            >
                                                <Typography>
                                                    {master.last_name}
                                                    {' '}
                                                    {master.first_name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="gray"
                                                >
                                                    #
                                                    {master.id}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Typography
                        variant="body2"
                        sx={{mt: 1}}
                    >
                        {t('school_page.group_page.attributes_window.modal.reminder.master')}
                    </Typography>

                    <Button
                        onClick={handleAttach}
                        disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.attributes_window.modal.add_dialog.master')}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}