import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { AuthControllerRefreshQueryResponse } from '../../ts/authDTO/AuthControllerRefresh'

export function getAuthControllerRefreshUrl() {
  return `/auth/refresh` as const
}

/**
 * @summary Обновление токена
 * {@link /auth/refresh}
 */
export async function authControllerRefresh(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<AuthControllerRefreshQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getAuthControllerRefreshUrl().toString(),
    ...requestConfig,
  })
  return res
}