import React, {useState} from 'react';
import {Dialog, DialogContent, DialogTitle, TextField} from '@mui/material';

import {validateIIN} from '@/global/helpers/validators';
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {LoadingButton} from "@mui/lab";
import {apiAddStudent} from "@/modules/students/services/student.service";
import type {StudentAddDto} from "@/global/api/gen/ts/StudentAddDto";
import {toast} from "react-toastify";
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";

interface Props {
    groupInfo: GroupDataDto,
}

function AddStudentModal({groupInfo, open, close}: ModalSystemProps<Props>) {

    const [studentForm, setStudentForm] = useState<StudentAddDto>({
        iin: '',
        first_name: '',
        last_name: '',
        patronymic: '',
        medical_certificate_number: '',
        medical_center_title: '',
        medical_center_bin: '',
        medical_examination_date: '',
        passed: false,
        group_id: groupInfo.id
    });
    const [iinValid, setIinValid] = useState<boolean>(false);

    const {mutate, isPending: loading} = apiAddStudent();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setStudentForm((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleIINChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setIinValid(validateIIN(value));

        setStudentForm((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const clearForm = () => {
        setStudentForm({
            iin: '',
            first_name: '',
            last_name: '',
            patronymic: '',
            medical_certificate_number: '',
            medical_center_title: '',
            medical_center_bin: '',
            medical_examination_date: '',
            passed: false,
            group_id: groupInfo.id,
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!iinValid) {
            toast.error('Некорректный ИИН');
            return;
        }

        mutate(studentForm, {
            onSuccess: () => {
                toast.success(`Курсант успешно добавлен в группу`);
                clearForm();
            }
        });
    };

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={() => close(5)}
        >
            <DialogTitle>
                Добавить курсанта в группу
                {' '}
                <b>
                    {groupInfo.title}
                </b>
            </DialogTitle>
            <DialogContent>
                <form
                    style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '20px'}}
                    onSubmit={handleSubmit}
                >
                    <TextField
                        name={'iin'}
                        value={studentForm.iin}
                        onChange={handleIINChange}
                        label={'ИИН'}
                        required={true}
                        color={iinValid ? 'primary' : 'error'}
                        helperText={iinValid ? '' : 'Некорректный ИИН'}
                    />
                    <TextField
                        name={'last_name'}
                        value={studentForm.last_name}
                        onChange={handleChange}
                        label={'Фамилия'}
                        required={true}
                    />
                    <TextField
                        name={'first_name'}
                        value={studentForm.first_name}
                        onChange={handleChange}
                        label={'Имя'}
                        required={true}
                    />
                    <TextField
                        name={'patronymic'}
                        value={studentForm.patronymic}
                        onChange={handleChange}
                        label={'Отчество'}
                    />
                    <TextField
                        name={'medical_certificate_number'}
                        value={studentForm.medical_certificate_number}
                        onChange={handleChange}
                        label={'Номер мед. справки (Необязательно)'}
                    />
                    <TextField
                        name={'medical_center_title'}
                        value={studentForm.medical_center_title}
                        onChange={handleChange}
                        label={'Название мед. центра (Необязательно)'}
                    />
                    <TextField
                        name={'medical_center_bin'}
                        value={studentForm.medical_center_bin}
                        onChange={handleChange}
                        label={'БИН мед. центра (Необязательно)'}
                    />
                    <TextField
                        name={'medical_examination_date'}
                        type={'date'}
                        value={studentForm.medical_examination_date}
                        onChange={handleChange}
                        label={'Дата прохождения мед. осмотра (Необязательно)'}
                    />

                    <LoadingButton
                        loading={loading}
                        variant={'contained'}
                        type={'submit'}
                    >
                        Добавить
                    </LoadingButton>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default AddStudentModal;