import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerAttachTransportToGroupMutationRequest,
  GroupsControllerAttachTransportToGroupMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerAttachTransportToGroup'

export function getGroupsControllerAttachTransportToGroupUrl() {
  return `/groups/attach/transport` as const
}

/**
 * @summary Добавление транспорта в группу
 * {@link /groups/attach/transport}
 */
export async function groupsControllerAttachTransportToGroup(
  data: GroupsControllerAttachTransportToGroupMutationRequest,
  config: Partial<RequestConfig<GroupsControllerAttachTransportToGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerAttachTransportToGroupMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerAttachTransportToGroupMutationRequest
  >({ method: 'POST', url: getGroupsControllerAttachTransportToGroupUrl().toString(), data, ...requestConfig })
  return res
}