import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  FeatureTogglesControllerChangeEnabledForSchoolFeatureToggleMutationRequest,
  FeatureTogglesControllerChangeEnabledForSchoolFeatureToggleMutationResponse,
} from '../../ts/Feature TogglesDTO/FeatureTogglesControllerChangeEnabledForSchoolFeatureToggle'

export function getFeatureTogglesControllerChangeEnabledForSchoolFeatureToggleUrl() {
  return `/feature-toggles/school/change-enabled` as const
}

/**
 * @summary Изменить состояние Feature Toggle для школы
 * {@link /feature-toggles/school/change-enabled}
 */
export async function featureTogglesControllerChangeEnabledForSchoolFeatureToggle(
  data: FeatureTogglesControllerChangeEnabledForSchoolFeatureToggleMutationRequest,
  config: Partial<RequestConfig<FeatureTogglesControllerChangeEnabledForSchoolFeatureToggleMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    FeatureTogglesControllerChangeEnabledForSchoolFeatureToggleMutationResponse,
    ResponseErrorConfig<Error>,
    FeatureTogglesControllerChangeEnabledForSchoolFeatureToggleMutationRequest
  >({ method: 'POST', url: getFeatureTogglesControllerChangeEnabledForSchoolFeatureToggleUrl().toString(), data, ...requestConfig })
  return res
}