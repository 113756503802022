import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { TransactionsControllerGetStatusFromFreedomMutationResponse } from '../../ts/TransactionsDTO/TransactionsControllerGetStatusFromFreedom'

export function getTransactionsControllerGetStatusFromFreedomUrl() {
  return `/transactions/freedom-webhook` as const
}

/**
 * @summary Получение статуса платежа Freedom
 * {@link /transactions/freedom-webhook}
 */
export async function transactionsControllerGetStatusFromFreedom(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransactionsControllerGetStatusFromFreedomMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getTransactionsControllerGetStatusFromFreedomUrl().toString(),
    ...requestConfig,
  })
  return res
}