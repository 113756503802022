import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerGetTeacherByIdQueryResponse,
  EmployeeControllerGetTeacherByIdPathParams,
} from '../../ts/employeesDTO/EmployeeControllerGetTeacherById'

export function getEmployeeControllerGetTeacherByIdUrl({ id }: { id: EmployeeControllerGetTeacherByIdPathParams['id'] }) {
  return `/employees/get/teacher/${id}` as const
}

/**
 * @summary Получение учителя по id
 * {@link /employees/get/teacher/:id}
 */
export async function employeeControllerGetTeacherById(
  { id }: { id: EmployeeControllerGetTeacherByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerGetTeacherByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getEmployeeControllerGetTeacherByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}