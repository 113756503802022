import React, {type FormEvent, useEffect, useState} from 'react';
import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import LoadingButton from '../../global/components/LoadingButton';
import {formatDateForInput} from '@/global/helpers/text';
import {useUserStore} from '@/global/store/UserStore';
import {apiGetSchoolById, apiUpdateSchool} from "@/modules/schools/services/school.service";
import {apiGetLocalityBySlug, apiGetRegionList} from "@/modules/regions/services/regions.service";
import type {SchoolChangeInfoUpdateDto} from "@/global/api/gen/ts/SchoolChangeInfoUpdateDto";
import {toast} from "react-toastify";

function SchoolChangeInfoPage() {

    const {id} = useParams();

    const {t} = useTranslation();

    const userStore = useUserStore((state) => state);

    const {data: schoolInfo, isLoading: schoolInfoLoading} = apiGetSchoolById(+(id ?? 0));
    const {data: regionsList} = apiGetRegionList({
        limit: 100,
        offset: 0
    });

    const {mutate: requestChangeInfo, isPending: changeInfoLoading} = apiUpdateSchool();

    const [schoolForm, setSchoolForm] = useState<SchoolChangeInfoUpdateDto>({
        id: +(id ?? 0),
        title: "",
        bin: "",
        director: "",
        director_iin: "",
        director_qualification_certificate_number: '',
        director_qualification_certificate_issuer: '',
        director_qualification_certificate_issue_date: '',
        director_qualification_certificate_end_date: '',
        email: "",
        address: "",
        phone1: "",
        phone2: "",
        region: "",
        locality: "",
        signData: ""
    });

    const {data: localitiesList} = apiGetLocalityBySlug(schoolForm.region, {
        limit: 100,
        offset: 0,
    });

    const [signString, setSignString] = useState<string>('');

    const [ws, setWs] = useState<WebSocket | null>(null);
    const [_messages, setMessages] = useState<string[]>([]);
    const [_callback, setCallback] = useState<string | null>(null);

    const connectNCAWebSocket = () => {
        const websocket = new WebSocket('wss://127.0.0.1:13579/');

        websocket.onopen = () => {
            toast.info('Соединение с NCALayer успешно подключено');
        };

        websocket.onmessage = (event) => {
            setMessages(prevMessages => [...prevMessages, event.data]);

            const result = JSON.parse(event.data);

            if (result) {
                const responseObject = result.responseObject;

                if (responseObject) {
                    setSignString(responseObject);
                }
            }
        };

        websocket.onerror = (_event) => {
            toast.error('Не удалось установить соединение с NCALayer. Включите NCALayer и попробуйте попытку');
            if (window.confirm('Не удалось установить соединение с NCALayer. Нажмите OK для повторной попытки')) {
                connectNCAWebSocket();
            }
        };

        websocket.onclose = () => {
            setWs(null); // Обновляем состояние, указывая на закрытие соединения
        };

        setWs(websocket ? websocket : null); // Обновляем состояние с новым экземпляром WebSocket
    };

    useEffect(() => {
        connectNCAWebSocket();

        return () => {
            ws?.close();
        };
    }, []);

    useEffect(() => {
        if (schoolInfo) {
            setSchoolForm({
                id: schoolInfo.id,
                title: schoolInfo.title,
                bin: schoolInfo.bin,
                director: schoolInfo.director,
                director_iin: schoolInfo.director_iin,
                director_qualification_certificate_number: schoolInfo.director_qualification_certificate_number ?? '',
                director_qualification_certificate_issuer: schoolInfo.director_qualification_certificate_issuer ?? '',
                director_qualification_certificate_issue_date: schoolInfo.director_qualification_certificate_issue_date ?? '',
                director_qualification_certificate_end_date: schoolInfo.director_qualification_certificate_end_date ?? '',
                email: schoolInfo.email,
                address: schoolInfo.address,
                phone1: schoolInfo.phone1 ?? '',
                phone2: schoolInfo.phone2 ?? '',
                region: schoolInfo.region,
                locality: schoolInfo.locality,
                signData: ''
            });
        }
    }, [schoolInfo]);

    function signXml(storageName: string, keyType: string, xmlToSign: string, callBack: string) {
        const signXml = {
            module: "kz.gov.pki.knca.commonUtils",
            method: "signXml",
            args: [storageName, keyType, xmlToSign, "", ""]
        };
        setCallback(callBack);
        ws?.send(JSON.stringify(signXml));
    }

    const xmsSignCall = (xml: string) => {
        const storageType = "PKCS12";
        signXml(storageType, "SIGNATURE", xml, "signXmlBack");
    };

    const handleRegionChange = (e: SelectChangeEvent) => {
        setRegion(e.target.value);
    };

    const handleLocalitiesChange = (e: SelectChangeEvent) => {
        setLocality(e.target.value);
    };

    const setRegion = (chosenRegion: string) => {
        setSchoolForm((prev) => {
            return {
                ...prev,
                region: chosenRegion
            };
        });
    };

    const setLocality = (chosenLocality: string) => {

        if (chosenLocality === 'Другое (вписать в адрес)') {
            setSchoolForm((prev) => {
                return {
                    ...prev,
                    locality: 'Другое (вписать в адрес)'
                };
            });
            return;
        }

        setSchoolForm((prev) => {
            return {
                ...prev,
                locality: chosenLocality
            };
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setSchoolForm((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        requestChangeInfo(schoolForm, {
            onSuccess: (data) => {
                toast.success(data.message);
            }
        });
    };

    useEffect(() => {
        if (signString) {
            setSchoolForm((prev) => {
                return {
                    ...prev,
                    signData: signString
                };
            });
        }
    }, [signString]);

    if (schoolInfoLoading || changeInfoLoading) {
        return (
            <Box
                className="App"
                sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
            >
                <PageHeader/>
                <Box sx={wrapperSx}>

                    <Box>
                        <Stack
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'flex-start'}
                        >
                            <Typography
                                sx={{mt: 3}}
                                variant={'h5'}
                            >
                                <b>
                                    {t('school_page.change_info_page.title')}
                                </b>
                            </Typography>
                            <Typography variant="subtitle2">

                            </Typography>
                        </Stack>
                    </Box>
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <CircularProgress/>
                    </Stack>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Box sx={wrapperSx}>
                <Typography
                    sx={{mt: 3}}
                    variant={'h5'}
                >
                    <b>
                        {t('school_page.change_info_page.title')}
                    </b>
                </Typography>
                <Stack
                    flexDirection={'column'}
                    sx={{
                        my: 1,
                        py: 1
                    }}
                >
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px'
                        }}
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            name={'bin'}
                            value={schoolForm.bin}
                            disabled={true}
                            label={t('school_page.change_info_page.bin')}
                            sx={{width: '100%'}}
                        />
                        <TextField
                            name={'title'}
                            value={schoolForm.title}
                            label={t('school_page.change_info_page.title')}
                            sx={{width: '100%'}}
                            disabled={userStore.isObserver}
                            required={true}
                            onChange={handleChange}
                        />
                        <TextField
                            name={'director'}
                            value={schoolForm.director}
                            label={t('school_page.change_info_page.director')}
                            sx={{width: '100%'}}
                            disabled={userStore.isObserver}
                            required={true}
                            onChange={handleChange}
                        />
                        <TextField
                            name={'director_iin'}
                            value={schoolForm.director_iin}
                            label={t('school_page.change_info_page.director_iin')}
                            sx={{width: '100%'}}
                            disabled={userStore.isObserver}
                            required={true}
                            onChange={handleChange}
                        />
                        <TextField
                            type={'text'}
                            placeholder={t('auth.first-step.director_qualification_certificate_number')}
                            value={schoolForm.director_qualification_certificate_number}
                            name={'director_qualification_certificate_number'}
                            onChange={handleChange}
                            required={true}
                            disabled={userStore.isObserver}
                        />
                        <TextField
                            type={'text'}
                            placeholder={t('auth.first-step.director_qualification_certificate_issuer')}
                            value={schoolForm.director_qualification_certificate_issuer}
                            name={'director_qualification_certificate_issuer'}
                            onChange={handleChange}
                            required={true}
                            disabled={userStore.isObserver}
                        />
                        <TextField
                            name={'director_qualification_certificate_issue_date'}
                            type={'date'}
                            value={formatDateForInput(schoolForm.director_qualification_certificate_issue_date)}
                            onChange={handleChange}
                            label={t('auth.first-step.director_qualification_certificate_issue_date')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={userStore.isObserver}
                        />
                        <TextField
                            name={'director_qualification_certificate_end_date'}
                            type={'date'}
                            value={formatDateForInput(schoolForm.director_qualification_certificate_end_date)}
                            onChange={handleChange}
                            label={t('auth.first-step.director_qualification_certificate_end_date')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={userStore.isObserver}
                        />
                        <TextField
                            name={'email'}
                            value={schoolForm.email}
                            label={t('school_page.change_info_page.email')}
                            sx={{width: '100%'}}
                            disabled={userStore.isObserver}
                            required={true}
                            onChange={handleChange}
                        />
                        <TextField
                            name={'address'}
                            value={schoolForm.address}
                            label={t('school_page.change_info_page.address')}
                            sx={{width: '100%'}}
                            disabled={userStore.isObserver}
                            required={true}
                            onChange={handleChange}
                        />
                        <TextField
                            name={'phone1'}
                            value={schoolForm.phone1}
                            label={t('school_page.change_info_page.phone1')}
                            sx={{width: '100%'}}
                            disabled={userStore.isObserver}
                            required={true}
                            onChange={handleChange}
                        />
                        <TextField
                            name={'phone2'}
                            value={schoolForm.phone2}
                            label={t('school_page.change_info_page.phone2')}
                            sx={{width: '100%'}}
                            disabled={userStore.isObserver}
                            onChange={handleChange}
                        />
                        <FormControl>
                            <InputLabel id={'region-label'}>
                                {t('auth.first-step.region')}
                            </InputLabel>
                            <Select
                                labelId={'region-label'}
                                onChange={handleRegionChange}
                                name={'region'}
                                label={t('auth.first-step.region')}
                                sx={{
                                    borderRadius: '16px',
                                    ".MuiOutlinedInput-notchedOutline": {
                                        borderWidth: 0,
                                        borderRadius: '16px',
                                    }
                                }}
                                value={schoolForm.region}
                                required={true}
                                disabled={userStore.isObserver}
                            >
                                {regionsList?.items.map((region) => {
                                    return (
                                        <MenuItem
                                            value={region.name_ru}
                                            key={region.name_ru}
                                        >
                                            {region.name_ru}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        {localitiesList && (
                            <FormControl>
                                <InputLabel id={'locality-label'}>
                                    {t('auth.first-step.locality')}
                                </InputLabel>
                                <Select
                                    labelId={'locality-label'}
                                    onChange={handleLocalitiesChange}
                                    name={'locality'}
                                    label={t('auth.first-step.locality')}
                                    sx={{
                                        borderRadius: '16px',
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: 0,
                                            borderRadius: '16px',
                                        }
                                    }}
                                    value={schoolForm.locality ?? ''}
                                    required={true}
                                    disabled={(localitiesList?.items.length === 0) || userStore.isObserver}
                                >
                                    {localitiesList?.items.map((locality) => {
                                        return (
                                            <MenuItem
                                                value={locality.name_ru}
                                                key={locality.name_ru}
                                            >
                                                {locality.name_ru}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        )}

                        {!userStore.isObserver && (
                            <LoadingButton
                                onClick={() =>
                                    ws ? xmsSignCall('<signData>Согласен с отправляемыми данными</signData>') : connectNCAWebSocket()
                                }
                                variant={'contained'}
                                sx={{width: 'auto', height: '56px'}}
                                disabled={!ws}
                            >
                                {!signString ? 'Подписать заявку на изменение информации' : 'Переподписать другим ЭЦП'}
                            </LoadingButton>
                        )}

                        {!userStore.isObserver && signString && (
                            <LoadingButton
                                variant={'outlined'}
                                type={'submit'}
                            >
                                Изменить информацию
                            </LoadingButton>
                        )}
                    </form>
                </Stack>
            </Box>
        </Box>
    );
}

export default SchoolChangeInfoPage;