import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';

import PageHeader from '../../global/components/PageHeader';
import { useSchoolStore } from '@/School/store/SchoolStore';
import type { BaseInterface } from '../actions/base.type';
import { getBaseInfo, updateBaseInfo } from '../actions/api';
import { useSnackbarStore } from '@/global/store/SnackbarStore';
import { useUserStore } from '@/global/store/UserStore';
import { wrapperSx } from '@/global/helpers/globalstyles';

function BasePage() {

    const snackbar = useSnackbarStore((state) => state);
    const schoolInfo = useSchoolStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const [loading, setLoading] = useState<boolean>(false);
    const [baseInfo, setBaseInfo] = useState<BaseInterface>({
        school_id: schoolInfo.id ? schoolInfo.id : 500000,
        autosimulators: null,
        mt_cabinets: null,
        bd_cabinets: null,
        osh_cabinets: null,
        tl_cabinets: null,
        vd_cabinets: null,
        ve_cabinets: null,
        ct_cabinets: null,
        vm_labs: null,
        vd_labs: null,
        tl_bd_mt_cabinets: null,
        vd_ve_osh_cabinets: null
    });
    const [exists, setExists] = useState<boolean>(true);
    const [isChanging, setIsChanging] = useState<boolean>(false);

    useEffect(() => {
        fetchBaseInfo();
    }, [schoolInfo]);

    const fetchBaseInfo = () => {
        if (schoolInfo.id) {
            getBaseInfo(schoolInfo.id)
                .then((res) => {
                    if (res.data.status === false) {
                        setExists(false);
                    } else {
                        setExists(true);
                        setBaseInfo(res.data);
                    }
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setBaseInfo((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleSave = () => {
        setIsChanging(false);
        setLoading(true);
        updateBaseInfo(baseInfo)
            .then((res) => {
                fetchBaseInfo();
                setLoading(false);
                snackbar.successMessage(res.data.message);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            });
    };

    return (
        <Box>
            <PageHeader />

            <Box sx={{...wrapperSx, pb: 2}}>

                <Stack
                    flexDirection={'column'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    sx={{mb: 2}}
                >
                    <Typography
                        sx={{mt: 3}}
                        variant={'h5'}
                    >
                        <b>
                            Учебно-материальная база
                        </b>
                    </Typography>
                    <Typography variant="subtitle1">
                        В данном разделе отображается учебно-материальная база учебной организации 
                        {' '}
                        <b>
                            {schoolInfo.title}
                        </b>
                    </Typography>
                </Stack>
                {loading && <CircularProgress />}
                {!loading && !exists && (
                <Typography sx={{mb: 2}}>
                    <i>
                        Отсутствует информация по учебно-материальной базе
                    </i>
                </Typography>
)}
                {!loading && (
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <TextField
                        label="Количество автотренажеров"
                        name={'autosimulators'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.autosimulators}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество кабинетов медицинской подготовки"
                        name={'mt_cabinets'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.mt_cabinets}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество кабинетов по БД"
                        name={'bd_cabinets'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.bd_cabinets}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество кабинетов по охране труда"
                        name={'osh_cabinets'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.osh_cabinets}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество кабинетов по ПДД"
                        name={'tl_cabinets'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.tl_cabinets}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество кабинетов по устройству ТС"
                        name={'vd_cabinets'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.vd_cabinets}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество кабинетов по эксплуатации ТС"
                        name={'ve_cabinets'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.ve_cabinets}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество компьютерной техники"
                        name={'ct_cabinets'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.ct_cabinets}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество лабораторий по техническому обслуживанию ТС"
                        name={'vm_labs'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.vm_labs}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество лабораторий по устройству ТС"
                        name={'vd_labs'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.vd_labs}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество объединенных кабинетов ПДД, БД и медицинской подготовки"
                        name={'tl_bd_mt_cabinets'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.tl_bd_mt_cabinets}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Количество объединенных кабинетов по устройству ТС, эксплуатации ТС и охране труда"
                        name={'vd_ve_osh_cabinets'}
                        type="number"
                        disabled={!isChanging}
                        value={baseInfo.vd_ve_osh_cabinets}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        onChange={handleChange}
                    />
                </Box>
)}

                <Stack
                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                    gap={2}
                    sx={{mt: 2, mb: 2}}
                >
                    <Button
                        disabled={!isChanging}
                        variant={'contained'}
                        onClick={() => {handleSave();}}
                    >
                        Сохранить
                    </Button>
                    <Button
                        disabled={isChanging}
                        variant={'contained'}
                        onClick={() => {setIsChanging(true);}}
                    >
                        Изменить
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
}

export default BasePage;