import React, {Fragment} from 'react';
import {Avatar, Box, Button, Paper, Stack, type SxProps, type Theme, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useUserStore} from '@/global/store/UserStore';
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {useModal} from "@/global/providers/ModalProvider";
import {TeachersModal} from "@/modules/employees/components/TeachersModal";
import {apiDetachEmployee} from "@/modules/employees/services/employees.service";
import {toast} from "react-toastify";
import type {GroupTeacherDto} from "@/global/api/gen/ts/GroupTeacherDto";
import {LoadingButton} from "@mui/lab";

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface TeachersWindowProps {
    groupInfo: GroupDataDto;
}

function TeachersWindow({groupInfo} : TeachersWindowProps) {

    const {t} = useTranslation();

    const {openModal} = useModal();

    const userStore = useUserStore((state) => state);

    const openTeacherModal = () => {
        openModal(TeachersModal, {groupInfo}, `teacher_modal_${groupInfo.id}`);
    };

    const {mutate, isPending} = apiDetachEmployee();

    const detachTeacher = (data:GroupTeacherDto) => {
        mutate({
            groupId: data.group_id,
            employeeId: data.teacher_id,
            type: 'teachers'
        }, {
            onSuccess: () => {
                toast.success(`Сотрудник ${data.teacher.last_name} ${data.teacher.first_name} откреплен`);
            }
        });
    };

    return (
        <Box>
            <Paper sx={{...paperSx, mb: 5}}>

                <Typography variant="h4">
                    {t('school_page.group_page.attributes_window.window.this_group.teacher')}
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{mb: 1.5}}
                    color="gray"
                >
                    {t('school_page.group_page.attributes_window.window.this_group.teacher')}
                </Typography>
                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    {groupInfo.teacher?.length === 0 && (
                        <Typography
                            fontSize={'14px'}
                            color={"gray"}
                        >
                            {t('school_page.group_page.attributes_window.window.no_data')}
                        </Typography>
                    )}

                    {groupInfo.teacher && groupInfo.teacher.length > 0 && (
                        groupInfo.teacher.map((teacher) => {
                            return (
                                <Fragment key={teacher.id}>
                                    <Stack
                                        sx={{ml: 2, width: '400px', p: 2, borderRadius: '10px', background: '#F4F6F8'}}
                                        flexDirection="row"
                                        alignItems={'center'}
                                    >
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {teacher.teacher.last_name[0]}
                                            {teacher.teacher.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent="start"
                                                sx={{width: '100%'}}
                                            >
                                                <Typography>
                                                    {teacher.teacher.last_name} 
                                                    {' '}
                                                    {teacher.teacher.first_name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="gray"
                                                    sx={{ml: 1}}
                                                >
                                                    #
                                                    {teacher.teacher.id}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <LoadingButton
                                            loading={isPending}
                                            onClick={() => detachTeacher(teacher)}
                                            variant={'contained'}
                                            sx={{
                                                paddingX: 4
                                            }}
                                        >
                                            Открепить
                                        </LoadingButton>
                                    </Stack>
                                </Fragment>
                            );
                        })
                    )}
                    <Button
                        onClick={openTeacherModal}
                        sx={{
                            width: 'fit-content',
                            ml: '16px'
                        }}
                        variant={'contained'}
                        disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.attributes_window.modal.add_btn.teacher')}
                    </Button>
                </Stack>
            </Paper>
        </Box>
    );
}

export default TeachersWindow;