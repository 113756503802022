import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  AuthControllerRegisterStudentMutationRequest,
  AuthControllerRegisterStudentMutationResponse,
} from '../../ts/authDTO/AuthControllerRegisterStudent'

export function getAuthControllerRegisterStudentUrl() {
  return `/auth/register/student` as const
}

/**
 * @summary Регистрация студента
 * {@link /auth/register/student}
 */
export async function authControllerRegisterStudent(
  data: AuthControllerRegisterStudentMutationRequest,
  config: Partial<RequestConfig<AuthControllerRegisterStudentMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<AuthControllerRegisterStudentMutationResponse, ResponseErrorConfig<Error>, AuthControllerRegisterStudentMutationRequest>({
    method: 'POST',
    url: getAuthControllerRegisterStudentUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}