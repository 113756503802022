import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { AuthControllerLogoutMutationResponse } from '../../ts/authDTO/AuthControllerLogout'

export function getAuthControllerLogoutUrl() {
  return `/auth/logout` as const
}

/**
 * @summary Выход из системы
 * {@link /auth/logout}
 */
export async function authControllerLogout(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<AuthControllerLogoutMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getAuthControllerLogoutUrl().toString(),
    ...requestConfig,
  })
  return res
}