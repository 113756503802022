import { notificationsControllerCreateAppointment } from './notificationsControllerCreateAppointment'
import { notificationsControllerGetAllUserNotifications } from './notificationsControllerGetAllUserNotifications'
import { notificationsControllerGetNewUserNotifications } from './notificationsControllerGetNewUserNotifications'
import { notificationsControllerMarkAsRead } from './notificationsControllerMarkAsRead'

export const notificationsService = {
    notificationsControllerCreateAppointment,
    notificationsControllerGetAllUserNotifications,
    notificationsControllerGetNewUserNotifications,
    notificationsControllerMarkAsRead,
  }