import {Avatar, Paper, Stack, type SxProps, type Theme, Typography} from '@mui/material';
import type {ReactNode} from "react";

const paperSx: SxProps<Theme> = {
    padding: 2.5,
    // boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.20) 0px 7px 15px -4px',
    boxShadow: 'none',
    borderRadius: 5,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    p: '36px',
    minWidth: '400px',
    width: '400px',
    minHeight: '285px',
    cursor: 'pointer',
    // transitionProperty: 'box-shadow, transform',
    '&:hover': {
        backgroundColor: '#fcfcff',
        // boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 12px 24px -4px',
        transform: 'scale(1.02)'
    }
};

interface CardProps {
    title: string;
    icon: ReactNode;
    footer: { title: string, value?: string | number | null, type?: 'Date' }[];
    onClick: () => void;
}

interface CardItemProps {
    title: string;
    value?: string | number | null;
    type?: 'Date';
}

function CardItem({title, value}: CardItemProps) {
    return (
        <Stack
            sx={{
                width: '156px',
                borderRadius: '20px',
                background: '#f5f5f5',
                p: '30px'
            }}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
        >
            <Typography
                fontSize={'48px'}
                letterSpacing={0.17}
                lineHeight={'48px'}
                color={'#05304F'}
            >
                {value}
            </Typography>
            <Typography>
                {title}
            </Typography>
        </Stack>
    );
}

export function Card({title, icon, footer, onClick}: CardProps) {

    return (
        <Paper
            sx={paperSx}
            onClick={onClick}
        >
            <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Typography
                    fontSize={28}
                    fontWeight={500}
                    lineHeight={1.1}
                    sx={{mt: 0.4, mr: 5}}
                >
                    {title}
                </Typography>
                <Avatar
                    sx={{color: '#fff', bgcolor: '#1E9AF3'}}
                >
                    {icon}
                </Avatar>
            </Stack>
            <Stack
                flexDirection="row"
                gap={'14px'}
            >
                {footer.map((item) => {
                    return (
                        <CardItem
                            key={item.title}
                            title={item.title}
                            type={item.type}
                            value={item.value}
                        />
                    );
                })}
            </Stack>
        </Paper>
    );
}
