import {usePagination} from "@/global/hooks/usePagination";
import {apiCertificateApplicationSearch} from "@/modules/certificates/services/certificates.service";
import {ServerDataGrid} from "@/global/components/ServerDataGrid";
import React, {useMemo} from "react";
import type {GridColDef} from "@mui/x-data-grid";
import {
    type CertificateApplicationDto,
    certificateApplicationDtoDeliveryMethodEnum
} from "@/global/api/gen/ts/CertificateApplicationDto";
import {Alert, Box, Button, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {Download} from "@mui/icons-material";
import {useModal} from "@/global/providers/ModalProvider";
import {AddTrackNumberModal} from "@/modules/certificates/dialogs/AddTrackNumberModal";
import {ChangeInfoApplicationModal} from "@/modules/certificates/dialogs/ChangeInfoApplicationModal";
import {Link} from "react-router-dom";
import {BACKEND_URL} from "@/global/actions/apiVariables";

export function CertificateApplicationsTable() {
    const {grid, api} = usePagination();
    const {data, isLoading} = apiCertificateApplicationSearch(api);

    const {openModal} = useModal();

    const onOpenModalTrackNumber = (application: CertificateApplicationDto) => {
        openModal(AddTrackNumberModal, {application}, `track_number_${application.id}`);
    };

    const onOpenModalChangeInfo = (application: CertificateApplicationDto) => {
        openModal(ChangeInfoApplicationModal, {application}, `change_info_${application.id}`);
    };

    const columns: GridColDef<CertificateApplicationDto>[] = useMemo(() => [
        {
            field: 'id',
            headerName: '№',
            width: 90,
            sortable: false,
            renderCell: ({row}) => (
                <Typography>
                    {row.id}
                </Typography>
            )
        },
        {
            field: 'fio',
            headerName: 'ФИО',
            minWidth: 300,
            flex: 1,
            sortable: false,
            renderCell: ({row}) => (
                <Stack>
                    <Typography>
                        {row.full_name}
                    </Typography>
                    <Typography color={'#00000066'}>
                        {row.iin}
                    </Typography>
                </Stack>
            )
        },
        {
            field: 'certificate_number',
            headerName: 'СЕРТИФИКАТ',
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: ({row}) => (
                row.certificate_number
            )
        },
        {
            field: 'school_title',
            headerName: 'АВТОШКОЛА',
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: ({row}) => (
                row.school_title
            )
        },
        {
            field: 'category',
            headerName: 'КАТЕГОРИЯ',
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: ({row}) => (
                row.certificate_data.category
            )
        },
        {
            field: 'delivery_method',
            headerName: 'СПООСОБ ДОСТАВКИ',
            minWidth: 200,
            sortable: false,
            renderCell: ({row}) => {
                const simpleDelivery = row.delivery_method === certificateApplicationDtoDeliveryMethodEnum['1'];

                return (
                    <Typography
                        sx={{
                            paddingX: 2,
                            paddingY: 1,
                            backgroundColor: simpleDelivery ? '#1BA8754D' : '#FF58584D'
                        }}
                    >
                        {simpleDelivery ? 'Обычная' : 'Экспресс'}
                    </Typography>
                );
            }
        },
        {
            field: 'address',
            headerName: 'АДРЕС ДОСТАВКИ',
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: ({row}) => (
                row.address
            )
        },
        {
            field: 'buttons',
            headerName: 'ДОКУМЕНТЫ',
            minWidth: 200,
            sortable: false,
            renderCell: ({row}) => (
                <Stack
                    flexDirection={'row'}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Tooltip title={'Личная карточка водителя'}>
                        <IconButton
                            component={Link}
                            target={'_blank'}
                            to={`${BACKEND_URL}/pdf/driver-personal-card/${row.id}`}
                        >
                            <Download color={'success'}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Подтверждение обучения'}>
                        <IconButton
                            component={Link}
                            target={'_blank'}
                            to={`${BACKEND_URL}/pdf/driver-confirmation/${row.id}`}
                        >
                            <Download color={'error'}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Сертификат с оценками на казахском и русском языке'}>
                        <IconButton
                            component={Link}
                            target={'_blank'}
                            to={`${BACKEND_URL}/pdf/driver-grades/${row.id}`}
                        >
                            <Download color={'secondary'}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Экзаменационная карточка водителя'}>
                        <IconButton
                            component={Link}
                            target={'_blank'}
                            to={`${BACKEND_URL}/pdf/pdd-template/${row.id}`}
                        >
                            <Download color={'primary'}/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            )
        },
        {
            field: 'track_number',
            headerName: 'ТРЕК-НОМЕР',
            minWidth: 250,
            flex: 1,
            sortable: false,
            renderCell: ({row}) => (

                row.tracking_number ? (
                        <Tooltip title={'Для изменение трек-номера нажмите на поле'}>
                            <Alert
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onOpenModalTrackNumber(row);
                                }}
                                severity={'info'}
                                icon={false}
                            >
                                {row.tracking_number}
                            </Alert>
                        </Tooltip>
                    )
                    : (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                onOpenModalTrackNumber(row);
                            }}
                            sx={{
                                textTransform: 'none',
                                borderRadius: '0'
                            }}
                        >
                            Добавить трек-номер
                        </Button>
                    )
            )
        },
    ], []);

    return (
        <Box sx={{marginTop: 2}}>
            <ServerDataGrid
                {...grid}
                onRowClick={(params) => onOpenModalChangeInfo(params.row)}
                rows={data?.items ?? []}
                columns={columns}
                loading={isLoading}
                rowCount={data?.count ?? 0}
                disableRowSelectionOnClick
                disableColumnMenu={true}
                disableColumnFilter={true}
            />
        </Box>
    );
}