import {apiGetDriverListBySchool} from "@/modules/employees/services/employees.service";
import React, {useState} from "react";
import type {
    EmployeeControllerGetAllSchoolTeachersQueryParamsStatusEnum
} from "@/global/api/gen/ts/employeesDTO/EmployeeControllerGetAllSchoolTeachers";
import {Tab, Tabs} from "@mui/material";
import EmployeesTable from "@/Employees/components/EmployeesTable";

interface Props {
    id: number
}

const tabs = {
    active: {id: 1, label: `Активные`},
    inactive: {id: 2, label: `Архив`},
};

export function DriverPage({id}: Props) {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0
    });
    const [status, setStatus] = useState<EmployeeControllerGetAllSchoolTeachersQueryParamsStatusEnum>(1);

    const {data, isLoading} = apiGetDriverListBySchool(id, {
        offset: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize,
        status
    });

    return (
        <>
            <Tabs
                value={status}
                onChange={(_e, newValue) => setStatus(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{mt: 2, ml: 2}}
            >
                {Object.values(tabs).map(tab => (
                    <Tab
                        label={tab.label}
                        key={tab.id}
                        value={tab.id}
                    />
                ))}
            </Tabs>

            <EmployeesTable
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                rows={data?.items ?? []}
                loading={isLoading}
                employee={'drivers'}
                rowCount={data?.total ?? 0}
            />
        </>
    );
}