import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { UsersControllerGetSchoolUsersQueryResponse, UsersControllerGetSchoolUsersQueryParams } from '../../ts/usersDTO/UsersControllerGetSchoolUsers'

export function getUsersControllerGetSchoolUsersUrl() {
  return `/users/managers` as const
}

/**
 * @summary Получить всех менеджеров
 * {@link /users/managers}
 */
export async function usersControllerGetSchoolUsers(
  params: UsersControllerGetSchoolUsersQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerGetSchoolUsersQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getUsersControllerGetSchoolUsersUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}