import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerFindStudentsFromAisByIinQueryResponse,
  StudentsControllerFindStudentsFromAisByIinPathParams,
} from '../../ts/StudentsDTO/StudentsControllerFindStudentsFromAisByIin'

export function getStudentsControllerFindStudentsFromAisByIinUrl({ iin }: { iin: StudentsControllerFindStudentsFromAisByIinPathParams['iin'] }) {
  return `/students/integration/${iin}` as const
}

/**
 * @summary Получение студентов по IIN из АИС
 * {@link /students/integration/:iin}
 */
export async function studentsControllerFindStudentsFromAisByIin(
  { iin }: { iin: StudentsControllerFindStudentsFromAisByIinPathParams['iin'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerFindStudentsFromAisByIinQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerFindStudentsFromAisByIinUrl({ iin }).toString(),
    ...requestConfig,
  })
  return res
}