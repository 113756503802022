import {
	Card, CardContent, CardMedia, Chip, type ChipProps, type SxProps, type Theme, Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import type { TestQuestionPreviewDto } from '../../actions/testQuestionsSearch.type';
import { formatDateTime } from '@/global/helpers/text';
import { MEDIA_URL } from '@/global/actions/apiVariables';

export default function QuestionCard({data}: { data: TestQuestionPreviewDto }) {
    // @ts-ignore
    const color: ChipProps['color'] = {
        0: 'warning',
        1000: 'warning',
        1001: 'success'
    }[data.category?.id || 0] || 'default';
    const theme = useTheme();
    const navigate = useNavigate();
    const cardSx: SxProps<Theme> = {
        cursor: 'pointer',
        borderRadius: 4,
        transitionProperty: 'box-shadow, transform',
        boxShadow: theme.palette.mode == 'light' ? 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.50) 0px 2px 12px -4px' : undefined,
        '&:hover': {
            backgroundColor: theme.palette.mode == 'light' ? '#fcfcff' : '#222222',
            boxShadow: theme.palette.mode == 'light' ? 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 12px 24px -4px' : undefined,
            transform: 'scale(1.01)'
        },
        '&:active': {
            backgroundColor: theme.palette.mode == 'light' ? '#fcfcff' : '#222222',
            boxShadow: theme.palette.mode == 'light' ? 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 5px 18px -4px' : undefined,
            transform: 'scale(1.00)'
        }
    };
    return (
        <Card
            sx={cardSx}
            onClick={() => navigate(`/questions/${data.id}`)}
        >
            <CardMedia
                component="img"
                sx={{bgcolor: 'lightgray', minHeight: 210}}
                image={data.task_file_id ? `${MEDIA_URL}/get/${data.task_file_id}.jpg` : undefined}
            />
            <CardContent>
                <Typography
                    variant="h4"
                    sx={{mb: 1}}
                >
                    Вопрос #
                    {data.id}
                </Typography>
                <Typography
                    variant="body1"
                    fontSize={18}
                    lineHeight={1.3}
                    sx={{minHeight: 10, mb: 1.7}}
                >
                    {data.title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{color: grey[600]}}
                    fontSize={15}
                >
                    Создано: 
                    {' '}
                    {formatDateTime(data.created_at)}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{color: grey[600], mb: 1.5}}
                    fontSize={15}
                >
                    Изменено: 
                    {' '}
                    {formatDateTime(data.updated_at)}
                </Typography>
                <Chip
                    label={data.category?.id ? `${data.category?.id}. ${data.category?.title}` : '⚠️ Без категории'}
                    sx={{mb: -0.5, width: '100%'}}
                    color={color}
                />
            </CardContent>
        </Card>
    );

}
