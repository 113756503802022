import PageHeader from "@/global/components/PageHeader";
import {Box, Stack, Typography} from "@mui/material";
import {CertificateBuyingStepper} from "./components/CertificateBuyingStepper";

export function CertificateBuyingPage() {
    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Stack
                width={{xs: '90%', lg: '80%'}}
                alignItems={'center'}
                justifyContent={'center'}
                gap={4}
                margin={'0 auto'}
                marginTop={4}
            >
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize: {xs: '30px', md: '37px'}
                    }}
                >
                    Сервис выдачи свидетельств
                </Typography>
                <CertificateBuyingStepper/>
            </Stack>
        </Box>
    );
}