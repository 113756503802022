import type {
    DateRange,
    DateRangeParams,
    RegisterParams,
    SchoolUsersParams
} from "@/modules/statistic/types/statistic.type";

export const statisticQueryKey = {
    all: ['statistic'] as const,
    users: () => [...statisticQueryKey.all, 'users'] as const,
    schools: () => [...statisticQueryKey.all, 'schools'] as const,
    groups: () => [...statisticQueryKey.all, 'groups'] as const,
    usersRange: (params: DateRangeParams) => [...statisticQueryKey.users(), params] as const,
    schoolsRange: (params: DateRangeParams) => [...statisticQueryKey.schools(), params] as const,
    groupsRange: (params: DateRangeParams) => [...statisticQueryKey.groups(), params] as const,
    age: () => [...statisticQueryKey.users(), 'age'] as const,
    gender: () => [...statisticQueryKey.users(), 'gender'] as const,
    region: () => [...statisticQueryKey.users(), 'region'] as const,
    categoryUsers: () => [...statisticQueryKey.users(), 'category'] as const,
    schoolExams: () => [...statisticQueryKey.users(), 'schools', 'exams'] as const,
    ageExams: () => [...statisticQueryKey.age(), 'exams'] as const,
    registerTime: (params: RegisterParams) => [...statisticQueryKey.users(), 'register', params] as const,
    schoolUsers: (params: SchoolUsersParams) => [...statisticQueryKey.users(), 'schools', params] as const,
    schoolsOverview: (params: DateRange) => [...statisticQueryKey.schools(), 'overview', params] as const
};