import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerGiveCertificateToStudentMutationResponse,
  StudentsControllerGiveCertificateToStudentPathParams,
} from '../../ts/StudentsDTO/StudentsControllerGiveCertificateToStudent'

export function getStudentsControllerGiveCertificateToStudentUrl({ id }: { id: StudentsControllerGiveCertificateToStudentPathParams['id'] }) {
  return `/students/certificate/${id}` as const
}

/**
 * @summary Выдача сертификата студенту
 * {@link /students/certificate/:id}
 */
export async function studentsControllerGiveCertificateToStudent(
  { id }: { id: StudentsControllerGiveCertificateToStudentPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerGiveCertificateToStudentMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getStudentsControllerGiveCertificateToStudentUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}