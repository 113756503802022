import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerDetachDriverToGroupMutationRequest,
  GroupsControllerDetachDriverToGroupMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerDetachDriverToGroup'

export function getGroupsControllerDetachDriverToGroupUrl() {
  return `/groups/detach/driver` as const
}

/**
 * @summary Удаление водителя из группы
 * {@link /groups/detach/driver}
 */
export async function groupsControllerDetachDriverToGroup(
  data: GroupsControllerDetachDriverToGroupMutationRequest,
  config: Partial<RequestConfig<GroupsControllerDetachDriverToGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerDetachDriverToGroupMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerDetachDriverToGroupMutationRequest
  >({ method: 'POST', url: getGroupsControllerDetachDriverToGroupUrl().toString(), data, ...requestConfig })
  return res
}