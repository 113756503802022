import {Navigate} from 'react-router-dom';

import {type RouteInterface, TypeKeys} from '../routes/routes';
import {useUserStore} from '../store/UserStore';
import {toast} from "react-toastify";

export function MiddlewareRoute({isPublic, role, redirect, element}: RouteInterface) {
    const isSuperAdmin = useUserStore((state) => state.isSuperAdmin);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const isObserver = useUserStore((state) => state.isObserver);
    const isStudent = useUserStore((state) => state.isStudent);
    const allManagerRoles = useUserStore((state) => state.roles);

    if (isPublic) {
        return element;
    }

    if (role === TypeKeys.all && (isSuperAdmin || isAdmin || isObserver || isStudent || allManagerRoles.length > 0)) {
        return element;
    }

    if (role === TypeKeys.student && isStudent) {
        return element;
    }

    if (role === TypeKeys.excludeStudent && (isSuperAdmin || isAdmin || isObserver || allManagerRoles.length > 0)) {
        return element;
    }

    if (role === TypeKeys.admin && (isSuperAdmin || isAdmin)) {
        return element;
    }

    if (role === TypeKeys.superadmin && isSuperAdmin) {
        return element;
    }

    toast.error('У вас нет права доступа к данной странице');
    return <Navigate to={redirect ?? '/landing'}/>;
}