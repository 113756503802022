import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerResendAppealToMailMutationRequest,
  RegisterAppealsControllerResendAppealToMailMutationResponse,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerResendAppealToMail'

export function getRegisterAppealsControllerResendAppealToMailUrl() {
  return `/register-appeals/resend` as const
}

/**
 * @summary Отправить заявку на регистрацию по email
 * {@link /register-appeals/resend}
 */
export async function registerAppealsControllerResendAppealToMail(
  data: RegisterAppealsControllerResendAppealToMailMutationRequest,
  config: Partial<RequestConfig<RegisterAppealsControllerResendAppealToMailMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    RegisterAppealsControllerResendAppealToMailMutationResponse,
    ResponseErrorConfig<Error>,
    RegisterAppealsControllerResendAppealToMailMutationRequest
  >({ method: 'POST', url: getRegisterAppealsControllerResendAppealToMailUrl().toString(), data, ...requestConfig })
  return res
}