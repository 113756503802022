import {Button, Stack, Typography} from '@mui/material';
import React from 'react';
import type {SchoolWithUserDto} from '@/global/api/gen/ts/SchoolWithUserDto';
import type {SchoolDto} from '@/global/api/gen/ts/SchoolDto';
import {Link} from "react-router-dom";

interface Props {
	school: SchoolWithUserDto | SchoolDto,
}

export function SchoolCard({school}: Props) {
	return (
		<Stack
			flexDirection={'column'}
			sx={{
				p: '30px',
				background: '#f5f5f5',
				borderRadius: '20px',
				height: 'auto',
				flex: '0 1 310px'
			}}
		>
			<Typography
				fontSize={'14px'}
				lineHeight={'140%'}
				letterSpacing={'-0.02'}
				color={'#05304F'}
			>
				Учебная организация #
				{school.id}
				:
			</Typography>
			<Typography
				fontSize={'16px'}
				lineHeight={'140%'}
				letterSpacing={'-0.02'}
				color={'#05304F'}
			>
				{school.title}
			</Typography>
			<Typography
				fontSize={'14px'}
				lineHeight={'140%'}
				letterSpacing={'-0.02'}
				color={'#05304F'}
				sx={{
					mt: 1
				}}
			>
				БИН:
			</Typography>
			<Typography
				fontSize={'16px'}
				lineHeight={'140%'}
				letterSpacing={'-0.02'}
				color={'#05304F'}
			>
				{school.bin}
			</Typography>
			<Button
				component={Link}
				to={`/schools/${school.id}`}
				variant={'contained'}
				sx={{
					mt: 5,
					height: '40px'
				}}
			>
				Перейти
			</Button>
		</Stack>
	);
}