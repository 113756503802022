import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateApplicationControllerUpdateTrackNumberMutationRequest,
  CertificateApplicationControllerUpdateTrackNumberMutationResponse,
  CertificateApplicationControllerUpdateTrackNumberPathParams,
} from '../../ts/certificate-applicationDTO/CertificateApplicationControllerUpdateTrackNumber'

export function getCertificateApplicationControllerUpdateTrackNumberUrl({
  certificate_application_id,
}: {
  certificate_application_id: CertificateApplicationControllerUpdateTrackNumberPathParams['certificate_application_id']
}) {
  return `/certificate-application/tracking-number/${certificate_application_id}` as const
}

/**
 * @summary Установить трек номер и отправить его на почту
 * {@link /certificate-application/tracking-number/:certificate_application_id}
 */
export async function certificateApplicationControllerUpdateTrackNumber(
  { certificate_application_id }: { certificate_application_id: CertificateApplicationControllerUpdateTrackNumberPathParams['certificate_application_id'] },
  data: CertificateApplicationControllerUpdateTrackNumberMutationRequest,
  config: Partial<RequestConfig<CertificateApplicationControllerUpdateTrackNumberMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CertificateApplicationControllerUpdateTrackNumberMutationResponse,
    ResponseErrorConfig<Error>,
    CertificateApplicationControllerUpdateTrackNumberMutationRequest
  >({ method: 'PUT', url: getCertificateApplicationControllerUpdateTrackNumberUrl({ certificate_application_id }).toString(), data, ...requestConfig })
  return res
}