import { create } from 'zustand';

type severityType = 'success' | 'error' | 'warning' | 'info';

interface SnackbarState {
    open: boolean,
    message: string,
    severity: severityType,
    closeSnackbar: () => void,
    showMessage: (payload: SnackbarPayload) => void,
    errorMessage: (message: string) => void,
    successMessage: (message: string) => void,
    infoMessage: (message: string) => void,
}

interface SnackbarPayload {
    message: string,
    severity: severityType
}

export const useSnackbarStore = create<SnackbarState>()((set) => ({
    open: false,
    message: '123',
    severity: 'info',
    closeSnackbar: () => set((state) => ({open: false})),
    showMessage: (payload) => set((state) => ({
        open: true,
        message: payload.message,
        severity: payload.severity
    })),
    errorMessage: (message: string) => set((state) => ({
        open: true,
        message,
        severity: 'error'
    })),
    successMessage: (message: string) => set((state) => ({
        open: true,
        message,
        severity: 'success'
    })),
    infoMessage: (message: string) => set((state) => ({
        open: true,
        message,
        severity: 'info'
    }))
}));