import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CategoriesControllerGetSchoolCategoriesQueryResponse,
  CategoriesControllerGetSchoolCategoriesPathParams,
  CategoriesControllerGetSchoolCategoriesQueryParams,
} from '../../ts/CategoriesDTO/CategoriesControllerGetSchoolCategories'

export function getCategoriesControllerGetSchoolCategoriesUrl({ id }: { id: CategoriesControllerGetSchoolCategoriesPathParams['id'] }) {
  return `/categories/${id}` as const
}

/**
 * @summary Получение категорий школы
 * {@link /categories/:id}
 */
export async function categoriesControllerGetSchoolCategories(
  { id }: { id: CategoriesControllerGetSchoolCategoriesPathParams['id'] },
  params: CategoriesControllerGetSchoolCategoriesQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CategoriesControllerGetSchoolCategoriesQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCategoriesControllerGetSchoolCategoriesUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}