import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerGetGroupClassesQueryResponse,
  GroupsControllerGetGroupClassesPathParams,
  GroupsControllerGetGroupClassesQueryParams,
} from '../../ts/GroupsDTO/GroupsControllerGetGroupClasses'

export function getGroupsControllerGetGroupClassesUrl({ groupId }: { groupId: GroupsControllerGetGroupClassesPathParams['groupId'] }) {
  return `/groups/classes/${groupId}` as const
}

/**
 * @summary Получение классов группы по groupId
 * {@link /groups/classes/:groupId}
 */
export async function groupsControllerGetGroupClasses(
  { groupId }: { groupId: GroupsControllerGetGroupClassesPathParams['groupId'] },
  params: GroupsControllerGetGroupClassesQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerGetGroupClassesQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerGetGroupClassesUrl({ groupId }).toString(),
    params,
    ...requestConfig,
  })
  return res
}