import type {ReactNode} from "react";
import {Typography} from "@mui/material";

interface Props {
    children: ReactNode
}

export function HeaderInfo({children}: Props) {

    return (
        <>
            <Typography
                sx={{
                    backgroundColor: 'rgb(19,47,77)',
                    color: 'white',
                    padding: 1.5,
                    borderRadius: '8px'
                }}
            >
                {children}
            </Typography>
        </>
    );
}