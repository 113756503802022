export interface GroupDto {
    id: number;
    school_id: number;
    category_id: string;
    status: GroupStatus;
    title: string;
    students: any[];
    start_date: Date | string | null;
    end_date: Date | string | null;
    teacher: any;
    driver: any;
    master: any;
    transport: any;
}

export enum GroupStatus {
    New = 1,
    Active = 2,
    Exams = 3,
    Finished = 4
}

