import {Avatar, Button, Stack, Typography} from "@mui/material";
import {Book, DirectionsCar} from "@mui/icons-material";
import React from "react";
import {useTranslation} from "react-i18next";
import type {GroupDto} from "@/global/api/gen/ts/GroupDto";
import {useNavigate} from "react-router-dom";

interface Props {
    group: GroupDto | undefined | null;
    handleStudentIndexChange: (type: string) => void;
}

export function StudentMainGroupInfo({group, handleStudentIndexChange}: Props) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <Stack
            flexDirection={'column'}
            sx={{
                width: {xs: '100%', lg: '873px'},
                height: {xs: 'auto', lg: '499px'},
                p: {xs: '10px', lg: '40px'},
                background: '#fff',
                borderRadius: '20px'
            }}
            gap={'10px'}
        >
            <Stack
                flexDirection={{xs: 'column', lg: 'row'}}
                gap={'41px'}
            >
                <Stack
                    flexDirection={'row'}
                    sx={{
                        width: {xs: 'auto', lg: '387px'},
                        minHeight: '147px',
                        background: '#f4f7fe',
                        borderRadius: '20px',
                        p: '24px 16px 24px 24px'
                    }}
                >
                    <Stack
                        flexDirection={'column'}
                    >
                        <Typography
                            fontSize={'16px'}
                            lineHeight={'100%'}
                            color={'#1e9af3'}
                        >
                            ID
                            {group?.title}
                        </Typography>

                        <Stack
                            flexDirection={'column'}
                            sx={{
                                mt: 'auto'
                            }}
                            gap={'10px'}
                        >
                            <Typography
                                fontSize={'16px'}
                                lineHeight={'100%'}
                                color={'rgba(5,48,79,0.6)'}
                            >
                                {t('main-pages.manager.organization')}
                            </Typography>
                            <Typography
                                fontSize={'22px'}
                                lineHeight={'100%'}
                                color={'#05304f'}
                            >
                                {group?.school_id}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        flexDirection={'column'}
                        sx={{
                            ml: 'auto'
                        }}
                    >
                        <Avatar
                            sx={{color: '#1E9AF3', bgcolor: '#fff', mt: '-9px', width: 58, height: 58}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                    </Stack>
                </Stack>
            </Stack>

            <Stack
                flexDirection={{xs: 'column', lg: 'row'}}
                gap={'41px'}
            >
                <Stack
                    flexDirection={'row'}
                    sx={{
                        width: {xs: 'auto', lg: '387px'},
                        minHeight: '130px',
                        background: '#f4f7fe',
                        borderRadius: '20px',
                        p: '24px 16px 24px 24px'
                    }}
                >
                    <Stack
                        flexDirection={'column'}
                    >
                        <Stack
                            flexDirection={'column'}
                            gap={'10px'}
                        >
                            <Typography
                                fontSize={'16px'}
                                lineHeight={'100%'}
                                color={'rgba(5,48,79,0.6)'}
                            >
                                Категория обучения
                            </Typography>
                            <Typography
                                fontSize={'22px'}
                                lineHeight={'100%'}
                                color={'#05304f'}
                            >
                                {group?.category_id}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        flexDirection={'column'}
                        sx={{
                            ml: 'auto'
                        }}
                    >
                        <Avatar
                            sx={{color: '#1E9AF3', bgcolor: '#fff', mt: '-9px', width: 58, height: 58}}
                        >
                            <Book fontSize="medium"/>
                        </Avatar>
                    </Stack>
                </Stack>

                <Stack
                    flexDirection={'row'}
                    sx={{
                        width: {xs: 'auto', lg: '300px'},
                        minHeight: '130px',
                        background: '#f4f7fe',
                        borderRadius: '20px',
                        p: '24px 16px 24px 24px'
                    }}
                >
                    <Stack
                        flexDirection={'column'}
                    >
                        <Stack
                            flexDirection={'column'}
                            gap={'10px'}
                        >
                            <Typography
                                fontSize={'16px'}
                                lineHeight={'100%'}
                                color={'rgba(5,48,79,0.6)'}
                            >
                                Ваша учебная группа:
                            </Typography>
                            <Typography
                                fontSize={'22px'}
                                lineHeight={'100%'}
                                color={'#05304f'}
                            >
                                {group?.title}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

            <Stack
                flexDirection={'row'}
                gap={'10px'}
                sx={{
                    height: "fit-content",
                    ml: "auto",
                }}
            >
                <Button
                    sx={{
                        width: 'fit-content',
                        height: 'fit-content',
                        borderRadius: '16px',
                        minWidth: 'fit-content',
                        p: 0
                    }}
                    onClick={() => {
                        handleStudentIndexChange('previous');
                    }}
                >
                    <svg
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            width="52"
                            height="52"
                            rx="16"
                            fill="#F4F7FE"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M23.9141 32L17.9998 26L23.9141 20L26.4998 20L21.8283 25L33.4141 25L33.4141 27L21.8283 27L26.4998 32L23.9141 32Z"
                            fill="#05304F"
                        />
                    </svg>
                </Button>
                <Button
                    sx={{
                        width: 'fit-content',
                        height: 'fit-content',
                        borderRadius: '16px',
                        minWidth: 'fit-content',
                        p: 0
                    }}
                    onClick={() => {
                        handleStudentIndexChange('next');
                    }}
                >
                    <svg
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="52"
                            y="52"
                            width="52"
                            height="52"
                            rx="16"
                            transform="rotate(-180 52 52)"
                            fill="#F4F7FE"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M27.5 20L33.4142 26L27.5 32L24.9143 32L29.5858 27L18 27L18 25L29.5858 25L24.9143 20L27.5 20Z"
                            fill="#05304F"
                        />
                    </svg>
                </Button>
            </Stack>

            <Button
                variant={'outlined'}
                sx={{
                    color: '#000',
                    textTransform: 'none'
                }}
                disabled={true}
                onClick={() => {
                    navigate(`/schools/}`);
                }}
            >
                {t('main-pages.manager.details')}
            </Button>
        </Stack>
    )
}