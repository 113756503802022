import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { NewsControllerAddNewsMutationRequest, NewsControllerAddNewsMutationResponse } from '../../ts/NewsDTO/NewsControllerAddNews'

export function getNewsControllerAddNewsUrl() {
  return `/news/new` as const
}

/**
 * @summary Создание новости
 * {@link /news/new}
 */
export async function newsControllerAddNews(
  data: NewsControllerAddNewsMutationRequest,
  config: Partial<RequestConfig<NewsControllerAddNewsMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<NewsControllerAddNewsMutationResponse, ResponseErrorConfig<Error>, NewsControllerAddNewsMutationRequest>({
    method: 'POST',
    url: getNewsControllerAddNewsUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}