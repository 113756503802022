import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateApplicationControllerGetCertificateApplicationByIdQueryResponse,
  CertificateApplicationControllerGetCertificateApplicationByIdPathParams,
} from '../../ts/certificate-applicationDTO/CertificateApplicationControllerGetCertificateApplicationById'

export function getCertificateApplicationControllerGetCertificateApplicationByIdUrl({
  id,
}: {
  id: CertificateApplicationControllerGetCertificateApplicationByIdPathParams['id']
}) {
  return `/certificate-application/${id}` as const
}

/**
 * @summary Получение заявки на сертификат
 * {@link /certificate-application/:id}
 */
export async function certificateApplicationControllerGetCertificateApplicationById(
  { id }: { id: CertificateApplicationControllerGetCertificateApplicationByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateApplicationControllerGetCertificateApplicationByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateApplicationControllerGetCertificateApplicationByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}