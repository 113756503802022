import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerTriggerRefreshQuickTransactionQueryResponse,
  TransactionsControllerTriggerRefreshQuickTransactionPathParams,
} from '../../ts/TransactionsDTO/TransactionsControllerTriggerRefreshQuickTransaction'

export function getTransactionsControllerTriggerRefreshQuickTransactionUrl({
  transactionId,
}: {
  transactionId: TransactionsControllerTriggerRefreshQuickTransactionPathParams['transactionId']
}) {
  return `/transactions/quick/refresh/${transactionId}` as const
}

/**
 * @summary Обновление быстрого платежа
 * {@link /transactions/quick/refresh/:transactionId}
 */
export async function transactionsControllerTriggerRefreshQuickTransaction(
  { transactionId }: { transactionId: TransactionsControllerTriggerRefreshQuickTransactionPathParams['transactionId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransactionsControllerTriggerRefreshQuickTransactionQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTransactionsControllerTriggerRefreshQuickTransactionUrl({ transactionId }).toString(),
    ...requestConfig,
  })
  return res
}