import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CabinetsControllerApproveCabinetMutationResponse,
  CabinetsControllerApproveCabinetPathParams,
} from '../../ts/CabinetsDTO/CabinetsControllerApproveCabinet'

export function getCabinetsControllerApproveCabinetUrl({ id }: { id: CabinetsControllerApproveCabinetPathParams['id'] }) {
  return `/cabinets/approve/${id}` as const
}

/**
 * @summary Подтвердить помещение
 * {@link /cabinets/approve/:id}
 */
export async function cabinetsControllerApproveCabinet(
  { id }: { id: CabinetsControllerApproveCabinetPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CabinetsControllerApproveCabinetMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getCabinetsControllerApproveCabinetUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}