import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerGetProductPriceByProductIdQueryResponse,
  TransactionsControllerGetProductPriceByProductIdPathParams,
} from '../../ts/TransactionsDTO/TransactionsControllerGetProductPriceByProductId'

export function getTransactionsControllerGetProductPriceByProductIdUrl({
  productId,
}: {
  productId: TransactionsControllerGetProductPriceByProductIdPathParams['productId']
}) {
  return `/transactions/products-prices/${productId}` as const
}

/**
 * @summary Получение цены услуги
 * {@link /transactions/products-prices/:productId}
 */
export async function transactionsControllerGetProductPriceByProductId(
  { productId }: { productId: TransactionsControllerGetProductPriceByProductIdPathParams['productId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransactionsControllerGetProductPriceByProductIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTransactionsControllerGetProductPriceByProductIdUrl({ productId }).toString(),
    ...requestConfig,
  })
  return res
}