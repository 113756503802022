import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateApplicationControllerUpdateCertificateDataMutationRequest,
  CertificateApplicationControllerUpdateCertificateDataMutationResponse,
  CertificateApplicationControllerUpdateCertificateDataPathParams,
} from '../../ts/certificate-applicationDTO/CertificateApplicationControllerUpdateCertificateData'

export function getCertificateApplicationControllerUpdateCertificateDataUrl({
  certificate_application_id,
}: {
  certificate_application_id: CertificateApplicationControllerUpdateCertificateDataPathParams['certificate_application_id']
}) {
  return `/certificate-application/update/${certificate_application_id}` as const
}

/**
 * @summary Обновить данные по заявке
 * {@link /certificate-application/update/:certificate_application_id}
 */
export async function certificateApplicationControllerUpdateCertificateData(
  { certificate_application_id }: { certificate_application_id: CertificateApplicationControllerUpdateCertificateDataPathParams['certificate_application_id'] },
  data: CertificateApplicationControllerUpdateCertificateDataMutationRequest,
  config: Partial<RequestConfig<CertificateApplicationControllerUpdateCertificateDataMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CertificateApplicationControllerUpdateCertificateDataMutationResponse,
    ResponseErrorConfig<Error>,
    CertificateApplicationControllerUpdateCertificateDataMutationRequest
  >({ method: 'PUT', url: getCertificateApplicationControllerUpdateCertificateDataUrl({ certificate_application_id }).toString(), data, ...requestConfig })
  return res
}