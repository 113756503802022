import { groupsControllerArchiveGroups } from './groupsControllerArchiveGroups'
import { groupsControllerAttachDriverToGroup } from './groupsControllerAttachDriverToGroup'
import { groupsControllerAttachMasterToGroup } from './groupsControllerAttachMasterToGroup'
import { groupsControllerAttachTeacherToGroup } from './groupsControllerAttachTeacherToGroup'
import { groupsControllerAttachTransportToGroup } from './groupsControllerAttachTransportToGroup'
import { groupsControllerChangeGroupCategory } from './groupsControllerChangeGroupCategory'
import { groupsControllerChangeGroupDates } from './groupsControllerChangeGroupDates'
import { groupsControllerChangeGroupStatus } from './groupsControllerChangeGroupStatus'
import { groupsControllerChangeGroupTitle } from './groupsControllerChangeGroupTitle'
import { groupsControllerCheckCurrentDateByEndDate } from './groupsControllerCheckCurrentDateByEndDate'
import { groupsControllerCorrectGroupsEndDates } from './groupsControllerCorrectGroupsEndDates'
import { groupsControllerCreateGroup } from './groupsControllerCreateGroup'
import { groupsControllerDeleteGroupById } from './groupsControllerDeleteGroupById'
import { groupsControllerDetachDriverToGroup } from './groupsControllerDetachDriverToGroup'
import { groupsControllerDetachMasterFromGroup } from './groupsControllerDetachMasterFromGroup'
import { groupsControllerDetachTeacherToGroup } from './groupsControllerDetachTeacherToGroup'
import { groupsControllerDetachTransportToGroup } from './groupsControllerDetachTransportToGroup'
import { groupsControllerGetAllGroups } from './groupsControllerGetAllGroups'
import { groupsControllerGetGroupById } from './groupsControllerGetGroupById'
import { groupsControllerGetGroupClasses } from './groupsControllerGetGroupClasses'
import { groupsControllerGetGroupsBySchoolId } from './groupsControllerGetGroupsBySchoolId'
import { groupsControllerGetGroupsWithStatusExamsByUserId } from './groupsControllerGetGroupsWithStatusExamsByUserId'
import { groupsControllerGetPracticeHoursInfoByGroupId } from './groupsControllerGetPracticeHoursInfoByGroupId'
import { groupsControllerGetSchoolGroupsCount } from './groupsControllerGetSchoolGroupsCount'
import { groupsControllerGetSectionsInfoByGroupId } from './groupsControllerGetSectionsInfoByGroupId'
import { groupsControllerGroupPrecalculateDate } from './groupsControllerGroupPrecalculateDate'
import { groupsControllerSoapAutoSubmitGroupClass } from './groupsControllerSoapAutoSubmitGroupClass'
import { groupsControllerSoapDeleteGroupClass } from './groupsControllerSoapDeleteGroupClass'
import { groupsControllerSoapSubmitGroupClass } from './groupsControllerSoapSubmitGroupClass'

export const groupsService = {
    groupsControllerGetGroupsWithStatusExamsByUserId,
    groupsControllerGetGroupClasses,
    groupsControllerGetSectionsInfoByGroupId,
    groupsControllerGetPracticeHoursInfoByGroupId,
    groupsControllerCreateGroup,
    groupsControllerChangeGroupDates,
    groupsControllerChangeGroupTitle,
    groupsControllerChangeGroupCategory,
    groupsControllerChangeGroupStatus,
    groupsControllerGetGroupsBySchoolId,
    groupsControllerGetSchoolGroupsCount,
    groupsControllerGetAllGroups,
    groupsControllerCorrectGroupsEndDates,
    groupsControllerGetGroupById,
    groupsControllerDeleteGroupById,
    groupsControllerAttachTeacherToGroup,
    groupsControllerAttachDriverToGroup,
    groupsControllerAttachMasterToGroup,
    groupsControllerDetachTeacherToGroup,
    groupsControllerDetachDriverToGroup,
    groupsControllerDetachMasterFromGroup,
    groupsControllerAttachTransportToGroup,
    groupsControllerDetachTransportToGroup,
    groupsControllerCheckCurrentDateByEndDate,
    groupsControllerArchiveGroups,
    groupsControllerSoapDeleteGroupClass,
    groupsControllerSoapSubmitGroupClass,
    groupsControllerSoapAutoSubmitGroupClass,
    groupsControllerGroupPrecalculateDate,
  }