import {useCustomQuery} from '@/global/hooks/useCustomQuery';
import {usersService} from '@/global/api/gen';
import {UserKeys} from '@/modules/users/services/user.keys';
import {useCustomMutation} from '@/global/hooks/useCustomMutation';
import type {ChangePasswordDto} from '@/global/api/gen/ts/ChangePasswordDto';
import type {OmmitedUseQueryOptions} from '@/global/types';
import type {UserDto} from '@/global/api/gen/ts/UserDto';
import type {
	UsersControllerGetSchoolUsersQueryParams
} from '@/global/api/gen/ts/usersDTO/UsersControllerGetSchoolUsers';
import type {
	UsersControllerGetUsersBySchoolIdQueryParams
} from "@/global/api/gen/ts/usersDTO/UsersControllerGetUsersBySchoolId";
import type {WorkerCreateDto} from "@/global/api/gen/ts/WorkerCreateDto";
import type {WorkerAttachDto} from "@/global/api/gen/ts/WorkerAttachDto";
import type {UserInfoChangeDto} from "@/global/api/gen/ts/UserInfoChangeDto";

// export const apiGetUserList = () => {
// 	return useCustomQuery({
// 		queryFn: () => usersService.usersControllerGetUsers()
// 			.then((res) => res.data),
// 		queryKey: UserKeys.search()
// 	});
// };

export const apiGetSchoolUserList = (params:UsersControllerGetSchoolUsersQueryParams) => {
	return useCustomQuery({
		queryFn: () => usersService.usersControllerGetSchoolUsers(params)
			.then((res) => res.data),
		queryKey: UserKeys.schoolSearch(params)
	});
};

export const apiGetUserById = (id:number, options?: OmmitedUseQueryOptions<UserDto>) => {
	return useCustomQuery({
		queryFn: () => usersService.usersControllerGetUserById({id})
			.then((res) => res.data),
		queryKey: UserKeys.detail(id),
		...options,
	});
};

export const apiUserByIin = (iin:string, options?: OmmitedUseQueryOptions<UserDto>) => {
	return useCustomQuery({
		queryFn: () => usersService.usersControllerGetStudentByIin({iin})
			.then((res) => res.data),
		queryKey: UserKeys.iin(iin),
		retry: 1,
		...options,
	});
};

export const apiChangePasswordById = () => {
	return useCustomMutation({
		mutationFn: ({id, ...data}: ChangePasswordDto & {id:number} ) => usersService.usersControllerChangePasswordForUserById({id},data)
			.then((res) => res.data),
		queryKey: UserKeys.all
	});
};

export const apiGetSchoolWorkers = (schoolId:number, params: UsersControllerGetUsersBySchoolIdQueryParams) => {
	return useCustomQuery({
		enabled: !!schoolId && !isNaN(schoolId),
		queryFn: () => usersService.usersControllerGetUsersBySchoolId({schoolId}, params)
			.then((res) => res.data),
		queryKey: UserKeys.workersWithParams(schoolId, params),
	});
};

export const apiCreateSchoolWorker = () => {
	return useCustomMutation({
		mutationFn: (data:WorkerCreateDto) => usersService.usersControllerApplyWorkerToSchool(data)
			.then((res) => res.data),
		queryKey: (data) => data.school_id ? UserKeys.workers(data.school_id) : UserKeys.all,
	});
};

export const apiAttachSchoolWorker = () => {
	return useCustomMutation({
		mutationFn: (data:WorkerAttachDto) => usersService.usersControllerAttachExistingWorkerToSchool(data)
			.then((res) => res.data),
		queryKey: (data) => data.school_id ? UserKeys.workers(data.school_id) : UserKeys.all,
	});
};

export const apiChangeUserInfo = () => {
	return useCustomMutation({
		mutationFn: (data:UserInfoChangeDto) => usersService.usersControllerChangeUserInfoById(data)
			.then((res) => res.data),
		queryKey: (data) => UserKeys.detail(data.id),
	});
};