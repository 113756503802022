import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useSchoolStore} from '../store/SchoolStore';
import PageHeader from '../../global/components/PageHeader';
import {useUserStore} from '@/global/store/UserStore';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {apiGetSchoolById} from "@/modules/schools/services/school.service";
import {SchoolPageInfoSection} from "@/modules/schools/components/SchoolPageInfoSection";
import {SchoolPageAttributesSection} from "@/modules/schools/components/SchoolPageAttributesSection";
import {SchoolPageFeaturesSection} from "@/modules/schools/components/SchoolPageFeaturesSection";
import {toast} from "react-toastify";
import {getMessageFromError} from "@/global/helpers/getMessageFromError";

function SchoolPage() {
    const {id} = useParams();

    const {t} = useTranslation();

    const isSuperAdmin = useUserStore((state) => state.isSuperAdmin);

    const currentSchool = useSchoolStore((state) => state);

    const {data: schoolInfo, error} = apiGetSchoolById(+(id ?? 0));
    const navigate = useNavigate();

    useEffect(() => {
        if (!id || isNaN(+id)) {
            toast.error('Неверный ID школы');
            navigate('/schools');
        }
    }, []);

    useEffect(() => {
        if (schoolInfo) {
            currentSchool.storeSchoolInfo(schoolInfo.id, schoolInfo.title);
        }
    }, [schoolInfo]);

    if (error) {
        toast.error(`Произошла ошибка при загрузке школы: ${getMessageFromError(error)}`);
        navigate('/schools');
        return null;
    }

    if (!schoolInfo) {
        return (
            <Dialog
                maxWidth={'md'}
                fullWidth={true}
                open={true}
            >
                <DialogTitle>
                    {t('school_page.ais-title')}
                </DialogTitle>
                <DialogContent>
                    <Stack
                        flexDirection={'column'}
                        gap={2}
                    >
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            <Typography>
                                {t('school_page.ais-content')}
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            <CircularProgress/>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column', pb: 3}}
        >
            <PageHeader/>
            <Box sx={wrapperSx}>

                <SchoolPageInfoSection schoolInfo={schoolInfo}/>

                <SchoolPageAttributesSection schoolId={schoolInfo.id}/>

                {isSuperAdmin && <SchoolPageFeaturesSection/>}

            </Box>
        </Box>
    );
}

export default SchoolPage;
