import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerCertificatePDFForProtocolExamsQueryResponse,
  CertificateControllerCertificatePDFForProtocolExamsPathParams,
} from '../../ts/CertificateDTO/CertificateControllerCertificatePDFForProtocolExams'

export function getCertificateControllerCertificatePDFForProtocolExamsUrl({
  number,
}: {
  number: CertificateControllerCertificatePDFForProtocolExamsPathParams['number']
}) {
  return `/certificate/pdf/protocol/${number}` as const
}

/**
 * {@link /certificate/pdf/protocol/:number}
 */
export async function certificateControllerCertificatePDFForProtocolExams(
  { number }: { number: CertificateControllerCertificatePDFForProtocolExamsPathParams['number'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerCertificatePDFForProtocolExamsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerCertificatePDFForProtocolExamsUrl({ number }).toString(),
    ...requestConfig,
  })
  return res
}