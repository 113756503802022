export const groupClassSubmitDtoTypeEnum = {
  theory: 'theory',
  practice: 'practice',
  exam: 'exam',
} as const

export type GroupClassSubmitDtoTypeEnum = (typeof groupClassSubmitDtoTypeEnum)[keyof typeof groupClassSubmitDtoTypeEnum]

export type GroupClassSubmitDto = {
  /**
   * @type string
   */
  type: GroupClassSubmitDtoTypeEnum
  /**
   * @type number
   */
  school_id: number
  /**
   * @type number
   */
  group_id: number
  /**
   * @type number
   */
  teacher_id?: number | null
  /**
   * @type number
   */
  driver_id?: number | null
  /**
   * @type number
   */
  master_id?: number | null
  /**
   * @type number
   */
  car_id?: number | null
  /**
   * @type string
   */
  start_date_time: string
  /**
   * @minLength 1
   * @maxLength 2
   * @type number
   */
  duration: number
  /**
   * @type number
   */
  student_id?: number | null
  /**
   * @type number
   */
  section_id: number
  /**
   * @type number
   */
  topic_id?: number | null
  /**
   * @type number
   */
  cabinet_id: number | null
}