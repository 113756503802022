import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerSoapSubmitGroupClassMutationRequest,
  GroupsControllerSoapSubmitGroupClassMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerSoapSubmitGroupClass'

export function getGroupsControllerSoapSubmitGroupClassUrl() {
  return `/groups/submit-class` as const
}

/**
 * {@link /groups/submit-class}
 */
export async function groupsControllerSoapSubmitGroupClass(
  data: GroupsControllerSoapSubmitGroupClassMutationRequest,
  config: Partial<RequestConfig<GroupsControllerSoapSubmitGroupClassMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerSoapSubmitGroupClassMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerSoapSubmitGroupClassMutationRequest
  >({ method: 'POST', url: getGroupsControllerSoapSubmitGroupClassUrl().toString(), data, ...requestConfig })
  return res
}