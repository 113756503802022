import React, {useState} from 'react';
import {Box, Stack, Typography} from '@mui/material';

import {useUserStore} from '@/global/store/UserStore';
import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {apiSearchStudentByIin} from "@/modules/students/services/student.service";
import {StudentMainGroupInfo} from "@/modules/students/components/StudentMainGroupInfo";
import {PrivacyPolicyAgreeSection} from "@/modules/students/components/PrivacyPolicyAgreeSection";
import {StudentMainPageIntroSection} from "@/modules/students/components/StudentMainPageIntroSection";
import {UserInfo} from "@/modules/students/components/UserInfo";
import {TicketsSection} from "@/modules/tickets/components/TicketsSection";
import {NewsSection} from "@/modules/news/components/NewsSection";

function StudentMainPage() {
    const currentUser = useUserStore((state) => state);

    const {data: studentsInfo} = apiSearchStudentByIin(currentUser.iin, {
        limit: 100,
        offset: 0
    });

    const [currentStudentIndex, setCurrentStudentIndex] = useState(0);

    const handleStudentIndexChange = (type: string) => {
        if (type === 'next') {
            if (studentsInfo?.items.length === currentStudentIndex + 1) {
                setCurrentStudentIndex(0);
            } else {
                setCurrentStudentIndex((prev) => prev + 1);
            }
        }

        if (type === 'previous') {
            if (currentStudentIndex === 0) {
                setCurrentStudentIndex(studentsInfo?.items.length ? studentsInfo?.items.length - 1 : 0);
            } else {
                setCurrentStudentIndex((prev) => prev - 1);
            }
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: '#f4f7fe'
            }}
        >
            <PageHeader/>
            <Box sx={{...wrapperSx, px: {xs: '10px', lg: '60px'}, pb: '60px'}}>
                <StudentMainPageIntroSection/>
                <Stack
                    flexDirection={{xs:'column', lg: 'row'}}
                    alignItems={{xs: 'center', lg: 'flex-start'}}
                    sx={{
                        mt: '46px'
                    }}
                    gap={'20px'}
                >
                    <UserInfo/>
                    {
                        studentsInfo?.items.length
                            ? (
                            <StudentMainGroupInfo
                                group={studentsInfo?.items[currentStudentIndex]?.group}
                                handleStudentIndexChange={handleStudentIndexChange}
                            />
                          )
                            : (
                            <Typography variant={"h4"}>
                                Студент пока не находится не в одной группе.
                            </Typography>
                          )}
                </Stack>
                <PrivacyPolicyAgreeSection/>
                <TicketsSection/>
                <NewsSection
                    authorized={true}
                />
            </Box>
        </Box>
    );
}

export default StudentMainPage;