import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  UsersControllerApplyWorkerToSchoolMutationRequest,
  UsersControllerApplyWorkerToSchoolMutationResponse,
} from '../../ts/usersDTO/UsersControllerApplyWorkerToSchool'

export function getUsersControllerApplyWorkerToSchoolUrl() {
  return `/users/worker/create` as const
}

/**
 * @summary Создать работника
 * {@link /users/worker/create}
 */
export async function usersControllerApplyWorkerToSchool(
  data: UsersControllerApplyWorkerToSchoolMutationRequest,
  config: Partial<RequestConfig<UsersControllerApplyWorkerToSchoolMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerApplyWorkerToSchoolMutationResponse, ResponseErrorConfig<Error>, UsersControllerApplyWorkerToSchoolMutationRequest>({
    method: 'POST',
    url: getUsersControllerApplyWorkerToSchoolUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}