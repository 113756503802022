import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerGetUserSchoolsQueryResponse,
  SchoolsControllerGetUserSchoolsQueryParams,
} from '../../ts/schoolsDTO/SchoolsControllerGetUserSchools'

export function getSchoolsControllerGetUserSchoolsUrl() {
  return `/schools/search/public` as const
}

/**
 * @summary Получение списка всех школ для пользователей
 * {@link /schools/search/public}
 */
export async function schoolsControllerGetUserSchools(
  params: SchoolsControllerGetUserSchoolsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGetUserSchoolsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerGetUserSchoolsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}