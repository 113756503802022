import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { SchoolsControllerSearchSchoolsQueryResponse, SchoolsControllerSearchSchoolsQueryParams } from '../../ts/schoolsDTO/SchoolsControllerSearchSchools'

export function getSchoolsControllerSearchSchoolsUrl() {
  return `/schools` as const
}

/**
 * @summary Поиск автошкол
 * {@link /schools}
 */
export async function schoolsControllerSearchSchools(
  params: SchoolsControllerSearchSchoolsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerSearchSchoolsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerSearchSchoolsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}