import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerDeleteCertificateMutationRequest,
  CertificateControllerDeleteCertificateMutationResponse,
} from '../../ts/CertificateDTO/CertificateControllerDeleteCertificate'

export function getCertificateControllerDeleteCertificateUrl() {
  return `/certificate/delete` as const
}

/**
 * @summary Удаление сертификата по номеру
 * {@link /certificate/delete}
 */
export async function certificateControllerDeleteCertificate(
  data: CertificateControllerDeleteCertificateMutationRequest,
  config: Partial<RequestConfig<CertificateControllerDeleteCertificateMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CertificateControllerDeleteCertificateMutationResponse,
    ResponseErrorConfig<Error>,
    CertificateControllerDeleteCertificateMutationRequest
  >({ method: 'POST', url: getCertificateControllerDeleteCertificateUrl().toString(), data, ...requestConfig })
  return res
}