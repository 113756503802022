import {type ChangeEvent, type FormEvent, useState} from 'react';
import {CircularProgress, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography} from '@mui/material';

import {useSnackbarStore} from '@/global/store/SnackbarStore';
import {validateEmail, validatePhoneNumber} from '@/global/helpers/validators';
import LoadingButton from '../../../global/components/LoadingButton';
import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {HasMatchUser} from "@/Group/components/GroupPage/HasMatchUser";
import {apiCreateUserForStudent} from "@/modules/students/services/student.service";
import type {StudentUserCreateDto} from "@/global/api/gen/ts/StudentUserCreateDto";
import {apiUserByIin} from "@/modules/users/services/user.service";

interface ModalProps {
    student: StudentDto,
}

export function CreateStudentUserModal({open, close, student}: ModalSystemProps<ModalProps>) {

    const snackbar = useSnackbarStore((state) => state);

    const {data: studentUserByIIN, isLoading: getLoading} = apiUserByIin(student.iin);
    const [studentInfo, setStudentInfo] = useState<StudentUserCreateDto>({
        student_id: student.id,
        last_name: '',
        first_name: '',
        patronymic: '',
        iin: '',
        email: '',
        phone: '',
        gender: 0,
        address: '',
    });

    const {mutate: createUserForStudent, isPending:createUserLoading} = apiCreateUserForStudent();

    const handleStudentChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setStudentInfo((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!studentInfo.email || !validateEmail(studentInfo.email)) {
            snackbar.errorMessage('Неправильный формат электронной почты. Убедитесь, что адрес соответствует формату "example@domain.com".');
            return;
        }

        if (!studentInfo.phone ||!validatePhoneNumber(studentInfo.phone)) {
            snackbar.errorMessage('Неправильный формат номера телефона. Убедитесь, что номер начинается с "+7" и содержит 11 цифр, например: +7***1112233.');
            return;
        }

        createUserForStudent(studentInfo, {
            onSuccess: () => {
                snackbar.successMessage(`Личный кабинет пользователя для студента ${studentInfo.last_name} ${studentInfo.first_name} был успешно создан.`);
            }
        });
    };

    const onClose = () => {
        if (getLoading || createUserLoading) {
            snackbar.errorMessage('Невозможно выйти из диалогового окна во время запроса');
            return;
        }
        close(5);
    };

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Создание/прикрепление личного кабинета студента
            </DialogTitle>
            <DialogContent>
                {getLoading && (
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <CircularProgress/>
                    </Stack>
                )}

                {studentUserByIIN && !getLoading && (
                    <HasMatchUser
                        user={studentUserByIIN}
                        student={student}
                    />
                )}

                {!studentUserByIIN && !getLoading && (
                    <Stack
                        flexDirection={'column'}
                        gap={'10px'}
                    >
                        <Typography>
                            Внимание! По ИИН данного студента (
                            {studentInfo.last_name}
                            {' '}
                            {studentInfo.first_name}
                            {' '}
                            -
                            {' '}
                            {studentInfo.iin}
                            ) - отсутствует пользователь на портале Avtomektep.KZ.
                        </Typography>
                        <Typography>
                            Для прохождения онлайн внутришкольных экзаменов - необходимо создать для каждого студента
                            личный кабинет на портале.
                        </Typography>
                        <form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                margin: '15px 0'
                            }}
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                name={'last_name'}
                                value={studentInfo.last_name}
                                label={'Фамилия'}
                                disabled={true}
                            />
                            <TextField
                                name={'first_name'}
                                value={studentInfo.first_name}
                                label={'Имя'}
                                disabled={true}
                            />
                            <TextField
                                name={'patronymic'}
                                value={studentInfo.patronymic}
                                label={'Отчество'}
                                disabled={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                name={'iin'}
                                value={studentInfo.iin}
                                label={'ИИН'}
                                disabled={true}
                            />
                            <TextField
                                name={'email'}
                                value={studentInfo.email}
                                label={'Электронная почта'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={createUserLoading}
                                onChange={handleStudentChange}
                            />
                            <TextField
                                name={'phone'}
                                value={studentInfo.phone}
                                label={'Номер телефона*'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={createUserLoading}
                                onChange={handleStudentChange}
                            />

                            <LoadingButton
                                variant={'contained'}
                                type={'submit'}
                                loading={createUserLoading}
                            >
                                Создать личный кабинет студента
                            </LoadingButton>
                        </form>
                    </Stack>
                )}
            </DialogContent>
        </Dialog>
    );
}

