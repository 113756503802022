import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import { useUserStore } from '@/global/store/UserStore';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { apiFinishExam, apiGetTheoryExam, apiSubmitAnswer } from '../actions/api';
import TheoryExamBeginningModal from '../components/TheoryExamBeginningModal';
import TimerComponent from '../components/ExamTimer';
import type { TheoryExamQuestionType, TheoryExamType } from '@/global/actions/types.api';
import PageHeader from '../../global/components/PageHeader';
import VideoPlayer from '../components/VideoPlayer';
import LoadingButton from '../../global/components/LoadingButton';
import { PDDTEST_MEDIA_API_URL, PROCTORING_URL } from '@/global/actions/apiVariables';

interface handleStartProps {
    began_at: Date,
    current_time: Date
}

interface setTimerPropsType {
    began_at: Date | null,
    current_time: Date | null
}

enum AnswersButtonStartSymbols {
    A = 1,
    B = 2,
    C = 3,
    D = 4,
    E = 5,
    F = 6
}

function TheoryExamPage() {
    const currentUser = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const navigate = useNavigate();
    const {id} = useParams();

    const videoRef = useRef<HTMLVideoElement>(null);

    const [theoryExamInfo, theoryExamLoading, theoryExamError, fetchTheoryExam] = useLoadingHook<TheoryExamType>(apiGetTheoryExam);
    const [submitAnswerResult, submitAnswerLoading, submitAnswerError, submitAnswer] = useLoadingHook(apiSubmitAnswer);
    const [finishExamResult, finishExamLoading, finishExamError, requestFinishExam] = useLoadingHook(apiFinishExam);

    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');
    const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');

    const [beginnigModalOpen, setBeginningModalOpen] = useState<boolean>(true);
    const [timerProps, setTimerProps] = useState<setTimerPropsType>({
        began_at: null,
        current_time: null,
    });
    const [examStarted, setExamStarted] = useState<boolean>(false);

    const [currentQuestionOrder, setCurrentQuestionOrder] = useState<number | null>(null);
    const [currentQuestion, setCurrentQuestion] = useState<TheoryExamQuestionType | null>(null);
    const [selectedAnswerId, setSelectedAnswerId] = useState<number | null>(null);
    const [mediaDevicesPermissionStatus, setMediaDevicesPermissionStatus] = useState<'granted' | 'denied' | 'default'>('default');

    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [socketStatus, setSocketStatus] = useState<'connecting' | 'connected' | 'disconnected' | 'error'>('disconnected');
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);

    useEffect(() => {
        fetchTheoryExam(Number(id));
    }, [id]);

    useEffect(() => {
        if (theoryExamInfo) {
            // Выбор первого вопроса, на который не ответил пользователь
            const unansweredOrder = getFirstUnansweredQuestionOrder();

            if (unansweredOrder) {
                const unansweredQuestion = unansweredOrder ? getQuestionByOrder(unansweredOrder) : null;

                unansweredOrder && setCurrentQuestionOrder(unansweredOrder);
                unansweredQuestion && unansweredOrder && setCurrentQuestion(unansweredQuestion);
            } else {
                finishExam();
            }
        }
        if (theoryExamError) {
            toast.error(theoryExamError.response?.data?.message ? theoryExamError.response?.data?.message : 'Произошла непредвиденная ошибка');
        }
    }, [theoryExamInfo, theoryExamError]);

    const handleBeginExam = async (timerProps: handleStartProps) => {
        if (!selectedVideoDevice) {
            toast.error('Отсутствует выбранный источник видео');
            return;
        }

        if (!selectedAudioDevice) {
            toast.error('Отсутствует выбранный источник аудио');
            return;
        }

        setTimerProps({
            current_time: new Date(timerProps.current_time),
            began_at: new Date(timerProps.began_at),
        });

        const stream = await navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: {
                    exact: selectedVideoDevice
                }
            },
            audio: {
                deviceId: {
                    exact: selectedAudioDevice
                },
            }
        });
        setStream(stream);

        setExamStarted(true);
    };

    const getQuestionByOrder = (questionOrder: number) => {
        if (theoryExamInfo) {
            // Используем find вместо map, чтобы найти вопрос по ID
            return theoryExamInfo.questions.find((question) => question.order === questionOrder) || null;
        } else {
            return null;
        }
    };

    const getFirstUnansweredQuestionOrder = () => {
        if (theoryExamInfo) {
            // Используем find для нахождения первого вопроса без ответа
            const unansweredQuestion = theoryExamInfo.questions.find((question) => !question.submitted_answer_id);
            if (unansweredQuestion) {
                console.log(`У вопроса ${unansweredQuestion.id} отсутствует ответ`);
                return unansweredQuestion.order;
            }

            return null; // Возвращаем null, если все вопросы имеют ответы
        } else {
            return null;
        }
    };

    const handleSelectAnswer = (answerId: number) => {
        setSelectedAnswerId(answerId);
    };

    const handleSubmitAnswer = () => {
        if (currentQuestion) {
            submitAnswer({
                question_id: currentQuestion.id,
                answer_id: selectedAnswerId
            });
        }
    };

    useEffect(() => {
        if (submitAnswerResult) {
            if (currentQuestionOrder && currentQuestionOrder !== theoryExamInfo?.questions.length) {
                setCurrentQuestionOrder(currentQuestionOrder + 1);
                setCurrentQuestion(getQuestionByOrder(currentQuestionOrder +1));
            } else if (currentQuestionOrder && currentQuestionOrder === theoryExamInfo?.questions.length) {
                finishExam();
            }

            setSelectedAnswerId(null);
        }

        if (submitAnswerError) {
            toast.error(submitAnswerError.response.data.message ?? 'Произошла непредвиденная ошибка');
        }
    }, [submitAnswerResult, submitAnswerError]);

    useEffect(() => {
        if (finishExamResult) {
            toast.success(finishExamResult.message);
            navigate('/theory-exams/results/' + theoryExamInfo?.id);
        }

        if (finishExamError) {
            toast.error(finishExamError.response.data.message ?? 'Произошла непредвиденная ошибка');
        }
    }, [finishExamResult, finishExamError]);

    const handleTimeExpire = () => {
        toast.info(`Время истекло - экзамен завершился.`);
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.close();
            toast.info('Трансляция ваших видеоданных завершена.');
        }
        requestFinishExam(Number(theoryExamInfo?.id));
    };

    const finishExam = () => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.close();
            toast.info('Трансляция ваших видеоданных завершена.');
        }
        requestFinishExam(Number(theoryExamInfo?.id));
    };

    useEffect(() => {
        const requestPermissions = async () => {
            try {
                // Запрашиваем доступ к видео и аудио
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

                // Если запрос прошел успешно, устанавливаем 'granted'
                setMediaDevicesPermissionStatus('granted');

                // Останавливаем поток, чтобы не использовать устройства
                stream.getTracks().forEach(track => track.stop());
            } catch (err) {
                // Если произошла ошибка, устанавливаем 'denied'
                setMediaDevicesPermissionStatus('denied');
                console.error("Error accessing media devices:", err);
            }
        };

        requestPermissions();
    }, []);

    const getDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();

        const videoInputs = devices.filter((device) => device.kind === 'videoinput');
        const audioInputs = devices.filter((device) => device.kind === 'audioinput');

        setVideoDevices(videoInputs);
        setAudioDevices(audioInputs);

        if (videoInputs.length > 0) setSelectedVideoDevice(videoInputs[0].deviceId);
        if (audioInputs.length > 0) setSelectedAudioDevice(audioInputs[0].deviceId);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    },  [currentQuestion, selectedAnswerId]);

    useEffect(() => {
        if (examStarted) {
            const newSocket = new WebSocket(PROCTORING_URL); // Подключение к WebSocket серверу

            setSocket(newSocket);
        }
    }, [examStarted]);

    useEffect(() => {
        if (examStarted && socket && theoryExamInfo && stream) {
            const streamKey = theoryExamInfo.proctoring_id; // Генерация уникального streamKey
            socket.onopen = () => {
                console.log('WebSocket connection opened');
                console.log('Generated streamKey:', streamKey);
                socket.send(streamKey); // Отправляем streamKey первым сообщением
                startStreaming(stream); // Начинаем отправку видеоданных
                socket.onclose = () => console.log('WebSocket connection closed');
                socket.onerror = (error) => console.error('WebSocket error:', error);
            };
        }
    }, [examStarted, socket, stream]);

    useEffect(() => {
        if (videoRef.current) {
            handleStream();
        }
    }, [videoRef.current]);

    const handleKeyDown = (event: KeyboardEvent) => {
        const key = event.key;

        if (key >= '1' && key <= '9') {
            const answerIndex = Number(key) - 1;
            if (currentQuestion?.answers && answerIndex < currentQuestion.answers.length) {
                setSelectedAnswerId(currentQuestion.answers[answerIndex].id);
            }
        } else if (key === 'Enter' && selectedAnswerId !== null) {
            handleSubmitAnswer();
        }
    };

    // Функция для начала стриминга
    const startStreaming = (stream: MediaStream) => {
        const newMediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm;codecs=vp8,opus' });

        newMediaRecorder.ondataavailable = (event: BlobEvent) => {
            if (socket) {
                console.log(`MediaRecorder EventData (size > 0):`);
                console.log(event.data);
                socket.send(event.data); // Отправка видео данных через WebSocket
            }
        };

        newMediaRecorder.start(1000); // Отправка фрагментов каждые 1000 мс
        setMediaRecorder(newMediaRecorder);
    };

    const handleStream = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: {
                    exact: selectedVideoDevice
                }
            },
            audio: {
                deviceId: {
                    exact: selectedAudioDevice
                },
            }
        });
        if (videoRef.current) {
            videoRef.current.srcObject = stream;
        }
        setStream(stream);
    };

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, position: 'relative', minHeight: '100vh'}}>

            <PageHeader />

            {examStarted && timerProps.began_at && timerProps.current_time && (
            <Stack
                flexDirection={'row'}
                justifyContent={'center'}
                sx={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    opacity: 0.5,
                    zIndex: 2
                }}
            >
                <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    style={{width: '100%', maxWidth: '450px'}}
                >
                </video>
            </Stack>
)}

            {examStarted && timerProps.began_at && timerProps.current_time && (
                <Stack
                    flexDirection={'column'}
                >
                    <Stack
                        flexDirection={'row'}
                        gap={'10px'}
                        sx={{
                            flexWrap: 'nowrap',
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            my: '16px',
                            mx: '60px',
                            scrollSnapType: 'x mandatory' // Добавляем snapping для горизонтальной оси
                        }}
                    >
                        {theoryExamInfo?.questions?.map((question) => {
                            return (
                                <Stack
                                    sx={{
                                        width: '40px',
                                        minWidth: '40px',
                                        height: '40px',
                                        borderRadius: '10px',
                                        backgroundColor: question.order === currentQuestionOrder ? '#ff6a00' : '#f8a76e',
                                    }}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    key={question.order}
                                >
                                    <Typography
                                        sx={{
                                            color: 'white'
                                        }}
                                    >
                                        {question.order}
                                    </Typography>
                                </Stack>
                            );
                        })}
                    </Stack>

                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-evenly'}
                        alignItems={'center'}
                        sx={{
                            py: '24px'
                        }}
                    >
                        <Stack
                            flexDirection={'column'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                width: 'fit-content',
                                maxWidth: '50%'
                            }}
                        >
                            <Typography
                                textAlign={'center'}
                            >
                                Вопрос 
                                {' '}
                                {currentQuestion?.order}
                                /
                                {theoryExamInfo?.total_questions}
                            </Typography>
                            <Typography
                                variant={'h5'}
                                textAlign={'center'}
                                sx={{
                                    maxWidth: '50%'
                                }}
                            >
                                {currentQuestion?.ru_question}
                            </Typography>
                            <VideoPlayer
                                src={`${PDDTEST_MEDIA_API_URL ? PDDTEST_MEDIA_API_URL : 'https://media.pddtest.kz'}/${currentQuestion?.task_file_id}.mp4`}
                            />
                        </Stack>

                        <Stack
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                width: 'fit-content'
                            }}
                            gap={'16px'}
                        >
                            <TimerComponent
                                began_at={timerProps.began_at}
                                current_time={timerProps.current_time}
                                onExpire={() => {handleTimeExpire();}}
                            />

                            {currentQuestion?.answers?.map((answer, index) => {
                                return (
                                    <Button
                                        sx={{
                                            minWidth: '300px',
                                            justifyContent: 'start',
                                            p: '30px',
                                            flexGrow: 1,
                                            width: '100%',
                                        }}
                                        variant={selectedAnswerId === answer.id ? 'contained' : 'outlined'}
                                        disabled={submitAnswerLoading || finishExamLoading}
                                        onClick={() => handleSelectAnswer(answer.id)}
                                        key={answer.id}
                                    >
                                        <Typography
                                            sx={{
                                                textAlign: 'left',
                                            }}
                                        >
                                            {AnswersButtonStartSymbols[index + 1]}
                                            .
                                            {answer.ru_text}
                                        </Typography>
                                    </Button>
                                );
                            })}
                            <LoadingButton
                                sx={{
                                    minWidth: '300px',
                                    justifyContent: 'start',
                                    px: '16px'
                                }}
                                variant={'outlined'}
                                loading={submitAnswerLoading || finishExamLoading}
                                disabled={!selectedAnswerId}
                                onClick={() => handleSubmitAnswer()}
                            >
                                <Typography
                                    sx={{
                                        textAlign: 'left',
                                    }}
                                >
                                    Ответить
                                </Typography>
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Stack>
            )}

            <TheoryExamBeginningModal
                examInfo={theoryExamInfo}
                open={beginnigModalOpen}
                onClose={() => {setBeginningModalOpen(false);}}
                handleStartExam={handleBeginExam}
                videoDevices={videoDevices}
                audioDevices={audioDevices}
                getDevices={getDevices}
                selectedVideoDevice={selectedVideoDevice}
                selectedAudioDevice={selectedAudioDevice}
                mediaDevicesPermissionStatus={mediaDevicesPermissionStatus}
            />

        </Box>
    );
}

export default TheoryExamPage;
