import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerGetAdminSchoolsQueryResponse,
  SchoolsControllerGetAdminSchoolsQueryParams,
} from '../../ts/schoolsDTO/SchoolsControllerGetAdminSchools'

export function getSchoolsControllerGetAdminSchoolsUrl() {
  return `/schools/search` as const
}

/**
 * @summary Получение списка всех школ для админов
 * {@link /schools/search}
 */
export async function schoolsControllerGetAdminSchools(
  params: SchoolsControllerGetAdminSchoolsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGetAdminSchoolsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerGetAdminSchoolsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}