import { usersControllerApplyCustomerToSchool } from './usersControllerApplyCustomerToSchool'
import { usersControllerApplyWorkerToSchool } from './usersControllerApplyWorkerToSchool'
import { usersControllerAttachExistingWorkerToSchool } from './usersControllerAttachExistingWorkerToSchool'
import { usersControllerChangePasswordForUserById } from './usersControllerChangePasswordForUserById'
import { usersControllerChangeUserInfoById } from './usersControllerChangeUserInfoById'
import { usersControllerCheckEmailForPasswordReset } from './usersControllerCheckEmailForPasswordReset'
import { usersControllerGetSchoolUsers } from './usersControllerGetSchoolUsers'
import { usersControllerGetStudentByIin } from './usersControllerGetStudentByIin'
import { usersControllerGetUserAuthJournal } from './usersControllerGetUserAuthJournal'
import { usersControllerGetUserById } from './usersControllerGetUserById'
import { usersControllerGetUsers } from './usersControllerGetUsers'
import { usersControllerGetUsersBySchoolId } from './usersControllerGetUsersBySchoolId'
import { usersControllerGetUserTrialTries } from './usersControllerGetUserTrialTries'
import { usersControllerResetPassword } from './usersControllerResetPassword'

export const usersService = {
    usersControllerGetUsers,
    usersControllerGetUserTrialTries,
    usersControllerGetStudentByIin,
    usersControllerGetSchoolUsers,
    usersControllerGetUsersBySchoolId,
    usersControllerGetUserAuthJournal,
    usersControllerGetUserById,
    usersControllerChangePasswordForUserById,
    usersControllerChangeUserInfoById,
    usersControllerCheckEmailForPasswordReset,
    usersControllerResetPassword,
    usersControllerApplyWorkerToSchool,
    usersControllerAttachExistingWorkerToSchool,
    usersControllerApplyCustomerToSchool,
  }