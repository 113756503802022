import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { UsersControllerGetUserByIdQueryResponse, UsersControllerGetUserByIdPathParams } from '../../ts/usersDTO/UsersControllerGetUserById'

export function getUsersControllerGetUserByIdUrl({ id }: { id: UsersControllerGetUserByIdPathParams['id'] }) {
  return `/users/${id}` as const
}

/**
 * @summary Получить пользователя по id
 * {@link /users/:id}
 */
export async function usersControllerGetUserById(
  { id }: { id: UsersControllerGetUserByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerGetUserByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getUsersControllerGetUserByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}