import { Box, CircularProgress, Divider, List, ListItem, Stack, Typography } from '@mui/material';
import React from 'react';

import { wrapperSx } from '@/global/helpers/globalstyles';
import PageHeader from '../../global/components/PageHeader';
import PageInsideHeader from '../../global/components/PageInsideHeader';
import { useUserStore } from '@/global/store/UserStore';
import { formatDate } from '@/global/helpers/text';
import { apiGetAllNotificationList } from '@/modules/notifications/services/notifications.service';

function AllNotificationsPage() {

	const currentUser = useUserStore((state) => state);
	const {data, isLoading} = apiGetAllNotificationList(+(currentUser.id ?? 0), {limit: 100, offset: 0});

	if (isLoading || !data) {
		return (
			<Box
				className="App"
				sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
			>
				<PageHeader/>
				<Box sx={{...wrapperSx}}>
					<PageInsideHeader text={'Все уведомления'}/>

					<CircularProgress sx={{mt: 2}}/>
				</Box>
			</Box>
		);
	}

	return (
		<Box
			className="App"
			sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
		>
			<PageHeader/>
			<Box sx={{...wrapperSx}}>
				<PageInsideHeader
					text={'Все уведомления'}
					subtext={'На данной странице вы можете прочесть историю уведомлений вашего аккаунта'}
				/>

				<Stack
					flexDirection={'column'}
					sx={{width: '100%'}}
				>
					{data.items.map((notification) => {
						return (
							<List
								key={notification.id}
								sx={{width: '100%'}}
							>
								<ListItem
									key={notification.id}
									sx={{width: '100%'}}
								>
									<Stack
										gap={1}
										sx={{width: '100%'}}
									>
										<Typography variant={'h6'}>
											{notification.title}
										</Typography>
										<Typography>
											{notification.text}
										</Typography>
										<Typography>
											<i>
												Дата уведомления:
												{formatDate(notification.created_at)}
											</i>
										</Typography>
										<Divider/>
									</Stack>
								</ListItem>
							</List>
						);
					})}
				</Stack>
			</Box>
		</Box>
	);
}

export default AllNotificationsPage;