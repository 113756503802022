import {Box, Button, Dialog, DialogContent, DialogTitle, Stack, Tab, Tabs, TextField, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {useSnackbarStore} from '@/global/store/SnackbarStore';
import SearchByIINPage from '../components/SearchByIIN';
import SearchByBINPage from '../components/SearchByBIN';

function GovPage() {

    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();

    const [tab, setTab] = useState<string>('students');

    const [openModal, setOpenModal] = useState<boolean>(true);
    const [password, setPassword] = useState<string>('');
    const [passwordTries, setPasswordTries] = useState<number>(3);

    useEffect(() => {
        if (passwordTries <= 0) {
            navigate('/');
        }
    }, [passwordTries]);

    const handlePassword = () => {
        if (!password) {
            snackbar.errorMessage('Введите пароль');
        }

        if (password === 'govkursant2024') {
            setOpenModal(false);
        } else {
            setPasswordTries((prev) => prev - 1);
        }
    };

    const tabs = {
        students: {id: 'students', label: `Студенты`},
        schools: {id: 'schools', label: `Школы`},
    };

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Box sx={{...wrapperSx}}>

                <Tabs
                    value={tab}
                    onChange={(e, newValue) => setTab(newValue)}
                    variant="scrollable"
                    scrollButtons={false}
                    sx={{ml: 2}}
                >
                    {Object.values(tabs).map(tab => (
                        <Tab
                            label={tab.label}
                            key={tab.id}
                            value={tab.id}
                        />
                    ))}
                </Tabs>

                <Dialog
                    open={openModal}
                    maxWidth={'sm'}
                    fullWidth={true}
                >
                    <DialogTitle>
                        <Typography variant={'h6'}>
                            Введите пароль
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Stack
                            flexDirection={'column'}
                            gap={2}
                        >
                            <Typography>
                                У вас есть 3 попытки на ввод правильного пароля для доступа к получению информации по
                                ИИН студентов
                            </Typography>

                            {passwordTries < 3 && (
                                <Typography color={'red'}>
                                    Осталось
                                    {passwordTries}
                                    {' '}
                                    количества попыток
                                </Typography>
                            )}

                            <TextField
                                name={'password'}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                type={'password'}
                                label={'Пароль'}
                            />

                            <Button
                                variant={'contained'}
                                onClick={handlePassword}
                            >
                                Ввести
                            </Button>
                        </Stack>
                    </DialogContent>
                </Dialog>

                {!openModal && tab === 'students' && <SearchByIINPage/>}
                {!openModal && tab === 'schools' && <SearchByBINPage/>}
            </Box>
        </Box>
    );
}

export default GovPage;