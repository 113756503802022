import type {
    EmployeeControllerGetEmployeeInfoQueryParams
} from "@/global/api/gen/ts/employeesDTO/EmployeeControllerGetEmployeeInfo";
import type {
    EmployeeControllerGetAllSchoolTeachersQueryParams
} from "@/global/api/gen/ts/employeesDTO/EmployeeControllerGetAllSchoolTeachers";
import type {
    EmployeeControllerGetActiveSchoolTeachersQueryParams
} from "@/global/api/gen/ts/employeesDTO/EmployeeControllerGetActiveSchoolTeachers";

export const EmployeesKeys = {
    all: ['employees'] as const,
    schoolEmployees: (id:number) => [...EmployeesKeys.all, 'schools', `${id}` ] as const,
    drivers: (id:number) => [...EmployeesKeys.schoolEmployees(id), 'drivers'] as const,
    teachers: (id:number) => [...EmployeesKeys.schoolEmployees(id), 'teachers'] as const,
    masters: (id:number) => [...EmployeesKeys.schoolEmployees(id), 'masters'] as const,
    search: (params:EmployeeControllerGetAllSchoolTeachersQueryParams ) => [params, 'search'] as const,
    searchDrivers: (id:number, params:EmployeeControllerGetAllSchoolTeachersQueryParams )=> [...EmployeesKeys.drivers(id), ...EmployeesKeys.search(params)],
    searchTeachers: (id:number, params:EmployeeControllerGetAllSchoolTeachersQueryParams )=> [...EmployeesKeys.teachers(id), ...EmployeesKeys.search(params)],
    searchMasters: (id:number, params:EmployeeControllerGetAllSchoolTeachersQueryParams )=> [...EmployeesKeys.masters(id), ...EmployeesKeys.search(params)],
    searchActiveTeachers: (id:number, params:EmployeeControllerGetActiveSchoolTeachersQueryParams )=> [...EmployeesKeys.searchTeachers(id, params), 'active'],
    searchActiveDrivers: (id:number, params:EmployeeControllerGetActiveSchoolTeachersQueryParams )=> [...EmployeesKeys.searchDrivers(id, params), 'active'],
    searchActiveMasters: (id:number, params:EmployeeControllerGetActiveSchoolTeachersQueryParams )=> [...EmployeesKeys.searchMasters(id, params), 'active'],
    detail: (id:number) => [...EmployeesKeys.all, 'detail', `${id}`] as const,
    detailWithParams: (id:number, params:EmployeeControllerGetEmployeeInfoQueryParams) => [...EmployeesKeys.detail(id), params]
};