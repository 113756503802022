import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { TicketControllerGetTicketByIdQueryResponse, TicketControllerGetTicketByIdPathParams } from '../../ts/TicketsDTO/TicketControllerGetTicketById'

export function getTicketControllerGetTicketByIdUrl({ id }: { id: TicketControllerGetTicketByIdPathParams['id'] }) {
  return `/tickets/get/${id}` as const
}

/**
 * @summary Получение тикета по id
 * {@link /tickets/get/:id}
 */
export async function ticketControllerGetTicketById(
  { id }: { id: TicketControllerGetTicketByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TicketControllerGetTicketByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTicketControllerGetTicketByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}