import {type ChangeEvent, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputBase,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    Typography
} from '@mui/material';
import {DirectionsCar} from '@mui/icons-material';
import {useSnackbarStore} from '@/global/store/SnackbarStore';
import PageHeader from '../../global/components/PageHeader';
import {apiChangeSchoolCategories, apiGetCategoryList} from "@/modules/categories/services/category.service";
import {apiGetLocalityBySlug, apiGetRegionList} from "@/modules/regions/services/regions.service";
import {apiGetSchoolById, apiUpdateSchoolByAdmin} from "@/modules/schools/services/school.service";
import type {SchoolCategoriesDto} from "@/global/api/gen/ts/SchoolCategoriesDto";
import type {LocalityDto} from "@/global/api/gen/ts/LocalityDto";
import type {SchoolUpdateDto} from "@/global/api/gen/ts/SchoolUpdateDto";
import {ChangeSchoolCategoryCheckboxes} from "@/modules/categories/components/ChangeSchoolCategoryCheckboxes";
import {ChangeSchoolMainCategoryCheckboxes} from "@/modules/categories/components/ChangeSchoolMainCategoryCheckboxes";

function ChangeSchoolAdmin() {
    const snackbar = useSnackbarStore((state) => state);
    const {id} = useParams();

    const [schoolInfo, setSchoolInfo] = useState<SchoolUpdateDto>({
        id: 500000,
        address: '',
        bin: '',
        title: '',
        director: '',
        phone1: '',
        phone2: '',
        region: '',
        locality: '',
        director_qualification_certificate_end_date: '',
        director_qualification_certificate_issue_date: '',
        director_qualification_certificate_issuer: '',
        director_qualification_certificate_number: '',
    });
    const [localitiesArray, setLocalitiesArray] = useState<LocalityDto[]>([]);
    const [changing, setChanging] = useState<boolean>(false);
    const {data: categoriesData, isLoading: categoriesLoading} = apiGetCategoryList(+(id ?? 0), {
        limit: 100,
        offset: 0
    });
    const {data: regionsArray} = apiGetRegionList({
        limit: 100,
        offset: 0,
    });

    const {data: localityArray} = apiGetLocalityBySlug(schoolInfo.region ?? '', {
        limit: 100,
        offset: 0,
    });

    const {mutate: updateSchoolByAdmin, isPending: isLoadingUpdateSchool} = apiUpdateSchoolByAdmin();
    const {mutate: changeSchoolCategories, isPending: isLoadingChangingCategories} = apiChangeSchoolCategories();

    const loading = isLoadingUpdateSchool || isLoadingChangingCategories;

    const {data: dataSchool} = apiGetSchoolById(+(id ?? 0));

    const [categoriesArray, setCategoriesArray] = useState<string[]>([]);

    useEffect(() => {
        if (!dataSchool) {
            return;
        }
        setSchoolInfo(dataSchool);
    }, [dataSchool]);

    useEffect(() => {
        if (!regionsArray || !localityArray) {
            return;
        }
        regionsArray.items.map((region) => {
            if (region.name_ru === schoolInfo.region) {
                if (region.id === 1 || region.id === 2 || region.id === 3) {
                    setLocalitiesArray(localityArray.items);
                } else {
                    setLocalitiesArray([
                        ...localityArray.items,
                        {
                            id: 50000,
                            region_id: region.id,
                            name_kz: 'Другое (вписать в адрес)',
                            name_ru: 'Другое (вписать в адрес)'
                        }
                    ]);
                }
                if (schoolInfo.locality) {
                    setLocality(schoolInfo.locality);
                }
            }
        });
    }, [localityArray, regionsArray, schoolInfo]);

    useEffect(() => {
        if (categoriesData) {
            reformatCategories(categoriesData.items);
        }
    }, [categoriesData]);

    const reformatCategories = (categoriesData: SchoolCategoriesDto[]) => {
        const array = categoriesData.map((category) => {
            return category.category_id;
        });

        setCategoriesArray(array);
    };

    const handleRegionChange = (e: SelectChangeEvent) => {
        const chosenRegion = e.target.value;
        setRegion(chosenRegion);
    };

    const setRegion = (chosenRegion: string) => {
        setSchoolInfo((prev) => {
            return {
                ...prev,
                region: chosenRegion
            };
        });
    };

    const handleLocalityChange = (e: SelectChangeEvent) => {
        const chosenLocality = e.target.value;
        setLocality(chosenLocality);
    };

    const setLocality = (chosenLocality: string) => {
        if (chosenLocality === 'Другое (вписать в адрес)') {
            setSchoolInfo((prev) => {
                return {
                    ...prev,
                    locality: 'Другое (вписать в адрес)'
                };
            });
            return;
        }

        setSchoolInfo((prev) => {
            return {
                ...prev,
                locality: chosenLocality
            };
        });
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSchoolInfo((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleCategoriesChange = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = categoriesArray.concat(name);

            setCategoriesArray(newCategories);
        }

        if (!checked) {
            const isIncluded = categoriesArray.includes(name);

            if (isIncluded) {
                const newCategories: string[] = [];

                for (let i = 0; i < categoriesArray.length; i++) {
                    const element = categoriesArray[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setCategoriesArray(newCategories);
            }
        }
    };

    const startChange = () => {
        setChanging(true);
    };

    const cancelChange = () => {
        setSchoolInfo(schoolInfo);
        if (categoriesData) {
            reformatCategories(categoriesData.items);
        }
        setChanging(false);
    };

    const checkSchoolInfo = () => {
        return !(
            !schoolInfo.bin || !schoolInfo.title || !schoolInfo.director || !schoolInfo.region || !schoolInfo.address || !schoolInfo.phone1
        );
    };

    const handleSubmit = () => {
        const schoolUpdateDto = {
            ...schoolInfo,
            locality: schoolInfo.locality === 'Другое (вписать в адрес)' ? '' : schoolInfo.locality
        };

        const isFormValid = checkSchoolInfo();

        if (!isFormValid) {
            snackbar.errorMessage(`Необходимо заполнить все требуемые поля (Название, директор, регион, адрес, номер телефона (основной))`);
            return;
        }

        setChanging(false);

        updateSchoolByAdmin(schoolUpdateDto, {
            onSuccess: () => {
                changeSchoolCategories({school_id: Number(id), categories: categoriesArray});
            },
            onError: () => {
                cancelChange();
            }
        });
    };

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>

            <PageHeader/>

            <Stack
                flexDirection="row"
                alignItems="center"
                sx={{mt: 1, mb: 3}}
            >
                <Avatar
                    sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                >
                    <DirectionsCar fontSize="medium"/>
                </Avatar>
                <Stack>
                    <Typography
                        variant="h5"
                        color="dimgray"
                    >
                        Учебная организация по подготовке водителей транспортных средств
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{mt: 0.3}}
                    >
                        {schoolInfo?.title}
                    </Typography>
                </Stack>
            </Stack>

            <Box flexDirection={'row'}>
                <Stack gap={1}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}}>
                        <b>
                            Изменить данные школы:
                        </b>
                    </Typography>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.bin}
                            disabled={!changing}
                            name={'bin'}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>
                            БИН
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.title}
                            name={'title'}
                            disabled={!changing}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>
                            Название учебной организации
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.director}
                            disabled={!changing}
                            name={'director'}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>
                            Директор
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <Select
                            labelId={'region-label'}
                            onChange={handleRegionChange}
                            name={'region'}
                            value={schoolInfo.region}
                            required={true}
                            disabled={!changing}
                            sx={{
                                borderRadius: '16px',
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderWidth: 0,
                                    borderRadius: '16px',
                                }
                            }}
                        >
                            {regionsArray?.items.map((region) => {
                                return (
                                    <MenuItem
                                        value={region.name_ru}
                                        key={region.name_ru}
                                    >
                                        {region.name_ru}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText id={'region-label'}>
                            Регион
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <Select
                            labelId={'locality-label'}
                            onChange={handleLocalityChange}
                            name={'locality'}
                            value={schoolInfo.locality ? schoolInfo.locality : ''}
                            required={true}
                            disabled={localitiesArray.length === 0 || !changing}
                            sx={{
                                borderRadius: '16px',
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderWidth: 0,
                                    borderRadius: '16px',
                                }
                            }}
                        >
                            {localitiesArray.map((locality) => {
                                return (
                                    <MenuItem
                                        value={locality.name_ru}
                                        key={locality.name_ru}
                                    >
                                        {locality.name_ru}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText id={'locality-label'}>
                            Населенный пункт
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.address}
                            name={'address'}
                            disabled={!changing}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>
                            Адрес
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.phone1}
                            name={'phone1'}
                            disabled={!changing}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>
                            Номер телефона (основной)
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <InputBase
                            value={schoolInfo.phone2}
                            name={'phone2'}
                            disabled={!changing}
                            onChange={handleInputChange}
                        />
                        <FormHelperText id={'region-label'}>
                            Номер телефона (дополнительный)
                        </FormHelperText>
                    </FormControl>

                    <Stack flexDirection={'column'}>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}}>
                            <b>
                                Категории учебной организации, по которым разрешено проводить обучение:
                            </b>
                        </Typography>
                        {categoriesLoading ? (
                                <Stack
                                    flexDirection={'row'}
                                    justifyContent={'center'}
                                >
                                    <CircularProgress/>
                                </Stack>
                            )
                            : (
                                <ChangeSchoolMainCategoryCheckboxes
                                    handleCategoriesChange={handleCategoriesChange}
                                    changing={changing}
                                    categoriesArray={categoriesArray}
                                />
                            )}
                    </Stack>
                    <Stack flexDirection={'column'}>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}}>
                            <b>
                                Подкатегории учебной организации, по которым разрешено проводить обучение:
                            </b>
                        </Typography>
                        {categoriesLoading ? (
                                <Stack
                                    flexDirection={'row'}
                                    justifyContent={'center'}
                                >
                                    <CircularProgress/>
                                </Stack>
                            )
                            : (
                                <ChangeSchoolCategoryCheckboxes
                                    changing={changing}
                                    categoriesArray={categoriesArray}
                                    handleCategoriesChange={handleCategoriesChange}
                                />
                            )}
                    </Stack>
                    {!loading && (
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                            gap={1}
                        >
                            {!changing && (
                                <Button
                                    onClick={startChange}
                                    variant={'contained'}
                                >
                                    Начать редактирование
                                </Button>
                            )}
                            {changing && (
                                <Button
                                    onClick={cancelChange}
                                    variant={'contained'}
                                    color={'error'}
                                >
                                    Отменить редактирование
                                </Button>
                            )}
                            <Button
                                onClick={handleSubmit}
                                variant={'contained'}
                            >
                                Сохранить
                            </Button>
                        </Stack>
                    )}

                    {loading && (
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                            gap={1}
                        >
                            <CircularProgress/>
                        </Stack>
                    )}
                </Stack>
            </Box>

        </Box>
    );
}

export default ChangeSchoolAdmin;