import {Avatar, Box, Stack, type SxProps, type Theme, Typography} from "@mui/material";
import {blue, red} from "@mui/material/colors";
import {formatDateTime, getName} from "@/global/helpers/text";
import React from "react";
import {useUserStore} from "@/global/store/UserStore";
import type {UserDto} from "@/global/api/gen/ts/UserDto";
import type {TicketMessageDto} from "@/global/api/gen/ts/TicketMessageDto";
import DOMPurify from "dompurify";

const messageStyle: SxProps<Theme> = {
    p: 1.5,
    mb: 2.5,
    borderRadius: 3,
    maxWidth: '90%',
    backgroundColor: 'rgb(231,236,239)',
    color: 'black',
    wordWrap: 'anywhere'
};

interface Props {
    user_id: number;
    user: UserDto | null;
    manager: UserDto | null;
    message: TicketMessageDto;
}

export function RenderMessage({user_id, user, manager, message}:Props){
    const userInfo = useUserStore((state) => state);

    const isRight = message.user_id === userInfo.id;

    return (
        <Stack
            key={message.id}
            flexDirection={isRight ? 'row' : 'row-reverse'}
            alignItems='center'
            justifyContent='center'
            sx={{width: '100%', gap: '20px'}}
        >
            <Stack
                alignItems={isRight ? "flex-end" : 'flex-start'}
                sx={{width: '100%'}}
            >
                <Typography
                    fontSize={14}
                    sx={{color: 'gray', mb: 0.5}}
                >
                    {message.user_id === user_id ? getName(user ?? {}).fullName : getName(manager ?? {}).fullName}
                    ,
                    {formatDateTime(message.created_at)}
                </Typography>
                <Box
                    sx={{...messageStyle, backgroundColor: 'rgb(196,225,244)', fontFamily: 'sans-serif'}}
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message.message, {ALLOWED_TAGS:[]})}}
                />
            </Stack>
            <Avatar
                sx={{mr: 1.5, fontSize: 16, background: isRight ? blue[400] : red[400]}}
            >
                {message.user_id === user_id ? getName(user ?? {}).partialName : getName(manager ?? {}).partialName}
            </Avatar>
        </Stack>
    );
}