import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { EmployeeControllerFireMasterByIdMutationResponse } from '../../ts/employeesDTO/EmployeeControllerFireMasterById'

export function getEmployeeControllerFireMasterByIdUrl() {
  return `/employees/fire/master` as const
}

/**
 * @summary Увольнение мастера
 * {@link /employees/fire/master}
 */
export async function employeeControllerFireMasterById(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerFireMasterByIdMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getEmployeeControllerFireMasterByIdUrl().toString(),
    ...requestConfig,
  })
  return res
}