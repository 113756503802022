import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerUpdateMedicalInfoByStudentIdMutationRequest,
  StudentsControllerUpdateMedicalInfoByStudentIdMutationResponse,
} from '../../ts/StudentsDTO/StudentsControllerUpdateMedicalInfoByStudentId'

export function getStudentsControllerUpdateMedicalInfoByStudentIdUrl() {
  return `/students/medical-info` as const
}

/**
 * @summary Добавление информации о здоровье студента
 * {@link /students/medical-info}
 */
export async function studentsControllerUpdateMedicalInfoByStudentId(
  data: StudentsControllerUpdateMedicalInfoByStudentIdMutationRequest,
  config: Partial<RequestConfig<StudentsControllerUpdateMedicalInfoByStudentIdMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    StudentsControllerUpdateMedicalInfoByStudentIdMutationResponse,
    ResponseErrorConfig<Error>,
    StudentsControllerUpdateMedicalInfoByStudentIdMutationRequest
  >({ method: 'POST', url: getStudentsControllerUpdateMedicalInfoByStudentIdUrl().toString(), data, ...requestConfig })
  return res
}