import { ticketControllerChangeTicketStatusClosed } from './ticketControllerChangeTicketStatusClosed'
import { ticketControllerCreateTicket } from './ticketControllerCreateTicket'
import { ticketControllerGetAllTickets } from './ticketControllerGetAllTickets'
import { ticketControllerGetMessageHistory } from './ticketControllerGetMessageHistory'
import { ticketControllerGetTicketById } from './ticketControllerGetTicketById'
import { ticketControllerGetTicketsByUserId } from './ticketControllerGetTicketsByUserId'
import { ticketControllerSendMessage } from './ticketControllerSendMessage'

export const ticketsService = {
    ticketControllerCreateTicket,
    ticketControllerGetAllTickets,
    ticketControllerGetTicketsByUserId,
    ticketControllerGetTicketById,
    ticketControllerSendMessage,
    ticketControllerChangeTicketStatusClosed,
    ticketControllerGetMessageHistory,
  }