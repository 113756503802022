import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerGetQuickTransactionByIdQueryResponse,
  TransactionsControllerGetQuickTransactionByIdPathParams,
} from '../../ts/TransactionsDTO/TransactionsControllerGetQuickTransactionById'

export function getTransactionsControllerGetQuickTransactionByIdUrl({
  transactionId,
}: {
  transactionId: TransactionsControllerGetQuickTransactionByIdPathParams['transactionId']
}) {
  return `/transactions/quick/${transactionId}` as const
}

/**
 * @summary Получение быстрого платежа
 * {@link /transactions/quick/:transactionId}
 */
export async function transactionsControllerGetQuickTransactionById(
  { transactionId }: { transactionId: TransactionsControllerGetQuickTransactionByIdPathParams['transactionId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransactionsControllerGetQuickTransactionByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTransactionsControllerGetQuickTransactionByIdUrl({ transactionId }).toString(),
    ...requestConfig,
  })
  return res
}