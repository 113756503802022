import { useState } from 'react';

import { AppealsGrid } from '@/modules/appeals/components/AppealsGrid';
import { apiGetDeniedAppeals } from '@/modules/appeals/services/appeal.service';

interface DeniedAppealsProps {
	openAppeal: (id: number) => void,
}

export function DeniedAppeals({openAppeal}: DeniedAppealsProps) {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25,
		page: 0
	});

	const {data, isLoading} = apiGetDeniedAppeals({
		offset: paginationModel.page * paginationModel.pageSize,
		limit: paginationModel.pageSize,
	});

	return (
    <AppealsGrid
        openAppeal={openAppeal}
		rowCount={data?.total ?? 0}
		rows={data?.items ?? []}
        loading={isLoading}
        paginationModel={paginationModel}
        setPaginationModel={(model)=> setPaginationModel(model)}
    />
	);
}