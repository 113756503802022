import {Chip, CircularProgress, Stack, Switch, Typography} from "@mui/material";
import React from "react";
import {apiChangeFeatureEnable, apiGetFeatureToggles} from "@/modules/feature-toggles/services/feature-toggles.service";

export function FeatureTogglesInfo() {

    const {data, isLoading} = apiGetFeatureToggles({
        offset: 0,
        limit: 100
    });

    const {mutate} =apiChangeFeatureEnable();

    const handleChangeEnabled = async (checked: boolean, moduleName: string) => {
        mutate({
            module_name: moduleName,
            is_enabled: checked,
        });
    };

    return (
        <Stack
            direction={'column'}
            width={'100%'}
            sx={{
                mt: '38px',
                p: '52px',
                borderRadius: '24px',
                background: '#F5F5F5',
            }}
        >
            <Typography
                variant={'h5'}
                sx={{
                    mt: '32px'
                }}
            >
                Модули системы:
            </Typography>
            {isLoading && <CircularProgress/>}
            {data && (
                <Stack
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                    sx={{
                        py: '32px',
                        gap: '24px'
                    }}
                >
                    {data.items.map((feature) => {
                        return (
                            <Stack
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    width: '300px',
                                    height: 'auto',
                                    minHeight: '150px',
                                    borderRadius: '16px',
                                    backgroundColor: '#FFFFFF',
                                    py: '20px',
                                    px: '16px',
                                    gap: '16px'
                                }}
                                key={feature.id}
                            >
                                <Typography
                                    fontSize={'14px'}
                                    color={'gray'}
                                >
                                    #
                                    {feature.module_name}
                                </Typography>
                                <Typography
                                    fontSize={'14px'}
                                    fontWeight={'bold'}
                                >
                                    Состояние:
                                </Typography>
                                <Typography
                                    fontSize={'14px'}
                                    sx={{
                                        mt: '-16px'
                                    }}
                                >
                                    <Chip
                                        label={feature.is_enabled ? 'Включен' : 'Отключен'}
                                        color={feature.is_enabled ? 'success' : 'error'}
                                        sx={{
                                            mt: 1
                                        }}
                                    />
                                </Typography>
                                <Typography
                                    fontSize={'14px'}
                                    fontWeight={'bold'}
                                >
                                    Описание модуля:
                                </Typography>
                                <Typography
                                    fontSize={'14px'}
                                    sx={{
                                        mt: '-16px'
                                    }}
                                >
                                    {feature.description}
                                </Typography>
                                <Stack
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                >
                                    <Typography>
                                        Откл.
                                    </Typography>
                                    <Switch
                                        checked={feature.is_enabled}
                                        onChange={(e) => handleChangeEnabled(e.target.checked, feature.module_name)}
                                    />
                                    <Typography>
                                        Вкл.
                                    </Typography>
                                </Stack>
                            </Stack>
                        );
                    })}
                </Stack>
            )}
        </Stack>
    );
}