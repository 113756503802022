import {type ChangeEvent} from 'react';
import {z} from 'zod';
import type {Control, FieldPath, FieldValues} from 'react-hook-form';
import type {TextFieldProps as BaseProps} from '@mui/material/TextField/TextField';
import {TextFieldForm} from "@/global/components/FormInputs/TextFieldForm";

export type ControlledFieldProps<T extends FieldValues> = {
    control: Control<T>;
    name: FieldPath<T>;
    resetError: (name: FieldPath<T>) => void;
    label?: string;
    uniqueRequired?: boolean;
} & Omit<BaseProps, 'variant'>;

export function EmailInputForm<T extends FieldValues>({
                                                      control,
                                                      name,
                                                      resetError,
                                                      uniqueRequired = true,
                                                      onChange,
                                                      ...props
                                                  }: ControlledFieldProps<T>) {

    const validate = (rawString: string, displayError: boolean) => {
        const parsedEmail = z.string().email().safeParse(rawString);
        if (!parsedEmail.success) {
            if (displayError) {
                control.setError(name, { message: 'Некорректная почта' });
            } else {
                control.setError(name, { type: 'onBlur', message: '' });
            }
            return;
        }
        resetError(name);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        validate(e.target.value, false);
        if (typeof onChange === 'function') {
            onChange(e);
        }
    };

    return (
        <TextFieldForm
            control={control}
            onChange={handleChange}
            name={name}
            onBlur={(e) => validate(e.target.value, true)}
            {...props}
        />
    );
}
