import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateApplicationControllerCreateCertificateApplicationMutationRequest,
  CertificateApplicationControllerCreateCertificateApplicationMutationResponse,
} from '../../ts/certificate-applicationDTO/CertificateApplicationControllerCreateCertificateApplication'

export function getCertificateApplicationControllerCreateCertificateApplicationUrl() {
  return `/certificate-application` as const
}

/**
 * @summary Создание заявки на сертификат
 * {@link /certificate-application}
 */
export async function certificateApplicationControllerCreateCertificateApplication(
  data: CertificateApplicationControllerCreateCertificateApplicationMutationRequest,
  config: Partial<RequestConfig<CertificateApplicationControllerCreateCertificateApplicationMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CertificateApplicationControllerCreateCertificateApplicationMutationResponse,
    ResponseErrorConfig<Error>,
    CertificateApplicationControllerCreateCertificateApplicationMutationRequest
  >({ method: 'POST', url: getCertificateApplicationControllerCreateCertificateApplicationUrl().toString(), data, ...requestConfig })
  return res
}