import { useState } from 'react';

import { AppealsGrid } from '@/modules/appeals/components/AppealsGrid';
import { apiGetApprovedAppeals } from '@/modules/appeals/services/appeal.service';

interface ApprovedAppealsProps {
	openAppeal: (id: number) => void,
}

export function ApprovedAppeals({openAppeal}: ApprovedAppealsProps) {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25, page: 0
	});

	const {data, isLoading} = apiGetApprovedAppeals({
		offset: paginationModel.page * paginationModel.pageSize, limit: paginationModel.pageSize
	});
	
	return (
		<AppealsGrid
			openAppeal={openAppeal}
			rows={data?.items ?? []}
			loading={isLoading}
			rowCount={data?.total ?? 0}
			paginationModel={paginationModel}
			setPaginationModel={(model) => setPaginationModel(model)}
		/>
	);
}