import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerChangeGroupStatusMutationRequest,
  GroupsControllerChangeGroupStatusMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerChangeGroupStatus'

export function getGroupsControllerChangeGroupStatusUrl() {
  return `/groups/change-status` as const
}

/**
 * @summary Изменение статуса группы
 * {@link /groups/change-status}
 */
export async function groupsControllerChangeGroupStatus(
  data: GroupsControllerChangeGroupStatusMutationRequest,
  config: Partial<RequestConfig<GroupsControllerChangeGroupStatusMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerChangeGroupStatusMutationResponse, ResponseErrorConfig<Error>, GroupsControllerChangeGroupStatusMutationRequest>({
    method: 'POST',
    url: getGroupsControllerChangeGroupStatusUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}