import {Checkbox, Stack, TextField, Typography} from "@mui/material";
import LoadingButton from "@/global/components/LoadingButton";
import React, {type ChangeEvent, type Dispatch, type SetStateAction} from "react";
import {CheckBoxOutlineBlankOutlined, CheckBoxOutlined} from "@mui/icons-material";

interface Props {
    iin: string;
    handleIinChange: (e: ChangeEvent<HTMLInputElement>) => void,
    agreed: boolean;
    setAgreed: Dispatch<SetStateAction<boolean>>,
    handleFetchStudents: () => void,
    studentsByIINLoading: boolean,
}

export function IinAgreeForm({
                                           iin,
                                           handleIinChange,
                                           agreed,
                                           setAgreed,
                                           handleFetchStudents,
                                           studentsByIINLoading
                                       }: Props) {

    return (
        <Stack
            width={{lg: '60%', xs: '100%'}}
            flexDirection={'column'}
            gap={2}
            sx={{
                margin: '0 auto',
                p: 4,
                borderRadius: '26px',
                background: '#F5F5F5',
                gap: '16px'
            }}
        >
            <Stack gap={1}>
                <Typography>
                    ИИН:
                </Typography>
                <TextField
                    name={'iin'}
                    fullWidth
                    value={iin}
                    onChange={handleIinChange}
                    placeholder={'123 456 789 123'}
                    sx={{
                        borderRadius: '10px',
                        '.MuiOutlinedInput-root': {
                            backgroundColor: '#ebf0fb',
                        },
                        '.MuiOutlinedInput-input': {
                            color: '#05304F',
                            padding: '18px',
                            fontSize: '16px',
                            lineHeight: '24px',
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent'
                        }
                    }}
                />
            </Stack>

            <Stack
                flexDirection={'row'}
                gap={2}
            >
                <Checkbox
                    id={'checkbox'}
                    sx={{
                        width: '50px',
                        height: '50px',
                        '.MuiSvgIcon-root': {
                            color: '#1E9AF2',
                        }
                    }}
                    icon={<CheckBoxOutlineBlankOutlined/>}
                    checkedIcon={<CheckBoxOutlined/>}
                    checked={agreed}
                    onChange={(e) => {
                        setAgreed(e.target.checked);
                    }}
                />

                <Typography
                    component={'label'}
                    htmlFor={'checkbox'}
                    sx={{
                        cursor: 'pointer',
                        fontWeight: 'lighter',
                    }}
                >
                    В соответствии с Законом РК "О персональных данных и их защите" в случае необходимости
                    получения информации в отношении третьего лица подтверждаю, что имеется согласие данного
                    лица
                </Typography>
            </Stack>

            <LoadingButton
                variant={'contained'}
                sx={{
                    px: '50px',
                    textTransform: 'none',
                }}
                disabled={!iin}
                loading={studentsByIINLoading}
                onClick={handleFetchStudents}
            >
                Записаться
            </LoadingButton>
        </Stack>
    );
}