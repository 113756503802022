import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerGetInActiveSchoolTeachersQueryResponse,
  EmployeeControllerGetInActiveSchoolTeachersPathParams,
  EmployeeControllerGetInActiveSchoolTeachersQueryParams,
} from '../../ts/employeesDTO/EmployeeControllerGetInActiveSchoolTeachers'

export function getEmployeeControllerGetInActiveSchoolTeachersUrl({ id }: { id: EmployeeControllerGetInActiveSchoolTeachersPathParams['id'] }) {
  return `/employees/school/teacher/inactive/${id}` as const
}

/**
 * @summary Получение неактивных учителей школы
 * {@link /employees/school/teacher/inactive/:id}
 */
export async function employeeControllerGetInActiveSchoolTeachers(
  { id }: { id: EmployeeControllerGetInActiveSchoolTeachersPathParams['id'] },
  params: EmployeeControllerGetInActiveSchoolTeachersQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerGetInActiveSchoolTeachersQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getEmployeeControllerGetInActiveSchoolTeachersUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}