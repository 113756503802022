import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerSubmitGradeByStudentIdMutationRequest,
  StudentsControllerSubmitGradeByStudentIdMutationResponse,
  StudentsControllerSubmitGradeByStudentIdPathParams,
} from '../../ts/StudentsDTO/StudentsControllerSubmitGradeByStudentId'

export function getStudentsControllerSubmitGradeByStudentIdUrl({ studentId }: { studentId: StudentsControllerSubmitGradeByStudentIdPathParams['studentId'] }) {
  return `/students/grade/${studentId}` as const
}

/**
 * @summary Выставление оценки студенту
 * {@link /students/grade/:studentId}
 */
export async function studentsControllerSubmitGradeByStudentId(
  { studentId }: { studentId: StudentsControllerSubmitGradeByStudentIdPathParams['studentId'] },
  data: StudentsControllerSubmitGradeByStudentIdMutationRequest,
  config: Partial<RequestConfig<StudentsControllerSubmitGradeByStudentIdMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    StudentsControllerSubmitGradeByStudentIdMutationResponse,
    ResponseErrorConfig<Error>,
    StudentsControllerSubmitGradeByStudentIdMutationRequest
  >({ method: 'POST', url: getStudentsControllerSubmitGradeByStudentIdUrl({ studentId }).toString(), data, ...requestConfig })
  return res
}