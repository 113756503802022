import type {
	SchoolsControllerSearchSchoolsQueryParams
} from '@/global/api/gen/ts/schoolsDTO/SchoolsControllerSearchSchools';
import type {
	SchoolsControllerGetAdminSchoolsQueryParams
} from '@/global/api/gen/ts/schoolsDTO/SchoolsControllerGetAdminSchools';
import type {
	SchoolsControllerGetSchoolsWithFeatureTogglesQueryParams
} from "@/global/api/gen/ts/schoolsDTO/SchoolsControllerGetSchoolsWithFeatureToggles";
import type {
	SchoolsControllerGetSchoolInfoByBinQueryParams
} from "@/global/api/gen/ts/schoolsDTO/SchoolsControllerGetSchoolInfoByBin";
import type {
	SchoolsControllerGetSchoolsByUserIdQueryParams
} from "@/global/api/gen/ts/schoolsDTO/SchoolsControllerGetSchoolsByUserId";

export const SchoolKeys = {
	all: ['schools'] as const,
	search: ['schools', 'search'] as const,
	byUser: (id:number, params: SchoolsControllerGetSchoolsByUserIdQueryParams) => [...SchoolKeys.search, 'user', `${id}`, params],
	detail: (id:number) => ['schools', 'detail', `${id}`] as const,
	school: (id: number) => [...SchoolKeys.all, id] as const,
	schoolPublic: (params: SchoolsControllerSearchSchoolsQueryParams) => [...SchoolKeys.search, 'public', params] as const,
	schoolAdmin: (params: SchoolsControllerGetAdminSchoolsQueryParams) => [...SchoolKeys.search, 'admin', params] as const,
	attributes: (id:number) => [...SchoolKeys.detail(id), 'attributes'] as const,
	featureToggles: (params: SchoolsControllerGetSchoolsWithFeatureTogglesQueryParams) => [...SchoolKeys.search, 'feature-toggles', params] as const,
	detailByBin: (bin: string, params: SchoolsControllerGetSchoolInfoByBinQueryParams)=> [...SchoolKeys.search, 'bin', bin, params] as const,
};