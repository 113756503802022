import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransportControllerDetachTransportFromGroupMutationRequest,
  TransportControllerDetachTransportFromGroupMutationResponse,
} from '../../ts/TransportDTO/TransportControllerDetachTransportFromGroup'

export function getTransportControllerDetachTransportFromGroupUrl() {
  return `/transport/detach` as const
}

/**
 * @summary Открепление транспорта от группы
 * {@link /transport/detach}
 */
export async function transportControllerDetachTransportFromGroup(
  data: TransportControllerDetachTransportFromGroupMutationRequest,
  config: Partial<RequestConfig<TransportControllerDetachTransportFromGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    TransportControllerDetachTransportFromGroupMutationResponse,
    ResponseErrorConfig<Error>,
    TransportControllerDetachTransportFromGroupMutationRequest
  >({ method: 'POST', url: getTransportControllerDetachTransportFromGroupUrl().toString(), data, ...requestConfig })
  return res
}