import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerClearGeneratedPasswordByUserIdQueryResponse,
  StudentsControllerClearGeneratedPasswordByUserIdPathParams,
} from '../../ts/StudentsDTO/StudentsControllerClearGeneratedPasswordByUserId'

export function getStudentsControllerClearGeneratedPasswordByUserIdUrl({
  userId,
}: {
  userId: StudentsControllerClearGeneratedPasswordByUserIdPathParams['userId']
}) {
  return `/students/users/clear-password/${userId}` as const
}

/**
 * {@link /students/users/clear-password/:userId}
 */
export async function studentsControllerClearGeneratedPasswordByUserId(
  { userId }: { userId: StudentsControllerClearGeneratedPasswordByUserIdPathParams['userId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerClearGeneratedPasswordByUserIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerClearGeneratedPasswordByUserIdUrl({ userId }).toString(),
    ...requestConfig,
  })
  return res
}