import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {SchoolsFeatureTogglesTable} from "@/modules/schools/tables/SchoolsFeatureTogglesTable";
import {FeatureTogglesInfo} from "@/modules/feature-toggles/components/FeatureTogglesInfo";

function SuperAdminModulesPage() {

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Box sx={wrapperSx}>
                <FeatureTogglesInfo/>

                <Stack
                    direction={'column'}
                    width={'100%'}
                    sx={{
                        mt: '38px',
                        p: '52px',
                        borderRadius: '24px',
                        background: '#F5F5F5',
                    }}
                >
                    <Typography
                        variant={'h5'}
                        sx={{
                            mt: '32px'
                        }}
                    >
                        Управление теоретическими экзаменами по школам
                    </Typography>
                    <SchoolsFeatureTogglesTable/>
                </Stack>
            </Box>
        </Box>
    );
}

export default SuperAdminModulesPage;