import { categoriesControllerAddCategories } from './categoriesControllerAddCategories'
import { categoriesControllerChangeCategoriesBySchoolId } from './categoriesControllerChangeCategoriesBySchoolId'
import { categoriesControllerGetAllCategories } from './categoriesControllerGetAllCategories'
import { categoriesControllerGetSchoolCategories } from './categoriesControllerGetSchoolCategories'
import { categoriesControllerGetSectionsAndTopicsById } from './categoriesControllerGetSectionsAndTopicsById'

export const categoriesService = {
    categoriesControllerGetAllCategories,
    categoriesControllerGetSchoolCategories,
    categoriesControllerGetSectionsAndTopicsById,
    categoriesControllerAddCategories,
    categoriesControllerChangeCategoriesBySchoolId,
  }