import {apiGetLastNews} from "@/modules/news/services/news.services";
import {useTranslation} from "react-i18next";
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import React from "react";
import {NewsCard} from "@/modules/news/components/NewsCard";

interface NewsSectionProps {
    authorized?: boolean;
}

export function NewsSection({authorized = false}: NewsSectionProps) {

    const {data: lastNewsResponse, isLoading: lastNewsLoading} = apiGetLastNews();

    const {t} = useTranslation();

    if (!lastNewsResponse || lastNewsResponse.length === 0) {
        return <></>;
    }

    return (
        <Box
            flexDirection={'column'}
            sx={{
                p: {xs: '0', lg: authorized ? '0' : '50px'},
                background: authorized ? 'transparent' : '#fff',
                borderRadius: '30px',
                mt: {xs: '44px', lg: authorized ? '70px' : '100px'},
            }}
        >
            <Typography
                fontSize={{xs: '35px', lg: authorized ? '40px' : '59px'}}
                lineHeight={'98%'}
                color={'#05304F'}
                fontWeight={'bold'}
                sx={{
                    maxWidth: '800px'
                }}
            >
                {t('landing.schools-landing.news-title')}
            </Typography>
            <Typography
                fontSize={{xs: '16px', lg: '17px'}}
                lineHeight={{xs: '130%', lg: authorized ? '100%' : '150%'}}
                color={'#05304F'}
                sx={{
                    mt: {xs: '16px', lg: authorized ? '20px' : '28px'},
                    maxWidth: '758px'
                }}
            >
                {t('landing.schools-landing.news-subtitle')}
            </Typography>
            <Stack
                display={'flex'}
                flexWrap={'wrap'}
                flexDirection={{xs: 'column', lg: 'row'}}
                alignItems={{xs: 'center', lg: 'stretch'}}
                sx={{
                    mt: {xs: '40px', lg: authorized ? '47px' : '60px'}
                }}
                gap={'20px'}
            >
                {(!lastNewsResponse || lastNewsLoading) && (<CircularProgress/>)}
                {lastNewsResponse?.map((news) => {
                    return (
                        <NewsCard
                            authorized={authorized}
                            id={news.id}
                            title_ru={news.title_ru}
                            title_kz={news.title_kz}
                            description_ru={news.description_ru}
                            description_kz={news.description_kz}
                            created_at={news.created_at}
                            thumbnail={news.thumbnail}
                            key={news.id}
                        />
                    );
                })}
            </Stack>
        </Box>
    );
}