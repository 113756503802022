import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerAttachMasterToGroupMutationRequest,
  GroupsControllerAttachMasterToGroupMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerAttachMasterToGroup'

export function getGroupsControllerAttachMasterToGroupUrl() {
  return `/groups/attach/master` as const
}

/**
 * @summary Добавление мастера в группу
 * {@link /groups/attach/master}
 */
export async function groupsControllerAttachMasterToGroup(
  data: GroupsControllerAttachMasterToGroupMutationRequest,
  config: Partial<RequestConfig<GroupsControllerAttachMasterToGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerAttachMasterToGroupMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerAttachMasterToGroupMutationRequest
  >({ method: 'POST', url: getGroupsControllerAttachMasterToGroupUrl().toString(), data, ...requestConfig })
  return res
}