import React, { type ReactNode } from 'react';
import { Alert, Snackbar, Typography } from '@mui/material';

import { useSnackbarStore } from '../store/SnackbarStore';

interface SnackbarHocProps {
    children: ReactNode
}

function SnackbarHoc({children} : SnackbarHocProps) {

    const open = useSnackbarStore(state => state.open);
    const message = useSnackbarStore(state => state.message);
    const severity = useSnackbarStore(state => state.severity);

    const closeSnackbar = useSnackbarStore(state => state.closeSnackbar);

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2500}
                onClose={() => {closeSnackbar();}}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
            >
                <Alert
                    onClose={() => {closeSnackbar();}}
                    severity={severity}
                    sx={{display: 'flex', alignItems: 'center'}}
                >
                    <Typography variant={'h6'}>
                        {message}
                    </Typography>
                </Alert>
            </Snackbar>

            {children}
        </>
    );
}

export default SnackbarHoc;