import React from 'react';
import { Alert, Avatar, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Person } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';

import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '@/global/helpers/globalstyles';
import ActionButton from '../../Landing/components/ActionButton';
import { formatDate } from '@/global/helpers/text';
import { apiGetKursantInfo } from '@/modules/students/services/student.service';
import { useModal } from '@/global/providers/ModalProvider';
import { KursantInfoForm } from '@/modules/students/modals/KursantInfoForm';
import { toast } from 'react-toastify';

const AvatarSx = {
	fontSize: 16, color: '#99999F', bgcolor: '#FFFFFF'
};

function SchoolKursants() {

	const {id, kursantId} = useParams();
	const navigate = useNavigate();

	const {data:kursantInfo} = apiGetKursantInfo(+(kursantId ?? 0), {schoolId: +(id ?? 0)});
	const {openModal}= useModal();

	const handleOpenModal = () => {
		if (!kursantInfo) {
			toast.error('Информация о курсанте не загружен. Обновите страницу');
			return;
		}
		openModal(KursantInfoForm, {kursantInfo}, `docs_${kursantId}`);
	};

	if (!kursantInfo) {
		return (
			<Box
				className="App"
				sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
			>
				<PageHeader/>

				<Box sx={{...wrapperSx}}>
					<Stack sx={{p: 5, justifyContent: 'center', alignItems: 'center'}}>
						<CircularProgress/>
					</Stack>
				</Box>
			</Box>
		);
	}

	return (
		<Box
			className="App"
			sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
		>
			<PageHeader/>

			<Box sx={wrapperSx}>
				<Stack
					flexDirection={'column'}
					justifyContent={'flex-start'}
					alignItems={'flex-start'}
					sx={{mb: 2}}
				>
					<Typography
						sx={{mt: 3}}
						variant={'h5'}
					>
						<b>
							Курсанты учебной организации (студенты)
						</b>
					</Typography>
					<Typography variant="subtitle1">
						Здесь отображается информация о курсанте&nbsp;
						<i>
							<b>
								{kursantInfo.last_name}
								&nbsp;
								{kursantInfo.first_name}
							</b>
						</i>
					</Typography>
				</Stack>

				{!kursantInfo.exam_card && (
					<Alert severity={'error'}>
						Необходимо внести дополнительные данные о курсанте для генерации дополнительной документации
						(Экзаменационная карточка, Результаты экзаменов, Договор об обучении, Подтверждение обучения)
					</Alert>
				)}

				{kursantInfo.exam_card && (
					<Alert severity={'success'}>
						Дополнительные данные о курсанте заполнены. У вас есть доступ к дополнительной документации
						(Экзаменационная карточка, Результаты экзаменов, Договор об обучении, Подтверждение обучения)
					</Alert>
				)}

				<Stack
					flexDirection={'row'}
					gap={10}
				>
					<Stack
						flexDirection={'column'}
						sx={{py: 2}}
					>
						<Stack
							flexDirection={'row'}
							alignItems={'center'}
							gap={1}
						>
							<Avatar
								sx={AvatarSx}
							>
								<Person/>
							</Avatar>
							<Typography>
								<b>
									Ф.И.О:
								</b>
							</Typography>
							<Typography>
								{kursantInfo.last_name}
								&nbsp;
								{kursantInfo.first_name}
								&nbsp;
								{kursantInfo.patronymic}
							</Typography>
						</Stack>

						<Stack
							flexDirection={'row'}
							alignItems={'center'}
							gap={1}
						>
							<Avatar
								sx={AvatarSx}
							>
								<Person/>
							</Avatar>
							<Typography>
								<b>
									ИИН:
								</b>
							</Typography>
							<Typography>
								{kursantInfo.iin}
							</Typography>
						</Stack>

						<Stack
							flexDirection={'row'}
							alignItems={'center'}
							gap={1}
						>
							<Avatar
								sx={AvatarSx}
							>
								<Person/>
							</Avatar>
							<Typography>
								<b>
									Дата рождения:
								</b>
							</Typography>
							<Typography>
								{kursantInfo.exam_card ? formatDate(kursantInfo.exam_card.date_of_birth) : 'Н/Д'}
							</Typography>
						</Stack>

						<Stack
							flexDirection={'row'}
							alignItems={'center'}
							gap={1}
						>
							<Avatar
								sx={AvatarSx}
							>
								<Person/>
							</Avatar>
							<Typography>
								<b>
									Номер удостоверения личности
								</b>
							</Typography>
							<Typography>
								{kursantInfo.kursant_contract
								 ? kursantInfo.kursant_contract.identification_number
								 : 'Н/Д'}
							</Typography>
						</Stack>

						<Stack
							flexDirection={'row'}
							alignItems={'center'}
							gap={1}
						>
							<Avatar
								sx={AvatarSx}
							>
								<SchoolIcon/>
							</Avatar>
							<Typography>
								<b>
									Учебная группа:
								</b>
							</Typography>
							<Typography>
								{kursantInfo.group?.title}
							</Typography>
						</Stack>

						<Stack
							flexDirection={'row'}
							alignItems={'center'}
							gap={1}
						>
							<Avatar
								sx={AvatarSx}
							>
								<SchoolIcon/>
							</Avatar>
							<Typography>
								<b>
									Дата начала обучения:
								</b>
							</Typography>
							<Typography>
								{kursantInfo.group?.start_date
								 ? formatDate(new Date(kursantInfo.group?.start_date))
								 : 'Н/Д'}
							</Typography>
						</Stack>

						<Stack
							flexDirection={'row'}
							alignItems={'center'}
							gap={1}
						>
							<Avatar
								sx={AvatarSx}
							>
								<SchoolIcon/>
							</Avatar>
							<Typography>
								<b>
									Дата окончания обучения:
								</b>
							</Typography>
							<Typography>
								{kursantInfo.group?.end_date ? formatDate(new Date(kursantInfo.group.end_date)) : 'Н/Д'}
							</Typography>
						</Stack>

						<Stack
							flexDirection={'row'}
							alignItems={'center'}
							gap={1}
						>
							<Avatar
								sx={AvatarSx}
							>
								<SchoolIcon/>
							</Avatar>
							<Typography>
								<b>
									Категория обучения:
								</b>
							</Typography>
							<Typography>
								{kursantInfo.group?.category_id}
							</Typography>
						</Stack>
					</Stack>
					<Stack
						flexDirection={'column'}
						sx={{py: 2}}
					>
						<ActionButton
							title={'Экзаменационная карточка водителя'}
							width={'250px'}
							height={'150px'}
							onClick={() => {navigate(`/schools/${id}/kursants/${kursantInfo.id}/exam-card`);}}
						/>
						<ActionButton
							title={'Результаты экзаменов'}
							width={'250px'}
							height={'150px'}
							onClick={() => {navigate(`/schools/${id}/kursants/${kursantInfo.id}/results`);}}
						/>
					</Stack>
					<Stack
						flexDirection={'column'}
						sx={{py: 2}}
					>
						<ActionButton
							title={'Договор'}
							width={'250px'}
							height={'150px'}
							onClick={() => {navigate(`/schools/${id}/kursants/${kursantInfo.id}/kursant-contract`);}}
						/>
						<ActionButton
							title={'Подтверждение обучения'}
							width={'250px'}
							height={'150px'}
							onClick={() => {navigate(`/schools/${id}/kursants/${kursantInfo.id}/studying-confirmation`);}}
						/>
					</Stack>
				</Stack>
				{!kursantInfo.exam_card && (
					<Button
						variant={'contained'}
						onClick={handleOpenModal}
					>
						Внести дополнительные данные Курсанта
					</Button>
				)}

			</Box>
		</Box>
	);
}

export default SchoolKursants;