import {Button, InputBase, Stack, Typography} from '@mui/material';
import {type ChangeEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import LoadingButton from '../../global/components/LoadingButton';
import {apiRegisterAsStudent} from "@/modules/auth/services/auth.service";
import {useNavigate} from "react-router-dom";
import {useSnackbarStore} from "@/global/store/SnackbarStore";
import type {UserCreateDto} from "@/global/api/gen/ts/UserCreateDto";
import {useModal} from "@/global/providers/ModalProvider";
import {OfferDialog} from "@/modules/auth/dialogs/OfferDialog";

const inputStyle = {WebkitBoxShadow: "0 0 0 1000px white inset"};

export function RegisterStudentComponent() {
    const {t} = useTranslation();

    const {mutate, isPending: registerLoading} = apiRegisterAsStudent();
    const {openModal} = useModal();

    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [hidden, setHidden] = useState<boolean>(true);

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);

    const [userForm, setUserForm] = useState<UserCreateDto>({
        iin: '',
        gender: 0,
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        phone: '',
        address: '',
        password: ''
    });

    const handleUserChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setUserForm((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const finishReg = () => {
        userForm.patronymic = userForm.patronymic ?? null;
        mutate(userForm, {
            onSuccess: () => {
                cleanInputs();
                snackbar.successMessage('Вы успешно зарегистрировались! Перенаправляем на страницу авторизации.');
                navigate('/auth');
            }
        });
    };

    const cleanInputs = () => {
        setUserForm({
            iin: '',
            gender: 0,
            first_name: '',
            last_name: '',
            patronymic: '',
            email: '',
            phone: '',
            address: '',
            password: ''
        });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setConfirmPassword(e.target.value);
    };

    const handleOpenOffer = () => {
        if (!userForm.iin || !userForm.first_name || !userForm.last_name || !userForm.email || !userForm.phone) {
            toast.error(t('auth.register-student.required-data'));
            return;
        }

        if (!userForm.password || !confirmPassword) {
            toast.error(t('auth.register-student.required-passwords'));
            return;
        }

        if (confirmPassword !== userForm.password) {
            toast.error(t('auth.register-student.mismatch-passwords'));
            return;
        }

        if (!/^[a-zA-Z0-9$#!@%]*$/.test(userForm.password)) {
            toast.error(t('auth.register-student.invalid-password'));
            return;
        }

        openModal(OfferDialog, {handleFinish: finishReg, registerLoading});
    };

    return (
        <Stack flexDirection={'column'}>
            <Stack
                flexDirection={'row'}
                sx={{
                    p: '36px',
                    my: 2,
                    background: '#FFF',
                    borderRadius: '16px',
                }}
                gap={'36px'}
            >
                <Stack
                    flexDirection={'column'}
                    sx={{width: '50%'}}
                    gap={'16px'}
                >
                    <Typography className={'info-header'}>
                        <b>
                            {t('auth.register-student.user-info')}
                        </b>
                    </Typography>

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.iin')}
                        value={userForm.iin}
                        name={'iin'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.last_name')}
                        value={userForm.last_name}
                        name={'last_name'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.first_name')}
                        value={userForm.first_name}
                        name={'first_name'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.patronymic')}
                        value={userForm.patronymic}
                        name={'patronymic'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={false}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.email')}
                        value={userForm.email}
                        name={'email'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={t('auth.register-student.phone')}
                        value={userForm.phone}
                        name={'phone'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />
                </Stack>

                <Stack
                    flexDirection={'column'}
                    sx={{width: '50%'}}
                    gap={'16px'}
                >
                    <Typography className={'info-header'}>
                        <b>
                            {t('auth.register-student.password-info')}
                        </b>
                    </Typography>

                    <InputBase
                        type={hidden ? 'password' : 'text'}
                        placeholder={t('auth.register-student.password')}
                        value={userForm.password}
                        name={'password'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />
                    <InputBase
                        type={hidden ? 'password' : 'text'}
                        placeholder={t('auth.register-student.repeat-password')}
                        value={confirmPassword}
                        name={'password'}
                        onChange={handleChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    {hidden && (
                        <Button
                            variant={'outlined'}
                            color={'info'}
                            sx={{
                                width: 'fit-content'
                            }}
                            onClick={() => {
                                setHidden(false);
                            }}
                        >
                            {t('auth.register-student.show')}
                        </Button>
                    )}
                    {!hidden && (
                        <Button
                            variant={'outlined'}
                            color={'info'}
                            sx={{
                                width: 'fit-content'
                            }}
                            onClick={() => {
                                setHidden(true);
                            }}
                        >
                            {t('auth.register-student.hide')}
                        </Button>
                    )}
                </Stack>

            </Stack>
            <Stack
                flexDirection={'row'}
                justifyContent={'center'}
                gap={'20px'}
                sx={{mt: '36px'}}
            >
                <LoadingButton
                    onClick={handleOpenOffer}
                    variant={'contained'}
                    sx={{width: '207px', height: '56px'}}
                    loading={registerLoading}
                >
                    {t('auth.register-student.register')}
                </LoadingButton>

            </Stack>

        </Stack>
    );
}
