import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CategoriesControllerAddCategoriesMutationRequest,
  CategoriesControllerAddCategoriesMutationResponse,
} from '../../ts/CategoriesDTO/CategoriesControllerAddCategories'

export function getCategoriesControllerAddCategoriesUrl() {
  return `/categories/add` as const
}

/**
 * @summary Добавление категорий в школу
 * {@link /categories/add}
 */
export async function categoriesControllerAddCategories(
  data: CategoriesControllerAddCategoriesMutationRequest,
  config: Partial<RequestConfig<CategoriesControllerAddCategoriesMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CategoriesControllerAddCategoriesMutationResponse, ResponseErrorConfig<Error>, CategoriesControllerAddCategoriesMutationRequest>({
    method: 'POST',
    url: getCategoriesControllerAddCategoriesUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}