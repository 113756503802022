import {apiConfigGuest, apiConfigReg, BACKEND_URL} from '@/global/actions/apiVariables';
import type {AuthDto} from '../dto/AuthDto';
import {axiosInstance} from '@/global/api/api';

export const login = (userAuthDto: AuthDto) => {
	return axiosInstance.post(`${BACKEND_URL}/auth/login`, userAuthDto, apiConfigGuest);
};

export const register = (regFormData: FormData) => {
	return axiosInstance.post(`${BACKEND_URL}/auth/register`, regFormData, apiConfigReg);
};

export const registerByAdmin = (regFormData: FormData) => {
	return axiosInstance.post(`${BACKEND_URL}/auth/register-by-admin`, regFormData);
};

export const getLocalitiesByRegionId = (regionId: number, params: {limit:number, offset:number}) => {
	return axiosInstance.get(`${BACKEND_URL}/region/${regionId}`, {params});
};