import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ru from './global/translation/ru.json';
import kz from './global/translation/kz.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            ru: {
                translation: ru
            },
            kz: {
              translation: kz
            }
        },
        supportedLngs: ['ru', 'kz'],
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;