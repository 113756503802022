import { authControllerCheckAuth } from './authControllerCheckAuth'
import { authControllerCheckLogin } from './authControllerCheckLogin'
import { authControllerLogin } from './authControllerLogin'
import { authControllerLogout } from './authControllerLogout'
import { authControllerRefresh } from './authControllerRefresh'
import { authControllerRegister } from './authControllerRegister'
import { authControllerRegisterStudent } from './authControllerRegisterStudent'
import { authControllerRegisterUnion } from './authControllerRegisterUnion'
import { authControllerReturnRegisterXML } from './authControllerReturnRegisterXML'

export const authService = {
    authControllerRegister,
    authControllerRegisterStudent,
    authControllerRegisterUnion,
    authControllerReturnRegisterXML,
    authControllerLogin,
    authControllerLogout,
    authControllerRefresh,
    authControllerCheckLogin,
    authControllerCheckAuth,
  }