import {Button, Stack, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";
import TicketsUserGrid from "@/Tickets/components/TicketsUserGrid";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export function TicketsSection() {
    const {t} = useTranslation();

    const navigate = useNavigate();

    return (
        <Stack
            flexDirection={'column'}
            sx={{
                background: '#fff',
                borderRadius: '20px',
                mt: '20px'
            }}
        >
            <Stack
                flexDirection={{xs:'column', lg: 'row'}}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                    mt: '47px',
                    px: '40px',
                    mb: '40px'
                }}
            >
                <Typography
                    fontSize={'30px'}
                    lineHeight={'100%'}
                    color={'#05304F'}
                >
                    {t('main-pages.manager.your-appointments')}
                </Typography>
                <Button
                    variant={'contained'}
                    startIcon={<Add/>}
                    sx={{
                        px: '90px',
                        mt: {xs: '20px', lg: '0'}
                    }}
                    onClick={() => navigate('/tickets/new')}
                >
                    {t('main-pages.manager.new-appointment')}
                </Button>
            </Stack>
            <TicketsUserGrid withoutTabs={true}/>
        </Stack>
    );
}