import React from 'react';
import { Paper, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
    iin: string,
    handleIin: (value: string) => void
    handleCheck: () => void
    loading: boolean
}

export function IinFieldForm({ iin, handleIin, handleCheck, loading }: Props) {
    return (
        <Stack
            width={{ xs: '90%', lg: '50%' }}
            alignItems={'center'}
            justifyContent={'center'}
            gap={4}
            margin={'0 auto'}
            marginTop={4}
            component={Paper}
            sx={{
                p: 4,
                borderRadius: 4
            }}
        >
            <Typography variant={'h4'}>
                Введите ИИН
            </Typography>
            <TextField
                name={'iin'}
                fullWidth
                value={iin}
                onChange={(e) => handleIin(e.target.value)}
                placeholder={'123456789123'}
                onPaste={(e) => {
                    e.preventDefault();

                    const clipboardData = e.clipboardData;
                    const pastedText = clipboardData?.getData('text');

                    if (pastedText) {
                        const prepareText = pastedText.trim().replace(/\s/g, '').slice(0, 12);
                        handleIin(prepareText);
                    }
                    return false;
                }}
                sx={{
                    borderRadius: '10px',
                    '.MuiOutlinedInput-root': {
                        backgroundColor: '#ebf0fb'
                    },
                    '.MuiOutlinedInput-input': {
                        color: '#05304F',
                        padding: '18px',
                        fontSize: '16px',
                        lineHeight: '24px'
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent'
                    }
                }}
            />

            <LoadingButton
                loading={loading}
                onClick={handleCheck}
                variant={'contained'}
            >
                Проверить
            </LoadingButton>

        </Stack>
    );
}