import React, {type ChangeEvent, memo} from 'react';
import {
    type Control,
    Controller,
    type ControllerProps,
    type FieldPath,
    type FieldValues,
    type Path,
    type PathValue
} from 'react-hook-form';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    TextField as Base,
    type TextFieldProps as BaseProps,
    type TextFieldVariants
} from '@mui/material';

export type ControlledFieldProps<T extends FieldValues> = {
    control: Control<T>;
    name: FieldPath<T>;
    label?: string;
    formSx?: BaseProps['sx'];
    variant?: TextFieldVariants,
    rules?: ControllerProps<T>['rules'],
    valuePreprocess?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => string,
} & Omit<BaseProps, 'variant'>;

export function TextFieldForm<T extends FieldValues>({
                                                         formSx,
                                                         label,
                                                         name,
                                                         defaultValue,
                                                         control,
                                                         onBlur,
                                                         onChange,
                                                         rules,
                                                         valuePreprocess,
                                                         ...props
                                                     }: ControlledFieldProps<T>) {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={(defaultValue ?? '') as PathValue<T, Path<T> & string>}
            rules={rules}
            render={({
                         field: {
                             onChange: fieldOnChange,
                             onBlur: fieldOnBlur,
                             ...field
                         },
                         fieldState: { error }
                     }) => (
                <FormControl
                    fullWidth
                    margin={props.margin ?? 'none'}
                    error={!!error}
                    sx={formSx}
                >
                    {label && (
                        <FormLabel>
                            {label}
                        </FormLabel>
                    )}
                    <Base
                        {...props}
                        {...field}
                        onChange={(e) => {
                            if (valuePreprocess) {
                                e.target.value = valuePreprocess(e);
                            };
                            onChange?.(e);
                            fieldOnChange(e);
                        }}
                        onBlur={(e) => {
                            onBlur?.(e);
                            fieldOnBlur();
                        }}
                    />
                    {error && (
                        <FormHelperText>
                            {error?.message}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}

export default memo(TextFieldForm) as <T extends FieldValues>(
    props: ControlledFieldProps<T>
) => React.JSX.Element;
