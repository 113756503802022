import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  NotificationsControllerGetAllUserNotificationsQueryResponse,
  NotificationsControllerGetAllUserNotificationsPathParams,
  NotificationsControllerGetAllUserNotificationsQueryParams,
} from '../../ts/NotificationsDTO/NotificationsControllerGetAllUserNotifications'

export function getNotificationsControllerGetAllUserNotificationsUrl({ id }: { id: NotificationsControllerGetAllUserNotificationsPathParams['id'] }) {
  return `/notifications/all/${id}` as const
}

/**
 * @summary Получение всех уведомлений
 * {@link /notifications/all/:id}
 */
export async function notificationsControllerGetAllUserNotifications(
  { id }: { id: NotificationsControllerGetAllUserNotificationsPathParams['id'] },
  params: NotificationsControllerGetAllUserNotificationsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<NotificationsControllerGetAllUserNotificationsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getNotificationsControllerGetAllUserNotificationsUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}