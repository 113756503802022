import {useCustomQuery} from "@/global/hooks/useCustomQuery";
import {featureTogglesService} from "@/global/api/gen";
import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import type {
    FeatureTogglesControllerGetAllFeatureTogglesQueryParams
} from "@/global/api/gen/ts/Feature TogglesDTO/FeatureTogglesControllerGetAllFeatureToggles";
import {FeatureTogglesKeys} from "@/modules/feature-toggles/services/feature-toggles.keys";
import type {ChangeFeatureEnableDto} from "@/global/api/gen/ts/ChangeFeatureEnableDto";
import type {ChangeSchoolFeatureEnableDto} from "@/global/api/gen/ts/ChangeSchoolFeatureEnableDto";

export const apiGetFeatureToggles = (params:FeatureTogglesControllerGetAllFeatureTogglesQueryParams) => {
    return useCustomQuery({
        queryFn: () => featureTogglesService.featureTogglesControllerGetAllFeatureToggles(params)
            .then((res) => res.data),
        queryKey: FeatureTogglesKeys.searchWithParams(params)
    });
};

export const apiChangeFeatureEnable = () => {
    return useCustomMutation({
        mutationFn: (data: ChangeFeatureEnableDto) => featureTogglesService.featureTogglesControllerChangeEnabledForFeatureToggle(data)
            .then((res) => res.data),
        queryKey: FeatureTogglesKeys.search(),
    });
};

export const apiChangeSchoolFeatureEnable = () => {
    return useCustomMutation({
        mutationFn: (data: ChangeSchoolFeatureEnableDto) => featureTogglesService.featureTogglesControllerChangeEnabledForSchoolFeatureToggle(data)
            .then((res) => res.data),
        queryKey: FeatureTogglesKeys.all,
    });
};

export const apiGetFeatureEnabled = (moduleName:string) => {
    return useCustomQuery({
        queryFn: () => featureTogglesService.featureTogglesControllerGetFeatureToggleByModuleName({moduleName})
            .then((res) => res.data),
        queryKey: FeatureTogglesKeys.detail(moduleName)
    });
};