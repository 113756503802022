import React from 'react';
import {Box, Chip, Grid, Typography} from '@mui/material';
import {toast} from "react-toastify";
import {useUserStore} from '@/global/store/UserStore';
import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import {apiSubmitGrade} from "@/modules/students/services/student.service";

interface GradesType {
    id: number;
    student_id: number;
    maintenance: number | null;
    rules: number | null;
    practice: number | null;
}

interface GradesComponentProps {
    schoolId: number;
    grades: null | GradesType;
    addLoadingId: (rowId: number) => void;
    removeLoadingId: (rowId: number) => void;
    row: StudentDto;
}

const gradesOptions = [2, 3, 4, 5];

export function GradesComponent({schoolId, grades, addLoadingId, removeLoadingId, row}: GradesComponentProps) {

    const userStore = useUserStore((state) => state);

    const {mutate: submitGradeByStudentId} = apiSubmitGrade();

    const submitGrade = (subject: string, grade: number) => {

        addLoadingId(row.id);
        const validSubjects = ['maintenance', 'rules', 'practice'];

        if (!validSubjects.includes(subject)) {
            toast.error('Выбран несуществующий предмет. Обратитесь в службу поддержки');
            return;
        }

        submitGradeByStudentId({
            student_id: row.id,
            [subject]: grade,
            school_id: schoolId,
        });

        toast.success('Изменение оценки произошло успешно.');
        removeLoadingId(row.id);
    };

    const handleGradeClick = (subject: string, grade: number) => {
        submitGrade(subject, grade);
    };

    return (
        <Box
            py={2}
            sx={{width: '100%'}}
        >
            <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{width: '100%'}}
            >
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                >
                    <Typography variant={'subtitle2'}>
                        Устройство и техническое обслуживание:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={1}
                >
                    {gradesOptions.map((grade) => (
                        <Chip
                            key={grade}
                            label={grade}
                            clickable={grades?.maintenance !== grade}
                            variant={"filled"}
                            color={grades?.maintenance === grade ? "primary" : "default"}
                            disabled={grades?.maintenance === grade || userStore.isObserver}
                            onClick={() => {
                                handleGradeClick('maintenance', grade);
                            }}
                        />
                    ))}
                </Grid>

                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                >
                    <Typography variant={'subtitle2'}>
                        Правила и основы движения:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={1}
                >
                    {gradesOptions.map((grade) => (
                        <Chip
                            key={grade}
                            label={grade}
                            clickable={grades?.rules !== grade}
                            variant={"filled"}
                            color={grades?.rules === grade ? "primary" : "default"}
                            disabled={grades?.rules === grade || userStore.isObserver}
                            onClick={() => {
                                handleGradeClick('rules', grade);
                            }}
                        />
                    ))}
                </Grid>

                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                >
                    <Typography variant={'subtitle2'}>
                        Практическое вождение:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={1}
                >
                    {gradesOptions.map((grade) => (
                        <Chip
                            key={grade}
                            label={grade}
                            clickable={grades?.practice !== grade}
                            variant={"filled"}
                            color={grades?.practice === grade ? "primary" : "default"}
                            disabled={grades?.practice === grade || userStore.isObserver}
                            onClick={() => {
                                handleGradeClick('practice', grade);
                            }}
                        />
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}