import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerCheckCurrentDateByEndDateQueryResponse,
  GroupsControllerCheckCurrentDateByEndDatePathParams,
} from '../../ts/GroupsDTO/GroupsControllerCheckCurrentDateByEndDate'

export function getGroupsControllerCheckCurrentDateByEndDateUrl({ id }: { id: GroupsControllerCheckCurrentDateByEndDatePathParams['id'] }) {
  return `/groups/check/date/${id}` as const
}

/**
 * {@link /groups/check/date/:id}
 */
export async function groupsControllerCheckCurrentDateByEndDate(
  { id }: { id: GroupsControllerCheckCurrentDateByEndDatePathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerCheckCurrentDateByEndDateQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerCheckCurrentDateByEndDateUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}