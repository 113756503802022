import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerCreateQuickTransactionMutationRequest,
  TransactionsControllerCreateQuickTransactionMutationResponse,
} from '../../ts/TransactionsDTO/TransactionsControllerCreateQuickTransaction'

export function getTransactionsControllerCreateQuickTransactionUrl() {
  return `/transactions/create-quick-fee` as const
}

/**
 * @summary Создание быстрого платежа
 * {@link /transactions/create-quick-fee}
 */
export async function transactionsControllerCreateQuickTransaction(
  data: TransactionsControllerCreateQuickTransactionMutationRequest,
  config: Partial<RequestConfig<TransactionsControllerCreateQuickTransactionMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    TransactionsControllerCreateQuickTransactionMutationResponse,
    ResponseErrorConfig<Error>,
    TransactionsControllerCreateQuickTransactionMutationRequest
  >({ method: 'POST', url: getTransactionsControllerCreateQuickTransactionUrl().toString(), data, ...requestConfig })
  return res
}