import React, {Fragment} from 'react';
import {Avatar, Box, Button, Paper, Stack, type SxProps, type Theme, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useUserStore} from '@/global/store/UserStore';
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {useModal} from "@/global/providers/ModalProvider";
import {DriversModal} from "@/modules/employees/components/DriversModal";
import type {GroupDriverDto} from "@/global/api/gen/ts/GroupDriverDto";
import {toast} from "react-toastify";
import {apiDetachEmployee} from "@/modules/employees/services/employees.service";
import {LoadingButton} from "@mui/lab";

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface DriversWindowProps {
    groupInfo: GroupDataDto;
}

function DriversWindow({groupInfo}: DriversWindowProps) {

    const {t} = useTranslation();

    const userStore = useUserStore((state) => state);

    const {openModal} = useModal();

    const openDriverModal = () => {
        openModal(DriversModal, {groupInfo}, `driver_modal_${groupInfo.id}`);
    };

    const {mutate, isPending} = apiDetachEmployee();

    const detachDriver = (data:GroupDriverDto) => {
        mutate({
            groupId: data.group_id,
            employeeId: data.driver_id,
            type: 'drivers'
        }, {
            onSuccess: () => {
                toast.success(`Сотрудник ${data.driver.last_name} ${data.driver.first_name} откреплен`);
            }
        });
    };

    return (
        <Box>
            <Paper sx={{...paperSx, mb: 5}}>

                <Typography variant="h4">
                    {t('school_page.group_page.attributes_window.window.subtitle.driver')}
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{mb: 1.5}}
                    color="gray"
                >
                    {t('school_page.group_page.attributes_window.window.this_group.driver')}
                </Typography>
                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    {groupInfo?.driver?.length === 0 && (
                        <Typography
                            fontSize={'14px'}
                            color={"gray"}
                        >
                            {t('school_page.group_page.attributes_window.window.no_data')}
                        </Typography>
                    )}

                    {groupInfo?.driver?.length > 0 && (
                        groupInfo?.driver?.map((driver) => {
                            return (
                                <Fragment key={driver.id}>
                                    <Stack
                                        sx={{ml: 2, width: '400px', p: 2, borderRadius: '10px', background: '#F4F6F8'}}
                                        flexDirection="row"
                                        alignItems={'center'}
                                    >
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {driver.driver.last_name[0]}
                                            {driver.driver.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent="start"
                                                sx={{width: '100%'}}
                                            >
                                                <Typography>
                                                    {driver.driver.last_name}
                                                    {' '}
                                                    {driver.driver.first_name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="gray"
                                                    sx={{ml: 1}}
                                                >
                                                    #
                                                    {driver.driver.id}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <LoadingButton
                                            loading={isPending}
                                            onClick={() => detachDriver(driver)}
                                            variant={'contained'}
                                            sx={{
                                                paddingX: 4
                                            }}
                                        >
                                            Открепить
                                        </LoadingButton>
                                    </Stack>
                                </Fragment>
                            );
                        })
                    )}
                    <Button
                        onClick={openDriverModal}
                        sx={{
                            width: 'fit-content',
                            ml: '16px'
                        }}
                        variant={'contained'}
                        disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.attributes_window.modal.add_btn.driver')}
                    </Button>
                </Stack>
            </Paper>
        </Box>
    );
}

export default DriversWindow;