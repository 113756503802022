import {
	Box, FormControl, FormHelperText, MenuItem, Select, type SelectChangeEvent, Stack, TextField, Typography
} from '@mui/material';
import React, { type ChangeEvent, type FormEvent, useState } from 'react';

import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '@/global/helpers/globalstyles';
import { apiCreateNotification } from '@/modules/notifications/services/notifications.service';
import type { NotificationCreateDto, NotificationCreateDtoTypeEnum } from '@/global/api/gen/ts/NotificationCreateDto';
import { LoadingButton } from '@mui/lab';

export function AdminCreateNotification() {

	const {mutate, isPending} = apiCreateNotification();
    const [notificationDto, setNotificationDto] = useState<NotificationCreateDto>({
        title: '',
        text: '',
        type: null,
        target: null
    });

    const cleanForm = () => {
        setNotificationDto({
            title: '',
            text: '',
            type: null,
            target: ''
        });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNotificationDto((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleTypeSelect = (e: SelectChangeEvent<NotificationCreateDtoTypeEnum>) => {
        setNotificationDto((prev) => {
            return {
                ...prev,
                type: e.target.value as NotificationCreateDtoTypeEnum
            };
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        mutate(notificationDto, {
			onSuccess: () => {
				cleanForm();
			}
		});
    };

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <Stack
                    sx={{mb: 2}}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                >
                    <Typography
                        sx={{mt: 3}}
                        variant={'h5'}
                    >
                        <b>
                            Создать новое уведомление
                        </b>
                    </Typography>
                </Stack>

                <form
                    onSubmit={handleSubmit}
                >
                    <Stack gap={1}>
                        <TextField 
                            value={notificationDto.title}
                            name={'title'}
                            helperText={'Название'}
                            onChange={handleChange}
                            required={true}
                        />
                        <TextField 
                            value={notificationDto.text}
                            name={'text'}
                            helperText={'Текст'}
                            onChange={handleChange}
                            required={true}
                            multiline={true}
                            minRows={5}
                            inputProps={{style: {borderRadius: '0', height: 'auto'}}}
                        />
                        <FormControl>
                            <Select
								variant={'outlined'}
                                value={notificationDto.type ?? ''}
                                required={true}
                                onChange={handleTypeSelect}
                            >
                                <MenuItem value={'general'}>
                                    Все пользователи
                                </MenuItem>
                                {/* <MenuItem value={'role'}>
                                    Специалисты автошкол
                                </MenuItem> */}
                                {/* <MenuItem value={'personal'}>
                                    Определенный пользователь
                                </MenuItem> */}
                            </Select>
                            <FormHelperText id={'region-label'}>
                                Тип уведомления
                            </FormHelperText>
                        </FormControl>

                        <LoadingButton 
                            type='submit'
                            variant='contained'
                            loading={isPending}
                        >
                            Создать
                        </LoadingButton>
                    </Stack>
                </form>
            </Box>
        </Box>
    );
}
