import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerGetSchoolsWithFeatureTogglesQueryResponse,
  SchoolsControllerGetSchoolsWithFeatureTogglesQueryParams,
} from '../../ts/schoolsDTO/SchoolsControllerGetSchoolsWithFeatureToggles'

export function getSchoolsControllerGetSchoolsWithFeatureTogglesUrl() {
  return `/schools/feature-toggles` as const
}

/**
 * @summary Получение списка школ с фичами
 * {@link /schools/feature-toggles}
 */
export async function schoolsControllerGetSchoolsWithFeatureToggles(
  params: SchoolsControllerGetSchoolsWithFeatureTogglesQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGetSchoolsWithFeatureTogglesQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerGetSchoolsWithFeatureTogglesUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}