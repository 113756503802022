import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransportControllerCreateTransportMutationRequest,
  TransportControllerCreateTransportMutationResponse,
} from '../../ts/TransportDTO/TransportControllerCreateTransport'

export function getTransportControllerCreateTransportUrl() {
  return `/transport/create` as const
}

/**
 * @summary Создание транспорта
 * {@link /transport/create}
 */
export async function transportControllerCreateTransport(
  data: TransportControllerCreateTransportMutationRequest,
  config: Partial<RequestConfig<TransportControllerCreateTransportMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransportControllerCreateTransportMutationResponse, ResponseErrorConfig<Error>, TransportControllerCreateTransportMutationRequest>({
    method: 'POST',
    url: getTransportControllerCreateTransportUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}