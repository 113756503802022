import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerGetSectionsInfoByGroupIdQueryResponse,
  GroupsControllerGetSectionsInfoByGroupIdPathParams,
  GroupsControllerGetSectionsInfoByGroupIdQueryParams,
} from '../../ts/GroupsDTO/GroupsControllerGetSectionsInfoByGroupId'

export function getGroupsControllerGetSectionsInfoByGroupIdUrl({ groupId }: { groupId: GroupsControllerGetSectionsInfoByGroupIdPathParams['groupId'] }) {
  return `/groups/theory-hours/${groupId}` as const
}

/**
 * @summary Получение секций группы по groupId
 * {@link /groups/theory-hours/:groupId}
 */
export async function groupsControllerGetSectionsInfoByGroupId(
  { groupId }: { groupId: GroupsControllerGetSectionsInfoByGroupIdPathParams['groupId'] },
  params: GroupsControllerGetSectionsInfoByGroupIdQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerGetSectionsInfoByGroupIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerGetSectionsInfoByGroupIdUrl({ groupId }).toString(),
    params,
    ...requestConfig,
  })
  return res
}