import {Stack, Typography} from '@mui/material';
import React from 'react';

import {GroupStatusChip} from '@/GovPage/components/GroupStatusChip';
import {formatDate} from '@/global/helpers/text';
import type {StudentInfoType} from "@/global/api/gen/ts/StudentInfoType";
import {HeaderInfo} from "@/modules/students/components/StudentsByIin/HeaderInfo";
import {StudentIntegrationField} from "@/modules/students/components/StudentsByIin/StudentIntegrationField";
import {UserIcon} from "@/global/icons/UserIcon";

interface StudentGroupCardProps {
    studentInfo: StudentInfoType,
    closed?: boolean,
}

export function StudentIntegrationCard({
                                           studentInfo,
                                           closed,
                                       }: StudentGroupCardProps) {

    return (
        <Stack
            display={'flex'}
            flexDirection={'column'}
            sx={{
                borderRadius: '26px',
                backgroundColor: '#FFFFFF',
                padding: 3,
                gap: '16px'
            }}
            key={studentInfo.id}
        >
            <Stack
                flexDirection={'row'}
                alignItems={'center'}
                gap={2}
            >
                <UserIcon size={61}/>
                <Typography fontSize={'34px'}>
                    {`${studentInfo.last_name} ${studentInfo.first_name[0]}. ${studentInfo.patronymic?.[0] || ''}.`}
                </Typography>
            </Stack>
            <HeaderInfo>
                Данные курсанта
            </HeaderInfo>
            <Stack
                flexDirection={'column'}
                gap={1}
            >
                <StudentIntegrationField
                    value={(
                        <>
                            {studentInfo.last_name}
                            {' '}
                            {studentInfo.first_name}
                            {' '}
                            {studentInfo.patronymic ?? ''}
                        </>
                    )}
                    label={'ФИО:'}
                />
                <StudentIntegrationField
                    label={'ИИН курсанта:'}
                    value={studentInfo.iin}
                />
                <StudentIntegrationField
                    label={'Автошкола:'}
                    value={studentInfo.school_title}
                />
                <StudentIntegrationField
                    label={'Номер свидетельства:'}
                    value={studentInfo.certificate_number}
                />
            </Stack>

            <HeaderInfo>
                Учебные данные
            </HeaderInfo>

            <Stack
                flexDirection={'column'}
                alignItems={'center'}
                gap={1}
            >
                <Stack alignSelf={'flex-end'}>
                    <GroupStatusChip
                        status={studentInfo.group_status}
                        variant="chip"
                    />
                </Stack>
                <StudentIntegrationField
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    label={'Учебная группа:'}
                    value={studentInfo.group_title}
                />
                <StudentIntegrationField
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    label={'Категория обучения:'}
                    value={studentInfo.category}
                />
                <StudentIntegrationField
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    label={'Дата начала обучения:'}
                    value={formatDate(studentInfo.start_date)}
                />
                <StudentIntegrationField
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    label={'Дата окончания обучения:'}
                    value={closed ? '-' : formatDate(studentInfo.end_date)}
                />
            </Stack>
            <HeaderInfo>
                Внутришкольные экзамены
            </HeaderInfo>

            <Stack
                flexDirection={'column'}
                gap={1}
            >
                <StudentIntegrationField
                    flexDirection={'row'}
                    gap={2}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    label={'Устройство и техническое обслуживание:'}
                    value={studentInfo.maintenance || 'Н/Д'}
                />
                <StudentIntegrationField
                    flexDirection={'row'}
                    gap={2}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    label={'Правила и основы движения:'}
                    value={studentInfo.rules || 'Н/Д'}
                />
                <StudentIntegrationField
                    flexDirection={'row'}
                    gap={2}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    label={'Практическое вождение:'}
                    value={studentInfo.practice || 'Н/Д'}
                />
            </Stack>
        </Stack>
    );
}