import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerChangeGroupCategoryMutationRequest,
  GroupsControllerChangeGroupCategoryMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerChangeGroupCategory'

export function getGroupsControllerChangeGroupCategoryUrl() {
  return `/groups/change-category` as const
}

/**
 * @summary Изменение категории группы
 * {@link /groups/change-category}
 */
export async function groupsControllerChangeGroupCategory(
  data: GroupsControllerChangeGroupCategoryMutationRequest,
  config: Partial<RequestConfig<GroupsControllerChangeGroupCategoryMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    GroupsControllerChangeGroupCategoryMutationResponse,
    ResponseErrorConfig<Error>,
    GroupsControllerChangeGroupCategoryMutationRequest
  >({ method: 'POST', url: getGroupsControllerChangeGroupCategoryUrl().toString(), data, ...requestConfig })
  return res
}