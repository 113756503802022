import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Box, Button, Chip, Stack, type Theme, Typography} from '@mui/material';
import DOMPurify from 'dompurify';
import {SkipNext, SkipPrevious} from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTranslation} from 'react-i18next';
import {formatDate} from '@/global/helpers/text';
import PageHeader from '../../global/components/PageHeader';
import 'react-quill/dist/quill.snow.css';
import {apiGetNews} from "@/modules/news/services/news.services";

interface NewsSanitizedContent {
    sanitized_content_ru: string,
    sanitized_content_kz: string
}

function NextPreviousButton({type, id}: { type: 'next' | 'previous', id: number }) {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <Button
            variant={'outlined'}
            startIcon={type === 'next' ? <SkipNext/> : <SkipPrevious/>}
            disabled={!id}
            onClick={() => {
                navigate(`/news/${id}`);
            }}
            sx={{
                fontSize: {xs: '12px', lg: '14px'},
                width: 'fit-content'
            }}
        >
            {type === 'next' ? t('news-page.next') : t('news-page.previous')}
        </Button>
    );
}

function NewsPage() {

    const {newsId} = useParams();

    const {t, i18n} = useTranslation();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const {data: newsInfoResponse} = apiGetNews(+(newsId ?? 0));

    const [sanitizedContent, setSanitizedContent] = useState<NewsSanitizedContent>({
        sanitized_content_ru: '',
        sanitized_content_kz: ''
    });

    useEffect(() => {
        if (!newsId) {
            toast.error('Такая новость отсутствует в базе данных.');
        }
    }, [newsId]);

    useEffect(() => {
        if (newsInfoResponse) {
            const sanitizedContentRu = DOMPurify.sanitize(newsInfoResponse.current.content_ru.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'));
            const sanitizedContentKz = DOMPurify.sanitize(newsInfoResponse.current.content_kz.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'));

            setSanitizedContent({
                sanitized_content_ru: sanitizedContentRu,
                sanitized_content_kz: sanitizedContentKz,
            });
        }
    }, [newsInfoResponse]);

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column', pb: 2}}
        >
            <PageHeader/>

            <Stack
                flexDirection={'column'}
                gap={'16px'}
                sx={{
                    mx: 5,
                    px: 2,
                    py: 2,
                    background: '#fff',
                    borderRadius: '16px'
                }}
            >
                {newsInfoResponse?.current.thumbnail && (
                    <img
                        src={newsInfoResponse.current.thumbnail}
                        alt={''}
                        style={{
                            width: isMobile ? '100%' : '800px',
                            height: isMobile ? 'auto' : '400px',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            borderRadius: '16px'
                        }}
                    />
                )}
                {newsInfoResponse && (
                    <>
                        <Typography
                            variant={'h5'}
                            fontWeight={'bold'}
                        >
                            {i18n.language === 'ru' ? newsInfoResponse.current.title_ru : newsInfoResponse.current.title_kz}
                        </Typography>
                        <Stack
                            sx={{
                                fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                whiteSpace: 'pre-wrap',
                            }}
                            className={'ql-editor'}
                            dangerouslySetInnerHTML={{__html: i18n.language === 'ru' ? sanitizedContent.sanitized_content_ru : sanitizedContent.sanitized_content_kz}}
                        />
                        <Chip
                            label={`${t('news-page.created_at')} ${formatDate(newsInfoResponse.current.created_at)}`}
                            sx={{width: 'fit-content'}}
                        />
                        <Stack
                            flexDirection={'row'}
                            justifyContent={{xs: 'flex-start', lg: 'space-between'}}
                            gap={{xs: '16px', lg: '0'}}
                            sx={{
                                mt: {xs: '20px', lg: '44px'}
                            }}
                        >
                            {
                                newsInfoResponse.previous && (
                                    <NextPreviousButton
                                        type={'previous'}
                                        id={newsInfoResponse.previous.id}
                                    />
                                )}

                            {
                                newsInfoResponse.next && (
                                    <NextPreviousButton
                                        type={'next'}
                                        id={newsInfoResponse.next.id}
                                    />
                                )}

                        </Stack>
                    </>
                )}
            </Stack>
        </Box>
    );
}

export default NewsPage;