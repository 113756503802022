import { Checkbox, Divider, FormControlLabel, FormGroup, Stack } from '@mui/material';
import React from 'react';
import { availableCategories } from '@/modules/appeals/types/appeals.types';
import type { RegisterAppealDto } from '@/global/api/gen/ts/RegisterAppealDto';

interface Props {
	appealInfo: RegisterAppealDto,
}

export function SchoolTab({appealInfo}: Props) {
	return (
		<>
			<section className={'appeal-school'}>
				<h4>
					Данные учебного центра
				</h4>
				<p>
                <span className={'appeal-label'}>
                    БИН:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.bin}
					</span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Название:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.title}
     </span>	
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Электронная почта:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.email}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Основной телефон:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.phone1}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Дополнительный телефон:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.phone2}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Регион:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.region}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Населенный пункт:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.locality}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Адрес:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.address}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Ф.И.О. директора:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.director}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    ИИН директора:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.school_info?.director_iin}
     </span>
				</p>
			</section>
			<section className={'appeal-categories'}>
				<h4>
					Категории
				</h4>
				<FormGroup>
					<Stack
						flexDirection="row"
						flexWrap="wrap"
						className={'appeal-categories-ctn'}
					>
						{availableCategories.map(categoryId => (
							<FormControlLabel
								key={categoryId}
								label={categoryId}
								sx={{mr: 3}}
								className={'appeal-category-label'}
								control={(
									<Checkbox
										checked={appealInfo.school_info?.categories.includes(categoryId)}
										disabled={true}
									/>
								)}
							/>
						))}
					</Stack>
				</FormGroup>
			</section>
			<section className={'appeal-user'}>
				<h4>
					Данные пользователя
				</h4>
				<p>
                <span className={'appeal-label'}>
                    ИИН:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.user_info.iin}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Ф.И.О:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.user_info.last_name + ' ' + appealInfo.user_info.first_name + ' ' + appealInfo.user_info.patronymic}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Электронная почта:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.user_info.email}
     </span>
				</p>
				<Divider/>
				<p>
                <span className={'appeal-label'}>
                    Телефон:
                </span>
					<span className={'appeal-value'}>
                    {appealInfo.user_info.phone}
     </span>
				</p>
			</section>
		</>
	);
}