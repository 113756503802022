import React, { useState } from 'react';
import {
	CircularProgress, Dialog, DialogContent, DialogTitle, InputBase, Stack, Tab, Tabs, Typography
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { toast } from 'react-toastify';

import { useUserStore } from '@/global/store/UserStore';
import type { ModalSystemProps } from '@/global/providers/ModalProvider';
import { TransportsTab } from '@/modules/appeals/modals/TransportsTab';
import { EmployeesTab } from '@/modules/appeals/modals/EmployeesTab';
import { SchoolTab } from '@/modules/appeals/modals/SchoolTab';
import {
	apiAppealChangeEmail, apiAppealResendEmail, apiApproveAppeal, apiDenyAppeal, apiGetAppealById
} from '@/modules/appeals/services/appeal.service';
import { LoadingButton } from '@mui/lab';

interface AppealModalProps {
	appealId: number;
}

const AppealStatus = {
	1: 'Новая',
	2: 'Ожидает подтверждения по e-mail',
	3: 'Зарегистрирована',
	10: 'Отклонена'
};

type AppealInfoType = 'school' | 'employees' | 'transports';

export function AppealModal({appealId, close, open}: ModalSystemProps<AppealModalProps>) {
	const {data: appealInfo, isSuccess} = apiGetAppealById(appealId);
	const {mutate:mutateDeny, isPending: isPendingDeny} = apiDenyAppeal();
	const {mutate:mutateApprove, isPending: isPendingApprove} = apiApproveAppeal();
	const {mutate:mutateResend, isPending: isPendingResend} = apiAppealResendEmail();
	const {mutate:mutateChange, isPending: isPendingChange} = apiAppealChangeEmail();
	const [newEmail, setNewEmail] = useState<string>('');
	const [currentTab, setCurrentTab] = useState<AppealInfoType>('school');

	const userStore = useUserStore((state) => state);

	const declineAppeal = () => {
		mutateDeny({id:appealId}, {
			onSuccess: () => {
				toast.success('Заявка успешно отклонена');
				close(0);
			}
		});
	};

	const approveAppeal = () => {
		mutateApprove({id:appealId}, {
			onSuccess: () => {
				toast.success('Заявка успешно принята');
				close(0);
			}
		});
	};

	const resendConfirm = () => {
		if (!appealInfo) {
			toast.error("Произошла ошибка. Повторите позже");
			return;
		}
		if (!appealInfo.user_info.email) {
			toast.error("К заявке не привязана почта.");
			return;
		}
		mutateResend({email: appealInfo.user_info.email}, {
			onSuccess: () => {
				toast.error(`Письмо успешно отпровлено на почту ${appealInfo.user_info.email}`);
			}
		});
	};

	const changeEmail = () => {
		if (!newEmail) {
			toast.error('Необходимо ввести новый email');
			return;
		}
		mutateChange({email: newEmail, id: appealId }, {
			onSuccess: ()=> {
				toast.success(`Электронная почта была изменена. Также было послано новое письмо с подтверждением регистрации`);
			}
		});
	};

	const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setNewEmail(e.target.value);
	};

	return (
		<Dialog
			open={open}
			fullWidth={true}
			maxWidth={'xl'}
			onClose={() => {
				close(5);
			}}
			className={'appeal'}
		>
			<DialogTitle>
				{`Заявка #${appealId}`}
			</DialogTitle>
			<DialogContent>
				{!isSuccess && <CircularProgress/>}
				{isSuccess && (
					<>
						<section className={'appeal-status'}>
							<Typography>
								Статус заявки -
								<b>
									{AppealStatus[appealInfo.status]}
								</b>
							</Typography>
							<Typography>
								Тип компании -
								<b>
									{appealInfo.school_info?.type === 'natural'
									 ? 'Индивидуальный предприниматель (ИП)'
									 : 'Товарищество с ограниченной ответственностью (ТОО)'}
								</b>
							</Typography>
						</section>
						<Tabs
							value={currentTab}
							onChange={(_event, value) => {setCurrentTab(value);}}
							sx={{mt: 1}}
						>
							<Tab
								label={'Школа'}
								value={'school'}
							/>
							<Tab
								label={'Сотрудники'}
								value={'employees'}
							/>
							<Tab
								label={'Транспорт'}
								value={'transports'}
							/>
						</Tabs>
						{currentTab === 'school' && <SchoolTab appealInfo={appealInfo}/>}
						{currentTab === 'employees' && <EmployeesTab attributes_info={appealInfo.attributes_info}/>}
						{currentTab === 'transports' && <TransportsTab attributes_info={appealInfo.attributes_info}/>}

						{appealInfo.status === 1 && userStore.isAdmin && (
							<section className={'appeal-actions'}>
								<h4>
									Действия
								</h4>
								<Stack
									flexDirection="row"
									flexWrap="wrap"
								>
									<LoadingButton
										className={'appeal-actions-decline'}
										variant={'contained'}
										onClick={declineAppeal}
										loading={isPendingDeny}
										disabled={isPendingApprove}
									>
										Отклонить
									</LoadingButton>
									<LoadingButton
										className={'appeal-actions-accept'}
										variant={'contained'}
										onClick={approveAppeal}
										disabled={isPendingDeny}
										loading={isPendingApprove}
									>
										Принять
									</LoadingButton>
								</Stack>
							</section>
						)}

						{appealInfo.status === 2 && userStore.isAdmin && (
							<section className={'appeal-actions'}>
								<h4>
									Действия
								</h4>
								<Stack
									flexDirection="row"
									flexWrap="wrap"
								>
									<LoadingButton
										className={'appeal-actions-decline'}
										variant={'contained'}
										onClick={resendConfirm}
										loading={isPendingResend}
										disabled={isPendingApprove || isPendingDeny}
									>
										<EmailIcon style={{marginRight: '5px'}}/>
										Перепослать письмо с подтверждением
									</LoadingButton>

									<InputBase
										disabled={isPendingDeny || isPendingApprove}
										onChange={handleChangeEmail}
										value={newEmail}
										placeholder={'Новый email'}
										sx={{ml: 'auto', mr: '5px'}}
									/>

									<LoadingButton
										className={'appeal-actions-change-email'}
										variant={'contained'}
										onClick={changeEmail}
										disabled={isPendingDeny || isPendingDeny || !newEmail}
										loading={isPendingChange}
										style={{marginLeft: '5px !important'}}
									>
										Сменить email
									</LoadingButton>

								</Stack>
							</section>
						)}
					</>
				)}
			</DialogContent>
		</Dialog>
	);
}