import { type ChangeEvent, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Box, Button, Stack, TextField, Typography } from '@mui/material';

import { useUserStore } from '@/global/store/UserStore';
import { useSnackbarStore } from '@/global/store/SnackbarStore';
import PageHeader from '../../global/components/PageHeader';
import { getName } from '@/global/helpers/text';
import { globalInputStyle } from '@/global/helpers/globalstyles';
import { apiChangePasswordById, apiGetUserById } from '@/modules/users/services/user.service';
import { LoadingButton } from '@mui/lab';

interface PasswordsDto {
    newPassword: string,
    confirmPassword: string
}

function ChangeUserPassword() {

    const currentUser = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();
    const {id} = useParams();

    const [passwords, setPasswords] = useState<PasswordsDto>({
        newPassword: '',
        confirmPassword: ''
    });
    const [hidden, setHidden] = useState<boolean>(true);

	const {mutate, isPending} = apiChangePasswordById();

	const {data} = apiGetUserById(+(id ?? 0), {
		enabled: isAdmin || (!!id && !!currentUser.id && +(currentUser.id) === +(id)),
	});

	const nameResult = useMemo(()=> getName(data ?? {}), [data]);

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        if (/^[a-zA-Z0-9$#!@%]*$/.test(newValue)) {
            setPasswords((prev) => {
                return {
                    ...prev,
                    [e.target.name]: newValue
                };
            });
        } else {
            snackbar.errorMessage(`Пароль может содержать только: латинские символы [a-z, A-Z], цифры (1-9), спец. символы ($,#,!,@,%)`);
        }
    };

    const handleSubmit = () => {
		if (!id || isNaN(+id)) {
			snackbar.errorMessage(`Непревиденная ошибка`);
			navigate('/');
			return;
		}
        mutate({
			id: +(id),
			newPassword: passwords.newPassword
		}, {
			onSuccess: () => {
				snackbar.successMessage(`Пароль успешно изменен.`);
			}
		});
    };

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>

            <PageHeader/>

            <Typography variant={'h6'}>
                Выбранный пользователь:
            </Typography>

            <Stack
                flexDirection={'row'}
                alignItems={'center'}
                sx={{
                    mt: 2,
                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
                    p: 2,
                    borderRadius: 1
                }}
            >
                <Avatar
                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                >
                    {nameResult.partialName}
                </Avatar>
                <Typography>
                    {nameResult.fullName}
					&nbsp;#&nbsp;
                    {data?.id ?? 0}
                </Typography>
            </Stack>

            <Stack
                gap={2}
                sx={{mt: 2}}
            >
                <TextField
                    name={'newPassword'}
                    sx={globalInputStyle}
                    label={'Новый пароль'}
                    type={hidden ? 'password' : 'text'}
                    value={passwords.newPassword}
                    onChange={handlePasswordChange}
                />

                <TextField
                    name={'confirmPassword'}
                    sx={globalInputStyle}
                    label={'Введите пароль еще раз'}
                    type={hidden ? 'password' : 'text'}
                    value={passwords.confirmPassword}
                    onChange={handlePasswordChange}
                />

                {hidden && (
                <Button
                    onClick={() => {setHidden(false);}}
                >
                    Показать пароль
                </Button>
				)}

                {!hidden && (
                <Button
                    onClick={() => {setHidden(true);}}
                >
                    Скрыть пароль
                </Button>
				)}
                <LoadingButton
                    variant={'contained'}
                    onClick={handleSubmit}
                    loading={isPending}
                >
                    Сменить пароль
                </LoadingButton>
            </Stack>

        </Box>
    );
}

export default ChangeUserPassword;