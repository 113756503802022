import dayjs from 'dayjs';
import * as ru from 'dayjs/locale/ru';

export function getDayDateTime(initialDate: Date | string) {
  let dateFormatted = initialDate;
  if (typeof dateFormatted === 'string') {
    dateFormatted = new Date(dateFormatted);
  }
  dayjs.locale(ru);
  // Преобразование входной даты в момент времени с указанием часового пояса Asia/Yekaterinburg
  const formatted = dayjs(dateFormatted).format();

  const day = extractDayOfWeek(formatted);
  const date = extractDate(formatted);
  const time = extractTime(formatted);

  return {
    day,
    date,
    time
  };
}

// Функция для извлечения даты в формате "день месяц год"
export function extractDate(formatted: string): string {
  return dayjs(formatted).format('D MMMM YYYY');
}

// Функция для извлечения дня недели
export function extractDayOfWeek(formatted: string): string {
  const day = dayjs(formatted).format('dddd');
  return day.charAt(0).toUpperCase() + day.slice(1);
}

// Функция для извлечения времени в формате "часы:минуты"
export function extractTime(formatted: string): string {
  return dayjs(formatted).format('HH:mm');
}
