import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  FeatureTogglesControllerGetAllFeatureTogglesQueryResponse,
  FeatureTogglesControllerGetAllFeatureTogglesQueryParams,
} from '../../ts/Feature TogglesDTO/FeatureTogglesControllerGetAllFeatureToggles'

export function getFeatureTogglesControllerGetAllFeatureTogglesUrl() {
  return `/feature-toggles` as const
}

/**
 * @summary Получить все Feature Toggles
 * {@link /feature-toggles}
 */
export async function featureTogglesControllerGetAllFeatureToggles(
  params: FeatureTogglesControllerGetAllFeatureTogglesQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<FeatureTogglesControllerGetAllFeatureTogglesQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getFeatureTogglesControllerGetAllFeatureTogglesUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}