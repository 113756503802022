import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  UsersControllerApplyCustomerToSchoolMutationRequest,
  UsersControllerApplyCustomerToSchoolMutationResponse,
} from '../../ts/usersDTO/UsersControllerApplyCustomerToSchool'

export function getUsersControllerApplyCustomerToSchoolUrl() {
  return `/users/customer/create` as const
}

/**
 * @summary Создать клиента
 * {@link /users/customer/create}
 */
export async function usersControllerApplyCustomerToSchool(
  data: UsersControllerApplyCustomerToSchoolMutationRequest,
  config: Partial<RequestConfig<UsersControllerApplyCustomerToSchoolMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    UsersControllerApplyCustomerToSchoolMutationResponse,
    ResponseErrorConfig<Error>,
    UsersControllerApplyCustomerToSchoolMutationRequest
  >({ method: 'POST', url: getUsersControllerApplyCustomerToSchoolUrl().toString(), data, ...requestConfig })
  return res
}