import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSnackbarStore } from '@/global/store/SnackbarStore';
import { getExamCard, getExamResults, getKursantContract, getStudyingConfirmation } from '../actions/api';

function KursantDocsPDF() {

  const {id, kursantId, type} = useParams();
  const navigate = useNavigate();
  const snackbar = useSnackbarStore((state) => state);
  const [pdfData, setPdfData] = useState<string>('');

  useEffect(() => {
    fetchInfo();
  }, [kursantId]);

  const fetchInfo = () => {
    if (type === 'exam-card') {
      getExamCard(Number(kursantId))
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' });

          const url = URL.createObjectURL(blob);
          setPdfData(url);
        })
        .catch((err) => {
          snackbar.errorMessage(err.response.data.message ? err.response.data.message : `Необходимо заполнить дополнительные данные для формирования данного документа`);
          navigate(`/schools/${id}/kursants/${kursantId}`);
        });
    }

    if (type === 'kursant-contract') {
      getKursantContract(Number(kursantId))
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' });

          const url = URL.createObjectURL(blob);
          setPdfData(url);
        })
        .catch((err) => {
          snackbar.errorMessage(err.response.data.message ? err.response.data.message : `Необходимо заполнить дополнительные данные для формирования данного документа`);
          navigate(`/schools/${id}/kursants/${kursantId}`);
        });
    }

    if (type === 'studying-confirmation') {
      getStudyingConfirmation(Number(kursantId))
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' });

          const url = URL.createObjectURL(blob);
          setPdfData(url);
        })
        .catch((err) => {
          snackbar.errorMessage(err.response.data.message ? err.response.data.message : `Необходимо заполнить дополнительные данные для формирования данного документа`);
          navigate(`/schools/${id}/kursants/${kursantId}`);
        });
    }

    if (type === 'results') {
      getExamResults(Number(kursantId))
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' });

          const url = URL.createObjectURL(blob);
          setPdfData(url);
        })
        .catch((err) => {
          snackbar.errorMessage(err.response.data.message ? err.response.data.message : `Необходимо заполнить дополнительные данные для формирования данного документа`);
          navigate(`/schools/${id}/kursants/${kursantId}`);
        });
    }

  };

  return (
      <iframe
          src={pdfData}
          style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '0',
        top: '0'
      }}
      >
      </iframe>
  );
}

export default KursantDocsPDF;