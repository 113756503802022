import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {Avatar, Box, Button, CircularProgress, Stack, type SxProps, type Theme, Typography} from '@mui/material';

import {useUserStore} from '@/global/store/UserStore';
import PageHeader from '../../global/components/PageHeader';
import UsersSchools from '../components/UsersSchools';
import {apiGetUserById} from "@/modules/users/services/user.service";

const titleSx: SxProps<Theme> = {
    width: 120,
    fontWeight: 500,
    color: '#5E5E5E',
    fontSize: 17
};

const valueSx: SxProps<Theme> = {
    fontSize: 17
};

function UserPage() {

    const currentUser = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const {id} = useParams();
    const {data: userInfo, isLoading: loading} = apiGetUserById(+(id ?? 0), {
        enabled: isAdmin || (!!currentUser.id && !!id && +(currentUser.id) === +(id))
    });

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>
            <PageHeader/>

            {loading && <CircularProgress />}

            {!loading && userInfo && (
                <>
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        sx={{mt: 1, mb: 3}}
                    >
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                        </Avatar>
                        <Stack>
                            <Typography
                                variant="h5"
                                color="dimgray"
                            >
                                Специалист ID:
                                {userInfo.id}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{mt: 0.3}}
                            >
                                {userInfo.last_name} 
                                {' '}
                                {userInfo.first_name}  
                                {' '}
                                {userInfo.patronymic ? userInfo.patronymic : ''}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack sx={{mt: 0}}>
                        <Stack
                            flexDirection="row"
                            gap={1}
                            sx={{mt: 0.5}}
                            alignItems={'center'}
                        >
                            <Typography sx={titleSx}>
                                ИИН
                            </Typography>
                            <Typography sx={valueSx}>
                                {userInfo?.iin}
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            gap={1}
                            sx={{mt: 0.5}}
                            alignItems={'center'}
                        >
                            <Typography sx={titleSx}>
                                Фамилия
                            </Typography>
                            <Typography sx={valueSx}>
                                {userInfo.last_name}
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            gap={1}
                            sx={{mt: 0.5}}
                            alignItems={'center'}
                        >
                            <Typography sx={titleSx}>
                                Имя
                            </Typography>
                            <Typography sx={valueSx}>
                                {userInfo.first_name}
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            gap={1}
                            sx={{mt: 0.5}}
                            alignItems={'center'}
                        >
                            <Typography sx={titleSx}>
                                Отчество
                            </Typography>
                            <Typography sx={valueSx}>
                                {userInfo.patronymic ? userInfo.patronymic : 'Не указано'}
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            gap={1}
                            sx={{mt: 0.5}}
                            alignItems={'center'}
                        >
                            <Typography sx={titleSx}>
                                Телефон
                            </Typography>
                            <Typography sx={valueSx}>
                                {userInfo.phone ? userInfo.phone : 'Не указано'}
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            gap={1}
                            sx={{mt: 0.5}}
                            alignItems={'center'}
                        >
                            <Typography sx={titleSx}>
                                Электронная почта
                            </Typography>
                            <Typography sx={valueSx}>
                                {userInfo.email ? userInfo.email : 'Не указано'}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        gap={'16px'}
                        sx={{mt: '16px'}}
                    > 
                        {isAdmin &&  (
                        <Link to={`/change-userinfo/${userInfo.id}`}>
                            <Button variant={'outlined'}>
                                Изменить данные пользователя
                            </Button>
                        </Link>
)}
                        {isAdmin &&  (
                        <Link to={`/change-password/${userInfo.id}`}>
                            <Button variant={'outlined'}>
                                Сменить пароль
                            </Button>
                        </Link>
)}
                    </Stack>

                    <UsersSchools userId={userInfo.id} />
                </>
            )}
        </Box>
    );
}

export default UserPage;
