import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerIncreaseRatingMutationResponse,
  SchoolsControllerIncreaseRatingPathParams,
} from '../../ts/schoolsDTO/SchoolsControllerIncreaseRating'

export function getSchoolsControllerIncreaseRatingUrl({ schoolId }: { schoolId: SchoolsControllerIncreaseRatingPathParams['schoolId'] }) {
  return `/schools/rating/increase/${schoolId}` as const
}

/**
 * @summary Увеличение рейтинга автошколы
 * {@link /schools/rating/increase/:schoolId}
 */
export async function schoolsControllerIncreaseRating(
  { schoolId }: { schoolId: SchoolsControllerIncreaseRatingPathParams['schoolId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerIncreaseRatingMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getSchoolsControllerIncreaseRatingUrl({ schoolId }).toString(),
    ...requestConfig,
  })
  return res
}