import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import type { AttributesResponseDataType } from '@/global/api/gen/ts/AttributesResponseDataType';
import { availableCategories } from '@/modules/appeals/types/appeals.types';

interface Props {
	attributes_info: AttributesResponseDataType | null;
}

export function EmployeesTab({attributes_info}: Props) {
	return (
		<>
			<section className={'appeal-employees'}>
				<h4>
					Сотрудники учебного центра
				</h4>
				<Typography
					sx={{
						fontWeight: 'bold'
					}}
				>
					Преподаватели
				</Typography>
				{attributes_info?.teachers?.map((teacher) => {
					return (
						<Stack
							key={teacher.iin}
							display={'flex'}
							flexDirection={'row'}
							flexWrap={'wrap'}
							sx={{
								background: '#F4F6F8FF', borderRadius: '10px', width: 'fit-content'
							}}
						>
							<Box
								flexDirection={'column'}
								sx={{p: 2}}
							>
								<Typography
									sx={{p: 0}}
								>
									{teacher.last_name}
									{teacher.first_name}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Серия ВУ:
									{teacher.driver_license_series}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Номер ВУ:
									{teacher.driver_license_number}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Номер квалификационного свидетельства:
									{teacher.qualification_certificate}
								</Typography>
								<FormGroup>
									<Stack
										flexDirection="row"
										flexWrap="wrap"
										className={'appeal-categories-ctn'}
										sx={{
											maxWidth: '400px'
										}}
									>
										{availableCategories.map(categoryId => (
											<FormControlLabel
												key={categoryId}
												label={categoryId}
												sx={{mr: 3}}
												className={'appeal-category-label'}
												control={(
													<Checkbox
														checked={teacher.driver_license_categories.includes(categoryId)}
														disabled={true}
													/>
												)}
											/>
										))}
									</Stack>
								</FormGroup>
							</Box>
						</Stack>
					);
				})}
				<Typography
					sx={{
						fontWeight: 'bold'
					}}
				>
					Мастера обучения вождению
				</Typography>
				{attributes_info?.drivers?.map((driver) => {
					return (
						<Stack
							key={driver.iin}
							display={'flex'}
							flexDirection={'row'}
							flexWrap={'wrap'}
							sx={{
								background: '#F4F6F8FF', borderRadius: '10px', width: 'fit-content'
							}}
						>
							<Box
								flexDirection={'column'}
								sx={{p: 2}}
							>
								<Typography
									sx={{p: 0}}
								>
									{driver.last_name}
									{' '}
									{driver.first_name}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Серия ВУ:
									{' '}
									{driver.driver_license_series}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Номер ВУ:
									{driver.driver_license_number}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Номер квалификационного свидетельства:
									{driver.qualification_certificate}
								</Typography>
								<FormGroup>
									<Stack
										flexDirection="row"
										flexWrap="wrap"
										className={'appeal-categories-ctn'}
										sx={{
											maxWidth: '400px'
										}}
									>
										{availableCategories.map(categoryId => (
											<FormControlLabel
												key={categoryId}
												label={categoryId}
												sx={{mr: 3}}
												className={'appeal-category-label'}
												control={(
													<Checkbox
														checked={driver.driver_license_categories.includes(categoryId)}
														disabled={true}
													/>
												)}
											/>
										))}
									</Stack>
								</FormGroup>
							</Box>
						</Stack>
					);
				})}
				<Typography
					sx={{
						fontWeight: 'bold'
					}}
				>
					Мастера производственного обучения
				</Typography>
				{attributes_info?.masters?.map((master) => {
					return (
						<Stack
							key={master.iin}
							display={'flex'}
							flexDirection={'row'}
							flexWrap={'wrap'}
							sx={{
								background: '#F4F6F8FF', borderRadius: '10px', width: 'fit-content'
							}}
						>
							<Box
								flexDirection={'column'}
								sx={{p: 2}}
							>
								<Typography
									sx={{p: 0}}
								>
									{master.last_name}
									{master.first_name}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Серия ВУ:
									{master.driver_license_series}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Номер ВУ:
									{master.driver_license_number}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Номер квалификационного свидетельства:
									{master.qualification_certificate}
								</Typography>
								<FormGroup>
									<Stack
										flexDirection="row"
										flexWrap="wrap"
										className={'appeal-categories-ctn'}
										sx={{
											maxWidth: '400px'
										}}
									>
										{availableCategories.map(categoryId => (
											<FormControlLabel
												key={categoryId}
												label={categoryId}
												sx={{mr: 3}}
												className={'appeal-category-label'}
												control={(
													<Checkbox
														checked={master.driver_license_categories.includes(categoryId)}
														disabled={true}
													/>
												)}
											/>
										))}
									</Stack>
								</FormGroup>
							</Box>
						</Stack>
					);
				})}
			</section>
		</>
	);
}