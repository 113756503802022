import axios from 'axios';
import Cookies from "js-cookie";

import {useUserStore} from "../store/UserStore";

import {apiConfigAuthorized, apiConfigRefreshToken, BACKEND_URL, FRONTEND_URL} from './apiVariables';

const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
});

axiosInstance.interceptors.request.use((request) => {
    if (Cookies.get('accessToken')) {
        const accessToken = Cookies.get('accessToken');
        request.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return request;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if ('response' in error && error.response.status === 401) {
            try {
                const newTokens = await refreshAccessToken();
                originalRequest.headers['Authorization'] = 'Bearer ' + newTokens.accessToken;
                return axiosInstance(originalRequest);
            } catch (e) {
                logoutToAuth();
            }
        }
        return Promise.reject(error);
    }
);

export async function refreshAccessToken(isBadRequest = false) {
    const refreshToken = Cookies.get('refreshToken');

    if (!refreshToken) {
        useUserStore.getState().logOut();
        window.location.assign(`${FRONTEND_URL}/auth`);
    }

    try {
        const response = await axios.get(`${BACKEND_URL}/auth/refresh`, apiConfigRefreshToken());
        setTokens(response.data);
        return response.data;
    } catch (e) {
        if (isBadRequest) {
            logoutToAuth();
        } else {
            logoutToAuth();
        }
    }
}

export const setTokens = ({accessToken, refreshToken}: any) => {
    Cookies.set('accessToken', accessToken);
    Cookies.set('refreshToken', refreshToken);
};

export const clearTokens = () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
};

export const logoutFromSystem = () => {
    axiosInstance.post(`${BACKEND_URL}/auth/logout`, {}, apiConfigAuthorized())
        .then(() => {
            logoutToAuth();
        })
        .catch(() => {
            logoutToAuth();
        })
        .finally(() => {
            clearTokens();
        });
};

export const logoutToAuth = () => {
    useUserStore.getState().logOut();
    window.location.replace(`${FRONTEND_URL}/auth`);
};

export const checkAuth = () => {
    return axiosInstance.get(`${BACKEND_URL}/auth/check/token`, apiConfigAuthorized());
};

export default axiosInstance;