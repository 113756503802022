import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerGenerateExamCardQueryResponse,
  CertificateControllerGenerateExamCardPathParams,
} from '../../ts/CertificateDTO/CertificateControllerGenerateExamCard'

export function getCertificateControllerGenerateExamCardUrl({ studentId }: { studentId: CertificateControllerGenerateExamCardPathParams['studentId'] }) {
  return `/certificate/doc/exam/${studentId}` as const
}

/**
 * {@link /certificate/doc/exam/:studentId}
 */
export async function certificateControllerGenerateExamCard(
  { studentId }: { studentId: CertificateControllerGenerateExamCardPathParams['studentId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerGenerateExamCardQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerGenerateExamCardUrl({ studentId }).toString(),
    ...requestConfig,
  })
  return res
}