import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, Chip, CircularProgress, Stack, Typography} from '@mui/material';
import {CheckCircleOutline, WarningAmberOutlined} from '@mui/icons-material';
import {toast} from 'react-toastify';
import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {TransactionChipStatus} from '@/global/actions/types.api';
import {
    apiGetQuickTransaction,
    apiRefreshStatusQuickTransaction
} from "@/modules/transactions/services/transactions.services";

function BuyingPage() {

    const {transactionId} = useParams();

    const {
        data: transactionResponse,
        isLoading: transactionLoading,
        refetch: requestTransaction
    } = apiGetQuickTransaction(+(transactionId ?? 0));

    const {
        data: refreshResponse,
        isLoading: refreshLoading,
        error: refreshError,
        refetch: requestRefresh
    } = apiRefreshStatusQuickTransaction(+(transactionId ?? 0));

    const [refreshTries, setRefreshTries] = useState<number>(0);

    useEffect(() => {
        if (transactionResponse?.status === 1 && refreshTries === 0) {
            void requestRefresh();
        } else if (transactionResponse?.status === 1 && refreshTries > 0 && refreshTries < 5) {
            setTimeout(() => {
                void requestRefresh();
            }, 5000);
        }
    }, [transactionResponse, refreshTries]);

    useEffect(() => {
        if (refreshResponse?.status === 1) {
            toast.info('Заказ все еще не оплачен. Обновим информацию через 5 секунд.');
            setRefreshTries((prev) => prev + 1);
        }
    }, [refreshResponse, refreshError]);

    useEffect(() => {
        if (refreshResponse?.status === 2) {
            void requestTransaction();
        }
    }, [refreshResponse]);

    if (transactionLoading) {
        return (
            <Box
                className="App"
                sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
            >
                <PageHeader/>

                <Box sx={wrapperSx}>
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <CircularProgress/>
                    </Stack>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>

            <Box sx={wrapperSx}>
                <Stack
                    direction={'column'}
                    width={'100%'}
                    sx={{
                        mt: '38px',
                        p: '52px',
                        borderRadius: '24px',
                        background: '#F5F5F5',
                        gap: '16px'
                    }}
                >
                    <Typography fontSize={'14px'}>
                        Транзакция #
                        {transactionId}
                    </Typography>
                    {transactionResponse?.type === 'theory_exams' && (
                        <Typography
                            variant={'h5'}
                        >
                            Приобретение попыток прохождения внутришкольного экзамена
                        </Typography>
                    )}

                    {transactionResponse?.type === 'trial_exams' && (
                        <Typography
                            variant={'h5'}
                        >
                            Приобретение попыток прохождения пробного теоретического экзамена
                        </Typography>
                    )}

                    {transactionResponse?.type === 'payment_access' && (
                        <Typography
                            variant={'h5'}
                        >
                            Оплата пошлины за хранение учебных данных
                        </Typography>
                    )}

                    {(transactionResponse?.type === 'theory_exams' || transactionResponse?.type === 'trial_exams') && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                        >
                            <Typography>
                                Количество приобретаемых попыток:
                            </Typography>
                            <Chip label={transactionResponse?.amount}/>
                        </Stack>
                    )}

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                    >
                        <Typography>
                            Статус транзакции:
                        </Typography>
                        <Chip
                            label={transactionResponse?.status && TransactionChipStatus[transactionResponse?.status]}
                            color={transactionResponse?.status === 3 ? 'error' : 'default'}
                        />
                    </Stack>

                    {transactionResponse?.status === 1 && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                        >
                            <Typography>
                                Ссылка на страницу оплаты:
                            </Typography>
                            <Chip
                                label={transactionResponse?.payment_url}
                                onClick={() => {
                                    if (transactionResponse?.payment_url) {
                                        window.open(transactionResponse.payment_url, '_blank');
                                    }
                                }}
                                sx={{
                                    cursor: 'pointer'
                                }}
                            />
                        </Stack>
                    )}

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                        sx={{
                            mb: '8px'
                        }}
                    >
                        <Typography
                            variant={'h6'}
                        >
                            Стоимость:
                            {' '}
                            {transactionResponse?.price}
                            {' '}
                            ₸
                        </Typography>
                    </Stack>

                    {transactionResponse?.status === 1 && refreshLoading && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                p: '32px',
                                borderRadius: '16px',
                                background: '#e3e3e3',
                                width: 'fit-content'
                            }}
                        >
                            <CircularProgress/>
                            <Typography>
                                Проверяем платеж...
                            </Typography>
                        </Stack>
                    )}

                    {transactionResponse?.status === 1 && !refreshLoading && refreshTries < 5 && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                p: '32px',
                                borderRadius: '16px',
                                background: '#fa8484',
                                width: 'fit-content'
                            }}
                        >
                            <WarningAmberOutlined
                                fontSize={'large'}
                            />
                            <Typography>
                                Заказ все еще не оплачен. Обновим информацию через 10 секунд.
                            </Typography>
                        </Stack>
                    )}

                    {(transactionResponse?.status === 1) && !refreshLoading && (refreshTries >= 5) && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                p: '32px',
                                borderRadius: '16px',
                                background: '#fa8484',
                                width: 'fit-content'
                            }}
                        >
                            <WarningAmberOutlined
                                fontSize={'large'}
                            />
                            <Stack>
                                <Typography>
                                    Заказ не оплачен. Перейдите по ссылке на страницу оплаты и произведите оплату.
                                </Typography>
                                <Typography>
                                    Если на странице оплаты - отображен статус "Заказ оплачен" - пожалуйста, обратитесь
                                    в службу поддержки.
                                </Typography>
                            </Stack>
                        </Stack>
                    )}

                    {transactionResponse?.status === 2 && (transactionResponse?.type === 'theory_exams' || transactionResponse?.type === 'trial_exams') && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                p: '32px',
                                borderRadius: '16px',
                                background: 'rgba(203,255,192,0.78)',
                                width: 'fit-content'
                            }}
                        >
                            <CheckCircleOutline
                                fontSize={'large'}
                                color={'secondary'}
                            />
                            <Typography>
                                Поздравляем! Вы успешно приобрели попытки (
                                {transactionResponse?.amount}
                                ) для прохождения
                                {' '}
                                {transactionResponse?.type === 'theory_exams' && 'внутришкольных экзаменов'}
                            </Typography>
                        </Stack>
                    )}

                    {transactionResponse?.status === 2 && (transactionResponse?.type === 'payment_access') && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                p: '32px',
                                borderRadius: '16px',
                                background: 'rgba(203,255,192,0.78)',
                                width: 'fit-content'
                            }}
                        >
                            <CheckCircleOutline
                                fontSize={'large'}
                                color={'secondary'}
                            />
                            <Typography>
                                Вы успешно оплатили пошлину за хранение учебных данных
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Box>
    );
}

export default BuyingPage;
