import {Box, Button, IconButton, Input, type SxProps, type Theme, Typography} from '@mui/material';
import React, {useState} from 'react';
import {Send} from '@mui/icons-material';

import {useUserStore} from '@/global/store/UserStore';
import {useSnackbarStore} from '@/global/store/SnackbarStore';
import {RenderMessage} from "@/Tickets/components/renderMessage";
import type {TicketDto} from "@/global/api/gen/ts/TicketDto";
import {apiCloseTicket, apiTicketSendMessage} from "@/modules/tickets/services/tickets.service";

interface MessagesHistoryProps {
    ticketInfo: TicketDto,
}

const paperSx: SxProps<Theme> = {
    paddingX: 2.3,
    paddingY: 1.5,
    boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.30) 0px 5px 12px -4px',
    mt: 2.5,
    mb: 2.5,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
};

function MessagesHistory({ticketInfo}: MessagesHistoryProps) {

    const userInfo = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [message, setMessage] = useState<string>('');

    const {mutate, isPending: isSending} = apiTicketSendMessage();
    const {mutate: mutateClose, isPending: isClosing} = apiCloseTicket();

    const send = () => {
        if (!message) {
            snackbar.errorMessage(`Невозможно послать пустое сообщение`);
            return;
        }

        mutate({id: ticketInfo.id , message}, {
            onSuccess: () => {
                setMessage('');
            }
        });
    };

    const closeTicket = () => {
        mutateClose({id: ticketInfo.id});
    };

    return (
        <Box sx={{m: 2}}>
            {ticketInfo.messages.map((message) => (
                <RenderMessage
                    key={message.id}
                    message={message}
                    manager={ticketInfo.manager}
                    user_id={ticketInfo.user_id}
                    user={ticketInfo.user}
                />
            ))}

            {ticketInfo.status === 10 && (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Typography color={'red'}>
                        Данное обращение было закрыто администратором.
                    </Typography>
                </Box>
            )}

            <Box sx={paperSx}>
                <Input
                    placeholder="Введите сообщение"
                    disableUnderline
                    multiline
                    minRows={4}
                    sx={{flex: 1}}
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value || '')}
                    disabled={ticketInfo.status === 10}
                />
                <IconButton
                    color="primary"
                    disabled={isSending}
                    onClick={send}
                >
                    <Send/>
                </IconButton>
            </Box>

            {userInfo.isAdmin && (
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        variant={'contained'}
                        color={'error'}
                        onClick={closeTicket}
                        disabled={isClosing || ticketInfo.status === 10}
                    >
                        Закрыть обращение
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default MessagesHistory;