import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CabinetsControllerDeleteCabinetMutationResponse,
  CabinetsControllerDeleteCabinetPathParams,
} from '../../ts/CabinetsDTO/CabinetsControllerDeleteCabinet'

export function getCabinetsControllerDeleteCabinetUrl({ id }: { id: CabinetsControllerDeleteCabinetPathParams['id'] }) {
  return `/cabinets/deny/${id}` as const
}

/**
 * @summary Удалить помещение
 * {@link /cabinets/deny/:id}
 */
export async function cabinetsControllerDeleteCabinet(
  { id }: { id: CabinetsControllerDeleteCabinetPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CabinetsControllerDeleteCabinetMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getCabinetsControllerDeleteCabinetUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}