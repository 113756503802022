import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerDecreaseRatingMutationResponse,
  SchoolsControllerDecreaseRatingPathParams,
} from '../../ts/schoolsDTO/SchoolsControllerDecreaseRating'

export function getSchoolsControllerDecreaseRatingUrl({ schoolId }: { schoolId: SchoolsControllerDecreaseRatingPathParams['schoolId'] }) {
  return `/schools/rating/decrease/${schoolId}` as const
}

/**
 * @summary Уменьшение рейтинга автошколы
 * {@link /schools/rating/decrease/:schoolId}
 */
export async function schoolsControllerDecreaseRating(
  { schoolId }: { schoolId: SchoolsControllerDecreaseRatingPathParams['schoolId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerDecreaseRatingMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getSchoolsControllerDecreaseRatingUrl({ schoolId }).toString(),
    ...requestConfig,
  })
  return res
}