import {Skeleton, Stack, Typography} from "@mui/material";
import React from "react";

interface MiniAttributesCardProps {
    count: number | string;
    title: string;
    loading?: boolean;
}

export function MiniAttributesCard({title, count, loading}: MiniAttributesCardProps) {
    if (loading) {
        return (
            <Skeleton
                variant={'rectangular'}
                width={118}
                height={106}
                sx={{
                    p: '22px',
                    background: '#f4f7fe',
                    borderRadius: '15px',
                }}
            />
        );
    }

    return (
        <Stack
            flexDirection={'column'}
            justifyContent={'space-between'}
            sx={{
                p: '22px',
                background: '#f4f7fe',
                borderRadius: '15px',
                width: '118px',
                height: '106px'
            }}
        >
            <Typography
                fontSize={'36px'}
                lineHeight={'100%'}
                color={'#05304f'}
            >
                {count ?? 'Н/Д'}
            </Typography>
            <Typography
                fontSize={'14px'}
                lineHeight={'100%'}
                color={'#000'}
            >
                {title}
            </Typography>
        </Stack>
    );
}