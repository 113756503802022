import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerFindStudentsByIINQueryResponse,
  StudentsControllerFindStudentsByIINPathParams,
  StudentsControllerFindStudentsByIINQueryParams,
} from '../../ts/StudentsDTO/StudentsControllerFindStudentsByIIN'

export function getStudentsControllerFindStudentsByIINUrl({ iin }: { iin: StudentsControllerFindStudentsByIINPathParams['iin'] }) {
  return `/students/search-by-iin/${iin}` as const
}

/**
 * @summary Получение студентов по IIN
 * {@link /students/search-by-iin/:iin}
 */
export async function studentsControllerFindStudentsByIIN(
  { iin }: { iin: StudentsControllerFindStudentsByIINPathParams['iin'] },
  params: StudentsControllerFindStudentsByIINQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerFindStudentsByIINQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerFindStudentsByIINUrl({ iin }).toString(),
    params,
    ...requestConfig,
  })
  return res
}