import {Avatar, Dialog, DialogContent, DialogTitle, Stack, Typography} from '@mui/material';
import {Person} from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import LoadingButton from '../../../global/components/LoadingButton';
import {getDayDateTime} from '@/global/helpers/formatters';
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {apiDeleteClass} from '@/modules/groups/services/group.service';
import type {GroupClassDto} from "@/global/api/gen/ts/GroupClassDto";

interface DeleteClassModalProps {
    classInfo: GroupClassDto,
}

function DeleteClassModal({classInfo, open, close}: ModalSystemProps<DeleteClassModalProps>) {
    const {mutate: requestDeleteClass, isPending} = apiDeleteClass();

    const onDelete = () => {
        requestDeleteClass({
            group_id: classInfo.group_id,
            class_id: classInfo.id,
        }, {
            onSuccess: () => {
                close(0);
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(5)}
            maxWidth={'xl'}
            fullWidth={true}
        >
            <DialogTitle>
                Удаление занятия #
                {classInfo.id}
            </DialogTitle>
            <DialogContent>
                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    <Typography
                        fontWeight={'bold'}
                    >
                        Подтвердите удаление
                        {' '}
                        {classInfo.type === 'theory' ? 'теоретического' : 'практического'}
                        {' '}
                        занятия:
                    </Typography>
                    <Typography>
                        Дата и время проведения занятия -
                        {' '}
                        {getDayDateTime(classInfo.start_date_time).date}
                        ,
                        {' '}
                        {getDayDateTime(classInfo.start_date_time).day}
                        ,
                        {' '}
                        {getDayDateTime(classInfo.start_date_time).time}
                    </Typography>
                    <Typography>
                        Раздел обучения -
                        {' '}
                        {classInfo.section?.title_ru}
                    </Typography>
                    <Typography>
                        Тема обучения -
                        {' '}
                        {classInfo.topic?.title_ru ?? 'Отсутствует'}
                    </Typography>
                    {classInfo?.group_id && (
                        <Stack>
                            <Typography fontWeight={'bold'}>
                                Учебная группа:
                            </Typography>
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                sx={{
                                    my: 1
                                }}
                            >
                                <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                >
                                    <SchoolIcon/>
                                </Avatar>
                                <Typography color={'gray'}>
                                    #
                                    {classInfo?.group_id}
                                </Typography>
                            </Stack>
                        </Stack>
                    )}
                    {classInfo?.student && (
                        <Stack>
                            <Typography fontWeight={'bold'}>
                                Студент:
                            </Typography>
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                sx={{
                                    my: 1
                                }}
                            >
                                <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                >
                                    <Person/>
                                </Avatar>
                                <Typography>
                                    {classInfo?.student?.last_name}
                                    {' '}
                                    {classInfo?.student?.first_name}
                                </Typography>
                            </Stack>
                        </Stack>
                    )}
                    {classInfo?.teacher && (
                        <Stack>
                            <Typography fontWeight={'bold'}>
                                Преподаватель:
                            </Typography>
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                sx={{
                                    my: 1
                                }}
                            >
                                <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                >
                                    <Person/>
                                </Avatar>
                                <Typography>
                                    {classInfo?.teacher?.last_name}
                                    {' '}
                                    {classInfo?.teacher?.first_name}
                                </Typography>
                            </Stack>
                        </Stack>
                    )}
                    {classInfo?.master && (
                        <Stack>
                            <Typography fontWeight={'bold'}>
                                Мастер производственного обучения:
                            </Typography>
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                sx={{
                                    my: 1
                                }}
                            >
                                <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                >
                                    <Person/>
                                </Avatar>
                                <Typography>
                                    {classInfo?.master?.last_name}
                                    {' '}
                                    {classInfo?.master?.first_name}
                                </Typography>
                            </Stack>
                        </Stack>
                    )}
                    {classInfo?.driver && (
                        <Stack>
                            <Typography fontWeight={'bold'}>
                                Мастер обучения вождению:
                            </Typography>
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                sx={{
                                    my: 1
                                }}
                            >
                                <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                >
                                    <Person/>
                                </Avatar>
                                <Typography>
                                    {classInfo?.driver?.last_name}
                                    {' '}
                                    {classInfo?.driver?.first_name}
                                </Typography>
                            </Stack>
                        </Stack>
                    )}
                    <LoadingButton
                        loading={isPending}
                        variant={'contained'}
                        sx={{
                            width: '300px'
                        }}
                        color={'error'}
                        onClick={onDelete}
                    >
                        Подтвердить удаление
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default DeleteClassModal;
