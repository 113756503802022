import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerGetActiveSchoolDriversQueryResponse,
  EmployeeControllerGetActiveSchoolDriversPathParams,
  EmployeeControllerGetActiveSchoolDriversQueryParams,
} from '../../ts/employeesDTO/EmployeeControllerGetActiveSchoolDrivers'

export function getEmployeeControllerGetActiveSchoolDriversUrl({ id }: { id: EmployeeControllerGetActiveSchoolDriversPathParams['id'] }) {
  return `/employees/school/driver/active/${id}` as const
}

/**
 * @summary Получение активных водителей школы
 * {@link /employees/school/driver/active/:id}
 */
export async function employeeControllerGetActiveSchoolDrivers(
  { id }: { id: EmployeeControllerGetActiveSchoolDriversPathParams['id'] },
  params: EmployeeControllerGetActiveSchoolDriversQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerGetActiveSchoolDriversQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getEmployeeControllerGetActiveSchoolDriversUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}