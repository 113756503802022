import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerDeleteSchoolAndUserMutationResponse,
  RegisterAppealsControllerDeleteSchoolAndUserPathParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerDeleteSchoolAndUser'

export function getRegisterAppealsControllerDeleteSchoolAndUserUrl({ id }: { id: RegisterAppealsControllerDeleteSchoolAndUserPathParams['id'] }) {
  return `/register-appeals/school/delete-only-school/${id}` as const
}

/**
 * @summary Удалить только школу
 * {@link /register-appeals/school/delete-only-school/:id}
 */
export async function registerAppealsControllerDeleteSchoolAndUser(
  { id }: { id: RegisterAppealsControllerDeleteSchoolAndUserPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerDeleteSchoolAndUserMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getRegisterAppealsControllerDeleteSchoolAndUserUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}