import {Button, Checkbox, Stack, Typography} from "@mui/material";
import {PdfIcon} from "@/global/icons/PdfIcon";
import React from "react";

export function PrivacyPolicyAgreeSection() {

    return (
        <Stack
            flexDirection={'column'}
            sx={{
                background: '#fff',
                borderRadius: '20px',
                mt: '20px'
            }}
        >
            <Stack
                flexDirection={'column'}
                sx={{
                    mt: '47px',
                    px: '40px',
                    mb: '40px'
                }}
            >
                <Typography
                    fontSize={'30px'}
                    lineHeight={'100%'}
                    color={'#05304F'}
                >
                    Пользовательское соглашение
                </Typography>

                <Stack
                    flexDirection={{xs: 'column', lg: 'row'}}
                    alignItems={'center'}
                    sx={{
                        mt: '20px'
                    }}
                >
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        sx={{
                            mb: {xs: '20px', lg: '0'}
                        }}
                    >
                        <PdfIcon/>

                        <Checkbox
                            sx={{
                                ml: '20px',
                                '& svg': {
                                    width: '30px',
                                    height: '30px'
                                }
                            }}
                            checked={true}
                        />

                        <Typography
                            fontSize={'13px'}
                            fontWeight={600}
                            sx={{
                                mt: '1px'
                            }}
                        >
                            Я ознакомлен и согласен с условиями использования системы Avtomektep.kz
                        </Typography>
                    </Stack>

                    <Button
                        variant={'contained'}
                        sx={{
                            px: '130px',
                            ml: 'auto'
                        }}
                        disabled={true}
                    >
                        Подписать
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
}