import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { UsersControllerGetUsersQueryResponse, UsersControllerGetUsersQueryParams } from '../../ts/usersDTO/UsersControllerGetUsers'

export function getUsersControllerGetUsersUrl() {
  return `/users` as const
}

/**
 * @summary Получить всех пользователей
 * {@link /users}
 */
export async function usersControllerGetUsers(params: UsersControllerGetUsersQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerGetUsersQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getUsersControllerGetUsersUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}