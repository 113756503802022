import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerDeleteGroupByIdMutationResponse,
  GroupsControllerDeleteGroupByIdPathParams,
} from '../../ts/GroupsDTO/GroupsControllerDeleteGroupById'

export function getGroupsControllerDeleteGroupByIdUrl({ id }: { id: GroupsControllerDeleteGroupByIdPathParams['id'] }) {
  return `/groups/delete/${id}` as const
}

/**
 * @summary Удаление группы
 * {@link /groups/delete/:id}
 */
export async function groupsControllerDeleteGroupById(
  { id }: { id: GroupsControllerDeleteGroupByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerDeleteGroupByIdMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getGroupsControllerDeleteGroupByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}