import { Box, Stack, Typography } from '@mui/material';

import PageHeader from '../../global/components/PageHeader';
import { useUserStore } from '@/global/store/UserStore';
import UsersList from '../components/UsersList';
import { wrapperSx } from '@/global/helpers/globalstyles';

function ChangePasswordList() {

	const isAdmin = useUserStore((state) => state.isAdmin);

	return (
		<Box
			className="App"
			sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
		>
			<PageHeader/>

			<Box sx={{...wrapperSx}}>

				<Stack
					flexDirection={'row'}
					justifyContent={'flex-start'}
					alignItems={'center'}
				>
					<Typography
						sx={{mt: 3}}
						variant={'h5'}
					>
						<b>
							Смена пароля
						</b>
					</Typography>
				</Stack>

				<Box>
					<Typography variant="h6">
						Специалисты (пользователи)
					</Typography>
					<Typography variant="subtitle2">
						Здесь отображаются все специалисты
						<b>
							всех учебных организаций
						</b>
					</Typography>
				</Box>

				{isAdmin && <UsersList/>}
			</Box>
		</Box>
	);
}

export default ChangePasswordList;