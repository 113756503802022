import {Avatar, Grid, Stack, type SxProps, type Theme, Typography} from "@mui/material";
import {DirectionsCar} from "@mui/icons-material";
import {formatDate} from "@/global/helpers/text";
import {CategoriesGrid, SubCategoriesGrid} from "@/School/components/CategoriesGrid";
import React from "react";
import type {SchoolWithUserDto} from "@/global/api/gen/ts/SchoolWithUserDto";
import {useTranslation} from "react-i18next";

interface Props {
    schoolInfo: SchoolWithUserDto
}

const titleSx: SxProps<Theme> = {
    width: 181,
    fontWeight: 500,
    color: '#000',
    fontSize: 13
};

const valueSx: SxProps<Theme> = {
    fontSize: 13
};

export function SchoolPageInfoSection({schoolInfo}: Props) {
    const {t} = useTranslation();

    return (
        <Stack
            flexDirection={'column'}
            sx={{
                mt: '17px',
                p: '32px',
                background: '#fff',
                width: 'fit-content',
                borderRadius: '16px'
            }}
            gap={'32px'}
        >
            <Stack
                flexDirection={'row'}
                alignItems={'center'}
            >
                <Avatar
                    sx={{
                        color: '#1E9AF3',
                        bgcolor: '#f5f5f5',
                        mr: 2,
                        width: 50,
                        height: 50,
                        border: '1px solid #1E9AF3'
                    }}
                >
                    <DirectionsCar fontSize="medium"/>
                </Avatar>
                <Typography variant={'h4'}>
                    {schoolInfo.title}
                </Typography>
            </Stack>
            <Grid
                className={'schools-info-grid'}
            >
                <section className={'school item-1'}>
                    <Typography className={'info-header'}>
                        <b>
                            {t('school_page.school_data.title')}
                        </b>
                    </Typography>

                    <Stack gap={'8px'}>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.school_data.bin_iin')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo?.bin}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.school_data.address')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo.region ? `${schoolInfo.region}, ` : ''}
                                {schoolInfo.locality ? `${schoolInfo.locality}, ` : ''}
                                {schoolInfo?.address}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.school_data.fio')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo?.director}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.school_data.director_iin')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo?.director_iin}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.school_data.director_qualification_certificate_number')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo?.director_qualification_certificate_number ?? 'Н/Д'}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.school_data.director_qualification_certificate_issuer')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo?.director_qualification_certificate_issuer ?? 'Н/Д'}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.school_data.director_qualification_certificate_issue_date')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo?.director_qualification_certificate_issue_date ? formatDate(schoolInfo?.director_qualification_certificate_issue_date) : 'Н/Д'}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.school_data.director_qualification_certificate_end_date')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo?.director_qualification_certificate_end_date ? formatDate(schoolInfo?.director_qualification_certificate_end_date) : 'Н/Д'}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.school_data.main_phone')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo?.phone1}
                            </Typography>
                        </Stack>
                    </Stack>
                </section>

                <CategoriesGrid
                    className={'item-2'}
                    categories={schoolInfo.categories ?? []}
                />

                <section className={'user item-3'}>
                    <Typography className={'info-header'}>
                        <b>
                            {t('school_page.user_data.title')}
                        </b>
                    </Typography>

                    <Stack gap={'8px'}>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.user_data.title')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo.manager?.iin}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.user_data.fio')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo.manager?.last_name ? `${schoolInfo.manager.last_name} ` : ''}
                                {schoolInfo.manager?.first_name}
                                {' '}
                                {schoolInfo.manager?.patronymic}
                            </Typography>
                        </Stack>
                        <Stack
                            className={'info-row'}
                            flexDirection="row"
                        >
                            <Typography sx={titleSx}>
                                <b>
                                    {t('school_page.user_data.mail')}
                                    :
                                </b>
                            </Typography>
                            <Typography sx={valueSx}>
                                {schoolInfo.manager?.email}
                            </Typography>
                        </Stack>
                    </Stack>
                </section>

                <SubCategoriesGrid
                    className={'item-4'}
                    categories={schoolInfo?.categories ?? []}
                />
            </Grid>
        </Stack>
    );
}