import {type ChangeEvent, useState} from 'react';
import {Dialog, DialogContent, DialogTitle, Stack, TextField, Typography} from '@mui/material';
import {toast} from 'react-toastify';
import LoadingButton from '../../../global/components/LoadingButton';
import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {apiDeleteStudent} from "@/modules/students/services/student.service";
import type {StudentDeleteDto} from "@/global/api/gen/ts/StudentDeleteDto";

interface ModalProps {
    student: StudentDto;
}

function DeleteStudentDialogModal({open, close, student}: ModalSystemProps<ModalProps>) {

    const {mutate: requestDelete, isPending} = apiDeleteStudent();

    const [studentInfo, setStudentInfo] = useState<StudentDeleteDto>({
        student_id: student.id,
        reason: '',
    });

    const handleDelete = () => {
        if (!studentInfo.reason) {
            toast.error("Необходимо вписать причину удаления курсанта из учебной группы");
            return;
        } else {
            requestDelete(studentInfo, {
                onSuccess: () => {
                    toast.success('Курсант успешно удален из учебной группы. Обновите страницу группы для обновления информации.');
                }
            });
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStudentInfo((prev) => {
            return {
                ...prev,
                reason: e.target.value,
            };
        });
    };

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={() => {
                if (isPending) {
                    return;
                }
                close(5);
            }}
        >
            <DialogTitle>
                Подтверждение удаления курсанта #
                {student.id}
                {' '}
                {student.last_name}
                {' '}
                {student.first_name}
            </DialogTitle>
            <DialogContent>
                <Stack
                    flexDirection={'column'}
                    gap={'10px'}
                >
                    <Typography>
                        Пожалуйста, подтвердите удаление курсанта или отмените действие в данном окне.
                    </Typography>
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            margin: '15px 0'
                        }}
                    >
                        <TextField
                            name={'last_name'}
                            value={student.last_name}
                            label={'Фамилия'}
                            disabled={true}
                        />
                        <TextField
                            name={'first_name'}
                            value={student.first_name}
                            label={'Имя'}
                            disabled={true}
                        />
                        <TextField
                            name={'patronymic'}
                            value={student.patronymic}
                            label={'Отчество'}
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            name={'iin'}
                            value={student.iin}
                            label={'ИИН'}
                            disabled={true}
                        />
                        <TextField
                            name={'reason'}
                            value={studentInfo.reason}
                            label={'Причина удаления студента'}
                            onChange={handleChange}
                        />

                        <Stack
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                            gap={'16px'}
                            sx={{
                                mt: '16px'
                            }}
                        >
                            <LoadingButton
                                variant={'outlined'}
                                color={'info'}
                                loading={isPending}
                                onClick={() => close(5)}
                            >
                                Отмена
                            </LoadingButton>

                            <LoadingButton
                                variant={'contained'}
                                onClick={handleDelete}
                                color={'error'}
                                loading={isPending}
                            >
                                Подтвердить удаление
                            </LoadingButton>
                        </Stack>
                    </form>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default DeleteStudentDialogModal;