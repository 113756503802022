import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerGetStatusFromPDDPaymentsModuleMutationRequest,
  TransactionsControllerGetStatusFromPDDPaymentsModuleMutationResponse,
} from '../../ts/TransactionsDTO/TransactionsControllerGetStatusFromPDDPaymentsModule'

export function getTransactionsControllerGetStatusFromPDDPaymentsModuleUrl() {
  return `/transactions/webhook` as const
}

/**
 * @summary Получение статуса платежа PaymentsService
 * {@link /transactions/webhook}
 */
export async function transactionsControllerGetStatusFromPDDPaymentsModule(
  data: TransactionsControllerGetStatusFromPDDPaymentsModuleMutationRequest,
  config: Partial<RequestConfig<TransactionsControllerGetStatusFromPDDPaymentsModuleMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    TransactionsControllerGetStatusFromPDDPaymentsModuleMutationResponse,
    ResponseErrorConfig<Error>,
    TransactionsControllerGetStatusFromPDDPaymentsModuleMutationRequest
  >({ method: 'POST', url: getTransactionsControllerGetStatusFromPDDPaymentsModuleUrl().toString(), data, ...requestConfig })
  return res
}