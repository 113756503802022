import type {TextFieldProps} from "@mui/material/TextField/TextField";
import {useState} from "react";
import {TextField} from "@mui/material";

type Props =  {
    displayError?:boolean,
    onChange: (email: string, isValid:boolean) => void;
} & Omit<TextFieldProps, 'onChange'>

export function validateEmail(email: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
}

export function EmailInput({onChange, defaultValue, displayError, ...props}: Props) {
    const [email, setEmail] = useState<string>(defaultValue ? defaultValue.toString() : '');
    const [error, setError] = useState<boolean | null>(null);
    const [helperText, setHelperText] = useState<string | null>(null);

    const validate = (rawString: string, displayError: boolean) => {
        const parsedPhone = validateEmail(rawString);

        if (!parsedPhone) {
            onChange(rawString, false);

            if (rawString.length <= 1) {
                setError(false);
                setHelperText(null);
            } else if (displayError) {
                setError(true);
                setHelperText('Некорректный Email');
            }

            return;
        }
        onChange(rawString, true);

        setError(null);
        setHelperText(null);
    };

    const handleChange = (values: string) => {
        validate(values, displayError ?? true);
        setEmail(values);
    };

    return (
        <TextField
            variant={'outlined'}
            sx={{
                '& .MuiTextField-root': {
                    marginTop: 2
                }
            }}
            {...props}
            error={!!error}
            helperText={error ? helperText : ''}
            value={email}
            onChange={(e) => handleChange(e.target.value)}
        />
    );
}
