import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Checkbox,
	Container,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	Paper,
	Stack,
	type SxProps,
	TextField,
	type Theme,
	Typography
} from '@mui/material';
import React, { type ChangeEvent, useEffect, useState } from 'react';
import { type Accept } from 'react-dropzone';
import { Add, Delete, Save, Visibility } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import QuestionCategorySelect from '../components/QuestionsCategorySelect';
import { MEDIA_URL } from '@/global/actions/apiVariables';
import DropZone from '../../../global/components/DropZone';
import type { TestQuestionAnswerDto } from '../../actions/testQuestionAnswer.type';
import FlexHeader from '../../../global/components/FlexHeader';
import { type TestQuestionUpdateDto } from '../../actions/testQuestion.type';
import LoadingButton from '../../../global/components/LoadingButton';
import { createQuestion, deleteQuestionById, getQuestionById, updateQuestion } from '../../actions/api';
import useLoadingHook from '../../../global/hooks/UseLoadingHook';
import { useSnackbarStore } from '@/global/store/SnackbarStore';
import PageHeader from '../../../global/components/PageHeader';
import { wrapperSx } from '@/global/helpers/globalstyles';

const fileCardSx: SxProps<Theme> = {
    borderRadius: 4,
    transitionProperty: 'box-shadow, transform',
    boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.50) 0px 2px 12px -4px',
    width: '100%',
};

const paperSx: SxProps<Theme> = {
    borderRadius: 4,
    boxShadow: 'rgba(145, 158, 146, 0.4) 0px 0px 2px 0px, rgba(145, 158, 167, 0.60) 0px 2px 12px -4px',
    minHeight: 100,
    background: '#ffffff',
    padding: 2
};

interface FileCardProps {
    fileId: string,
    fileSize?: number,
    type?: 'img' | 'video'
    onDelete: () => void,
    sx?: SxProps<Theme>,
    deletable?: boolean;
}

export function FileCard({fileId, fileSize, onDelete, type, deletable = true}: FileCardProps) {
    //const fileName = fileId.includes('_image') ? fileId + '.jpg' : fileId + '.mp4';
    const formattedSize = fileSize ? fileSize > 1024 ? (fileSize / 1024).toFixed(2) + ' МБ' : fileSize + ' КБ' : -1;

    return (
        <Card sx={{...fileCardSx}}>
            {type === 'video' ? (
                <CardMedia
                    component="video"
                    controls
                    src={`${MEDIA_URL}/${fileId}`}
                />
) : (
    <CardMedia
        component="img"
        image={`${MEDIA_URL}/${fileId}`}
    />
)}
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                >
                    {fileId}
                </Typography>
                {fileSize && (
                <Typography
                    variant="body1"
                    color="text.secondary"
                >
                    Размер:
                    {formattedSize}
                </Typography>
)}
            </CardContent>
            {deletable && (
            <CardActions>
                <Button
                    size="small"
                    color="error"
                    onClick={onDelete}
                >
                    Удалить файл
                </Button>
            </CardActions>
)}
        </Card>
    );
}

interface Props {
    questionId?: number;
    uploadToken?: string;
}

export default function QuestionEditForm() {
    const params = useParams();
    const questionId = params.id;
    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);

    const [data, questionLoading, questionsError, fetchQuestion] = useLoadingHook(getQuestionById);

    useEffect(() => {
        if(questionId){
            fetchQuestion(questionId);
        }

    }, [questionId]);

    const [sync, setSync] = useState<boolean>(!questionId);
    const [changed, setChanged] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(data?.title || '');
    const [categoryId, setCategoryId] = useState<number>(data?.category_id ? 0 : -1);

    const [ruQuestion, setRuQuestion] = useState<string>(data?.ru_question || '');
    const [ruExplanation, setRuExplanation] = useState<string>(data?.ru_explanation || '');

    const [kzQuestion, setKzQuestion] = useState<string>(data?.kz_question || '');
    const [kzExplanation, setKzExplanation] = useState<string>(data?.kz_explanation || '');

    const [enQuestion, setEnQuestion] = useState<string>(data?.en_question || '');
    const [enExplanation, setEnExplanation] = useState<string>(data?.en_explanation || '');

    const [answers, setAnswers] = useState<TestQuestionAnswerDto[]>(data?.answers || []);

    const [taskFile, setTaskFile] = useState<{ id: string | null, size: number | null }>({
        id: data?.task_file_id || null,
        size: data?.task_file_size || null
    });
    const [answerFile, setAnswerFile] = useState<{ id: string | null, size: number | null }>({
        id: data?.answer_file_id || null,
        size: data?.answer_file_size || null
    });
    const [explanationFile, setExplanationFile] = useState<{ id: string | null, size: number | null }>({
        id: data?.explanation_file_id || null,
        size: data?.explanation_file_size || null
    });

    const [createLoading, setCreateLoading] = useState<boolean>(false);

    useEffect(() => {
        if (data && !sync) {
            setTitle(data?.title || '');
            setCategoryId(data?.category_id || 0);
            setTaskFile({id: data?.task_file_id || null, size: data?.task_file_size || null});
            setAnswerFile({id: data?.answer_file_id || null, size: data?.answer_file_size || null});
            setExplanationFile({id: data?.explanation_file_id || null, size: data?.explanation_file_size || null});
            setRuQuestion(data?.ru_question || '');
            setRuExplanation(data?.ru_explanation || '');
            setKzQuestion(data?.kz_question || '');
            setKzExplanation(data?.kz_explanation || '');
            setEnQuestion(data?.en_question || '');
            setEnExplanation(data?.en_explanation || '');
            setAnswers(data?.answers || []);
            setSync(true);
        }
    }, [data]);

    useEffect(() => {
        return () => {
            snackbar.closeSnackbar();
        };
    }, []);

    const save = () => {
        if (!title) return snackbar.errorMessage('Вы не указали название вопроса');
        if (!ruQuestion || !ruExplanation || !enQuestion || !enExplanation || !kzQuestion || !kzExplanation) {
            return snackbar.errorMessage('Отсутствуют переводы на одном или нескольких языках. Проверьте все поля');
        }

        if (!answers?.length) return snackbar.errorMessage('Вы не указали варианты ответов');
        let isExistsCorrectAnswer = false;
        let isFullyTranslated = true;
        answers.map(answer => {
            if (answer.is_correct) isExistsCorrectAnswer = true;
            if (!answer.kz_text || !answer.ru_text || !answer.en_text) isFullyTranslated = false;
        });

        if (!isExistsCorrectAnswer) return snackbar.errorMessage('Вы не указали, какой вариант ответа является правильным');
        if (!isFullyTranslated) return snackbar.errorMessage('Отсутствуют переводы у вариантов ответов');

        const data: TestQuestionUpdateDto = {
            title, category_id: categoryId,
            task_file_id: taskFile?.id || null, task_file_size: taskFile?.size || null,
            answer_file_id: answerFile?.id || null, answer_file_size: answerFile?.size || null,
            explanation_file_id: explanationFile?.id || null, explanation_file_size: explanationFile?.size || null,
            ru_question: ruQuestion || null, ru_explanation: ruExplanation || null,
            en_question: enQuestion || null, en_explanation: enExplanation || null,
            kz_question: kzQuestion || null, kz_explanation: kzExplanation || null,
            answers: answers
        };

        if (questionId) {

            updateQuestion(questionId, data)
                .then(() => {
                    setChanged(false);
                    snackbar.successMessage('Вопрос успешно сохранён!');
                })
                .catch((e) => {
                    return snackbar.errorMessage(e.response.data.message);
                });

        } else {
            setCreateLoading(true);
            createQuestion(data)
                .then((r) => {
                    setChanged(false);
                    snackbar.closeSnackbar();
                    snackbar.successMessage('Вопрос успешно создан!');
                    navigate(`/questions/` + r.data.id);
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                })
                .finally(() => {
                        setCreateLoading(false);
                    });
                }
    };

    const deleteQuestion = () => {
                if (!data?.id) return snackbar.errorMessage('Не удалось получить ID вопроса');
                setCreateLoading(true);
                deleteQuestionById(data.id)
                    .then((r) => {
                        setChanged(false);
                        snackbar.closeSnackbar();
                        snackbar.successMessage('Вопрос успешно удалён');
                    })
                    .catch((err) => {
                        snackbar.errorMessage(err.response.data.message);
                    })
                    .finally(() => {
                        setCreateLoading(false);
                    });
    };

    const onFocus = () => {
        if (!changed) {
            setChanged(true);
            snackbar.showMessage({
                message: 'Осторожно, вы не сохранили изменения!',
                severity: 'error'
            });
        }
    };

    const getLastId = (arr: { id: number }[]) => {
        const found = arr.sort((a, b) => a.id < b.id ? 1 : a.id === b.id ? 0 : -1)[0];
        if (found) return found.id;
        else return 0;
    };

    const addAnswer = () => {
        const nextId = getLastId(answers) + 1;
        setAnswers([
            ...answers,
            {id: nextId, ru_text: '', kz_text: '', en_text: '', az_text: '', is_correct: false}
        ]);
        onFocus();
    };

    const deleteAnswer = (answerId: number) => {
        const updatedAnswers = answers.filter((answer) => answer.id !== answerId);
        setAnswers(updatedAnswers);
        onFocus();
    };

    const handleAnswerCheck = (answerId: number) => {
        const updatedAnswers = answers.map((answer) => {
            if (answer.id === answerId) return {...answer, is_correct: true};
            else return {...answer, is_correct: false};
        });

        setAnswers(updatedAnswers);
        onFocus();
    };

    const handleAnswerChange = (answerId: number, key: keyof TestQuestionAnswerDto, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        console.log('answers', answers);
        const updatedAnswers = answers.map((answer) => {
            if (answer.id === answerId) return {...answer, [key]: e.currentTarget.value};
            else return answer;
        });
        console.log('newAnswers', updatedAnswers);
        setAnswers(updatedAnswers);
        onFocus();
    };

    const Actions = (questionId ? (
        <Stack
            flexDirection="row"
            justifyContent="flex-end"
        >
            <LoadingButton
                startIcon={<Visibility/>}
                onClick={() => navigate(`/questions/demo/${questionId}`)}
                disabled={changed}
            >
                Предпросмотр
            </LoadingButton>
            <LoadingButton
                startIcon={<Save/>}
                variant="contained"
                loading={createLoading}
                onClick={save}
            >
                Сохранить
            </LoadingButton>
        </Stack>
)
        : (
            <Stack
                flexDirection="row"
                justifyContent="flex-end"
            >
                <LoadingButton
                    startIcon={<Save/>}
                    variant="contained"
                    loading={createLoading}
                    onClick={save}
                >
                    Создать
                </LoadingButton>
            </Stack>
));

    const acceptFileTypes: Accept = {'video/mp4': ['.mp4']};

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Box sx={{...wrapperSx, pb: 3, mt: 3}}>
                <Container
                    maxWidth="lg"
                    sx={{mt: 3}}
                >
                    <Box sx={{mb: 10}}>
                        <FlexHeader sx={{mb: 3}}>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Typography variant="h4">
                                    {questionId ? `Вопрос #${data?.id}` : 'Создание вопроса'}
                                </Typography>
                            </Stack>
                            {Actions}
                        </FlexHeader>
                        <QuestionCategorySelect
                            categoryId={categoryId}
                            onChange={(newValue) => {
                                setCategoryId(newValue);
                                onFocus();
                            }}
                        />
                        <TextField
                            label="Название"
                            fullWidth
                            sx={{mt: 3, mb: 3}}
                            onFocus={onFocus}
                            helperText="Это название видно только администраторам. Оно нужно для удобства поиска по вопросам"
                            value={title}
                            onChange={(e) => setTitle(e.currentTarget.value)}
                        />
                        <Grid
                            container
                            spacing={3}
                            sx={{mb: 4}}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{mb: 2}}
                                >
                                    Файл (вопрос)
                                </Typography>
                                {taskFile?.id ? (
                                    <FileCard
                                        fileId={taskFile.id}
                                        fileSize={taskFile.size || 0}
                                        onDelete={() => {
                                                      setTaskFile({id: null, size: null});
                                                      onFocus();
                                                  }}
                                    />
                                      )
                                        : (
                                            <DropZone
                                                sizeLimitMb={50}
                                                accept={acceptFileTypes}
                                                description="(Формат: mp4)"
                                                onUpload={(data) => {
                                                        setTaskFile({id: data.id, size: data.fileSize});
                                                        onFocus();
                                                    }}
                                            />
)
                                    }
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{mb: 2}}
                                >
                                    Файл (ответ)
                                </Typography>
                                {answerFile?.id ? (
                                    <FileCard
                                        fileId={answerFile.id}
                                        fileSize={answerFile.size || 0}
                                        onDelete={() => {
                                                      setAnswerFile({id: null, size: null});
                                                      onFocus();
                                                  }}
                                    />
                                      )
                                        : (
                                            <DropZone
                                                sizeLimitMb={50}
                                                accept={acceptFileTypes}
                                                description="(Формат: mp4)"
                                                onUpload={(data) => {
                                                        setAnswerFile({id: data.id, size: data.fileSize});
                                                        onFocus();
                                                    }}
                                            />
)
                                    }
                                <Typography
                                    variant="h5"
                                    sx={{mt: 2, mb: 2}}
                                >
                                    Видео объяснение
                                </Typography>
                                {explanationFile?.id ? (
                                    <FileCard
                                        fileId={explanationFile.id}
                                        fileSize={explanationFile.size || 0}
                                        onDelete={() => {
                                                      setExplanationFile({id: null, size: null});
                                                      onFocus();
                                                  }}
                                    />
                                      )
                                        : (
                                            <DropZone
                                                sizeLimitMb={50}
                                                accept={acceptFileTypes}
                                                description="(Формат: mp4)"
                                                onUpload={(data) => {
                                                        setExplanationFile({id: data.id, size: data.fileSize});
                                                        onFocus();
                                                    }}
                                            />
)
                                    }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            sx={{mb: 3}}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={4}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{mb: 1}}
                                >
                                    Перевод на казахский
                                </Typography>
                                <TextField
                                    label="Вопрос"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    onFocus={onFocus}
                                    sx={{mb: 2}}
                                    value={kzQuestion}
                                    onChange={(e) => setKzQuestion(e.currentTarget.value)}
                                />
                                <TextField
                                    label="Пояснение"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    onFocus={onFocus}
                                    sx={{mb: 2}}
                                    value={kzExplanation}
                                    onChange={(e) => setKzExplanation(e.currentTarget.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={4}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{mb: 1}}
                                >
                                    Перевод на русский
                                </Typography>
                                <TextField
                                    label="Вопрос"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    onFocus={onFocus}
                                    sx={{mb: 2}}
                                    value={ruQuestion}
                                    onChange={(e) => setRuQuestion(e.currentTarget.value)}
                                />
                                <TextField
                                    label="Пояснение"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    onFocus={onFocus}
                                    value={ruExplanation}
                                    onChange={(e) => setRuExplanation(e.currentTarget.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={4}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{mb: 1}}
                                >
                                    Перевод на английский
                                </Typography>
                                <TextField
                                    label="Вопрос"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    onFocus={onFocus}
                                    sx={{mb: 2}}
                                    value={enQuestion}
                                    onChange={(e) => setEnQuestion(e.currentTarget.value)}
                                />
                                <TextField
                                    label="Пояснение"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    onFocus={onFocus}
                                    value={enExplanation}
                                    onChange={(e) => setEnExplanation(e.currentTarget.value)}
                                />
                            </Grid>
                        </Grid>
                        <FlexHeader>
                            <Typography variant="h4">
                                Варианты ответа
                            </Typography>
                            <Button
                                startIcon={<Add/>}
                                onClick={() => addAnswer()}
                            >
                                Добавить вариант
                            </Button>
                        </FlexHeader>
                        <Grid
                            container
                            spacing={3}
                            sx={{mb: 10}}
                        >
                            {answers.sort((a, b) => a.id > b.id ? 1 : a.id === b.id ? 0 : -1)
                                    .map((answer, i) => (
                                        <Grid
                                            key={answer.id}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={4}
                                        >
                                            <Paper sx={paperSx}>
                                                <FlexHeader>
                                                    <Typography variant="h5">
                                                        Вариант #
                                                        {i + 1}
                                                    </Typography>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => deleteAnswer(answer.id)}
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                </FlexHeader>
                                                <TextField
                                                    label="Казахский"
                                                    fullWidth
                                                    multiline
                                                    variant="standard"
                                                    sx={{mb: 1}}
                                                    value={answer.kz_text}
                                                    onChange={(e) => handleAnswerChange(answer.id, 'kz_text', e)}
                                                />
                                                <TextField
                                                    label="Русский"
                                                    fullWidth
                                                    multiline
                                                    variant="standard"
                                                    sx={{mb: 1}}
                                                    value={answer.ru_text}
                                                    onChange={(e) => handleAnswerChange(answer.id, 'ru_text', e)}
                                                />
                                                <TextField
                                                    label="Английский"
                                                    fullWidth
                                                    multiline
                                                    variant="standard"
                                                    sx={{mb: 2}}
                                                    value={answer.en_text}
                                                    onChange={(e) => handleAnswerChange(answer.id, 'en_text', e)}
                                                />
                                                <FormGroup onClick={() => handleAnswerCheck(answer.id)}>
                                                    <FormControlLabel
                                                        control={<Checkbox/>}
                                                        label="Правильный ответ"
                                                        checked={answer.is_correct}
                                                    />
                                                </FormGroup>
                                            </Paper>
                                        </Grid>
                                      )
                                    )}
                        </Grid>
                        {questionId && (
                            <Stack
                                flexDirection="row"
                                justifyContent="flex-end"
                            >
                                <LoadingButton
                                    startIcon={<Delete/>}
                                    color="error"
                                    sx={{mr: 1}}
                                    loading={createLoading}
                                    onClick={deleteQuestion}
                                >
                                    Удалить вопрос
                                </LoadingButton>
                            </Stack>
)}
                    </Box>
                </Container>
                ;
            </Box>
        </Box>
    );
}