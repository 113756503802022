import React from "react";
import {Stack, Typography, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface TabType {
  order: number;
  text: string;
  type: string;
}

interface TabSliceProps {
  tabs: TabType[];
  onChange: (type: TabType) => void;
  currentTab: TabType;
  width?: number;
  activeColor?: string;
  reverseGamma?: boolean;
}

function TabSlice({tabs, onChange, currentTab, width = 220, activeColor = '#044EBD', reverseGamma = false}: TabSliceProps) {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  return (
      <Stack
          flexDirection={'row'}
          sx={{
        height: `${width + 8}`,
        borderRadius: '32px',
        background: reverseGamma ? '#fff' : 'rgb(255, 255, 255, 0.2)',
        position: 'relative',
        p: '4px'
      }}
      >
          <Stack
              sx={{
          width: `${width}px`,
          height: `${width}px`,
          position: 'absolute',
          background: activeColor,
          borderRadius: '50%',
          zIndex: 1,
          transition: 'left 0.2s ease',
          left: `${(currentTab.order * width) + 4}px`,
        }}
          />
          {tabs.map((tab) => {
        return (
            <Stack
                className={`tab ${currentTab.type === tab.type && 'tab-active'}`}
                sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: `${width}px`,
              height: `${width}px`,
              borderRadius: '100%',
              cursor: 'pointer',
              gap: '8px',
              zIndex: 2,
              userSelect: 'none',
            }}
                onClick={() => {
              onChange(tab);
            }}
                key={tab.type}
            >
                <Typography
                    fontSize={isMobile ? '11px' : '16px'}
                    color={currentTab.type === tab.type ? (reverseGamma ? '#fff' : '#1E9AF3') : (reverseGamma ? 'rgba(5,48,79,0.34)' : '#fff')}
              // color={currentTab.type === tab.type ? '#1E9AF3' : '#fff'}
                    fontWeight={currentTab.type === tab.type ? 'bolder' : 'normal'}
                >
                    {tab.text}
                </Typography>
            </Stack>
        );
      })}
      </Stack>
  );
}

export default TabSlice;
