import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { NewsControllerGetLastNewsQueryResponse } from '../../ts/NewsDTO/NewsControllerGetLastNews'

export function getNewsControllerGetLastNewsUrl() {
  return `/news/last` as const
}

/**
 * @summary Последние новости
 * {@link /news/last}
 */
export async function newsControllerGetLastNews(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<NewsControllerGetLastNewsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getNewsControllerGetLastNewsUrl().toString(),
    ...requestConfig,
  })
  return res
}