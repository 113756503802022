import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  BalanceControllerTopUpBalanceMutationRequest,
  BalanceControllerTopUpBalanceMutationResponse,
} from '../../ts/BalanceDTO/BalanceControllerTopUpBalance'

export function getBalanceControllerTopUpBalanceUrl() {
  return `/balance` as const
}

/**
 * @summary Установить баланс школы
 * {@link /balance}
 */
export async function balanceControllerTopUpBalance(
  data: BalanceControllerTopUpBalanceMutationRequest,
  config: Partial<RequestConfig<BalanceControllerTopUpBalanceMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<BalanceControllerTopUpBalanceMutationResponse, ResponseErrorConfig<Error>, BalanceControllerTopUpBalanceMutationRequest>({
    method: 'POST',
    url: getBalanceControllerTopUpBalanceUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}