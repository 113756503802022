import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CategoriesControllerChangeCategoriesBySchoolIdMutationRequest,
  CategoriesControllerChangeCategoriesBySchoolIdMutationResponse,
} from '../../ts/CategoriesDTO/CategoriesControllerChangeCategoriesBySchoolId'

export function getCategoriesControllerChangeCategoriesBySchoolIdUrl() {
  return `/categories/change` as const
}

/**
 * @summary Изменение категорий в школе
 * {@link /categories/change}
 */
export async function categoriesControllerChangeCategoriesBySchoolId(
  data: CategoriesControllerChangeCategoriesBySchoolIdMutationRequest,
  config: Partial<RequestConfig<CategoriesControllerChangeCategoriesBySchoolIdMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CategoriesControllerChangeCategoriesBySchoolIdMutationResponse,
    ResponseErrorConfig<Error>,
    CategoriesControllerChangeCategoriesBySchoolIdMutationRequest
  >({ method: 'POST', url: getCategoriesControllerChangeCategoriesBySchoolIdUrl().toString(), data, ...requestConfig })
  return res
}