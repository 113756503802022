import React, {useState} from 'react';
import {Box, Stack, TextField, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import StudentGroupCard from '../../Main/components/StudentGroupCard';
import {apiSearchStudentByIin} from "@/modules/students/services/student.service";
import {LoadingButton} from "@mui/lab";

function FeePage() {
    const {t} = useTranslation();

    const [iin, setIin] = useState<string>('');

    const {data: studentsByIIN, isLoading: studentsByIINLoading, refetch} = apiSearchStudentByIin(iin, {
        limit:100,
        offset:0
    }, {
        enabled:false
    });

    const handleIinChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIin(e.currentTarget.value);
    };

    const fetchStudent = () => {
        void refetch();
    };

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>

            <Box sx={wrapperSx}>
                <Stack
                    direction={'column'}
                    width={'100%'}
                    sx={{
                        mt: '38px',
                        p: '52px',
                        borderRadius: '24px',
                        background: '#F5F5F5',
                        gap: '16px'
                    }}
                >
                    <Typography
                        variant={'h6'}
                    >
                        {t('quick-fee-page.title')}
                    </Typography>

                    {/*<Tabs>*/}

                    {/*</Tabs>*/}

                    <Stack
                        flexDirection={'column'}
                        gap={2}
                    >
                        <TextField
                            label={t('quick-fee-page.iin')}
                            name={'iin'}
                            value={iin}
                            sx={{
                                width: 'fit-content',
                            }}
                            onChange={handleIinChange}
                        />

                        <LoadingButton
                            variant={'contained'}
                            sx={{
                                width: 'fit-content',
                                px: '50px'
                            }}
                            disabled={!iin}
                            loading={studentsByIINLoading}
                            onClick={fetchStudent}
                        >
                            {t('quick-fee-page.find')}
                        </LoadingButton>
                    </Stack>

                    {studentsByIIN && (
                        <Stack
                            flexDirection={'row'}
                            flexWrap={'wrap'}
                            gap={1}
                        >
                            {studentsByIIN.items.length > 0 && studentsByIIN.items.map((student) => {
                                return (
                                    <StudentGroupCard
                                        studentInfo={student}
                                        handleStartExam={() => {
                                        }}
                                        feePage={false}
                                        quickFeePage={true}
                                        attachmentModule={{is_enabled: true}}
                                        key={student.id}
                                    />
                                );
                            })}
                            {studentsByIIN.items.length === 0 && (
                                <Typography>
                                    По данному ИИН не найдено неоплаченных пошлин
                                </Typography>
                            )}
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Box>
    );
}

export default FeePage;
