import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  SchoolsControllerGetSchoolAttributesCountQueryResponse,
  SchoolsControllerGetSchoolAttributesCountPathParams,
} from '../../ts/schoolsDTO/SchoolsControllerGetSchoolAttributesCount'

export function getSchoolsControllerGetSchoolAttributesCountUrl({ id }: { id: SchoolsControllerGetSchoolAttributesCountPathParams['id'] }) {
  return `/schools/attributes/count/${id}` as const
}

/**
 * {@link /schools/attributes/count/:id}
 */
export async function schoolsControllerGetSchoolAttributesCount(
  { id }: { id: SchoolsControllerGetSchoolAttributesCountPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGetSchoolAttributesCountQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerGetSchoolAttributesCountUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}