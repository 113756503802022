import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { SchoolsControllerGenerateTempUsersQueryResponse } from '../../ts/schoolsDTO/SchoolsControllerGenerateTempUsers'

export function getSchoolsControllerGenerateTempUsersUrl() {
  return `/schools/temp/generate-users` as const
}

/**
 * {@link /schools/temp/generate-users}
 */
export async function schoolsControllerGenerateTempUsers(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGenerateTempUsersQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerGenerateTempUsersUrl().toString(),
    ...requestConfig,
  })
  return res
}