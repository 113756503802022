import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerGenerateCertificateMutationRequest,
  CertificateControllerGenerateCertificateMutationResponse,
} from '../../ts/CertificateDTO/CertificateControllerGenerateCertificate'

export function getCertificateControllerGenerateCertificateUrl() {
  return `/certificate/generate` as const
}

/**
 * @summary Генерация сертификата
 * {@link /certificate/generate}
 */
export async function certificateControllerGenerateCertificate(
  data: CertificateControllerGenerateCertificateMutationRequest,
  config: Partial<RequestConfig<CertificateControllerGenerateCertificateMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    CertificateControllerGenerateCertificateMutationResponse,
    ResponseErrorConfig<Error>,
    CertificateControllerGenerateCertificateMutationRequest
  >({ method: 'POST', url: getCertificateControllerGenerateCertificateUrl().toString(), data, ...requestConfig })
  return res
}