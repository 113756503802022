import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerGetActiveSchoolMastersQueryResponse,
  EmployeeControllerGetActiveSchoolMastersPathParams,
  EmployeeControllerGetActiveSchoolMastersQueryParams,
} from '../../ts/employeesDTO/EmployeeControllerGetActiveSchoolMasters'

export function getEmployeeControllerGetActiveSchoolMastersUrl({ id }: { id: EmployeeControllerGetActiveSchoolMastersPathParams['id'] }) {
  return `/employees/school/master/active/${id}` as const
}

/**
 * @summary Получение активных мастеров производственного обучения
 * {@link /employees/school/master/active/:id}
 */
export async function employeeControllerGetActiveSchoolMasters(
  { id }: { id: EmployeeControllerGetActiveSchoolMastersPathParams['id'] },
  params: EmployeeControllerGetActiveSchoolMastersQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerGetActiveSchoolMastersQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getEmployeeControllerGetActiveSchoolMastersUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}