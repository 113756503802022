export function PreviousIcon() {
    return (
        <svg
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="52"
                height="52"
                rx="16"
                fill="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9141 32L17.9998 26L23.9141 20L26.4998 20L21.8283 25L33.4141 25L33.4141 27L21.8283 27L26.4998 32L23.9141 32Z"
                fill="#05304F"
            />
        </svg>
    );
}