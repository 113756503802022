import React, { useMemo, useState } from 'react';
import { Avatar, Box } from '@mui/material';
import { type GridColDef } from '@mui/x-data-grid';
import { DirectionsCar } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { formatDate } from '@/global/helpers/text';
import { apiGetTransportListBySchool } from '@/modules/transports/services/transport.service';
import { ServerDataGrid } from '@/global/components/ServerDataGrid';
import type { TransportDto } from '@/global/api/gen/ts/TransportDto';

interface Props {
	id:number,
}

function TransportTable({id}:Props) {

    const {t} = useTranslation();

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25,
		page: 0
	});

	const {data, isLoading} = apiGetTransportListBySchool(id,{
		offset: paginationModel.page * paginationModel.pageSize, limit: paginationModel.pageSize
	});

	const columns: GridColDef<TransportDto>[] = useMemo(()=>[
        {field: 'empty', headerName: '', headerClassName: 'datagrid--header', maxWidth: 10, width: 10, sortable: false},
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {field: 'model', headerName: t('school_page.transport_page.transport_table.grid.model'), minWidth: 360, flex: 1, sortable: true,
            renderCell: ({row}) => {
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            <DirectionsCar />
                        </Avatar>
                        {row.brand} 
                        {row.model}
                    </React.Fragment>
                );
            }
        },
        {field: 'plate_number', headerName: t('school_page.transport_page.transport_table.grid.plate_number'), minWidth: 160},
        {field: 'category', headerName: t('school_page.transport_page.transport_table.grid.category'), minWidth: 160},
        {field: 'description', headerName: t('school_page.transport_page.transport_table.grid.description'), minWidth: 160},
        {field: 'created_at', headerName: t('school_page.transport_page.transport_table.grid.created'), minWidth: 200, valueFormatter: ({value}) => formatDate(value)},
    ], [t]);

    return (
        <Box sx={{mt: 2}}>
			<ServerDataGrid
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				rows={data?.items ?? []}
				// onRowClick={handleRowClick}
				columns={columns}
				loading={isLoading}
				rowCount={data?.total ?? 0}
				disableRowSelectionOnClick
				disableColumnMenu={true}
				disableColumnFilter={true}
			/>
        </Box>
    );
}

export default TransportTable;