import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegionControllerGetLocalitiesBySlugQueryResponse,
  RegionControllerGetLocalitiesBySlugPathParams,
  RegionControllerGetLocalitiesBySlugQueryParams,
} from '../../ts/RegionDTO/RegionControllerGetLocalitiesBySlug'

export function getRegionControllerGetLocalitiesBySlugUrl({ slug }: { slug: RegionControllerGetLocalitiesBySlugPathParams['slug'] }) {
  return `/region/slug/${slug}` as const
}

/**
 * @summary Получить locality пo slug
 * {@link /region/slug/:slug}
 */
export async function regionControllerGetLocalitiesBySlug(
  { slug }: { slug: RegionControllerGetLocalitiesBySlugPathParams['slug'] },
  params: RegionControllerGetLocalitiesBySlugQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegionControllerGetLocalitiesBySlugQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getRegionControllerGetLocalitiesBySlugUrl({ slug }).toString(),
    params,
    ...requestConfig,
  })
  return res
}