import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerGetSchoolStudentsWithGroupsQueryResponse,
  StudentsControllerGetSchoolStudentsWithGroupsPathParams,
  StudentsControllerGetSchoolStudentsWithGroupsQueryParams,
} from '../../ts/StudentsDTO/StudentsControllerGetSchoolStudentsWithGroups'

export function getStudentsControllerGetSchoolStudentsWithGroupsUrl({
  schoolId,
}: {
  schoolId: StudentsControllerGetSchoolStudentsWithGroupsPathParams['schoolId']
}) {
  return `/students/school/${schoolId}` as const
}

/**
 * @summary Получение студентов учебного центра
 * {@link /students/school/:schoolId}
 */
export async function studentsControllerGetSchoolStudentsWithGroups(
  { schoolId }: { schoolId: StudentsControllerGetSchoolStudentsWithGroupsPathParams['schoolId'] },
  params: StudentsControllerGetSchoolStudentsWithGroupsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerGetSchoolStudentsWithGroupsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerGetSchoolStudentsWithGroupsUrl({ schoolId }).toString(),
    params,
    ...requestConfig,
  })
  return res
}