import React from "react";
import {BackLink} from "@/global/components/BackLink";
import {Box} from "@mui/material";
import type {StudentInfoAisResponseDto} from "@/global/api/gen/ts/StudentInfoAisResponseDto";
import {StudentIntegrationCard} from "@/modules/students/components/StudentsByIin/StudentIntegrationCard";

interface Props {
    studentsByIIN: StudentInfoAisResponseDto,
    returnFirstStep: () => void;
}

export function StudentsByIinStepOpened({returnFirstStep, studentsByIIN}: Props) {
    return (
        <>
            <BackLink
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
                sx={{
                    alignSelf: 'flex-start'
                }}
            />
            <Box
                gap={2}
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(1, minmax(0, 1fr))',
                        md: 'repeat(2, minmax(0, 1fr))',
                        xl: 'repeat(3, minmax(0, 1fr))'
                    },
                }}
            >
                {(studentsByIIN?.students.length > 0 || studentsByIIN.students) && (
                    <>
                        {
                            studentsByIIN.students.map((student, index) => {
                                return (
                                    <StudentIntegrationCard
                                        studentInfo={student}
                                        key={index}
                                    />
                                );
                            })
                        }
                    </>
                )}
            </Box>
        </>
    );
}