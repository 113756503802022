import React, {useState} from 'react';
import {DataGrid, type GridColDef, type GridRowModel} from '@mui/x-data-grid';
import {Avatar, Box, Button, Chip, CircularProgress, Stack, Tooltip, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import {formatDateTime, getName} from '@/global/helpers/text';
import {FRONTEND_URL} from '@/global/actions/apiVariables';

import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import {GradesComponent} from "@/Group/components/GroupPage/StudentGrades";

interface ExamsGridProps {
    groupInfo: GroupDataDto;
}

function ExamsGrid({groupInfo}: ExamsGridProps) {

    const [loadingIds, setLoadingIds] = useState<number[]>([]);

    const columns: GridColDef<StudentDto>[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
            renderCell: (params) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name', headerName: 'ФИО', minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'iin',
            headerName: 'ИИН',
            width: 150,
            sortable: false,
        },
        {
            field: 'grades',
            headerName: 'Оценки',
            width: 500,
            sortable: false,
            renderCell: ({row}) => {
                return loadingIds.includes(row.id)
                    ? <CircularProgress/>
                    : (
                        <GradesComponent
                            schoolId={+(groupInfo.school_id)}
                            grades={row.grades}
                            addLoadingId={addLoadingId}
                            removeLoadingId={removeLoadingId}
                            row={row}
                        />
                    );
            }
        },
        {
            field: 'passed',
            headerName: 'Статус сдачи',
            width: 150,
            sortable: false,
            renderCell: ({row}) => {
                return row.passed ? (
                    <Chip
                        label={'Сдал'}
                        color={'primary'}
                    />
                ) : (
                    <Chip
                        label={'Не сдал'}
                        color={'error'}
                    />
                );
            }
        },
        {
            field: 'certification',
            headerName: 'Выдача сертификата',
            width: 250,
            sortable: false,
            renderCell: ({row}) => renderCertificate(row)
        },
        {
            field: 'certification_number',
            headerName: 'Номер сертификата',
            width: 250,
            sortable: false,
            renderCell: ({row}) => row.certificate_number ? `QZ ${row.certificate_number}` : 'Отсутствует'
        },
        {
            field: 'created_at',
            headerName: 'Дата прикрепления',
            width: 180,
            sortable: true,
            valueGetter: ({row}) => formatDateTime(row.created_at)
        },
    ];

    const addLoadingId = (rowId: number) => {
        setLoadingIds((prev) => {
            return [...prev, rowId];
        });
    };

    const removeLoadingId = (rowId: number) => {
        setLoadingIds((prev) => {
            return prev.filter((id) => {
                return id !== rowId;
            });
        });
    };

    const renderCertificate = (row: GridRowModel<StudentDto>) => {
        if (!row.passed) {
            return (
                <Typography style={{color: 'red'}}>
                    Необходима успешная сдача
                </Typography>
            );
        }

        if (!row.grades) {
            return (
                <Typography style={{color: 'red'}}>
                    Необходимо проставить оценки
                </Typography>
            );
        }

        if (row.passed) {
            return (
                <Stack
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap={1}
                >
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <Button
                            href={`${FRONTEND_URL}/certificate/pdf/${row.id}`}
                            variant={'contained'}
                            sx={{height: 'auto'}}
                            disabled={groupInfo.status !== 4}
                        >
                            Перейти к сертификату
                        </Button>

                        {groupInfo.status !== 4 && (
                            <Tooltip
                                title={'Для получения сертификатов необходимо закончить обучение группы'}
                                sx={{ml: 2}}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        )}
                    </Stack>
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                        <Button
                            href={`${FRONTEND_URL}/certificate/A4/pdf/${row.id}`}
                            variant={'contained'}
                            sx={{height: 'auto'}}
                            disabled={groupInfo.status !== 4}
                        >
                            Печать сертификата (А4)
                        </Button>

                        {groupInfo.status !== 4 && (
                            <Tooltip
                                title={'Для получения сертификатов необходимо закончить обучение группы'}
                                sx={{ml: 2}}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        )}
                    </Stack>
                </Stack>
            );
        }
    };

    if (groupInfo.status === 1 || groupInfo.status === 2) {
        return (
            <Box>
                <Typography variant={"h4"}>
                    Группа должна закончить обучение для возможности управления экзаменами
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <DataGrid
                columns={columns}
                rows={groupInfo.students ?? []}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30,
                        },
                    },
                }}
                pageSizeOptions={[30]}
                disableRowSelectionOnClick={true}
                getRowHeight={() => 'auto'}
                autoHeight={true}
            />
        </Box>
    );
}

export default ExamsGrid;