import React from 'react';
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { testButtonSx } from '@/Test/components/TestsList';

interface ActionButtonsProps {
    title: string,
    subtitle?: string,
    text?: string,
    backgroundImage?: string,
    onClick?: () => void,
    width?: string,
    height?: string,
}

function ActionButton({title, subtitle, backgroundImage, onClick, width, height, text} : ActionButtonsProps) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Paper
            variant="elevation"
            onClick={onClick ? () => {onClick();} : () => {}}
            sx={{
                ...testButtonSx,
                position: 'relative',
                width: width ? width : isMobile ? '100px' : '300px',
                height: height ? height : isMobile ? '100px' : '300px',

                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: backgroundImage ? backgroundImage : '' ,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.1,
                },

                '& > *': {
                    position: 'relative',
                },
            }}
        >
            <Box>
                <Stack gap={1}>
                    <Typography
                        fontWeight={600}
                        fontSize={isMobile ? 12 : 17}
                        sx={{mb: 0}}
                    >
                        {title}
                    </Typography>
                    {subtitle && (
                    <Typography
                        fontWeight={400}
                        fontSize={isMobile ? 10 : 17}
                        sx={{mt: 0}}
                    >
                        {subtitle}
                    </Typography>
)}
                    {text && (
                    <Typography
                        textAlign={'right'}
                        fontWeight={600}
                        fontSize={isMobile ? 14 : 20}
                        sx={{mt: 0}}
                    >
                        {text}
                    </Typography>
)}
                </Stack>
            </Box>
        </Paper>
    );
}

export default ActionButton;