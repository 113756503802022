import {useNavigate} from 'react-router-dom';
import {Button, Chip, Divider, Stack, Typography} from '@mui/material';
import React from 'react';

import {GroupStatusChip} from '@/GovPage/components/GroupStatusChip';
import {formatDate} from '@/global/helpers/text';
import type {StudentInfoType} from "@/global/api/gen/ts/StudentInfoType";

interface StudentGroupCardProps {
    studentInfo: StudentInfoType,
    handleStartExam: (studentId: number) => void,
    feePage?: boolean,
    quickFeePage?: boolean,
    integration?: boolean,
    attachmentModule?: {is_enabled:boolean}
}

function StudentGroupCard({
                              studentInfo,
                              handleStartExam,
                              feePage = false,
                              quickFeePage = false,
                              integration = false,
                              attachmentModule
                          }: StudentGroupCardProps) {

    const navigate = useNavigate();

    return (
        <Stack
            display={'flex'}
            flexDirection={'column'}
            sx={{
                width: '500px',
                height: 'auto',
                minHeight: '150px',
                borderRadius: '16px',
                backgroundColor: '#FFFFFF',
                py: '20px',
                px: '16px',
                gap: '16px'
            }}
            key={studentInfo.id}
        >
            <Stack
                flexDirection={'column'}
                sx={{
                    gap: '16px'
                }}
            >
                {integration && (
                    <Stack>
                        <Typography
                            fontSize={'14px'}
                        >
                            ФИО курсанта
                        </Typography>
                        <Typography
                            variant={'h6'}
                        >
                            {studentInfo.last_name}
                            {' '}
                            {studentInfo.first_name}
                            {' '}
                            {studentInfo.patronymic ?? ''}
                        </Typography>
                    </Stack>
                )}

                {integration && (
                    <Stack>
                        <Typography
                            fontSize={'14px'}
                        >
                            ИИН курсанта
                        </Typography>
                        <Typography
                            variant={'h6'}
                        >
                            {studentInfo.iin}
                        </Typography>
                    </Stack>
                )}

                <Stack>
                    <Typography
                        fontSize={'14px'}
                    >
                        Автошкола
                    </Typography>
                    <Typography
                        variant={'h6'}
                    >
                        {integration ? studentInfo.school_title : studentInfo.group?.school?.title}
                    </Typography>
                </Stack>

                {integration && (
                    <Stack>
                        <Typography
                            fontSize={'14px'}
                        >
                            Номер свидетельства
                        </Typography>
                        <Typography
                            variant={'h6'}
                        >
                            {studentInfo.certificate_number}
                        </Typography>
                    </Stack>
                )}

                {!integration && (
                    <Stack>
                        <Typography
                            fontSize={'14px'}
                        >
                            Адрес
                        </Typography>
                        <Typography
                            variant={'h6'}
                        >
                            {studentInfo.group?.school?.region}
                            ,
                            {studentInfo.group?.school?.locality}
                            ,
                            {studentInfo.group?.school?.address}
                        </Typography>
                    </Stack>
                )}
            </Stack>

            <Divider/>

            <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Stack>
                    <Typography
                        fontSize={'14px'}
                    >
                        Учебная группа
                    </Typography>
                    <Typography
                        variant={'h6'}
                    >
                        {integration ? studentInfo.group_title : studentInfo.group?.title}
                    </Typography>
                </Stack>

                <Stack>
                    <GroupStatusChip
                        status={(integration && studentInfo.group_status) ? studentInfo.group_status : studentInfo.group?.status}
                        variant="chip"
                    />
                </Stack>
            </Stack>

            <Stack
                flexDirection={'row'}
                justifyContent={'flex-start'}
                gap={'24px'}
            >
                <Stack>
                    <Typography
                        fontSize={'14px'}
                    >
                        Категория обучения
                    </Typography>
                    <Typography
                        variant={'h6'}
                    >
                        {integration ? studentInfo.category : studentInfo.group?.category_id}
                    </Typography>
                </Stack>
            </Stack>

            <Stack
                flexDirection={'row'}
                justifyContent={'flex-start'}
                gap={'24px'}
            >
                <Stack>
                    <Typography
                        fontSize={'14px'}
                    >
                        Дата начала обучения
                    </Typography>
                    <Typography
                        variant={'h6'}
                    >
                        {integration ? formatDate(studentInfo.start_date) : (studentInfo.group?.start_date?.toString() ?? 'Н/Д')}
                    </Typography>
                </Stack>

                <Stack>
                    <Typography
                        fontSize={'14px'}
                    >
                        Дата окончания обучения
                    </Typography>
                    <Typography
                        variant={'h6'}
                    >
                        {integration ? formatDate(studentInfo.end_date) : (studentInfo.group?.end_date?.toString() ?? 'Н/Д')}
                    </Typography>
                </Stack>
            </Stack>

            <Divider/>

            {<Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Stack>
                    <Typography
                        fontSize={'14px'}
                    >
                        Внутришкольные экзамены
                    </Typography>
                </Stack>
             </Stack>}

            {((studentInfo?.group?.status === 1 || studentInfo?.group?.status === 2) && (
                <Stack>
                    <Typography variant={'h6'}>
                        Отсутствуют данные по внутришкольным экзаменам, т.к. не закончено теоретическое и практическое
                        обучение.
                    </Typography>
                </Stack>
            ))}

            {((studentInfo?.group?.status === 3 || studentInfo?.group?.status === 4) && (
                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    <Stack>
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                Устройство и техническое обслуживание
                            </Typography>
                            <Typography
                                variant={'h6'}
                            >
                                {studentInfo.grades?.maintenance ? studentInfo.grades?.maintenance : 'Н/Д'}
                            </Typography>
                        </Stack>

                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                Правила и основы движения
                            </Typography>
                            <Typography
                                variant={'h6'}
                            >
                                {studentInfo.grades?.rules ? studentInfo.grades?.rules : 'Н/Д'}
                            </Typography>
                        </Stack>

                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                Практическое вождение
                            </Typography>
                            <Typography
                                variant={'h6'}
                            >
                                {studentInfo.grades?.practice ? studentInfo.grades?.practice : 'Н/Д'}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            ))}

            {integration && (
                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    <Stack>
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                Устройство и техническое обслуживание
                            </Typography>
                            <Typography
                                variant={'h6'}
                            >
                                {studentInfo.maintenance}
                            </Typography>
                        </Stack>

                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                Правила и основы движения
                            </Typography>
                            <Typography
                                variant={'h6'}
                            >
                                {studentInfo.rules}
                            </Typography>
                        </Stack>

                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                Практическое вождение
                            </Typography>
                            <Typography
                                variant={'h6'}
                            >
                                {studentInfo.practice}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            )}

            {(!studentInfo.grades?.maintenance || studentInfo.grades?.maintenance === 2)
                && (studentInfo?.group?.status === 3 || studentInfo?.group?.status === 4)
                && (
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                handleStartExam(studentInfo.id);
                            }}
                        >
                            Пройти экзамен
                        </Button>
                    </Stack>
                )}

            {studentInfo?.group?.status === 1 && !feePage && (
                <Divider/>
            )}

            {studentInfo?.group?.status === 1 && !feePage && !integration && !quickFeePage && !studentInfo?.user_id && (
                <Stack
                    flexDirection={'column'}
                    justifyContent={'flex-start'}
                    gap={'16px'}
                >
                    <Typography fontSize={'14px'}>
                        Пожалуйста, подтвердите своё обучение в данной автошколе
                    </Typography>
                    <Button
                        variant={'contained'}
                    >
                        Подтвердить
                    </Button>
                </Stack>
            )}

            {studentInfo?.group?.status === 1 && !feePage && !quickFeePage && !integration && attachmentModule?.is_enabled && studentInfo?.user_id && (
                <Stack
                    flexDirection={'column'}
                    justifyContent={'flex-start'}
                    gap={'16px'}
                >
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                    >
                        <Typography
                            fontSize={'14px'}
                        >
                            Статус пошлины:
                        </Typography>
                        <Typography
                            variant={'h6'}
                        >
                            {studentInfo.payment_access?.paid ? (
                                <Chip
                                    label={'Оплачено'}
                                    color={'success'}
                                />
                            ) : (
                                <Chip
                                    label={'Не оплачено'}
                                    color={'error'}
                                />
                            )}
                        </Typography>
                    </Stack>

                    {!studentInfo.payment_access && (
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                            alignItems={'center'}
                            gap={'16px'}
                        >
                            <Button
                                variant={'contained'}
                                onClick={() => {
                                    navigate(`/fee/${studentInfo?.id}`);
                                }}
                            >
                                Оплатить
                            </Button>
                        </Stack>
                    )}
                </Stack>
            )}

            {studentInfo?.group?.status === 1 && quickFeePage && !integration && (
                <Stack
                    flexDirection={'column'}
                    justifyContent={'flex-start'}
                    gap={'16px'}
                >
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                    >
                        <Typography
                            fontSize={'14px'}
                        >
                            Статус пошлины:
                        </Typography>
                        <Typography
                            variant={'h6'}
                        >
                            {studentInfo.payment_access?.paid ? (
                                <Chip
                                    label={'Оплачено'}
                                    color={'success'}
                                />
                            ) : (
                                <Chip
                                    label={'Не оплачено'}
                                    color={'error'}
                                />
                            )}
                        </Typography>
                    </Stack>

                    {!studentInfo.payment_access && (
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'flex-end'}
                            alignItems={'center'}
                            gap={'16px'}
                        >
                            <Button
                                variant={'contained'}
                                onClick={() => {
                                    navigate(`/quick-fee/${studentInfo?.id}`);
                                }}
                            >
                                Оплатить
                            </Button>
                        </Stack>
                    )}
                </Stack>
            )}

        </Stack>
    );
}

export default StudentGroupCard;