import {axiosInstance} from '@/global/api/api';
import {statisticQueryKey} from './statistic.queryKey';
import type {
    DateRange,
    DateRangeParams,
    RegisterParams,
    SchoolOverview,
    SchoolUsersParams,
    Statistic,
    StatisticOverview
} from '../types/statistic.type';
import {useCustomQuery} from '@/global/hooks/useCustomQuery';

export function apiStatisticOverview() {
    return useCustomQuery({
        queryKey: statisticQueryKey.all,
        queryFn: () => axiosInstance.get<StatisticOverview>('/statistic/main')
            .then((res) => res.data)
    });
}

export function apiStatisticUsers(params: DateRangeParams) {
    return useCustomQuery({
        queryKey: statisticQueryKey.usersRange(params),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/users', {params})
            .then((res) => res.data)
    });
}

export function apiStatisticSchools(params: DateRangeParams) {
    return useCustomQuery({
        queryKey: statisticQueryKey.schoolsRange(params),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/schools', {params})
            .then((res) => res.data)
    });
}

export function apiStatisticGroups(params: DateRangeParams) {
    return useCustomQuery({
        queryKey: statisticQueryKey.groupsRange(params),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/groups', {params})
            .then((res) => res.data)
    });
}

export function apiStatisticDemographicsAge() {
    return useCustomQuery({
        queryKey: statisticQueryKey.age(),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/demographics/age')
            .then((res) => res.data)
    });
}

export function apiStatisticDemographicsRegion() {
    return useCustomQuery({
        queryKey: statisticQueryKey.region(),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/demographics/region')
            .then((res) => res.data)
    });
}

export function apiStatisticDemographicsGender() {
    return useCustomQuery({
        queryKey: statisticQueryKey.gender(),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/demographics/gender')
            .then((res) => res.data)
    });
}

export function apiStatisticUsersByCategory() {
    return useCustomQuery({
        queryKey: statisticQueryKey.categoryUsers(),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/category/users')
            .then((res) => res.data)
    });
}

export function apiStatisticSchoolExams() {
    return useCustomQuery({
        queryKey: statisticQueryKey.schoolExams(),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/schools/exams')
            .then((res) => res.data)
    });
}

export function apiStatisticDemographicsAgeExams() {
    return useCustomQuery({
        queryKey: statisticQueryKey.ageExams(),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/demographics/age/passed')
            .then((res) => res.data)
    });
}

export function apiStatisticRegisterTime(params: RegisterParams) {
    return useCustomQuery({
        queryKey: statisticQueryKey.registerTime(params),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/users/register', {params})
            .then((res) => res.data)
    });
}

export function apiStatisticSchoolUsers(params: SchoolUsersParams) {
    return useCustomQuery({
        queryKey: statisticQueryKey.schoolUsers(params),
        queryFn: () => axiosInstance.get<{ items: Statistic[] }>('/statistic/schools/users', {params})
            .then((res) => res.data)
    });
}

export function apiSchoolsOverview(params: DateRange) {
    return useCustomQuery({
        queryKey: statisticQueryKey.schoolsOverview(params),
        queryFn: () => axiosInstance.get<{ items: SchoolOverview[] }>('/statistic/schools/overview', {params})
            .then((res) => res.data)
    });
}