import {useCustomQuery} from '@/global/hooks/useCustomQuery';
import type {AxiosError} from 'axios';
import {categoriesService} from '@/global/api/gen';
import {CategoryKeys} from '@/modules/categories/services/category.keys';
import type {
	CategoriesControllerGetSchoolCategoriesQueryParams
} from '@/global/api/gen/ts/CategoriesDTO/CategoriesControllerGetSchoolCategories';
import type {SchoolCategorySearchDto} from '@/global/api/gen/ts/SchoolCategorySearchDto';
import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import type {CategoriesChangeDto} from "@/global/api/gen/ts/CategoriesChangeDto";

export const apiGetCategoryList = (id: number, params: CategoriesControllerGetSchoolCategoriesQueryParams) => {
	return useCustomQuery<SchoolCategorySearchDto, AxiosError>({
		enabled: !!id && !isNaN(id),
		queryFn: () => categoriesService.categoriesControllerGetSchoolCategories({id}, params)
			.then((res) => res.data),
		queryKey: CategoryKeys.searchBySchoolWithParams(id, params),
	});
};

export const apiChangeSchoolCategories = () => {
	return useCustomMutation({
		mutationFn: (data:CategoriesChangeDto) => categoriesService.categoriesControllerChangeCategoriesBySchoolId(data)
			.then((res) => res.data),
		queryKey: (data) => CategoryKeys.searchBySchool(data.school_id),
	});
};

export const apiSearchSectionAndTopics = (id:string) => {
	return useCustomQuery({
		enabled: !!id,
		queryFn: () => categoriesService.categoriesControllerGetSectionsAndTopicsById({id})
			.then((res) => res.data),
		queryKey: CategoryKeys.sectionAndTopics(id),
	});
};