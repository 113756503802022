import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerSaveDocsInfoByStudentIdMutationRequest,
  StudentsControllerSaveDocsInfoByStudentIdMutationResponse,
  StudentsControllerSaveDocsInfoByStudentIdPathParams,
} from '../../ts/StudentsDTO/StudentsControllerSaveDocsInfoByStudentId'

export function getStudentsControllerSaveDocsInfoByStudentIdUrl({
  studentId,
}: {
  studentId: StudentsControllerSaveDocsInfoByStudentIdPathParams['studentId']
}) {
  return `/students/doc/save/${studentId}` as const
}

/**
 * @summary Сохранение документов студента
 * {@link /students/doc/save/:studentId}
 */
export async function studentsControllerSaveDocsInfoByStudentId(
  { studentId }: { studentId: StudentsControllerSaveDocsInfoByStudentIdPathParams['studentId'] },
  data: StudentsControllerSaveDocsInfoByStudentIdMutationRequest,
  config: Partial<RequestConfig<StudentsControllerSaveDocsInfoByStudentIdMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    StudentsControllerSaveDocsInfoByStudentIdMutationResponse,
    ResponseErrorConfig<Error>,
    StudentsControllerSaveDocsInfoByStudentIdMutationRequest
  >({ method: 'POST', url: getStudentsControllerSaveDocsInfoByStudentIdUrl({ studentId }).toString(), data, ...requestConfig })
  return res
}