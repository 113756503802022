import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  FeatureTogglesControllerChangeEnabledForFeatureToggleMutationRequest,
  FeatureTogglesControllerChangeEnabledForFeatureToggleMutationResponse,
} from '../../ts/Feature TogglesDTO/FeatureTogglesControllerChangeEnabledForFeatureToggle'

export function getFeatureTogglesControllerChangeEnabledForFeatureToggleUrl() {
  return `/feature-toggles/change-enabled` as const
}

/**
 * @summary Изменить состояние Feature Toggle
 * {@link /feature-toggles/change-enabled}
 */
export async function featureTogglesControllerChangeEnabledForFeatureToggle(
  data: FeatureTogglesControllerChangeEnabledForFeatureToggleMutationRequest,
  config: Partial<RequestConfig<FeatureTogglesControllerChangeEnabledForFeatureToggleMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    FeatureTogglesControllerChangeEnabledForFeatureToggleMutationResponse,
    ResponseErrorConfig<Error>,
    FeatureTogglesControllerChangeEnabledForFeatureToggleMutationRequest
  >({ method: 'POST', url: getFeatureTogglesControllerChangeEnabledForFeatureToggleUrl().toString(), data, ...requestConfig })
  return res
}