import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  BalanceControllerWithdrawBalanceMutationRequest,
  BalanceControllerWithdrawBalanceMutationResponse,
} from '../../ts/BalanceDTO/BalanceControllerWithdrawBalance'

export function getBalanceControllerWithdrawBalanceUrl() {
  return `/balance/update` as const
}

/**
 * @summary Изменить баланс школы
 * {@link /balance/update}
 */
export async function balanceControllerWithdrawBalance(
  data: BalanceControllerWithdrawBalanceMutationRequest,
  config: Partial<RequestConfig<BalanceControllerWithdrawBalanceMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<BalanceControllerWithdrawBalanceMutationResponse, ResponseErrorConfig<Error>, BalanceControllerWithdrawBalanceMutationRequest>({
    method: 'POST',
    url: getBalanceControllerWithdrawBalanceUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}