import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CategoriesControllerGetSectionsAndTopicsByIdQueryResponse,
  CategoriesControllerGetSectionsAndTopicsByIdPathParams,
} from '../../ts/CategoriesDTO/CategoriesControllerGetSectionsAndTopicsById'

export function getCategoriesControllerGetSectionsAndTopicsByIdUrl({ id }: { id: CategoriesControllerGetSectionsAndTopicsByIdPathParams['id'] }) {
  return `/categories/sections-topics/${id}` as const
}

/**
 * @summary Получение секций и тем в категории
 * {@link /categories/sections-topics/:id}
 */
export async function categoriesControllerGetSectionsAndTopicsById(
  { id }: { id: CategoriesControllerGetSectionsAndTopicsByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CategoriesControllerGetSectionsAndTopicsByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCategoriesControllerGetSectionsAndTopicsByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}