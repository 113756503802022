import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerCertificatePDFQueryResponse,
  CertificateControllerCertificatePDFPathParams,
} from '../../ts/CertificateDTO/CertificateControllerCertificatePDF'

export function getCertificateControllerCertificatePDFUrl({ studentId }: { studentId: CertificateControllerCertificatePDFPathParams['studentId'] }) {
  return `/certificate/pdf/${studentId}` as const
}

/**
 * {@link /certificate/pdf/:studentId}
 */
export async function certificateControllerCertificatePDF(
  { studentId }: { studentId: CertificateControllerCertificatePDFPathParams['studentId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerCertificatePDFQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerCertificatePDFUrl({ studentId }).toString(),
    ...requestConfig,
  })
  return res
}