import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerGenerateContractQueryResponse,
  CertificateControllerGenerateContractPathParams,
} from '../../ts/CertificateDTO/CertificateControllerGenerateContract'

export function getCertificateControllerGenerateContractUrl({ studentId }: { studentId: CertificateControllerGenerateContractPathParams['studentId'] }) {
  return `/certificate/doc/contract/${studentId}` as const
}

/**
 * {@link /certificate/doc/contract/:studentId}
 */
export async function certificateControllerGenerateContract(
  { studentId }: { studentId: CertificateControllerGenerateContractPathParams['studentId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerGenerateContractQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerGenerateContractUrl({ studentId }).toString(),
    ...requestConfig,
  })
  return res
}