import type {
    CertificateApplicationControllerSearchCertificateApplicationQueryParams
} from "@/global/api/gen/ts/certificate-applicationDTO/CertificateApplicationControllerSearchCertificateApplication";

export const CertificatesKeys = {
    all: ['certificates'] as const,
    lastCertificate: (iin: string) => [...CertificatesKeys.all, 'last', iin],
    check: (id: string) => [...CertificatesKeys.all, 'check', id] as const,
    certificatesApplication: ['certificate-application'] as const,
    certificateApplicationDetail: (id: number) => [`certificate-application`, `detail`, `${id}`] as const,
    certificateApplicationSearch: (params: CertificateApplicationControllerSearchCertificateApplicationQueryParams) => [`certificate-application`, `search`, params] as const,
};