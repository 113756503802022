import { useCustomQuery } from '@/global/hooks/useCustomQuery';
import { cabinetsService } from '@/global/api/gen';
import { useCustomMutation } from '@/global/hooks/useCustomMutation';
import type {
	CabinetsControllerGetSchoolCabinetsQueryParams
} from '@/global/api/gen/ts/CabinetsDTO/CabinetsControllerGetSchoolCabinets';
import { CabinetKeys } from '@/modules/cabinets/services/cabinet.keys';
import type { AddCabinetDtoFormData } from '@/global/api/gen/ts/AddCabinetDtoFormData';

export const apiGetCabinetsListBySchool = (schoolId:number , params: CabinetsControllerGetSchoolCabinetsQueryParams) => {
	return useCustomQuery({
		enabled: !!schoolId && !isNaN(schoolId),
		queryFn: () => cabinetsService.cabinetsControllerGetSchoolCabinets({schoolId}, params)
			.then((res) => res.data),
		queryKey: CabinetKeys.searchBySchool(schoolId, params)
	});
};

export const apiCreateCabinet = () => {
	return useCustomMutation({
		mutationFn: (data: AddCabinetDtoFormData) => cabinetsService.cabinetsControllerCreateCabinet(data)
			.then((res) => res.data),
		queryKey: (data) => CabinetKeys.bySchool(data.school_id)
	});
};