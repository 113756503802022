import { useCustomQuery } from '@/global/hooks/useCustomQuery';
import { notificationsService } from '@/global/api/gen';
import { useCustomMutation } from '@/global/hooks/useCustomMutation';
import { NotificationsKeys } from '@/modules/notifications/services/notifications.keys';
import type { UserNotificationCreateDto } from '@/global/api/gen/ts/UserNotificationCreateDto';
import type {
	NotificationsControllerGetNewUserNotificationsQueryParams
} from '@/global/api/gen/ts/NotificationsDTO/NotificationsControllerGetNewUserNotifications';
import type {
	NotificationsControllerGetAllUserNotificationsQueryParams
} from '@/global/api/gen/ts/NotificationsDTO/NotificationsControllerGetAllUserNotifications';
import type { NotificationCreateDto } from '@/global/api/gen/ts/NotificationCreateDto';

export const apiGetNewNotificationList = (id:number , params: NotificationsControllerGetNewUserNotificationsQueryParams) => {
	return useCustomQuery({
		enabled: !!id && !isNaN(id),
		queryFn: () => notificationsService.notificationsControllerGetNewUserNotifications({id}, params)
			.then((res) => res.data),
		queryKey: NotificationsKeys.userNew(id)
	});
};

export const apiGetAllNotificationList = (id:number , params: NotificationsControllerGetAllUserNotificationsQueryParams) => {
	return useCustomQuery({
		enabled: !!id && !isNaN(id),
		queryFn: () => notificationsService.notificationsControllerGetAllUserNotifications({id}, params)
			.then((res) => res.data),
		queryKey: NotificationsKeys.userAll(id)
	});
};

export const apiMarkNotification = () => {
	return useCustomMutation({
		mutationFn: (data: UserNotificationCreateDto) => notificationsService.notificationsControllerMarkAsRead(data)
			.then((res) => res.data),
		queryKey: (data) => NotificationsKeys.userNew(data.user_id)
	});
};

export const apiCreateNotification = () => {
	return useCustomMutation({
		mutationFn: (data: NotificationCreateDto) => notificationsService.notificationsControllerCreateAppointment(data)
			.then((res) => res.data),
		queryKey: NotificationsKeys.all
	});
};