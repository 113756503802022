import {Stack, Typography} from "@mui/material";
import {PhoneInput} from "@/global/components/PhoneInput";
import {EmailInput} from "@/global/components/EmailInput";
import React, {type Dispatch, type SetStateAction} from "react";
import {HeaderInfo} from "@/modules/students/components/StudentsByIin/HeaderInfo";
import {StudentIntegrationField} from "@/modules/students/components/StudentsByIin/StudentIntegrationField";
import type {StudentInfoType} from "@/global/api/gen/ts/StudentInfoType";
import {LoadingButton} from "@mui/lab";
import {BackLink} from "@/global/components/BackLink";
import {UserIcon} from "@/global/icons/UserIcon";

interface Props {
    handleStartTransaction: () => void,
    setPhone: Dispatch<SetStateAction<{ phone: string, isValid: boolean }>>,
    setEmail: Dispatch<SetStateAction<{ email: string, isValid: boolean }>>,
    studentInfo: StudentInfoType,
    isPending: boolean,
    returnFirstStep: () => void,
}

export function StudentsByIinStepStartTransaction({
                                                      returnFirstStep,
                                                      handleStartTransaction,
                                                      isPending,
                                                      studentInfo,
                                                      setPhone,
                                                      setEmail
                                                  }: Props) {
    return (
        <>
            <BackLink
                sx={{
                    alignSelf: 'flex-start',
                    fontSize: '18px',
                }}
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
            />
            <Stack
                gap={2}
                sx={{
                    width: {xs: '100%', md: '50%'},
                    backgroundColor: 'white',
                    padding: {xs: 1.5, md: 6,},
                    borderRadius: '26px',
                }}
            >
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={2}
                >
                    <UserIcon size={61}/>
                    <Typography fontSize={'34px'}>
                        {`${studentInfo.last_name} ${studentInfo.first_name[0]}. ${studentInfo.patronymic?.[0] || ''}.`}
                    </Typography>
                </Stack>
                <HeaderInfo>
                    Данные курсанта
                </HeaderInfo>
                <Stack
                    flexDirection={'column'}
                    gap={1}
                >
                    <StudentIntegrationField
                        value={(
                            <>
                                {studentInfo.last_name}
                                {' '}
                                {studentInfo.first_name}
                                {' '}
                                {studentInfo.patronymic ?? ''}
                            </>
                        )}
                        label={'ФИО:'}
                    />
                    <StudentIntegrationField
                        label={'ИИН курсанта'}
                        value={studentInfo.iin}
                    />
                    <StudentIntegrationField
                        label={'Автошкола'}
                        value={studentInfo.school_title}
                    />
                </Stack>
                <Typography
                    alignSelf={'center'}
                    textAlign={'center'}
                >
                    Получение данных о
                    <br/>
                    {' '}
                    процессе обучения
                </Typography>
                <Typography
                    alignSelf={'center'}
                    textAlign={'center'}
                    fontSize={'2.5rem'}
                    fontWeight={'lighter'}
                    sx={{
                        paddingBlock: 1,
                        paddingInline: 8,
                        borderRadius: 25,
                        background: 'linear-gradient(84.38deg, #CDD4F1 -5.65%, #FFFFFF 41.55%, #C7E7F4 61.13%, #EEF0FD 87.7%)',
                    }}
                >
                    1000 тг.
                </Typography>
                <Stack gap={2}>
                    <Stack gap={1}>
                        <Typography>
                            Электронная почта (куда придут данные):
                        </Typography>
                        <EmailInput
                            placeholder={'example@demo.kz'}
                            fullWidth
                            onChange={(email, isValid) => setEmail({email, isValid})}
                            sx={{
                                borderRadius: '10px',
                                '.MuiOutlinedInput-root': {
                                    backgroundColor: '#ebf0fb',
                                },
                                '.MuiOutlinedInput-input': {
                                    color: '#05304F',
                                    padding: '18px',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent'
                                }
                            }}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Typography>
                            Номер телефона:
                        </Typography>
                        <PhoneInput
                            placeholder={'+7 (777) 777-77-77'}
                            fullWidth
                            onChange={(phone, isValid) => setPhone({phone, isValid})}
                            sx={{
                                borderRadius: '10px',
                                '.MuiOutlinedInput-root': {
                                    backgroundColor: '#ebf0fb',
                                },
                                '.MuiOutlinedInput-input': {
                                    color: '#05304F',
                                    padding: '18px',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent'
                                }
                            }}
                        />
                    </Stack>
                </Stack>
                <LoadingButton
                    loading={isPending}
                    onClick={handleStartTransaction}
                    variant={'contained'}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'normal',
                        fontSize: '18px',
                        marginBottom: 5,
                    }}
                >
                    Перейти к оплате
                </LoadingButton>
            </Stack>
        </>
    );
}