import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerDeleteAppealByIdMutationResponse,
  RegisterAppealsControllerDeleteAppealByIdPathParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerDeleteAppealById'

export function getRegisterAppealsControllerDeleteAppealByIdUrl({ id }: { id: RegisterAppealsControllerDeleteAppealByIdPathParams['id'] }) {
  return `/register-appeals/delete/${id}` as const
}

/**
 * @summary Удалить заявку на регистрацию по id
 * {@link /register-appeals/delete/:id}
 */
export async function registerAppealsControllerDeleteAppealById(
  { id }: { id: RegisterAppealsControllerDeleteAppealByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerDeleteAppealByIdMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getRegisterAppealsControllerDeleteAppealByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}