import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { EmployeeControllerFireTeacherByIdMutationResponse } from '../../ts/employeesDTO/EmployeeControllerFireTeacherById'

export function getEmployeeControllerFireTeacherByIdUrl() {
  return `/employees/fire/teacher` as const
}

/**
 * @summary Увольнение учителя
 * {@link /employees/fire/teacher}
 */
export async function employeeControllerFireTeacherById(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerFireTeacherByIdMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getEmployeeControllerFireTeacherByIdUrl().toString(),
    ...requestConfig,
  })
  return res
}