import type { StudentInfoAisResponseDto } from '@/global/api/gen/ts/StudentInfoAisResponseDto';
import { Button, Stack, Typography } from '@mui/material';
import { StudentIntegrationCard } from '@/modules/students/components/StudentsByIin/StudentIntegrationCard';
import { BackLink } from '@/global/components/BackLink';

interface Props {
    iin: string;
    data: StudentInfoAisResponseDto | undefined;
    returnToInput: () => void;
}

export function IinDataForm({ iin, data, returnToInput }: Props) {

    if (!data || !data?.students?.length) {
        return (
            <Stack
                flexDirection={'column'}
                alignItems={'center'}
                sx={{
                    mt: 8
                }}
            >
                <Typography
                    sx={{
                        mb: 4
                    }}
                >
                    Нет данных по ИИН:
                    {' '}
                    {iin}
                </Typography>
                <Button
                    onClick={returnToInput}
                    variant={'contained'}
                >
                    Вернуться назад
                </Button>
            </Stack>
        );
    }

    return (
        <Stack
            flexDirection="column"
            alignItems="center"
        >
            <BackLink
                onClick={returnToInput}
                sx={{
                    alignSelf: 'flex-start',
                    ml: {
                        xs: 1,
                        md: 4
                    }
                }}
            />
            <Stack
                flexDirection="row"
                flexWrap="wrap"
                gap={2}
                sx={{
                    mx: 4,
                    mt: 4
                }}
            >
                {data.students.map((s, i) => {
                    return (
                        <Stack
                            sx={{
                                flex: {
                                    xs: '1 1 100%',
                                    lg: '1 1 40%'
                                }
                            }}
                            key={i}
                        >
                            <StudentIntegrationCard
                                studentInfo={s}
                            />
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
}