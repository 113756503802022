import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputBase,
    Stack,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {ExpandMoreOutlined} from '@mui/icons-material';

import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {apiGroupChangeTitle} from "@/modules/groups/services/group.service";
import type {GroupChangeTitleDto} from "@/global/api/gen/ts/GroupChangeTitleDto";

interface AdminChangeTitleProps {
    groupInfo: GroupDataDto;
}

function AdminChangeTitle({groupInfo}: AdminChangeTitleProps) {

    const [dto, setDto] = useState<GroupChangeTitleDto>({
        group_id: 500000,
        title: ''
    });

    const {mutate, isPending: loading} = apiGroupChangeTitle();

    useEffect(() => {
        setDto((prev) => {
            return {
                ...prev,
                group_id: Number(groupInfo.id),
                title: groupInfo.title
            };
        });
    }, [groupInfo]);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDto((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleSubmit = () => {
        mutate(dto);
    };

    return (
        <Accordion sx={{width: '100%'}}>
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined/>}
            >
                <Typography>
                    Изменить название группы
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={1}>
                    <FormControl>
                        <InputBase
                            type='text'
                            value={dto.title}
                            name='title'
                            onChange={handleTitleChange}
                        />
                        <FormHelperText>
                            Дата начала обучения
                        </FormHelperText>
                    </FormControl>
                    {!loading && (
                        <Button
                            variant='contained'
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Сохранить
                        </Button>
                    )}
                    {loading && (
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            <CircularProgress/>
                        </Stack>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export default AdminChangeTitle;