import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerGetAppealsWithStatusDeniedQueryResponse,
  RegisterAppealsControllerGetAppealsWithStatusDeniedQueryParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerGetAppealsWithStatusDenied'

export function getRegisterAppealsControllerGetAppealsWithStatusDeniedUrl() {
  return `/register-appeals/denied` as const
}

/**
 * @summary Получить все заявки на регистрацию со статусом denied
 * {@link /register-appeals/denied}
 */
export async function registerAppealsControllerGetAppealsWithStatusDenied(
  params: RegisterAppealsControllerGetAppealsWithStatusDeniedQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerGetAppealsWithStatusDeniedQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getRegisterAppealsControllerGetAppealsWithStatusDeniedUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}