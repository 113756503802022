import { Box, Stack } from '@mui/material';
import PageHeader from '@/global/components/PageHeader';
import React, { useCallback, useState } from 'react';
import { apiGetStudentsFromAisForAdmin } from '@/modules/students/services/student.service';
import { toast } from 'react-toastify';
import { IinDataForm, IinFieldForm } from '@/Landing/components/IinFormForAdmin';

type Steps = 'input' | 'data'

export function HiddenStudentsByIinPage() {
    const [iin, setIin] = useState('');
    const [step, setStep] = useState<Steps>('input');

    const { data, refetch, isLoading } = apiGetStudentsFromAisForAdmin(iin, {
        enabled: false,
        retry: false
    });

    const handleIinChange = useCallback((newValue: string) => {
        if (newValue.length > 12) {
            return;
        }

        setIin(newValue);
    }, []);

    const handleCheck = async () => {
        if (iin.length < 12 || iin.length > 12) {
            toast.error('Неверно введен ИИН');
            return;
        }

        refetch().then(() => {
            setStep('data');
        }).catch(() => {
            toast.error('Неизвестная ошибка');
        });
    };

    const handleReset = () => {
        setStep('input');
        setIin('');
    };

    return (
        <Box
            className={'App'}
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <PageHeader/>

            <Stack>
                {step === 'input' && (
                    <IinFieldForm
                        iin={iin}
                        handleIin={handleIinChange}
                        handleCheck={handleCheck}
                        loading={isLoading}
                    />
                )}
                {step === 'data' && (
                    <IinDataForm
                        iin={iin}
                        data={data}
                        returnToInput={handleReset}
                    />
                )}

            </Stack>
        </Box>
    );
}