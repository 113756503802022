import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerGetGroupsBySchoolIdQueryResponse,
  GroupsControllerGetGroupsBySchoolIdPathParams,
  GroupsControllerGetGroupsBySchoolIdQueryParams,
} from '../../ts/GroupsDTO/GroupsControllerGetGroupsBySchoolId'

export function getGroupsControllerGetGroupsBySchoolIdUrl({ id }: { id: GroupsControllerGetGroupsBySchoolIdPathParams['id'] }) {
  return `/groups/all-groups/${id}` as const
}

/**
 * @summary Получение групп по школе
 * {@link /groups/all-groups/:id}
 */
export async function groupsControllerGetGroupsBySchoolId(
  { id }: { id: GroupsControllerGetGroupsBySchoolIdPathParams['id'] },
  params: GroupsControllerGetGroupsBySchoolIdQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerGetGroupsBySchoolIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerGetGroupsBySchoolIdUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}