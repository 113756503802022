import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { AuthControllerCheckAuthQueryResponse } from '../../ts/authDTO/AuthControllerCheckAuth'

export function getAuthControllerCheckAuthUrl() {
  return `/auth/check/token` as const
}

/**
 * @summary Проверка токена
 * {@link /auth/check/token}
 */
export async function authControllerCheckAuth(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<AuthControllerCheckAuthQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getAuthControllerCheckAuthUrl().toString(),
    ...requestConfig,
  })
  return res
}