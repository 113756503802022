import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, CircularProgress} from "@mui/material";

import { getCertificateA4 } from '../actions/api';
import {useSnackbarStore} from '@/global/store/SnackbarStore';

function StudentCertificateA4() {

  const {id} = useParams();
  const navigate = useNavigate();
  const snackbar = useSnackbarStore((state) => state);
  const [pdfData, setPdfData] = useState<string>('');
  const [platform, setPlatform] = useState<string>('unknown');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getPlatform();
    fetchInfo();
  }, [id]);

  const fetchInfo = () => {
    setLoading(true);
    getCertificateA4(Number(id))
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);
        setPdfData(url);
      })
      .catch((err) => {
        let errorMessage = 'Произошла ошибка. Возможно данное свидетельство не существует.';

        if (err.response && err.response.data) {
          try {
            const decodedString = new TextDecoder().decode(err.response.data);
            const parsedError = JSON.parse(decodedString);
            if (parsedError.message) {
              errorMessage = parsedError.message;
            }
          } catch (parseError) {
            console.error("Ошибка при декодировании и парсинге ответа:", parseError);
          }
        }

        console.log(err);
        snackbar.errorMessage(errorMessage);
        navigate('/');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPlatform = () => {
    const userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i,
      os = null;

    if (macosPlatforms.test(userAgent)) {
      setPlatform("macos");
    } else if (iosPlatforms.test(userAgent)) {
      setPlatform("ios");
    } else if (windowsPlatforms.test(userAgent)) {
      setPlatform("windows");
    } else if (/android/.test(userAgent)) {
      setPlatform("android");
    } else if (!os && /linux/.test(userAgent)) {
      setPlatform("linux");
    }
  };

  if (loading) {
    return (<CircularProgress />);
  }

  if (platform === 'windows') {
    return (
        <iframe
            src={pdfData}
            style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: '0',
          top: '0'
        }}
            title={'certificate'}
        >
        </iframe>
    );
  } else {
    return (
        <Button
            variant={'contained'}
            href={pdfData}
            download={"certificate.pdf"}
        >
            Скачать сертификат
        </Button>
    );
  }
}

export default StudentCertificateA4;