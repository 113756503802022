import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TicketControllerChangeTicketStatusClosedMutationResponse,
  TicketControllerChangeTicketStatusClosedPathParams,
} from '../../ts/TicketsDTO/TicketControllerChangeTicketStatusClosed'

export function getTicketControllerChangeTicketStatusClosedUrl({ id }: { id: TicketControllerChangeTicketStatusClosedPathParams['id'] }) {
  return `/tickets/close/${id}` as const
}

/**
 * @summary Закрытие тикета
 * {@link /tickets/close/:id}
 */
export async function ticketControllerChangeTicketStatusClosed(
  { id }: { id: TicketControllerChangeTicketStatusClosedPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TicketControllerChangeTicketStatusClosedMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getTicketControllerChangeTicketStatusClosedUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}