import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerGetAppealsQueryResponse,
  RegisterAppealsControllerGetAppealsQueryParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerGetAppeals'

export function getRegisterAppealsControllerGetAppealsUrl() {
  return `/register-appeals` as const
}

/**
 * @summary Получить все заявки на регистрацию
 * {@link /register-appeals}
 */
export async function registerAppealsControllerGetAppeals(
  params: RegisterAppealsControllerGetAppealsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerGetAppealsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getRegisterAppealsControllerGetAppealsUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}