import React from 'react';
import {useParams} from 'react-router-dom';
import {Box, Divider, Stack, Typography} from '@mui/material';
import {toast} from 'react-toastify';

import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import LoadingButton from '../../global/components/LoadingButton';
import VisaIcon from '../../global/assets/images/VisaIcon';
import MasterCardIcon from '../../global/assets/images/MasterCardIcon';
import StudentGroupCard from '../../Main/components/StudentGroupCard';
import {apiCreateQuickTransaction, apiGetProductPriceById} from "@/modules/transactions/services/transactions.services";
import {apiGetFeeStudent} from "@/modules/students/services/student.service";

function FeePage() {

    const {studentId} = useParams();

    const {data: studentInfoResponse, isLoading: studentInfoLoading} = apiGetFeeStudent(+(studentId ?? 0));
    const {data: attachmentPriceResponse, isLoading: attachmentPriceLoading} = apiGetProductPriceById('attachment');

    const {mutate: requestIokaTransaction, isPending: iokaTransactionLoading} = apiCreateQuickTransaction();

    const handleIOKAClick = () => {
        if (studentInfoResponse && attachmentPriceResponse) {
            requestIokaTransaction({
                user_id: null,
                recipient_id: null,
                school_id: studentInfoResponse.group?.school_id,
                type: 'payment_access',
                amount: 1,
                price: attachmentPriceResponse.price,
                group_id: studentInfoResponse?.group_id,
                payment_system: 'freedom',
                iin: studentInfoResponse.iin
            }, {
                onSuccess: (response) => {
                    if (response.payment_url) {
                        window.location.href = response.payment_url;
                    }
                }
            });
        } else {
            toast.error('Отсутствует currentUser.id || studentInfoResponse || Информация о цене продуктов');
        }
    };

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>

            <Box sx={{...wrapperSx}}>
                <Stack
                    direction={'column'}
                    width={'100%'}
                    sx={{
                        mt: '38px',
                        p: '52px',
                        borderRadius: '24px',
                        background: '#F5F5F5',
                        gap: '16px'
                    }}
                >
                    <Typography
                        variant={'h6'}
                    >
                        Оплата пошлины за хранение учебных данных
                    </Typography>

                    <Typography
                        variant={'h6'}
                    >
                        Курсант:
                        {' '}
                        {studentInfoResponse?.last_name}
                        {' '}
                        {studentInfoResponse?.first_name}
                    </Typography>

                    {studentInfoResponse && (
                        <StudentGroupCard
                            studentInfo={studentInfoResponse}
                            handleStartExam={() => {
                            }}
                            feePage={true}
                        />
                    )}

                    <Typography fontWeight={'bold'}>
                        Стоимость пошлины -
                        {' '}
                        {attachmentPriceResponse?.price}
                        {' '}
                        ₸
                    </Typography>

                    <Divider/>

                    <Stack>
                        <Typography>
                            Сумма к оплате:
                        </Typography>
                        <Typography variant={'h6'}>
                            {attachmentPriceResponse?.price}
                            {' '}
                            ₸
                        </Typography>
                    </Stack>

                    <Divider/>

                    <Typography>
                        Выберите способ оплаты:
                    </Typography>

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                    >
                        <LoadingButton
                            variant={'contained'}
                            sx={{
                                width: '300px',
                                gap: '16px'
                            }}
                            onClick={() => {
                                handleIOKAClick();
                            }}
                            loading={studentInfoLoading || attachmentPriceLoading || iokaTransactionLoading}
                        >
                            <VisaIcon
                                width={'50px'}
                                height={'50px'}
                            />
                            <MasterCardIcon
                                width={'50px'}
                                height={'50px'}
                            />
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}

export default FeePage;
