import {type Theme} from '@mui/material';

export enum PeriodType {
    Month = 'month', Year = 'year',
}

export enum RegisterPeriodType {
    time = 'time', day_of_week = 'day_of_week',
}

export interface Statistic {
    x: string,
    y: number
}

export interface DateRange {
    end_date: string,
    start_date: string,
}

export interface DateRangeParams extends DateRange {
    period_type: PeriodType;
}

export interface RegisterParams {
    period_type: RegisterPeriodType;
}

export interface SchoolUsersParams {
    date: string,
}

export const LineChart = (theme: Theme, id: string, data: {
    items: Statistic[]
} | undefined, options: ApexCharts.ApexOptions = {}): ApexCharts.ApexOptions => (
    {
        theme: {
            mode: theme.palette.mode
        }, chart: {
            id: id, background: theme.palette.mode === 'dark' ? 'inherit' : undefined
        }, xaxis: {
            type: 'datetime'
        }, series: [{
            name: 'Пользователей', data: data?.items ?? []
        }], ...options
    }
);

export const BarChart = (theme: Theme, id: string, data: {
    items: Statistic[]
} | undefined, options: ApexCharts.ApexOptions = {}): ApexCharts.ApexOptions => (
    {
        theme: {
            mode: theme.palette.mode
        }, chart: {
            toolbar: {
                show: true, tools: {
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    customIcons: []
                }
            },
            animations: {enabled: false},
            type: 'bar', id: id, background: theme.palette.mode === 'dark' ? 'inherit' : undefined
        }, dataLabels: {
            style: {
                colors: ['black']
            }
        }, xaxis: {
            type: 'category'
        }, series: [{
            name: 'Пользователей', data: data?.items ?? []
        }], ...options
    }
);

export const PieChart = (theme: Theme, id: string, data: {
    items: Statistic[]
} | undefined, options: ApexCharts.ApexOptions = {}): ApexCharts.ApexOptions => {
    const series: number[] = [];
    const labels: string[] = [];
    if (data?.items) {
        data.items.forEach((el) => {
            series.push(Math.round(el.y));
            labels.push(el.x);
        });
    }
    return {
        theme: {
            mode: theme.palette.mode
        }, chart: {
            type: 'pie', id: id, background: theme.palette.mode === 'dark' ? 'inherit' : undefined
        }, legend: {
            position: 'bottom'
        }, xaxis: {
            type: 'category'
        }, series: series, labels: labels, ...options
    };
};

export interface SchoolOverview {
    id: number,
    title: string,
    locality: string,
    all_users: number,
    balance: number,
    groups: number,
    passed_users: number,
    certificates: number
}

export interface StatisticOverview {
    total_users: number,
    total_schools: number,
    total_groups: number,
    total_certificates: number
    total_students: number,
    total_passed_students: number
}