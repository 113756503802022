import {Alert, Box, Chip, List, Typography} from "@mui/material";
import {SchoolPageCardsSection} from "@/modules/schools/components/SchoolPageCardsSection";
import React, {useEffect, useState} from "react";
import {apiGetSchoolsAttributes} from "@/modules/schools/services/school.service";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {
    schoolId:number,
}

export function SchoolPageAttributesSection({schoolId}:Props) {
    const {id} = useParams();

    const [alertVisible, setAlertVisible] = useState<boolean>(false);
    const {t} = useTranslation();

    const {data: schoolAttributes, isLoading: attributesLoading} = apiGetSchoolsAttributes(+(id ?? 0));

    useEffect(() => {
        if (attributesLoading || !schoolAttributes) {
            setAlertVisible(false);
            return;
        }
        if (schoolAttributes.teachers.active === 0 ||
            schoolAttributes.drivers.active === 0 ||
            schoolAttributes.masters.active === 0 ||
            schoolAttributes.transports.total === 0) {
            setAlertVisible(true);
            return;
        }
        setAlertVisible(false);
    }, [schoolAttributes, attributesLoading]);

    return (
        <Box sx={{mt: 4}}>
            <Typography
                variant="h4"
                sx={{ml: 1}}
            >
                {t('school_page.database')}
            </Typography>
            {schoolAttributes && alertVisible && (
                <Alert
                    severity={'error'}
                    sx={{my: 1}}
                >
                    {t('school_page.alert')}
                    :
                    <List sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                        {!schoolAttributes.base.updated_at && (
                            <li>
                                <Chip label={t('school_page.study_base')}/>
                            </li>
                        )}
                        {schoolAttributes.teachers.active === 0 && (
                            <li>
                                <Chip label={t('school_page.teachers')}/>
                            </li>
                        )}
                        {schoolAttributes.drivers.active === 0 && (
                            <li>
                                <Chip label={t('school_page.drivers')}/>
                            </li>
                        )}
                        {schoolAttributes.masters.active === 0 && (
                            <li>
                                <Chip label={t('school_page.masters')}/>
                            </li>
                        )}
                        {schoolAttributes.transports.total === 0 && (
                            <li>
                                <Chip label={t('school_page.car')}/>
                            </li>
                        )}
                    </List>
                </Alert>
            )}
            <SchoolPageCardsSection
                attributesLoading={attributesLoading}
                id={schoolId}
                schoolAttributes={schoolAttributes}
            />
        </Box>
    );
}