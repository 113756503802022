import {DataGrid, type GridColDef} from '@mui/x-data-grid';
import React, {useMemo} from 'react';
import {apiSchoolsOverview} from '../services/statistic.service';
import {type DateRange, type SchoolOverview} from '../types/statistic.type';
import {Paper, Typography} from '@mui/material';

export function TableSchools({start_date, end_date}: DateRange) {

    const {data, isLoading} = apiSchoolsOverview({start_date, end_date});

    const columns: GridColDef<SchoolOverview>[] = useMemo(
        () => [
            {
                field: 'id',
                headerName: '№',
                sortable: true
            },
            {
                field: 'title',
                headerName: 'Название',
                flex: 2,
                minWidth: 200,
                sortable: false,
                renderCell: ({row}) => row.title
            },
            {
                field: 'locality',
                headerName: 'Город',
                flex: 2,
                minWidth: 200,
                sortable: false
            },
            {
                field: 'all_users',
                headerName: 'Кол-во учеников',
                sortable: false,
                flex: 1,
                minWidth: 150
            },
            {
                field: 'balance',
                headerName: 'Баланс',
                sortable: false,
                flex: 1,
                minWidth: 150
            },
            {
                field: 'groups',
                headerName: 'Кол-во групп',
                sortable: false,
                flex: 1,
                minWidth: 150
            },
            {
                field: 'passed_users',
                headerName: 'Кол-во закончивших учеников',
                sortable: false,
                flex: 1,
                minWidth: 150
            },
            {
                field: 'certificates',
                headerName: 'Кол-во сертификатов',
                sortable: false,
                flex: 1,
                minWidth: 150
            }
        ],
        []);

    return (
        <Paper
            sx={{
                padding: 2
            }}
        >
            <Typography
                variant="h4"
                sx={{mb: 0.5}}
            >
                Информация об автошколах
            </Typography>
            <DataGrid
                loading={isLoading}
                columns={columns}
                rows={data?.items ?? []}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30
                        }
                    }
                }}
                pageSizeOptions={[30]}
                disableRowSelectionOnClick={true}
                rowHeight={50}
                autoHeight
            />
        </Paper>
    );
}