import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerGetActiveSchoolTeachersQueryResponse,
  EmployeeControllerGetActiveSchoolTeachersPathParams,
  EmployeeControllerGetActiveSchoolTeachersQueryParams,
} from '../../ts/employeesDTO/EmployeeControllerGetActiveSchoolTeachers'

export function getEmployeeControllerGetActiveSchoolTeachersUrl({ id }: { id: EmployeeControllerGetActiveSchoolTeachersPathParams['id'] }) {
  return `/employees/school/teacher/active/${id}` as const
}

/**
 * @summary Получение активных учителей школы
 * {@link /employees/school/teacher/active/:id}
 */
export async function employeeControllerGetActiveSchoolTeachers(
  { id }: { id: EmployeeControllerGetActiveSchoolTeachersPathParams['id'] },
  params: EmployeeControllerGetActiveSchoolTeachersQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerGetActiveSchoolTeachersQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getEmployeeControllerGetActiveSchoolTeachersUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}