import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerCreateUserForStudentMutationRequest,
  StudentsControllerCreateUserForStudentMutationResponse,
} from '../../ts/StudentsDTO/StudentsControllerCreateUserForStudent'

export function getStudentsControllerCreateUserForStudentUrl() {
  return `/students/create-user` as const
}

/**
 * @summary Создание пользователя для студента
 * {@link /students/create-user}
 */
export async function studentsControllerCreateUserForStudent(
  data: StudentsControllerCreateUserForStudentMutationRequest,
  config: Partial<RequestConfig<StudentsControllerCreateUserForStudentMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    StudentsControllerCreateUserForStudentMutationResponse,
    ResponseErrorConfig<Error>,
    StudentsControllerCreateUserForStudentMutationRequest
  >({ method: 'POST', url: getStudentsControllerCreateUserForStudentUrl().toString(), data, ...requestConfig })
  return res
}