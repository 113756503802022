import type {SchoolAttributesCountDto} from "@/global/api/gen/ts/SchoolAttributesCountDto";
import {Card} from "@/School/components/Card";
import {DirectionsCar, ModeEditOutlined, Person, SensorDoor} from "@mui/icons-material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SchoolIcon from "@mui/icons-material/School";
import {Stack} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

interface Props {
    id: number;
    attributesLoading: boolean;
    schoolAttributes: SchoolAttributesCountDto | undefined;
}

export function SchoolPageCardsSection({id, schoolAttributes, attributesLoading}: Props) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <Stack
            flexDirection="row"
            flexWrap="wrap"
            sx={{mt: 1}}
            gap={'16px'}
        >
            <Card
                title={t('school_page.change-info')}
                icon={<ModeEditOutlined/>}
                footer={attributesLoading ? [] : []}
                onClick={() => {
                    navigate(`/schools/${id}/change-info`);
                }}
            />

            <Card
                title={t('school_page.cabinets')}
                icon={<SensorDoor/>}
                footer={attributesLoading ? [] : []}
                onClick={() => {
                    navigate(`/schools/${id}/cabinets`);
                }}
            />

            <Card
                title={t('school_page.teachers')}
                icon={<PersonAddIcon/>}
                footer={attributesLoading ? [] : [
                    {title: t('school_page.all'), value: schoolAttributes?.teachers.total},
                    {title: t('school_page.current'), value: schoolAttributes?.teachers.active}
                ]}
                onClick={() => {
                    navigate(`/schools/${id}/teachers`);
                }}
            />

            <Card
                title={t('school_page.drivers')}
                icon={<PersonAddIcon/>}
                footer={attributesLoading ? [] : [
                    {title: t('school_page.all'), value: schoolAttributes?.drivers.total},
                    {title: t('school_page.current'), value: schoolAttributes?.drivers.active}
                ]}
                onClick={() => {
                    navigate(`/schools/${id}/drivers`);
                }}
            />

            <Card
                title={t('school_page.masters')}
                icon={<PersonAddIcon/>}
                footer={attributesLoading ? [] : [
                    {title: t('school_page.all'), value: schoolAttributes?.masters.total},
                    {title: t('school_page.current'), value: schoolAttributes?.masters.active}
                ]}
                onClick={() => {
                    navigate(`/schools/${id}/masters`);
                }}
            />

            <Card
                title={t('school_page.car')}
                icon={<DirectionsCar/>}
                footer={attributesLoading ? [] : [
                    {title: t('school_page.all'), value: schoolAttributes?.transports.total},
                ]}
                onClick={() => {
                    navigate(`/schools/${id}/transport`);
                }}
            />

            <Card
                title={t('school_page.group')}
                icon={<SchoolIcon/>}
                footer={attributesLoading ? [] : [
                    {title: t('school_page.all'), value: schoolAttributes?.groups.total},
                    {title: t('school_page.current'), value: schoolAttributes?.groups.active}
                ]}
                onClick={() => {
                    navigate('/groups');
                }}
            />

            <Card
                title={t('school_page.students')}
                icon={<Person/>}
                footer={[]}
                onClick={() => {
                    navigate(`/schools/${id}/kursants`);
                }}
            />

            <Card
                title={t('school_page.users')}
                icon={<Person/>}
                footer={[]}
                onClick={() => {
                    navigate(`/schools/${id}/workers`);
                }}
            />
        </Stack>
    );
}