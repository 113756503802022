import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { SchoolsControllerInsertEmailAsSchoolEmailsQueryResponse } from '../../ts/schoolsDTO/SchoolsControllerInsertEmailAsSchoolEmails'

export function getSchoolsControllerInsertEmailAsSchoolEmailsUrl() {
  return `/schools/emails` as const
}

/**
 * {@link /schools/emails}
 */
export async function schoolsControllerInsertEmailAsSchoolEmails(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerInsertEmailAsSchoolEmailsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerInsertEmailAsSchoolEmailsUrl().toString(),
    ...requestConfig,
  })
  return res
}