import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerGetEmployeeInfoQueryResponse,
  EmployeeControllerGetEmployeeInfoPathParams,
  EmployeeControllerGetEmployeeInfoQueryParams,
} from '../../ts/employeesDTO/EmployeeControllerGetEmployeeInfo'

export function getEmployeeControllerGetEmployeeInfoUrl({ employeeId }: { employeeId: EmployeeControllerGetEmployeeInfoPathParams['employeeId'] }) {
  return `/employees/employee/${employeeId}` as const
}

/**
 * @summary Получить по id
 * {@link /employees/employee/:employeeId}
 */
export async function employeeControllerGetEmployeeInfo(
  { employeeId }: { employeeId: EmployeeControllerGetEmployeeInfoPathParams['employeeId'] },
  params: EmployeeControllerGetEmployeeInfoQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerGetEmployeeInfoQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getEmployeeControllerGetEmployeeInfoUrl({ employeeId }).toString(),
    params,
    ...requestConfig,
  })
  return res
}