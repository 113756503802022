import {useCustomQuery} from "@/global/hooks/useCustomQuery";
import {regionService} from "@/global/api/gen";
import type {RegionControllerGetRegionsQueryParams} from "@/global/api/gen/ts/RegionDTO/RegionControllerGetRegions";
import {RegionsKeys} from "@/modules/regions/services/regions.keys";
import type {
    RegionControllerGetLocalitiesBySlugQueryParams
} from "@/global/api/gen/ts/RegionDTO/RegionControllerGetLocalitiesBySlug";

export const apiGetRegionList = (params: RegionControllerGetRegionsQueryParams) => {
    return useCustomQuery({
        queryFn: () => regionService.regionControllerGetRegions(params)
            .then((res) => res.data),
        queryKey: RegionsKeys.search(params),
    });
};

export const apiGetLocalityBySlug = (slug:string, params: RegionControllerGetLocalitiesBySlugQueryParams) => {
    return useCustomQuery({
        enabled: !!slug,
        queryFn: () => regionService.regionControllerGetLocalitiesBySlug({slug}, params)
            .then((res) => res.data),
        queryKey: RegionsKeys.localities(slug,params),
    });
};

