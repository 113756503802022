import {Box, type SxProps} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import type {MouseEventHandler} from 'react';

interface Props {
    sx?: SxProps,
    label?: string,
    onClick?: MouseEventHandler,
}

export function BackLink({sx = {}, label = 'Назад', onClick}: Props) {
    return (
        <Box
            sx={{
                fontFamily: 'sans-serif',
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center',
                color: '#248AE8',
                cursor: 'pointer', ...sx
            }}
            onClick={onClick}
        >
            <ArrowBackIcon sx={{mr: 1}}/>
            {' '}
            {label}
        </Box>
    );
}