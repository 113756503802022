import type {
    TicketControllerGetTicketsByUserIdQueryParams
} from "@/global/api/gen/ts/TicketsDTO/TicketControllerGetTicketsByUserId";
import type {
    TicketControllerGetAllTicketsQueryParams
} from "@/global/api/gen/ts/TicketsDTO/TicketControllerGetAllTickets";

export const TicketsKeys = {
    all: ['tickets'] as const,
    search: (params: TicketControllerGetAllTicketsQueryParams) => [...TicketsKeys.all, 'search', params] as const,
    myTickets: (params:TicketControllerGetTicketsByUserIdQueryParams) => [...TicketsKeys.all, 'my', params] as const,
    id: (id:number) => [...TicketsKeys.all, `${id}`] as const,
    detail: (id:number) => [...TicketsKeys.id(id), 'detail'] as const,
    history: (id:number) => [...TicketsKeys.id(id), 'history'] as const,
};