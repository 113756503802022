import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import {newsService} from "@/global/api/gen";
import {NewsKeys} from "@/modules/news/services/news.keys";
import type {NewsAddDto} from "@/global/api/gen/ts/NewsAddDto";
import {useCustomQuery} from "@/global/hooks/useCustomQuery";

export const apiAddNews = () => {
    return useCustomMutation({
        mutationFn: (data:NewsAddDto) => newsService.newsControllerAddNews(data),
        queryKey: NewsKeys.all,
    });
};

export const apiGetNews = (newsId: number) => {
  return useCustomQuery({
      enabled: !!newsId && !isNaN(newsId),
      queryFn: () => newsService.newsControllerGetNewsById({newsId})
          .then((res) => res.data),
      queryKey: NewsKeys.detail(newsId)
  });
};

export const apiGetLastNews = () => {
    return useCustomQuery({
        queryFn: () => newsService.newsControllerGetLastNews()
            .then((res) => res.data),
        queryKey: NewsKeys.lastNews()
    });
};