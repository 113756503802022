import React from 'react';
import {Alert, Button, Step, StepContent, StepLabel, Stepper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useUserStore} from '@/global/store/UserStore';
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {apiChangeStatusGroup} from "@/modules/groups/services/group.service";
import {apiCreateCertificates} from "@/modules/certificates/services/certificates.service";
import {useModal} from "@/global/providers/ModalProvider";
import {DateEndGroupModal} from "@/modules/groups/dialogs/DateEndGroupModal";

interface GroupStepperProps {
    groupInfo: GroupDataDto;
    addStudent: () => void;
    formGroup: () => void;
}

function GroupStepper({groupInfo, addStudent, formGroup}: GroupStepperProps) {

    const {t} = useTranslation();

    const userStore = useUserStore((state) => state);
    const {openModal} = useModal();

    const {mutate} = apiChangeStatusGroup();
    const {mutate: createCertificates} = apiCreateCertificates();

    const startExam = () => {
        if (groupInfo.id) {
            mutate({
                group_id: groupInfo.id,
                status: 3
            }, {
                onSuccess: () => {
                    toast.success(t('school_page.group_page.group_stepper.messages.status_exam'));
                }
            });
        }
    };

    const openModalDateEndGroup = () => {
        openModal(DateEndGroupModal, {group_id: groupInfo.id},  `date_end_group_${groupInfo.id}`);
    };

    const finishExam = () => {
        // let examsDone = true;
        // const students = groupInfo.students;
        //
        // if (!students) {
        //     return;
        // }
        //
        // for (let i = 0; i < students.length; i++) {
        //     const student = students[i];
        //     if (student.passed === null) {
        //         examsDone = false;
        //         break;
        //     }
        // }
        //
        // if (!examsDone) {
        //     toast.error(t('school_page.group_page.group_stepper.messages.student_result'));
        //     return;
        // }

        if (groupInfo.id) {
            mutate({
                group_id: groupInfo.id,
                status: 4
            }, {
                onSuccess: () => {
                    createCertificates({id: groupInfo.id});
                    toast.success(t('school_page.group_page.group_stepper.messages.status_finished'));
                }
            });
        }
    };

    return (
        <Stepper
            orientation="vertical"
            activeStep={groupInfo.status - 1}
        >
            <Step>
                <StepLabel>
                    {t('school_page.group_page.group_stepper.created.status')}
                </StepLabel>
                <StepContent>
                    <Typography>
                        {t('school_page.group_page.group_stepper.created.form_group_required')}
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        {t('school_page.group_page.group_stepper.created.add')}
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        {t('school_page.group_page.group_stepper.created.form_group')}
                    </Typography>
                    <Alert
                        severity="warning"
                        sx={{mt: 1, mb: 2}}
                    >
                        {t('school_page.group_page.group_stepper.created.attention')}
                    </Alert>
                    <Button
                        onClick={addStudent}
                        variant={'contained'}
                        disabled={userStore.isObserver || userStore.isAdmin}
                    >
                        {t('school_page.group_page.group_stepper.created.add_student')}
                    </Button>
                    <Button
                        color={'info'}
                        variant={'contained'}
                        onClick={openModalDateEndGroup}
                        sx={{ml: 2}}
                    >
                        Предварительная дата окончания
                    </Button>
                    <Button
                        onClick={formGroup}
                        color={'success'}
                        variant={'contained'}
                        sx={{ml: 2}}
                        disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.group_stepper.created.form_group_btn')}
                    </Button>
                </StepContent>
            </Step>
            <Step>
                <StepLabel>
                    {t('school_page.group_page.group_stepper.active.status')}
                </StepLabel>
                <StepContent>
                    <Typography>
                        {t('school_page.group_page.group_stepper.active.group_formed')}
                    </Typography>
                    <Typography>
                        {t('school_page.group_page.group_stepper.active.data_fixed')}
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        {t('school_page.group_page.group_stepper.active.finish_study')}
                    </Typography>
                    <Typography sx={{mb: 2.5}}>
                        {t('school_page.group_page.group_stepper.active.exam_students')}
                    </Typography>
                    {(<Button
                        onClick={startExam}
                        color={'success'}
                        variant={'contained'}
                        sx={{ml: 2}}
                        disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.group_stepper.active.finish_study_btn')}
                      </Button>)}
                </StepContent>
            </Step>
            <Step>
                <StepLabel>
                    {t('school_page.group_page.group_stepper.exam.status')}
                </StepLabel>
                <StepContent>
                    <Typography sx={{mb: 1}}>
                        {t('school_page.group_page.group_stepper.exam.study_finished')}
                    </Typography>
                    <Typography sx={{mb: 0.5}}>
                        {t('school_page.group_page.group_stepper.exam.exam_chapter')}
                    </Typography>
                    <Typography sx={{mb: 2.5}}>
                        {t('school_page.group_page.group_stepper.exam.after_attestation')}
                    </Typography>
                    {(<Button
                        onClick={finishExam}
                        color={'warning'}
                        variant={'contained'}
                        sx={{ml: 2}}
                        disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.group_stepper.exam.complete')}
                      </Button>)}
                </StepContent>
            </Step>
            <Step>
                <StepLabel>
                    {t('school_page.group_page.group_stepper.finished.status')}
                </StepLabel>
                <StepContent>
                    <Typography>
                        {t('school_page.group_page.group_stepper.finished.exams_completed')}
                    </Typography>
                </StepContent>
            </Step>
        </Stepper>
    );
}

export default GroupStepper;