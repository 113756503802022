import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { AuthControllerRegisterUnionMutationRequest, AuthControllerRegisterUnionMutationResponse } from '../../ts/authDTO/AuthControllerRegisterUnion'

export function getAuthControllerRegisterUnionUrl() {
  return `/auth/register/union` as const
}

/**
 * @summary Регистрация объединения
 * {@link /auth/register/union}
 */
export async function authControllerRegisterUnion(
  data: AuthControllerRegisterUnionMutationRequest,
  config: Partial<RequestConfig<AuthControllerRegisterUnionMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<AuthControllerRegisterUnionMutationResponse, ResponseErrorConfig<Error>, AuthControllerRegisterUnionMutationRequest>({
    method: 'POST',
    url: getAuthControllerRegisterUnionUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}