import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import type { AttributesResponseDataType } from '@/global/api/gen/ts/AttributesResponseDataType';
import { availableCategories } from '@/modules/appeals/types/appeals.types';

interface Props {
	attributes_info: AttributesResponseDataType | null;
}

export function TransportsTab({attributes_info}: Props) {
	return (
		<>
			<section className={'appeal-employees'}>
				<h4>
					Сотрудники учебного центра
				</h4>
				<Typography
					sx={{
						fontWeight: 'bold'
					}}
				>
					Учебные транспортные средства
				</Typography>
				{attributes_info?.transports?.map((transport) => {
					return (
						<Stack
							key={transport.plate_number}
							display={'flex'}
							flexDirection={'row'}
							flexWrap={'wrap'}
							sx={{
								background: '#F4F6F8FF', borderRadius: '10px', width: 'fit-content'
							}}
						>
							<Box
								flexDirection={'column'}
								sx={{p: 2}}
							>
								<Typography
									sx={{p: 0}}
								>
									{/*{transport.brand}*/}
									{transport.model}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									ГРНЗ:
									{transport.plate_number}
								</Typography>
								<Typography
									sx={{p: 0}}
								>
									Описание:
									{transport.description}
								</Typography>
								<FormGroup>
									<Stack
										flexDirection="row"
										flexWrap="wrap"
										className={'appeal-categories-ctn'}
										sx={{
											maxWidth: '400px'
										}}
									>
										{availableCategories.map((categoryId) => {
											if (categoryId === transport.category) {
												return (
													<FormControlLabel
														key={categoryId}
														label={categoryId}
														sx={{mr: 3}}
														className={'appeal-category-label'}
														control={(
															<Checkbox
																checked={true}
																disabled={true}
															/>
														)}
													/>
												);
											}
										})}
									</Stack>
								</FormGroup>
							</Box>
						</Stack>
					);
				})}
			</section>
		</>
	);
}