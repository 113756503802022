import React, {useState} from 'react';
import {Avatar, Box, Tab, Tabs} from '@mui/material';
import {type GridColDef, type GridEventListener} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getName} from '@/global/helpers/text';
import {apiGetMyTickets} from "@/modules/tickets/services/tickets.service";
import {ServerDataGrid} from "@/global/components/ServerDataGrid";
import type {TicketDto} from "@/global/api/gen/ts/TicketDto";

interface TicketsUserGridProps {
    withoutTabs?: boolean
}

export enum TicketStatus {
    Новое = 1,
    Администратор = 2,
    Пользователь = 3,
    Закрытое = 10
}

function TicketsUserGrid({withoutTabs = false}: TicketsUserGridProps) {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [tab, setTab] = useState(0);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25, page: 0
    });

    const {data: ticketsList, isLoading: ticketsListLoading} = apiGetMyTickets({
        offset: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize,
    });

    const columns: GridColDef<TicketDto>[] = [
        {field: 'empty', headerName: '', headerClassName: 'datagrid--header', maxWidth: 10, width: 10, sortable: false},
        {
            field: 'id',
            headerName: 'ID',
            width: 90
        },
        {
            field: 'title',
            headerName: t('tickets-page.grid.title'),
            width: 300
        },
        {
            field: 'user',
            headerName: t('tickets-page.grid.user'),
            width: 300,
            renderCell: ({row}) => {
                const name = getName(row.user ?? {first_name: "", last_name:"", patronymic:''});
                return (
                    <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>
                );
            }
        },
        {
            field: 'manager',
            headerName: t('tickets-page.grid.manager'),
            width: 300,
            renderCell: ({row}) => {
                if (row.manager) {
                    const name = getName(row.manager);
                    return (
                        <React.Fragment>
                            <Avatar
                                sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                            >
                                {name.partialName}
                            </Avatar>
                            {name.fullName}
                        </React.Fragment>
                    );
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'status',
            headerName: t('tickets-page.grid.status'),
            width: 200,
            renderCell: ({row}) => {
                return TicketStatus[row.status];
            }
        },
    ];

    const tabs = {
        all: {id: 0, label: `Мои обращения`},
    };

    const handleRowClick: GridEventListener<'rowClick'> = (
        params,
    ) => {
        navigate('/tickets/' + params.row.id);
    };

    return (
        <Box className={'appeals'}>
            {!withoutTabs && (
            <Tabs
                value={tab}
                onChange={(_e, newValue) => setTab(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{mt: 2, ml: 2}}
            >
                {Object.values(tabs).map(tab => (
                    <Tab
                        label={tab.label}
                        key={tab.id}
                        value={tab.id}
                    />
))}
            </Tabs>
)}
            <ServerDataGrid
                className={'appeals-grid'}
                rows={ticketsList?.items ?? []}
                onRowClick={handleRowClick}
                rowCount={ticketsList?.total ?? 0}
                columns={columns}
                loading={ticketsListLoading}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                autoHeight={true}
                disableRowSelectionOnClick
                style={{
                    border: '0'
                }}
            />
        </Box>
    );
}

export default TicketsUserGrid;