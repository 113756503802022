import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerGetAllSchoolTeachersQueryResponse,
  EmployeeControllerGetAllSchoolTeachersPathParams,
  EmployeeControllerGetAllSchoolTeachersQueryParams,
} from '../../ts/employeesDTO/EmployeeControllerGetAllSchoolTeachers'

export function getEmployeeControllerGetAllSchoolTeachersUrl({ id }: { id: EmployeeControllerGetAllSchoolTeachersPathParams['id'] }) {
  return `/employees/school/teacher/${id}` as const
}

/**
 * @summary Получение всех учителей школы
 * {@link /employees/school/teacher/:id}
 */
export async function employeeControllerGetAllSchoolTeachers(
  { id }: { id: EmployeeControllerGetAllSchoolTeachersPathParams['id'] },
  params: EmployeeControllerGetAllSchoolTeachersQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerGetAllSchoolTeachersQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getEmployeeControllerGetAllSchoolTeachersUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}