import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import type { IRole } from '@/Auth/dto/AuthDto';

export interface IUSerInfo {
    email: string;
    phone: string | null;
    first_name: string;
    last_name: string;
    id: number | null;
    iin: string;
    roles: IRole[];
    isAdmin: boolean;
    isSuperAdmin: boolean;
    isStudent: boolean;
    isObserver: boolean;
    setUserInfo: (userInfo: any) => void;
    setIsAdmin: (isAdmin: boolean) => void;
    setIsObserver: (isObserver: boolean) => void;
    setIsSuperAdmin: (isSuperAdmin: boolean) => void;
    setIsStudent: (isStudent: boolean) => void;
    logOut: () => void;
}

// ts
export const useUserStore = create<IUSerInfo>()(
    persist(
        (set) => ({
          email: "",
          phone: "",
          first_name: "",
          last_name: "",
          id: null,
          iin: "",
          roles: [],
          isAdmin: false,
          isObserver: false,
          isSuperAdmin: false,
          isStudent: false,
          setUserInfo: (userInfo) => set((state) => ({
              email: userInfo.email,
              first_name: userInfo.first_name,
              last_name: userInfo.last_name,
              id: userInfo.id,
              iin: userInfo.iin,
              roles: userInfo.roles
          })),
          setIsObserver: (isObserver) => set((state) => ({
            isObserver
          })),
          setIsAdmin: (isAdmin) => set((state) => ({
              isAdmin
          })),
          setIsSuperAdmin: (isSuperAdmin) => set((state) => ({
              isSuperAdmin
          })),
          setIsStudent: (isStudent) => set((state) => ({
              isStudent
          })),
          logOut: () => set(() => ({
            email: "",
            phone: "",
            first_name: "",
            last_name: "",
            id: null,
            iin: "",
            roles: [],
            isObserver: false,
            isAdmin: false,
            isSuperAdmin: false,
            isStudent: false
          }))
        }),
        {
          name: 'user-storage',
          storage: createJSONStorage(() => localStorage),
        }
    )
);