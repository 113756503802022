import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  RegisterAppealsControllerGetAppealByIdQueryResponse,
  RegisterAppealsControllerGetAppealByIdPathParams,
} from '../../ts/register-appealsDTO/RegisterAppealsControllerGetAppealById'

export function getRegisterAppealsControllerGetAppealByIdUrl({ id }: { id: RegisterAppealsControllerGetAppealByIdPathParams['id'] }) {
  return `/register-appeals/${id}` as const
}

/**
 * @summary Получить заявку на регистрацию по id
 * {@link /register-appeals/:id}
 */
export async function registerAppealsControllerGetAppealById(
  { id }: { id: RegisterAppealsControllerGetAppealByIdPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<RegisterAppealsControllerGetAppealByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getRegisterAppealsControllerGetAppealByIdUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}