import React, { useMemo, useState } from 'react';
import { Box, Button, Chip, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { type GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '@/global/helpers/globalstyles';
import { useUserStore } from '@/global/store/UserStore';
import { apiGetCabinetsListBySchool } from '@/modules/cabinets/services/cabinet.service';
import { ServerDataGrid } from '@/global/components/ServerDataGrid';
import AddCabinetModal from '@/Cabinets/components/AddCabinetModal';
import { useModal } from '@/global/providers/ModalProvider';
import { toast } from 'react-toastify';
import type { CabinetDto } from '@/global/api/gen/ts/CabinetDto';

function SchoolCabinetsPage() {
	const {id} = useParams();

	const {t} = useTranslation();

	const userStore = useUserStore((state) => state);

	const [currentTab, setCurrentTab] = useState<string>('true');
	const {openModal} = useModal();

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25, page: 0
	});

	const {data, isLoading: cabinetsLoading} = apiGetCabinetsListBySchool(+(id ?? 0), {
		offset: paginationModel.page * paginationModel.pageSize,
		limit: paginationModel.pageSize,
		approved: currentTab === 'true'
	});

	const columns: GridColDef<CabinetDto>[] = useMemo(() => [{
		field: 'empty',
		headerName: '',
		headerClassName: 'datagrid--header',
		maxWidth: 10,
		width: 10,
		sortable: false
	}, {field: 'id', headerName: 'ID', width: 75, sortable: true}, {
		field: 'address',
		headerName: t('school_page.cabinets_page.address'),
		width: 150
	}, {field: 'file_url', headerName: t('school_page.cabinets_page.file_url'), width: 250}, {
		field: 'approved',
		headerName: t('school_page.cabinets_page.approved_status'),
		width: 300,
		renderCell: params => params.row.approved ? (
			<Chip
				label={t('school_page.cabinets_page.approved')}
				color={'primary'}
			/>
		) : (
			  <Chip label={t('school_page.cabinets_page.wait')}/>
			)
	}], []);

	const tabs = {
		true: {id: 1, label: t('school_page.cabinets_page.approved_cabinets'), value: 'true'},
		false: {id: 2, label: t('school_page.cabinets_page.wait'), value: 'false'}
	};

	const handleOpen = () => {
		if (!id || isNaN(+(id))) {
			toast.error('Непревиденная ошибка');
			return;
		}
		openModal(AddCabinetModal, {id:+id}, 'new_cabinet');
	};

	return (
		<Box
			className="App"
			sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
		>
			<PageHeader/>
			<Box sx={wrapperSx}>

				<Box>
					<Stack
						flexDirection={'column'}
						justifyContent={'center'}
						alignItems={'flex-start'}
					>
						<Typography
							sx={{mt: 3}}
							variant={'h5'}
						>
							<b>
								{t('school_page.cabinets_page.title')}
							</b>
						</Typography>
						<Typography variant="subtitle2">
							{t('school_page.cabinets_page.subtitle')}
						</Typography>
						<Stack
							flexDirection={'row'}
							sx={{mt: 2}}
						>
							{!userStore.isObserver && (
								<Button
									variant={'contained'}
									onClick={handleOpen}
								>
									{t('school_page.cabinets_page.apply-appeal')}
								</Button>
							)}
						</Stack>
					</Stack>
				</Box>

				<Tabs
					value={currentTab}
					onChange={(e, newValue) => setCurrentTab(newValue)}
					variant="scrollable"
					scrollButtons={false}
					sx={{ml: 2, mt: 2}}
				>
					{Object.values(tabs).map(tab => (
						<Tab
							label={tab.label}
							key={tab.id}
							value={tab.value}
						/>
					))}
				</Tabs>

				<ServerDataGrid
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					rows={data?.items ?? []}
					columns={columns}
					loading={cabinetsLoading}
					rowCount={data?.total ?? 0}
					disableRowSelectionOnClick
					disableColumnMenu={true}
					disableColumnFilter={true}
				/>
			</Box>
		</Box>
	);
}

export default SchoolCabinetsPage;