import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerGetGroupsWithStatusExamsByUserIdQueryResponse,
  GroupsControllerGetGroupsWithStatusExamsByUserIdQueryParams,
} from '../../ts/GroupsDTO/GroupsControllerGetGroupsWithStatusExamsByUserId'

export function getGroupsControllerGetGroupsWithStatusExamsByUserIdUrl() {
  return `/groups/exams` as const
}

/**
 * @summary Получение групп по userId
 * {@link /groups/exams}
 */
export async function groupsControllerGetGroupsWithStatusExamsByUserId(
  params: GroupsControllerGetGroupsWithStatusExamsByUserIdQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerGetGroupsWithStatusExamsByUserIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getGroupsControllerGetGroupsWithStatusExamsByUserIdUrl().toString(),
    params,
    ...requestConfig,
  })
  return res
}