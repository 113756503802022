import React, { useEffect, useRef } from 'react';
import { Chip, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TheoryExamStatus, type TheoryExamType } from '@/global/actions/types.api';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { apiStartExam, type StartTheoryExamResponseDto } from '../actions/api';
import LoadingButton from '../../global/components/LoadingButton';

interface handleStartProps {
    began_at: Date,
    current_time: Date
}

interface ModalProps {
    examInfo: TheoryExamType | null,
    open: boolean,
    onClose: () => void,
    handleStartExam: (props: handleStartProps) => void,
    videoDevices: MediaDeviceInfo[],
    audioDevices: MediaDeviceInfo[],
    getDevices: () => void,
    selectedVideoDevice: string | null,
    selectedAudioDevice: string | null,
    mediaDevicesPermissionStatus: 'granted' | 'denied' | 'default'
}

function TheoryExamBeginningModal({examInfo, open, onClose, handleStartExam, videoDevices, audioDevices, getDevices, selectedVideoDevice, selectedAudioDevice, mediaDevicesPermissionStatus} : ModalProps) {

    const navigate = useNavigate();

    const [startResponse, startLoading, startError, startExam] = useLoadingHook<StartTheoryExamResponseDto>(apiStartExam);

    const videoRefs = useRef<{ [key: string]: HTMLVideoElement | null }>({});

    const handleClick = () => {
        console.log(`Старт экзамена`);
        startExam(examInfo?.id);
    };

    useEffect(() => {
        if (mediaDevicesPermissionStatus) {
            getDevices();
        }
    }, [mediaDevicesPermissionStatus]);

    useEffect(() => {
        if (videoDevices.length > 0 && examInfo) {
            videoDevices.forEach(async (device) => {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: device.deviceId } });

                    // Присваиваем поток конкретному видео элементу через ref
                    if (videoRefs.current[device.deviceId]) {
                        videoRefs.current[device.deviceId]!.srcObject = stream;
                    }

                    // Останавливаем поток при размонтировании
                    return () => {
                        stream.getTracks().forEach((track) => track.stop());
                    };
                } catch (err) {
                    console.error('Error accessing device preview:', err);
                }
            });
        }
    }, [videoDevices, examInfo]);

    useEffect(() => {
        if (startResponse) {
            toast.success(startResponse.message);
            handleStartExam({
                current_time: startResponse.current_time,
                began_at: startResponse.began_at,
            });
            onClose();
        }

        if (startError) {
            toast.error(startError.response.data.message ? startError.response.data.message : 'Произошла непредвиденная ошибка');
        }
    }, [startResponse, startError]);

    function CreatedExamComponent() {
        return (
            <Stack
                flexDirection={'column'}
                gap={'16px'}
            >
                <Typography>
                    Ваш экзамен еще не запущен.
                </Typography>
                <Typography>
                    На прохождение экзамена вам дается 40 минут. По истечении времени таймера, расположенного сверху страницы экзаменов - экзамен автоматически завершится.
                </Typography>
                <LoadingButton
                    variant={'contained'}
                    onClick={handleClick}
                    sx={{
                        width: 'fit-content'
                    }}
                    loading={startLoading}
                >
                    Начать прохождение экзамена
                </LoadingButton>
            </Stack>
        );
    }

    function InProgressExamComponent() {
        return (
            <Stack
                flexDirection={'column'}
                gap={'16px'}
            >
                <Typography>
                    Ваш экзамен уже запущен.
                </Typography>
                <Typography>
                    На прохождение экзамена вам дается 40 минут. По истечении времени таймера, расположенного сверху страницы экзаменов - экзамен автоматически завершится.
                </Typography>
                <LoadingButton
                    variant={'contained'}
                    onClick={handleClick}
                    sx={{
                        width: 'fit-content'
                    }}
                    loading={startLoading}
                >
                    Возобновить прохождение экзамена
                </LoadingButton>
            </Stack>
        );
    }

    function NotReadyDevicesComponent() {
        return (
            <Stack>
                <Typography>
                    Для дальнейшего прохождения экзамена, необходимо подключить к вашему устройству - видеокамеру и микрофон, а также разрешить в браузере доступ к данным медиа-устройствам.
                </Typography>
            </Stack>
        );
    }

    if (examInfo) {
        return (
            <Dialog
                maxWidth={'xl'}
                fullWidth={true}
                open={open}
                onClose={onClose}
            >
                <DialogTitle>
                    <Typography
                        fontWeight={'bold'}
                    >
                        Информация об экзаменe
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {examInfo.status === TheoryExamStatus.Created && mediaDevicesPermissionStatus === 'granted' && (<CreatedExamComponent />)}
                    {examInfo.status === TheoryExamStatus.InProgress && mediaDevicesPermissionStatus === 'granted' &&  (<InProgressExamComponent />)}
                    {mediaDevicesPermissionStatus !== 'granted' && (<NotReadyDevicesComponent />)}
                    {mediaDevicesPermissionStatus === 'granted' && (
                        <Stack
                            mt={2}
                        >
                            <Divider
                                sx={{
                                    mb: 2
                                }}
                            />
                            <Typography>
                                Видеокамеры:
                            </Typography>
                            <Stack
                                mt={2}
                                flexDirection={'row'}
                                flexWrap={'wrap'}
                                alignItems={'center'}
                                gap={'16px'}
                            >
                                {videoDevices.map((device) => (
                                    <Stack
                                        key={device.deviceId}
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                        sx={{
                                            width: 'fit-content',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Typography
                                            fontSize={'14px'}
                                            fontWeight={'bold'}
                                        >
                                            {device.label || device.deviceId} 
                                            {' '}
                                            {selectedVideoDevice === device.deviceId && '- текущая камера'}
                                        </Typography>
                                        <video
                                            ref={(ref) => (videoRefs.current[device.deviceId] = ref)}
                                            style={{
                                                width: '480px',
                                                height: '100%',
                                                border: `3px solid ${selectedVideoDevice === device.deviceId ? '#044EBD' : '#a8a8a8'}`,
                                                background: `${selectedVideoDevice === device.deviceId ? '#044EBD' : '#a8a8a8'}`,
                                                borderRadius: '5px'
                                            }}
                                            autoPlay
                                            muted
                                        />
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    )}
                    {mediaDevicesPermissionStatus === 'granted' && (
                        <Stack
                            mt={2}
                        >
                            <Divider
                                sx={{
                                    mb: 2
                                }}
                            />
                            <Typography>
                                Аудиоустройства:
                            </Typography>
                            <Stack
                                mt={2}
                                flexDirection={'row'}
                                flexWrap={'wrap'}
                                alignItems={'center'}
                                gap={'16px'}
                            >
                                {audioDevices.map((device) => (
                                    <Chip
                                        key={device.deviceId}
                                        label={device.label || device.deviceId}
                                        color={selectedAudioDevice === device.deviceId ? 'primary' : 'default'}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    )}
                </DialogContent>
            </Dialog>
        );
    } else {
        return (
            <></>
        );
    }
}

export default TheoryExamBeginningModal;