import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  GroupsControllerChangeGroupDatesMutationRequest,
  GroupsControllerChangeGroupDatesMutationResponse,
} from '../../ts/GroupsDTO/GroupsControllerChangeGroupDates'

export function getGroupsControllerChangeGroupDatesUrl() {
  return `/groups/change-dates` as const
}

/**
 * @summary Изменение дат группы
 * {@link /groups/change-dates}
 */
export async function groupsControllerChangeGroupDates(
  data: GroupsControllerChangeGroupDatesMutationRequest,
  config: Partial<RequestConfig<GroupsControllerChangeGroupDatesMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<GroupsControllerChangeGroupDatesMutationResponse, ResponseErrorConfig<Error>, GroupsControllerChangeGroupDatesMutationRequest>({
    method: 'POST',
    url: getGroupsControllerChangeGroupDatesUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}