import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { AuthControllerCheckLoginMutationRequest, AuthControllerCheckLoginMutationResponse } from '../../ts/authDTO/AuthControllerCheckLogin'

export function getAuthControllerCheckLoginUrl() {
  return `/auth/check` as const
}

/**
 * @summary Проверка логина
 * {@link /auth/check}
 */
export async function authControllerCheckLogin(
  data: AuthControllerCheckLoginMutationRequest,
  config: Partial<RequestConfig<AuthControllerCheckLoginMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<AuthControllerCheckLoginMutationResponse, ResponseErrorConfig<Error>, AuthControllerCheckLoginMutationRequest>({
    method: 'POST',
    url: getAuthControllerCheckLoginUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}