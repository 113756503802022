import { CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { useId, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { PieChart } from '../types/statistic.type';
import { apiStatisticOverview } from '../services/statistic.service';

export function PieChartUsers() {
    const id = useId();
    const theme = useTheme();
    const {data, isLoading} = apiStatisticOverview();

    const chart = useMemo(() => {
        const chartData = [{
            x: 'Завершивших обучение студентов',
            y: data ? data.total_passed_students : 0
        }, {
            x: 'Обучающихся студентов',
            y: data ? (data.total_students - data.total_passed_students) : 0
        }];
        return PieChart(theme, id, {items: chartData});
    }, [data, theme, id]);

    return (
        <Paper
            sx={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                padding: 1
            }}
        >
            <Typography
                variant="h4"
                sx={{mb: 0.5}}
            >
                Статистика студентов
            </Typography>
            {isLoading && <CircularProgress/>}
            <Chart
                options={chart}
                series={chart.series}
                type="pie"
            />
        </Paper>
    );
}