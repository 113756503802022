import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {CancelOutlined, DeleteOutline} from "@mui/icons-material";
import React from "react";
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {apiDeleteGroup} from "@/modules/groups/services/group.service";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

interface Props {
    groupInfo: GroupDataDto
}

export function ConfirmDialog({open, close, groupInfo}: ModalSystemProps<Props>) {

    const navigate = useNavigate();

    const {mutate: deleteGroupApi, isPending: deleting} = apiDeleteGroup();

    const handleDelete = () => {
        deleteGroupApi({id: groupInfo.id}, {
            onSuccess: (res) => {
                toast.success(res.message);
                navigate('/groups');
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(5)}
        >
            <DialogTitle sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {groupInfo.title}
                {' '}
                <Typography
                    color={'gray'}
                    sx={{ml: 'auto'}}
                >
                    #
                    {groupInfo.id}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{mb: 1}}>
                    <i>
                        <b>
                            Внимание! Удаление группы возможно только на стадии набора.
                        </b>
                    </i>
                </Typography>
                <Typography>
                    Подтвердите удаление группы
                    <b>
                        {groupInfo.title}
                    </b>
                </Typography>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'center', pb: 4}}>
                <Button
                    startIcon={<CancelOutlined/>}
                    color="primary"
                    onClick={() => close(5)}
                    disabled={groupInfo.status !== 1}
                    variant={'outlined'}
                    sx={{mr: 5}}
                >
                    Закрыть
                </Button>
                <Button
                    startIcon={<DeleteOutline/>}
                    color="error"
                    onClick={() => {
                        handleDelete();
                    }}
                    disabled={groupInfo.status !== 1 || deleting}
                    variant={'contained'}
                >
                    Удалить группу
                </Button>
            </DialogActions>
        </Dialog>
    );
}