import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerGetFeeStudentInfoQueryResponse,
  StudentsControllerGetFeeStudentInfoPathParams,
} from '../../ts/StudentsDTO/StudentsControllerGetFeeStudentInfo'

export function getStudentsControllerGetFeeStudentInfoUrl({ studentId }: { studentId: StudentsControllerGetFeeStudentInfoPathParams['studentId'] }) {
  return `/students/fee/${studentId}` as const
}

/**
 * @summary Получение информации о студенте
 * {@link /students/fee/:studentId}
 */
export async function studentsControllerGetFeeStudentInfo(
  { studentId }: { studentId: StudentsControllerGetFeeStudentInfoPathParams['studentId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerGetFeeStudentInfoQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerGetFeeStudentInfoUrl({ studentId }).toString(),
    ...requestConfig,
  })
  return res
}