import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerGetTransactionByIdQueryResponse,
  TransactionsControllerGetTransactionByIdPathParams,
} from '../../ts/TransactionsDTO/TransactionsControllerGetTransactionById'

export function getTransactionsControllerGetTransactionByIdUrl({
  transactionId,
}: {
  transactionId: TransactionsControllerGetTransactionByIdPathParams['transactionId']
}) {
  return `/transactions/${transactionId}` as const
}

/**
 * @summary Получение подробностей покупки
 * {@link /transactions/:transactionId}
 */
export async function transactionsControllerGetTransactionById(
  { transactionId }: { transactionId: TransactionsControllerGetTransactionByIdPathParams['transactionId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<TransactionsControllerGetTransactionByIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getTransactionsControllerGetTransactionByIdUrl({ transactionId }).toString(),
    ...requestConfig,
  })
  return res
}