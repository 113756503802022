import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  NotificationsControllerCreateAppointmentMutationRequest,
  NotificationsControllerCreateAppointmentMutationResponse,
} from '../../ts/NotificationsDTO/NotificationsControllerCreateAppointment'

export function getNotificationsControllerCreateAppointmentUrl() {
  return `/notifications/create` as const
}

/**
 * @summary Создание уведомления
 * {@link /notifications/create}
 */
export async function notificationsControllerCreateAppointment(
  data: NotificationsControllerCreateAppointmentMutationRequest,
  config: Partial<RequestConfig<NotificationsControllerCreateAppointmentMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    NotificationsControllerCreateAppointmentMutationResponse,
    ResponseErrorConfig<Error>,
    NotificationsControllerCreateAppointmentMutationRequest
  >({ method: 'POST', url: getNotificationsControllerCreateAppointmentUrl().toString(), data, ...requestConfig })
  return res
}