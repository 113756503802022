import {Box, Stack, type Theme, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

export function AboutSystemBanner() {
    const {t} = useTranslation();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    return (
        <Box
            flexDirection={'column'}
            sx={{
                p: {xs: '20px', lg: '50px'},
                py: {xs: '44px', lg: '50px'},
                background: '#05304F',
                minHeight: '612px',
                borderRadius: '30px',
                mt: '100px',
                position: 'relative',
            }}
        >
            <img
                src={'/images/new-design/eagle.png'}
                alt={'Орел'}
                style={{
                    position: 'absolute',
                    right: isMobile ? '0px' : '50px',
                    top: 0,
                    width: isMobile ? '319px' : 'auto'
                }}
            />
            <Typography
                fontSize={{xs: '35px', lg: '59px'}}
                lineHeight={'98%'}
                color={'#fff'}
                sx={{
                    mt: {xs: '0', lg: '50px'}
                }}
            >
                {t('landing.schools-landing.about-system-title')}
            </Typography>
            <Typography
                fontSize={'17px'}
                lineHeight={'150%'}
                color={'#fff'}
                sx={{
                    mt: {xs: '24px', lg: '28px'}
                }}
            >
                {t('landing.schools-landing.information-title')}
            </Typography>
            <Stack
                flexDirection={{xs: 'column', lg: 'row'}}
                sx={{
                    mt: {xs: '40px', lg: '60px'}
                }}
                gap={{xs: '12px', lg: '20px'}}
            >
                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    sx={{
                        width: {xs: 'auto', lg: '640px'},
                        height: {xs: 'auto', lg: '118px'},
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        px: {xs: '15px', lg: '29px'},
                        py: {xs: '15px', lg: '0'}
                    }}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <svg
                        width={isMobile ? "45" : "60"}
                        height={isMobile ? "45" : "60"}
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            marginRight: isMobile ? '12px' : '24px',
                        }}
                    >
                        <circle
                            cx="30"
                            cy="30"
                            r="30"
                            fill="#F5F5F5"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M40.4516 34.1613H25.7419C24.8872 34.1613 24.1935 34.855 24.1935 35.7097C24.1935 36.5644 24.8872 37.2581 25.7419 37.2581H40.4516C41.3063 37.2581 42 36.5644 42 35.7097C42 34.855 41.3063 34.1613 40.4516 34.1613ZM40.4516 27.9677H25.7419C24.8872 27.9677 24.1935 28.6614 24.1935 29.5161C24.1935 30.3708 24.8872 31.0645 25.7419 31.0645H40.4516C41.3063 31.0645 42 30.3708 42 29.5161C42 28.6614 41.3063 27.9677 40.4516 27.9677ZM25.7419 24.871H40.4516C41.3063 24.871 42 24.1773 42 23.3226C42 22.4679 41.3063 21.7742 40.4516 21.7742H25.7419C24.8872 21.7742 24.1935 22.4679 24.1935 23.3226C24.1935 24.1773 24.8872 24.871 25.7419 24.871ZM20.3226 33.3871C19.0397 33.3871 18 34.4268 18 35.7097C18 36.9925 19.0397 38.0323 20.3226 38.0323C21.6054 38.0323 22.6452 36.9925 22.6452 35.7097C22.6452 34.4268 21.6054 33.3871 20.3226 33.3871ZM20.3226 27.1935C19.0397 27.1935 18 28.2333 18 29.5161C18 30.799 19.0397 31.8387 20.3226 31.8387C21.6054 31.8387 22.6452 30.799 22.6452 29.5161C22.6452 28.2333 21.6054 27.1935 20.3226 27.1935ZM20.3226 21C19.0397 21 18 22.0397 18 23.3226C18 24.6054 19.0397 25.6452 20.3226 25.6452C21.6054 25.6452 22.6452 24.6054 22.6452 23.3226C22.6452 22.0397 21.6054 21 20.3226 21Z"
                            fill="#1E9AF3"
                        />
                    </svg>

                    <Typography
                        fontSize={{sx: '15px', lg: '19px'}}
                        lineHeight={'140%'}
                        letterSpacing={'-0.02'}
                        color={'#05304F'}
                        fontWeight={'bold'}
                    >
                        {t('landing.schools-landing.cabinets')}
                    </Typography>
                    {!isMobile && (
                        <Typography
                            fontSize={{xs: '12px', lg: '19px'}}
                            lineHeight={'140%'}
                            letterSpacing={'-0.02'}
                            color={'#1E9AF3'}
                            sx={{
                                ml: 'auto'
                            }}
                        >
                            [01]
                        </Typography>
                    )}
                </Stack>
                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    sx={{
                        width: {xs: 'auto', lg: '640px'},
                        height: {xs: 'auto', lg: '118px'},
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        px: {xs: '15px', lg: '29px'},
                        py: {xs: '15px', lg: '0'}
                    }}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <svg
                        width={"45"}
                        height={"45"}
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            marginRight: isMobile ? '12px' : '24px',
                        }}
                    >
                        <circle
                            cx="30"
                            cy="30"
                            r="30"
                            fill="#F5F5F5"
                        />
                        <path
                            d="M18.4609 41H19.7571C20.5699 41 21.207 40.3547 21.207 39.517V37.6151C23.6456 37.7736 27.1715 37.8868 29.9945 37.8868C32.8285 37.8868 36.3545 37.7736 38.793 37.6151V39.517C38.793 40.3547 39.4191 41 40.2429 41H41.5391C42.3629 41 43 40.3547 43 39.517V32.9849C43 31.083 42.6266 30.0076 41.605 28.6491L40.6824 27.4151C40.276 25.3773 39.5509 23.2377 39.1664 22.4C38.5843 21.1207 37.4419 20.3623 35.981 20.1585C35.2451 20.068 32.8395 20 29.9945 20C27.1606 20 24.755 20.0793 24.019 20.1585C22.5581 20.3396 21.4157 21.1207 20.8336 22.4C20.4491 23.2377 19.7241 25.3773 19.3177 27.4151L18.395 28.6491C17.3735 30.0076 17 31.083 17 32.9849V39.517C17 40.3547 17.6371 41 18.4609 41ZM21.5366 26.4755C21.8002 25.2302 22.3274 23.6113 22.6899 22.9547C22.9865 22.4226 23.305 22.1962 23.8982 22.117C24.733 21.9925 26.6004 21.9245 29.9945 21.9245C33.3997 21.9245 35.267 21.9698 36.1019 22.117C36.684 22.2075 37.0026 22.4226 37.3101 22.9547C37.6837 23.6 38.178 25.2302 38.4634 26.4755C38.5624 26.8943 38.3975 27.0528 37.9692 27.0188C36.1458 26.9057 33.9709 26.7811 29.9945 26.7811C26.0292 26.7811 23.8543 26.9057 22.0309 27.0188C21.6025 27.0528 21.4487 26.8943 21.5366 26.4755ZM21.9869 34.9547C20.9434 34.9547 20.1635 34.1396 20.1635 33.0755C20.1635 32 20.9434 31.1962 21.9869 31.1962C23.0304 31.1962 23.8103 32 23.8103 33.0755C23.8103 34.1396 23.0304 34.9547 21.9869 34.9547ZM38.0131 34.9547C36.9697 34.9547 36.1897 34.1396 36.1897 33.0755C36.1897 32 36.9697 31.1962 38.0131 31.1962C39.0457 31.1962 39.8366 32 39.8366 33.0755C39.8366 34.1396 39.0457 34.9547 38.0131 34.9547ZM27.0837 34.4339C26.3148 34.4339 25.7765 33.8679 25.7765 33.0755C25.7765 32.2717 26.3148 31.717 27.0837 31.717H32.9164C33.6853 31.717 34.2235 32.2717 34.2235 33.0755C34.2235 33.8679 33.6853 34.4339 32.9164 34.4339H27.0837Z"
                            fill="#1E9AF3"
                        />
                    </svg>

                    <Typography
                        fontSize={{xs: '15px', lg: '19px'}}
                        lineHeight={'140%'}
                        letterSpacing={'-0.02'}
                        color={'#05304F'}
                        fontWeight={'bold'}
                        width={{xs: '197px', lg:'360px'}}
                    >
                        {t('landing.schools-landing.transports')}
                    </Typography>

                    {!isMobile && (
                        <Typography
                            fontSize={{xs: '14px', lg:'19px'}}
                            lineHeight={'140%'}
                            letterSpacing={'-0.02'}
                            color={'#1E9AF3'}
                            sx={{
                                ml: 'auto'
                            }}
                        >
                            [02]
                        </Typography>
                    )}
                </Stack>
            </Stack>

            <Stack
                flexDirection={{xs: 'column', lg: 'row'}}
                sx={{
                    mt: {xs: '12px', lg: '18px'}
                }}
                gap={{xs: '12px', lg: '20px'}}
            >
                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    sx={{
                        width: {xs: 'auto', lg: '640px'},
                        height: {xs: 'auto', lg: '118px'},
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        px: {xs: '10px', lg: '29px'},
                        py: {xs:'15px', lg: '0'}
                    }}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <svg
                        width={"45"}
                        height={"45"}
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            marginRight: isMobile ? '12px' : '24px',
                        }}
                    >
                        <circle
                            cx="30"
                            cy="30"
                            r="30"
                            fill="#F5F5F5"
                        />
                        <path
                            d="M32.5163 25.5712C32.5163 28.2705 30.3281 30.4586 27.6288 30.4586C24.9295 30.4586 22.7414 28.2705 22.7414 25.5712C22.7414 22.8719 24.9295 20.6837 27.6288 20.6837C30.3281 20.6837 32.5163 22.8719 32.5163 25.5712Z"
                            fill="#1E9AF3"
                            stroke="#1E9AF3"
                            strokeWidth="1.36747"
                        />
                        <path
                            d="M37.6572 27.7993V34.4847V27.7993Z"
                            fill="#1E9AF3"
                        />
                        <path
                            d="M37.6572 27.7993V34.4847"
                            stroke="#1E9AF3"
                            strokeWidth="2.73495"
                            strokeLinecap="round"
                        />
                        <path
                            d="M40.9999 31.1423H34.3145H40.9999Z"
                            fill="#1E9AF3"
                        />
                        <path
                            d="M40.9999 31.1423H34.3145"
                            stroke="#1E9AF3"
                            strokeWidth="2.73495"
                            strokeLinecap="round"
                        />
                        <path
                            d="M35.5655 38.5371C35.5739 38.5546 35.5748 38.566 35.5744 38.5737C35.5741 38.5828 35.5714 38.5963 35.5623 38.613C35.5433 38.6479 35.5014 38.6866 35.4362 38.7014L35.4361 38.7014C30.2965 39.8654 24.9617 39.8654 19.8221 38.7014L19.822 38.7014C19.7569 38.6866 19.715 38.6479 19.6959 38.6129C19.6869 38.5962 19.6842 38.5828 19.6838 38.5737C19.6835 38.566 19.6844 38.5546 19.6927 38.5371L19.6927 38.5371C20.2487 37.3774 21.1699 36.3432 22.3833 35.5563C23.8777 34.5872 25.7217 34.0546 27.6291 34.0546C29.5365 34.0546 31.3806 34.5872 32.875 35.5563C34.0883 36.3432 35.0096 37.3775 35.5655 38.5371ZM35.5655 38.5371L36.182 38.2415M35.5655 38.5371C35.5655 38.5371 35.5655 38.5371 35.5655 38.5371L36.182 38.2415M36.182 38.2415C36.41 38.7171 36.1016 39.2518 35.5871 39.3682M36.182 38.2415L35.5871 39.3682M35.5871 39.3682C30.3481 40.5548 24.9101 40.5548 19.6711 39.3682C19.1567 39.2518 18.8482 38.7171 19.0762 38.2415C19.691 36.9589 20.7015 35.832 22.0113 34.9826C23.6229 33.9374 25.5977 33.3708 27.6291 33.3708C29.6606 33.3708 31.6354 33.9374 33.247 34.9826L35.5871 39.3682Z"
                            fill="#1E9AF3"
                            stroke="#1E9AF3"
                            strokeWidth="1.36747"
                        />
                    </svg>

                    <Typography
                        fontSize={{xs: '15px', lg: '19px'}}
                        lineHeight={'140%'}
                        letterSpacing={'-0.02'}
                        color={'#05304F'}
                        fontWeight={'bold'}
                        sx={{
                            width: {xs: '224px', lg: '325px'}
                        }}
                    >
                        {t('landing.schools-landing.employees')}
                    </Typography>

                    {!isMobile && (
                        <Typography
                            fontSize={{xs: '14px', lg: '19px'}}
                            lineHeight={'140%'}
                            letterSpacing={'-0.02'}
                            color={'#1E9AF3'}
                            sx={{
                                ml: 'auto'
                            }}
                        >
                            [03]
                        </Typography>
                    )}
                </Stack>

                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    sx={{
                        width: {xs: 'auto', lg: '640px'},
                        height: {xs: 'auto', lg: '118px'},
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        px: {xs: '10px', lg: '29px'},
                        py: {xs: '15px', lg: '0'}
                    }}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <svg
                        width={"45"}
                        height={"45"}
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            marginRight: isMobile ? '12px' : '24px',
                        }}
                    >
                        <circle
                            cx="30"
                            cy="30"
                            r="30"
                            fill="#F5F5F5"
                        />
                        <path
                            d="M43.6629 25.9821L31.2722 20.2785C30.8703 20.0934 30.4423 20 29.9999 20C29.5576 20 29.1297 20.0934 28.7276 20.2785L16.337 25.9821C16.1322 26.0767 16 26.2831 16 26.5086C16 26.734 16.1322 26.9404 16.337 27.035L28.7276 32.7386C29.1295 32.9237 29.5576 33.0175 29.9999 33.0175C30.4423 33.0175 30.8703 32.9237 31.2724 32.7386L41.0511 28.2375V32.77C40.663 32.8138 40.3604 33.1441 40.3604 33.5432C40.3604 33.891 40.5905 34.192 40.9185 34.2879L40.2573 38.6515H43.302L42.6409 34.2879C42.9688 34.192 43.1989 33.891 43.1989 33.5432C43.1989 33.1441 42.8962 32.8138 42.5082 32.77V27.5666L43.663 27.035C43.8677 26.9404 44 26.734 44 26.5086C44 26.2831 43.8677 26.0767 43.6629 25.9821Z"
                            fill="#1E9AF3"
                        />
                        <path
                            d="M29.9996 35.0828C29.3846 35.0828 28.7886 34.952 28.2285 34.6943L22.168 31.9043V37.4626C22.168 38.6829 25.8553 40.2309 29.9996 40.2309C34.1439 40.2309 37.8313 38.6828 37.8313 37.4626V31.9043L31.7705 34.6943C31.2106 34.952 30.6148 35.0828 29.9996 35.0828Z"
                            fill="#1E9AF3"
                        />
                    </svg>

                    <Typography
                        fontSize={{xs: '15px', lg: '19px'}}
                        lineHeight={'140%'}
                        letterSpacing={'-0.02'}
                        color={'#05304F'}
                        fontWeight={'bold'}
                        width={{xs: 'auto', lg: '360px'}}
                    >
                        {t('landing.schools-landing.groups')}
                    </Typography>

                    {!isMobile && (
                        <Typography
                            fontSize={{xs: '14px', lg: '19px'}}
                            lineHeight={'140%'}
                            letterSpacing={'-0.02'}
                            color={'#1E9AF3'}
                            sx={{
                                ml: 'auto'
                            }}
                        >
                            [04]
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
}