import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerGetSchoolStudentsQueryResponse,
  StudentsControllerGetSchoolStudentsPathParams,
  StudentsControllerGetSchoolStudentsQueryParams,
} from '../../ts/StudentsDTO/StudentsControllerGetSchoolStudents'

export function getStudentsControllerGetSchoolStudentsUrl({ id }: { id: StudentsControllerGetSchoolStudentsPathParams['id'] }) {
  return `/students/${id}` as const
}

/**
 * @summary Получение студентов учебного центра
 * {@link /students/:id}
 */
export async function studentsControllerGetSchoolStudents(
  { id }: { id: StudentsControllerGetSchoolStudentsPathParams['id'] },
  params: StudentsControllerGetSchoolStudentsQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerGetSchoolStudentsQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getStudentsControllerGetSchoolStudentsUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}