import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerGetAllSchoolDriversQueryResponse,
  EmployeeControllerGetAllSchoolDriversPathParams,
  EmployeeControllerGetAllSchoolDriversQueryParams,
} from '../../ts/employeesDTO/EmployeeControllerGetAllSchoolDrivers'

export function getEmployeeControllerGetAllSchoolDriversUrl({ id }: { id: EmployeeControllerGetAllSchoolDriversPathParams['id'] }) {
  return `/employees/school/driver/${id}` as const
}

/**
 * @summary Получение всех водителей школы
 * {@link /employees/school/driver/:id}
 */
export async function employeeControllerGetAllSchoolDrivers(
  { id }: { id: EmployeeControllerGetAllSchoolDriversPathParams['id'] },
  params: EmployeeControllerGetAllSchoolDriversQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerGetAllSchoolDriversQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getEmployeeControllerGetAllSchoolDriversUrl({ id }).toString(),
    params,
    ...requestConfig,
  })
  return res
}