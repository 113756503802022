import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  UsersControllerGetUsersBySchoolIdQueryResponse,
  UsersControllerGetUsersBySchoolIdPathParams,
  UsersControllerGetUsersBySchoolIdQueryParams,
} from '../../ts/usersDTO/UsersControllerGetUsersBySchoolId'

export function getUsersControllerGetUsersBySchoolIdUrl({ schoolId }: { schoolId: UsersControllerGetUsersBySchoolIdPathParams['schoolId'] }) {
  return `/users/workers/${schoolId}` as const
}

/**
 * @summary Получить всех работников школы
 * {@link /users/workers/:schoolId}
 */
export async function usersControllerGetUsersBySchoolId(
  { schoolId }: { schoolId: UsersControllerGetUsersBySchoolIdPathParams['schoolId'] },
  params: UsersControllerGetUsersBySchoolIdQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerGetUsersBySchoolIdQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getUsersControllerGetUsersBySchoolIdUrl({ schoolId }).toString(),
    params,
    ...requestConfig,
  })
  return res
}