import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import {Button, Stack} from "@mui/material";
import React from "react";
import CorrectionDialog from "@/Group/components/GroupPage/CorrectionDialog";
import DeleteStudentDialogModal from "@/Group/components/GroupPage/DeleteStudentDialogModal";
import {useModal} from "@/global/providers/ModalProvider";
import {useTranslation} from "react-i18next";
import {useUserStore} from "@/global/store/UserStore";
import type {GroupDataDtoStatusEnum} from "@/global/api/gen/ts/GroupDataDto";

interface Props {
    row: StudentDto;
    status: GroupDataDtoStatusEnum
}

export function GridButtons({row,status}: Props) {

    const {openModal} = useModal();
    const {t} = useTranslation();

    const userStore = useUserStore((state) => state);

    const handleCorrectionOpen = (row: StudentDto) => {
        openModal(CorrectionDialog, {student: row}, `correction_${row.id}`);
    };

    const handleDeleteOpen = (row: StudentDto) => {
        openModal(DeleteStudentDialogModal, {student: row}, `delete_${row.id}`);
    };

    return (
        <Stack
            flexDirection={'row'}
            gap={'16px'}
        >
            <Button
                variant={'contained'}
                onClick={() => handleCorrectionOpen(row)}
                sx={{height: 'auto'}}
                disabled={(!!row.medical_certificate_number && !!row.medical_center_title && !!row.medical_center_bin && !!row.medical_examination_date) || userStore.isObserver}
            >
                {t('school_page.group_page.students_grid.add_medical_button')}
            </Button>

            <Button
                variant={'contained'}
                color={'error'}
                sx={{
                    height: 'auto'
                }}
                disabled={status === 4 || userStore.isObserver}
                onClick={() => handleDeleteOpen(row)}
            >
                {t('school_page.group_page.students_grid.delete_student')}
            </Button>
        </Stack>
    );
}
