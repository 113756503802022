import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { AuthControllerLoginMutationRequest, AuthControllerLoginMutationResponse } from '../../ts/authDTO/AuthControllerLogin'

export function getAuthControllerLoginUrl() {
  return `/auth/login` as const
}

/**
 * @summary Вход в систему
 * {@link /auth/login}
 */
export async function authControllerLogin(
  data: AuthControllerLoginMutationRequest,
  config: Partial<RequestConfig<AuthControllerLoginMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<AuthControllerLoginMutationResponse, ResponseErrorConfig<Error>, AuthControllerLoginMutationRequest>({
    method: 'POST',
    url: getAuthControllerLoginUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}