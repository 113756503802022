import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import '../styles/RegisterPage.scss';
import {Link} from 'react-router-dom';
import PageHeader from '../../global/components/PageHeader';
import {RegisterStudentComponent} from '../components/RegisterStudentComponent';

function RegisterAsStudentPage() {

    return (
        <Box
            className={'register App'}
            id={'register'}
            sx={{p: 5}}
        >
            <PageHeader />
            <Stack
                flexDirection={'row'}
                justifyContent={'center'}
                sx={{mt: '21px'}}
            >
                <Typography variant={'h6'}>
                    Регистрация в качестве студента
                </Typography>
            </Stack>

            <Stack>
                <Link
                    to={'/landing'}
                    style={{width: 'fit-content', color: 'grey'}}
                >
                    Вернуться на главную страницу
                </Link>
            </Stack>

            <RegisterStudentComponent/>
        </Box>
    );
}

export default RegisterAsStudentPage;