import React, {useMemo, useState} from 'react';
import {Avatar, Box, TextField} from '@mui/material';
import {type GridColDef, type GridRowParams} from '@mui/x-data-grid';
import {getName} from '@/global/helpers/text';
import {apiGetSchoolUserList} from '@/modules/users/services/user.service';
import type {UserDto} from '@/global/api/gen/ts/UserDto';
import {ServerDataGrid} from '@/global/components/ServerDataGrid';
import {useNavigate} from 'react-router-dom';

interface Props {
	setUser?: (user: UserDto) => void;
}

function UsersList({setUser}:Props) {
	const [filter, setFilter] = useState<string>('');
	const navigate = useNavigate();

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25, page: 0
	});

	const {data, isLoading} = apiGetSchoolUserList({
		offset: paginationModel.page * paginationModel.pageSize, limit: paginationModel.pageSize, q: filter
	});

	const columns: GridColDef<UserDto>[] = useMemo(() =>  [{field: 'id', headerName: 'ID', width: 110, sortable: true}, {
		field: 'fio', headerName: 'ФИО', minWidth: 200, flex: 1, sortable: true, renderCell: ({row}) => {
			const name = getName(row);
			return (
				<React.Fragment>
					<Avatar
						sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
					>
						{name.partialName}
					</Avatar>
					{name.fullName}
				</React.Fragment>
			);
		}
	}, {field: 'iin', headerName: 'ИИН', minWidth: 250}, {
		field: 'email',
		headerName: 'Электронная почта',
		minWidth: 250
	}], []);

	const selectUser = (user: GridRowParams<UserDto>) => {
		if (setUser) {
			setUser(user.row);
			return;
		}
		navigate(`/change-password/${user.row.id}`);
	};

	return (
		<Box>
			<TextField
				type={'text'}
				onChange={(e) => {setFilter(e.target.value);}}
				value={filter}
				label={'Поиск'}
				sx={{mt: 1}}
			/>

			<ServerDataGrid
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				rows={data?.items ?? []}
				onRowClick={selectUser}
				columns={columns}
				loading={isLoading}
				rowCount={data?.total ?? 0}
				disableRowSelectionOnClick
				disableColumnMenu={true}
				sx={{mt: 2}}
				disableColumnFilter={true}
			/>
		</Box>
	);
}

export default UsersList;