import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  TransactionsControllerCreateTransactionMutationRequest,
  TransactionsControllerCreateTransactionMutationResponse,
} from '../../ts/TransactionsDTO/TransactionsControllerCreateTransaction'

export function getTransactionsControllerCreateTransactionUrl() {
  return `/transactions/create` as const
}

/**
 * @summary Создание платежа
 * {@link /transactions/create}
 */
export async function transactionsControllerCreateTransaction(
  data: TransactionsControllerCreateTransactionMutationRequest,
  config: Partial<RequestConfig<TransactionsControllerCreateTransactionMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    TransactionsControllerCreateTransactionMutationResponse,
    ResponseErrorConfig<Error>,
    TransactionsControllerCreateTransactionMutationRequest
  >({ method: 'POST', url: getTransactionsControllerCreateTransactionUrl().toString(), data, ...requestConfig })
  return res
}