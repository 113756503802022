import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { SchoolsControllerGetStatisticQueryResponse } from '../../ts/schoolsDTO/SchoolsControllerGetStatistic'

export function getSchoolsControllerGetStatisticUrl() {
  return `/schools/statistic` as const
}

/**
 * {@link /schools/statistic}
 */
export async function schoolsControllerGetStatistic(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<SchoolsControllerGetStatisticQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getSchoolsControllerGetStatisticUrl().toString(),
    ...requestConfig,
  })
  return res
}