import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Edit, Report } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import { useSnackbarStore } from '@/global/store/SnackbarStore';
import useLoadingHook from '../../../global/hooks/UseLoadingHook';
import { getQuestionById, getQuestionsSearch, updateQuestionCategory } from '../../actions/api';
import { type TestQuestionPreviewDto, TestQuestionsFilterSort } from '../../actions/testQuestionsSearch.type';
import type { TestQuestionAnswerDto } from '../../actions/testQuestionAnswer.type';
import TestLanguageSelector, { type TestLanguages } from '../components/TestLanguageSelector';
import AnswerButton from '../components/AnswerButton';
import { MEDIA_URL } from '@/global/actions/apiVariables';
import QuestionCategorySelect from '../components/QuestionsCategorySelect';
import testStyles from '../TestStyles';
import { QuestionReportDialog } from '../components/QuestionReportDialog';
import SortSelect from '../components/SortSelect';
import SearchInput from '../../../global/components/SearchInput';
import PageHeader from '../../../global/components/PageHeader';
import { wrapperSx } from '@/global/helpers/globalstyles';

interface Props {
    questionId?: number;
}

export default function QuestionOverview() {
    const navigate = useNavigate();
    const params = useParams();
    const questionId = !params.id ? 1 : params.id;
    const snackbar = useSnackbarStore((state) => state);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [language, setLanguage] = useState<TestLanguages>('ru');
    const [categoryId, setCategoryId] = useState(-1);
    const [sort, setSort] = useState<TestQuestionsFilterSort | null>(TestQuestionsFilterSort.id_asc);
    const [search, setSearch] = useState<string>('');
    const [questions, questionsLoading, questionsError, fetchQuestions] = useLoadingHook(getQuestionsSearch);

    useEffect(() => {
        fetchQuestions({
            count: 4500,
            offset: 0,
            sort: sort ? [sort] : undefined,
            category_id: categoryId === -1 ? undefined : categoryId,
            q: search || undefined
        });
        console.log(questions);
    }, [categoryId, sort, search]);
    const [question, questionLoading, questionError, fetchQuestion] = useLoadingHook(getQuestionById);

    useEffect(() => {
        if(questionId){
            fetchQuestion(questionId);
        }

    }, [questionId]);

    useEffect(() => {
        if (questions && !questionId && !questionsLoading) {
            goQuestion(questions.items[0]?.id);
        }
    }, [questions]);

    const goQuestion = (id: number) => {
        navigate(`/questions/demo/${id}`);
    };

    const updateCategory = (newValue: number) => {
        if (!question) return;
        updateQuestionCategory(question.id, newValue)
            .then(r => {
                console.log(r);
                snackbar.successMessage('Категория успешно изменена!');
            })
            .catch(err => {
                console.error(err);
            });
    };

    const report = () => {
        if (!question?.id) return;
        setOpenModal(true);
    };
    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <Box sx={{mt: 3}}>
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                        sx={{mb: 2}}
                    >
                        <SearchInput
                            placeholder="Поиск по ID/названию"
                            sx={{width: '40%', minWidth: 250, maxWidth: 400, mr: 2}}
                            onChange={(e) => setSearch(e.currentTarget.value)}
                            size="small"
                        />
                        <SortSelect
                            items={TestQuestionsFilterSort}
                            sx={{maxWidth: 300, ml: 'auto', border: 'none'}}
                            onChange={(value) => setSort(value as TestQuestionsFilterSort | null)}
                            value={sort}
                        />
                    </Stack>
                    <Divider sx={{mt: 1.5, mb: 2.5}}/>
                    <Box
                        component="ul"
                        sx={testStyles.questionsList}
                    >
                        {questions && questions.items.map((item: TestQuestionPreviewDto) => (
                            <Box
                                key={item.id}
                                component="li"
                                onClick={() => goQuestion(item.id)}
                                className={`${item.id === question?.id ? 'active' : ''} clickable`}
                            >
                                <Typography className={'text'}>
                                    {item.id}
                                </Typography>
                            </Box>
                              )
                            )}
                    </Box>
                    {(questions?.items && questions.items.length === 0) && (
                    <Box sx={{mt: -3}}>
                        <Typography>
                            Не найдены вопросы по данным критериям!
                        </Typography>
                        <Divider sx={{mt: 1.5, mb: 5}}/>
                    </Box>
)}
                    {question && (
                    <Grid
                        container
                        spacing={4}
                        sx={testStyles.testContent}
                    >
                        <Grid
                            item
                            xs={12}
                            md={7}
                            lg={7}
                            xl={6}
                            className="side left"
                        >
                            <Stack
                                className="question"
                                alignItems="center"
                            >
                                <TestLanguageSelector
                                    previewOnly
                                    value={language}
                                    onChange={setLanguage}
                                />
                                <Typography
                                    variant="body1"
                                    sx={{mt: 1.5, mb: 1}}
                                    fontSize={17}
                                >
                                            Вопрос
                                            #
                                    {question.id}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    align="center"
                                    sx={{mb: 1}}
                                >
                                    {question[`${language}_question`]}
                                </Typography>
                                <video
                                    muted
                                    controls
                                    preload="auto"
                                    style={{margin: 15, width: 750, height: 400}}
                                    src={`${MEDIA_URL}/get/${question.task_file_id}.mp4`}
                                />
                                <video
                                    muted
                                    controls
                                    preload="auto"
                                    style={{margin: 15, width: 750, height: 400}}
                                    src={`${MEDIA_URL}/get/${question.answer_file_id}.mp4`}
                                />
                            </Stack>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            lg={5}
                            xl={6}
                            className="side right"
                        >
                            <Button
                                startIcon={<Report/>}
                                onClick={report}
                                color="error"
                                variant="outlined"
                                sx={{mb: 1}}
                            >
                                Сообщить об ошибке
                            </Button>
                            <Button
                                startIcon={<Edit/>}
                                sx={{mb: 2}}
                                onClick={() => navigate(`/questions/${question.id}`)}
                            >
                                Редактировать вопрос
                            </Button>
                            <Typography
                                variant="h5"
                                align="center"
                                sx={{mb: 1}}
                            >
                                Категория
                            </Typography>
                            <QuestionCategorySelect
                                categoryId={question.category_id}
                                onChange={updateCategory}
                            />
                            <Stack
                                className="answers"
                                sx={{mt: 4, mb: 4}}
                            >
                                {question?.answers?.sort((a: TestQuestionAnswerDto, b: TestQuestionAnswerDto) => a.id - b.id)
                                            .map((answer: TestQuestionAnswerDto, i: number) => (
                                                <AnswerButton
                                                    key={answer.id}
                                                    i={i}
                                                    label={answer[`${language}_text`] || 'ОТСУТСТВУЕТ ТЕКСТ У КНОПКИ'}
                                                    correct={answer.is_correct}
                                                />
                                              )
                                            )}
                            </Stack>
                            <Stack
                                className="explanation"
                                sx={{maxWidth: '90%'}}
                            >
                                <Typography
                                    variant="h5"
                                    align="center"
                                    sx={{mb: 1}}
                                >
                                            Объяснение
                                </Typography>
                                <Typography align="justify">
                                    {question[`${language}_explanation`]}
                                </Typography>
                            </Stack>
                            {question?.explanation_file_id && (
                            <video
                                muted
                                controls
                                preload="auto"
                                style={{margin: 15, width: 600, height: 350}}
                                src={`${MEDIA_URL}/get/${question?.explanation_file_id}.mp4`}
                            />
)}
                        </Grid>
                    </Grid>
                          )}
                </Box>
                <QuestionReportDialog
                    questionId={question?.id}
                    open={openModal}
                    close={() => {setOpenModal(false);}}
                />
            </Box>
        </Box>
    );
}