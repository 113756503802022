import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  UsersControllerGetUserTrialTriesQueryResponse,
  UsersControllerGetUserTrialTriesPathParams,
} from '../../ts/usersDTO/UsersControllerGetUserTrialTries'

export function getUsersControllerGetUserTrialTriesUrl({ userId }: { userId: UsersControllerGetUserTrialTriesPathParams['userId'] }) {
  return `/users/trial/tries/${userId}` as const
}

/**
 * @summary Получить количество попыток пользователя
 * {@link /users/trial/tries/:userId}
 */
export async function usersControllerGetUserTrialTries(
  { userId }: { userId: UsersControllerGetUserTrialTriesPathParams['userId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerGetUserTrialTriesQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getUsersControllerGetUserTrialTriesUrl({ userId }).toString(),
    ...requestConfig,
  })
  return res
}