import ReactDOM from 'react-dom/client';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: 'https://ce37a178c1a55b3518405ded98c705f8@sentry.418.one/43',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: import.meta.env.VITE_APP_MODE === 'development' ? 1 : 0.1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    environment: import.meta.env.VITE_APP_MODE || 'production',

    replaysOnErrorSampleRate: import.meta.env.VITE_APP_MODE === 'development' ? 0 : 0.05,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: import.meta.env.VITE_APP_MODE === 'development' ? 0 : 0.05,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
        Sentry.replayIntegration({
            // Additional Replay configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false
        })
    ]
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App/>);
