import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerCreateTeacherMutationRequest,
  EmployeeControllerCreateTeacherMutationResponse,
} from '../../ts/employeesDTO/EmployeeControllerCreateTeacher'

export function getEmployeeControllerCreateTeacherUrl() {
  return `/employees/create/teacher` as const
}

/**
 * @summary Создание учителя
 * {@link /employees/create/teacher}
 */
export async function employeeControllerCreateTeacher(
  data: EmployeeControllerCreateTeacherMutationRequest,
  config: Partial<RequestConfig<EmployeeControllerCreateTeacherMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerCreateTeacherMutationResponse, ResponseErrorConfig<Error>, EmployeeControllerCreateTeacherMutationRequest>({
    method: 'POST',
    url: getEmployeeControllerCreateTeacherUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}