import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  NotificationsControllerMarkAsReadMutationRequest,
  NotificationsControllerMarkAsReadMutationResponse,
} from '../../ts/NotificationsDTO/NotificationsControllerMarkAsRead'

export function getNotificationsControllerMarkAsReadUrl() {
  return `/notifications/mark` as const
}

/**
 * @summary Пометить уведомление как прочитанное
 * {@link /notifications/mark}
 */
export async function notificationsControllerMarkAsRead(
  data: NotificationsControllerMarkAsReadMutationRequest,
  config: Partial<RequestConfig<NotificationsControllerMarkAsReadMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<NotificationsControllerMarkAsReadMutationResponse, ResponseErrorConfig<Error>, NotificationsControllerMarkAsReadMutationRequest>({
    method: 'POST',
    url: getNotificationsControllerMarkAsReadUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}