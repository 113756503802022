import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { UsersControllerGetStudentByIinQueryResponse, UsersControllerGetStudentByIinPathParams } from '../../ts/usersDTO/UsersControllerGetStudentByIin'

export function getUsersControllerGetStudentByIinUrl({ iin }: { iin: UsersControllerGetStudentByIinPathParams['iin'] }) {
  return `/users/students/${iin}` as const
}

/**
 * @summary Получить пользователя по ИИН
 * {@link /users/students/:iin}
 */
export async function usersControllerGetStudentByIin(
  { iin }: { iin: UsersControllerGetStudentByIinPathParams['iin'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerGetStudentByIinQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getUsersControllerGetStudentByIinUrl({ iin }).toString(),
    ...requestConfig,
  })
  return res
}