import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { EmployeeControllerFireDriverByIdMutationResponse } from '../../ts/employeesDTO/EmployeeControllerFireDriverById'

export function getEmployeeControllerFireDriverByIdUrl() {
  return `/employees/fire/driver` as const
}

/**
 * @summary Увольнение водителя
 * {@link /employees/fire/driver}
 */
export async function employeeControllerFireDriverById(config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<EmployeeControllerFireDriverByIdMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getEmployeeControllerFireDriverByIdUrl().toString(),
    ...requestConfig,
  })
  return res
}