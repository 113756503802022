import React from 'react';
import {useParams} from 'react-router-dom';
import {Alert, Box, CircularProgress, Stack, type SxProps, type Theme, Typography} from '@mui/material';
import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {formatDate, maskName} from '@/global/helpers/text';
import {apiGetCertificateCheck} from "@/modules/certificates/services/certificates.service";

const titleSx: SxProps<Theme> = {
    width: 181,
    fontWeight: 500,
    color: '#000',
    fontSize: 14,
    mr: 5
};

const valueSx: SxProps<Theme> = {
    fontSize: 14
};

function CertificateCheckPage() {

    const {id} = useParams();

    const {data:certificateInfo, error:certificateError, isLoading: certificateLoading} = apiGetCertificateCheck(id ?? '');

    if (certificateLoading) {
        return (
            <Box
                className="App"
                sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
            >
                <PageHeader />
                <Box sx={wrapperSx}>
                    <CircularProgress />
                </Box>
            </Box>
        );
    }

    if (certificateError) {
        return (
            <Box
                className="App"
                sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
            >
                <PageHeader />
                <Box sx={wrapperSx}>
                    <Stack
                        sx={{mb: 2}}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                    >
                        <Typography
                            sx={{mt: 3}}
                            variant={'h5'}
                        >
                            <b>
                                Свидетельство об окончании обучения
                            </b>
                        </Typography>
                    </Stack>
                    <Alert
                        severity={'error'}
                    >
                        Внимание! Данное свидетельство отсутствует в базе данных.
                    </Alert>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <Stack
                    sx={{mb: 2}}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                >
                    <Typography
                        sx={{mt: 3}}
                        variant={'h5'}
                    >
                        <b>
                            Свидетельство об окончании обучения
                        </b>
                    </Typography>
                </Stack>

                <Alert sx={{mb: 2}}>
                    Данное свидетельство присутствует в базе данных. Пожалуйста, сверьте данные из свидетельства с информацией ниже.
                </Alert>

                <Stack
                    gap={'16px'}
                    sx={{background: '#fff', width: 'fit-content', p: '8px 16px', borderRadius: '16px'}}
                >
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} >
                        <b>
                            Информация по свидетельству
                        </b>
                    </Typography>
                    <Stack flexDirection="row">
                        <Typography sx={titleSx}>
                            <b>
                                Наименование учебной организации:
                            </b>
                        </Typography>
                        <Typography sx={valueSx}>
                            {certificateInfo?.group?.school?.title}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row">
                        <Typography sx={titleSx}>
                            <b>
                                Адрес учебной организации:
                            </b>
                        </Typography>
                        <Typography sx={valueSx}>
                            {certificateInfo?.group?.school?.region}
                            , 
                            {' '}
                            {certificateInfo?.group?.school?.locality}
                            , 
                            {' '}
                            {certificateInfo?.group?.school?.address}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row">
                        <Typography sx={titleSx}>
                            <b>
                                БИН/ИИН учебной организации:
                            </b>
                        </Typography>
                        <Typography sx={valueSx}>
                            {certificateInfo?.group?.school?.bin}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row">
                        <Typography sx={titleSx}>
                            <b>
                                Ф.И.О. курсанта:
                            </b>
                        </Typography>
                        <Typography sx={valueSx}>
                            {certificateInfo ? maskName(certificateInfo) : ''}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row">
                        <Typography sx={titleSx}>
                            <b>
                                Номер группы:
                            </b>
                        </Typography>
                        <Typography sx={valueSx}>
                            {certificateInfo?.group?.title}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row">
                        <Typography sx={titleSx}>
                            <b>
                                Категория/подкатегория обучения:
                            </b>
                        </Typography>
                        <Typography sx={valueSx}>
                            {certificateInfo?.group?.category_id}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row">
                        <Typography sx={titleSx}>
                            <b>
                                Срок обучения:
                            </b>
                        </Typography>
                        <Typography sx={valueSx}>
                            {formatDate(certificateInfo?.group?.start_date)}
                            {' '}
                            - 
                            {' '}
                            {formatDate(certificateInfo?.group?.end_date)}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row">
                        <Typography sx={titleSx}>
                            <b>
                                Серия и номер свидетельства:
                            </b>
                        </Typography>
                        <Typography sx={valueSx}>
                            QZ
                            {certificateInfo?.certificate_number}
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row">
                        <Typography sx={titleSx}>
                            <b>
                                № протокола и дата проведения экзамена:
                            </b>
                        </Typography>
                        <Typography sx={valueSx}>
                            №
                            {certificateInfo?.group?.title}
                            {' '}
                            от
                            {formatDate(certificateInfo?.group?.end_date)}
                            {' '}
                            г.
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}

export default CertificateCheckPage;