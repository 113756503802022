import { useState } from 'react';
import type { AxiosResponse } from 'axios';

function useLoadingHook<T>(axiosMethod: (...args: any[]) => Promise<AxiosResponse<T>>): [T | null, boolean, any, (...args: any[]) => Promise<void>, () => void] {
    const [dataState, setDataState] = useState<T | null>(null);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<any>(null);

    const execute = async (...args: any[]) => {
        setLoadingState(true);
        setErrorState(null);

        try {
            const response = await axiosMethod(...args);
            console.log(response);
            setDataState(response.data);
        } catch (error) {
            setErrorState(error);
        } finally {
            setLoadingState(false);
        }
    };

    const reset = () => {
        setDataState(null);
        setLoadingState(false);
        setErrorState(null);
    };

    return [dataState, loadingState, errorState, execute, reset];
}

export default useLoadingHook;