import React from 'react';
import { DataGrid, type GridColDef } from '@mui/x-data-grid';
import { CircularProgress, Stack, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

import { formatDateTime } from '@/global/helpers/text';

interface TransactionsGridProps {
    rows: any[]
}

function TransactionsGrid({rows}: TransactionsGridProps) {

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
        },
        {
            field: 'amount',
            headerName: 'Сумма',
            width: 160,
            sortable: false,
            renderCell: ({row}) => {
                return (
                    <Typography
                        variant={'subtitle2'}
                        sx={{color: row.type === 'topup' ? 'green' : 'red'}}
                    >
                        {row.type === 'topup' ? '+' : '-'} 
                        {' '}
                        {row.amount} 
                        {' '}
                        {'тг.'}
                    </Typography>
                );
            }
        },
        {
            field: 'type',
            headerName: 'Тип транзакции',
            width: 200,
            sortable: false,
            renderCell: ({row}) => {
                if (row.type === 'topup') {
                    return (
                        <Typography variant={'subtitle2'}>
                            Пополнение баланса
                        </Typography>
                    );
                }

                if (row.type === 'buy') {
                    return (
                        <Typography variant={'subtitle2'}>
                            Покупка продукта
                        </Typography>
                    );
                }

                if (row.type === 'withdraw') {
                    return (
                        <Typography variant={'subtitle2'}>
                            Списание средств
                        </Typography>
                    );
                }
            }
        },
        {
            field: 'status',
            headerName: 'Статус',
            width: 300,
            sortable: false,
            renderCell: ({row}) => {
                if (row.status === 'PENDING') {
                    return (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'10px'}
                        >
                            <CircularProgress style={{width: '25px', height: '25px'}}/>
                            <Typography variant={'subtitle2'}>
                                Ожидание подтверждения от банка
                            </Typography>
                        </Stack>
                    );
                }

                if (row.status === 'CAPTURED') {
                    return (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'10px'}
                            sx={{color: 'green'}}
                        >
                            <DoneIcon style={{width: '25px', height: '25px'}}/>
                            <Typography variant={'subtitle2'}>
                                Оплачено
                            </Typography>
                        </Stack>
                    );
                }

                if (row.status === 'CANCELLED') {
                    return (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'10px'}
                            sx={{color: 'red'}}
                        >
                            <DoNotDisturbIcon style={{width: '25px', height: '25px'}}/>
                            <Typography variant={'subtitle2'}>
                                Отмена
                            </Typography>
                        </Stack>
                    );
                }
            }
        },
        {
            field: 'createdAt',
            headerName: 'Дата',
            width: 200,
            sortable: false,
            renderCell: ({row}) => {
                return (
                    <Typography variant='subtitle2'>
                        {formatDateTime(row.createdAt)}
                    </Typography>
                );
            }
        },
    ];

    return (
        <DataGrid
            columns={columns}
            rows={rows}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 20,
                    },
                },
            }}
            localeText={{
                MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from} - ${to} из ${count}`,
                },
            }}
            autoHeight={true}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            disableColumnMenu={true}
            disableColumnFilter={true}
        />
    );
}

export default TransactionsGrid;