import {BACKEND_URL} from '@/global/actions/apiVariables';
import axiosInstance from '../../global/actions/main.api';

export const getCertificate = (studentId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/pdf/${studentId}`, {responseType: 'arraybuffer'});
};

export const getCertificateA4 = (studentId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/a4/pdf/${studentId}`, {responseType: 'arraybuffer'});
};

export const getEnrollment = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/pdf/enrollment/${groupId}`, {responseType: 'arraybuffer'});
};

export const getExamsProtocol = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/pdf/protocol/${groupId}`, {responseType: 'arraybuffer'});
};

export const getGraduation = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/pdf/graduation/${groupId}`, {responseType: 'arraybuffer'});
};