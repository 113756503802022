import {type ChangeEvent, useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Stack, TextField, Typography} from '@mui/material';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {InfoOutlined} from '@mui/icons-material';
import {toast} from 'react-toastify';

import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import PageInsideHeader from '../../global/components/PageInsideHeader';
import {convertFromDateTimeToDate} from '@/global/helpers/text';
import {apiChangeEmployeeInfo, apiGetEmployeeInfo} from "@/modules/employees/services/employees.service";
import type {
    EmployeeControllerGetEmployeeInfoQueryParamsTypeEnum
} from "@/global/api/gen/ts/employeesDTO/EmployeeControllerGetEmployeeInfo";
import type {EmployeeChangeBodyDto} from "@/global/api/gen/ts/EmployeeChangeBodyDto";
import {LoadingButton} from "@mui/lab";
import {CategoryCheckboxes} from "@/modules/employees/components/CategoryCheckboxes";
import {isEmployeeType} from "@/global/helpers/others";

function EmployeeEditInfo() {

    const {id, employeeId} = useParams();
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type') as EmployeeControllerGetEmployeeInfoQueryParamsTypeEnum | null;
    const navigate = useNavigate();

    const {data: employeeInfo, isLoading: employeeLoading} = apiGetEmployeeInfo(+(employeeId ?? 0), {
        schoolId: +(id ?? 0),
        type: type ?? 'teachers'
    });

    const [newEmployeeInfo, setNewEmployeeInfo] = useState<EmployeeChangeBodyDto>({
        first_name: '',
        last_name: '',
        patronymic: '',
        qualification_certificate: '',
        qualification_certificate_issuer: '',
        qualification_certificate_issue_date: '',
        qualification_certificate_end_date: '',
        iin: '',
        driver_license_series: '',
        driver_license_number: '',
        driver_license_date_of_issue: '',
        driver_license_expires_date: '',
        driver_license_categories: [],
        id: +(employeeId ?? 0),
        school_id: +(id ?? 0),
        type: type ?? 'teachers',
    });

    const [isChanging, setIsChanging] = useState<boolean>(false);
    const {mutate: submitChanges, isPending: submitLoading} = apiChangeEmployeeInfo();

    useEffect(() => {
        if (!isEmployeeType(type)) {
            toast.error(`Некорректная ссылка на страницу. Попробуйте перезайти из таблицы соответствующих сотрудников.`);
            navigate(-1);
        }
    }, []);

    useEffect(() => {
        if (employeeInfo) {
            setNewEmployeeInfo({
                first_name: employeeInfo.first_name,
                last_name: employeeInfo.last_name,
                patronymic: employeeInfo.patronymic ?? '',
                qualification_certificate: employeeInfo.qualification_certificate ?? '',
                qualification_certificate_issuer: employeeInfo?.qualification_certificate_issuer ?? '',
                qualification_certificate_issue_date: employeeInfo?.qualification_certificate_issue_date ?? '',
                qualification_certificate_end_date: employeeInfo?.qualification_certificate_end_date ?? '',
                iin: employeeInfo.iin ?? '',
                driver_license_series: employeeInfo.driver_license_series ?? '',
                driver_license_number: employeeInfo.driver_license_number ?? '',
                driver_license_date_of_issue: employeeInfo.driver_license_date_of_issue ?? '',
                driver_license_expires_date: employeeInfo.driver_license_expires_date ?? '',
                driver_license_categories: employeeInfo.driver_license_categories
                    ? employeeInfo.driver_license_categories
                    : [],
                id: +(employeeId ?? 0),
                school_id: +(id ?? 0),
                type: type ?? 'teachers',
            });
        }
    }, [employeeInfo]);

    if (!employeeInfo || employeeLoading) {
        return (
            <Box
                className="App"
                sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
            >
                <PageHeader/>
                <Box sx={wrapperSx}>
                    <PageInsideHeader text={'Корректировка информации по сотруднику'}/>
                    <CircularProgress/>
                </Box>
            </Box>
        );
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!isChanging) {
            toast.error(`Вы не можете редактировать данное поле не в режиме изменения.`);
            return;
        } else {
            setNewEmployeeInfo((prev) => {
                return {
                    ...prev, [e.target.name]: e.target.value
                };
            });
        }
    };

    const handleCategoriesChange = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = newEmployeeInfo.driver_license_categories?.concat(name) ?? [name];

            setNewEmployeeInfo((prev) => {
                return {
                    ...prev, driver_license_categories: newCategories
                };
            });
        }

        if (!checked) {
            const isIncluded = newEmployeeInfo?.driver_license_categories?.includes(name);

            if (isIncluded && newEmployeeInfo.driver_license_categories) {
                const newCategories: string[] = [];

                for (let i = 0; i < newEmployeeInfo.driver_license_categories.length; i++) {
                    const element = newEmployeeInfo.driver_license_categories[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setNewEmployeeInfo((prev) => {
                    return {
                        ...prev, driver_license_categories: newCategories
                    };
                });
            }
        }
    };

    const handleSubmit = () => {
        if (!employeeId || !id || !type) {
            toast.error('Произошла непредвиденная ошибка. Обновите страницу.');
            return;
        }
        const dto = {
            ...newEmployeeInfo, id: +(employeeId)
        };
        submitChanges({...dto, school_id: +(id), type}, {
            onSuccess: () => {
                setIsChanging(false);
                toast.success('Успешно обновлены данные сотрудника.');
                navigate(`/schools/${id}`);
            },
        });
    };

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Box sx={{...wrapperSx, pb: 3}}>
                <PageInsideHeader text={'Корректировка информации по сотруднику'}/>
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    sx={{my: 2}}
                >
                    <InfoOutlined sx={{color: 'gray', mr: 2}}/>
                    <Stack>
                        <Typography variant={'subtitle2'}>
                            <b>
                                Внимание!
                            </b>
                            С обновлением системы от 01.11.2023 г. необходимо внести следующую информацию по сотруднику:
                        </Typography>
                        <Typography variant={'subtitle2'}>
                            ИИН, номер квалификационного свидетельства, данные по водительскому удостоверению.
                        </Typography>
                    </Stack>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    gap={3}
                >
                    <Stack
                        flexDirection={'column'}
                        gap={2}
                    >
                        <TextField
                            value={newEmployeeInfo.last_name}
                            label={'Фамилия'}
                            name={'last_name'}
                            disabled={true}
                            onChange={handleChange}
                        />
                        <TextField
                            value={newEmployeeInfo.first_name}
                            label={'Имя'}
                            name={'first_name'}
                            disabled={true}
                            onChange={handleChange}
                        />
                        <TextField
                            value={newEmployeeInfo.patronymic}
                            label={'Отчество'}
                            name={'patronymic'}
                            disabled={true}
                            onChange={handleChange}
                        />
                        <TextField
                            value={newEmployeeInfo.qualification_certificate}
                            label={'Номер квалификационного свидетельства'}
                            name={'qualification_certificate'}
                            disabled={!isChanging}
                            onChange={handleChange}
                        />
                        <TextField
                            value={newEmployeeInfo.qualification_certificate_issuer}
                            label={'Кем выдано кв. свидетельство'}
                            name={'qualification_certificate_issuer'}
                            disabled={!isChanging}
                            onChange={handleChange}
                        />
                        <TextField
                            value={newEmployeeInfo.qualification_certificate_issue_date}
                            name={'qualification_certificate_issue_date'}
                            disabled={!isChanging}
                            onChange={handleChange}
                            label={'Дата выдачи кв. свидетельства'}
                            type={'date'}
                            InputLabelProps={{
                                shrink: true
                            }}

                        />
                        <TextField
                            value={newEmployeeInfo.qualification_certificate_end_date}
                            name={'qualification_certificate_end_date'}
                            disabled={!isChanging}
                            onChange={handleChange}
                            label={'Дата истечения кв. свидетельства'}
                            type={'date'}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <TextField
                            value={newEmployeeInfo.iin}
                            label={'ИИН'}
                            name={'iin'}
                            disabled={true}
                            onChange={handleChange}
                        />
                        <TextField
                            value={newEmployeeInfo.driver_license_series}
                            label={'Серия водительского удостоверения (2 буквы)'}
                            name={'driver_license_series'}
                            disabled={true}
                            onChange={handleChange}
                        />
                        <TextField
                            value={newEmployeeInfo.driver_license_number}
                            label={'Номер водительского удостоверения (цифры)'}
                            name={'driver_license_number'}
                            disabled={true}
                            onChange={handleChange}
                        />
                        <TextField
                            value={convertFromDateTimeToDate(newEmployeeInfo.driver_license_date_of_issue)}
                            name={'driver_license_date_of_issue'}
                            disabled={true}
                            onChange={handleChange}
                            type={'date'}
                            helperText={'Дата выдачи ВУ'}
                        />
                        <TextField
                            value={convertFromDateTimeToDate(newEmployeeInfo.driver_license_expires_date)}
                            name={'driver_license_expires_date'}
                            disabled={true}
                            onChange={handleChange}
                            type={'date'}
                            helperText={'Дата истечения ВУ'}
                        />
                        <Stack flexDirection={'column'}>
                            <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}}>
                                <b>
                                    Категории водительского удостоверения:
                                </b>
                            </Typography>
                            <CategoryCheckboxes
                                handleCategoriesChange={handleCategoriesChange}
                                newEmployeeInfo={newEmployeeInfo}
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        flexDirection={'row'}
                        gap={3}
                    >
                        {!isChanging && (
                            <Button
                                onClick={() => {
                                    setIsChanging(true);
                                }}
                                variant={'contained'}
                            >
                                Изменить информацию
                            </Button>
                        )}
                        {isChanging && (
                            <LoadingButton
                                onClick={() => {
                                    setIsChanging(false);
                                }}
                                variant={'contained'}
                                color={'error'}
                                loading={submitLoading}
                            >
                                Отменить изменения
                            </LoadingButton>
                        )}
                        {isChanging && (
                            <LoadingButton
                                onClick={() => {
                                    handleSubmit();
                                }}
                                variant={'contained'}
                                loading={submitLoading}
                            >
                                Сохранить изменения
                            </LoadingButton>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}

export default EmployeeEditInfo;