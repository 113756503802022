import {Button, Stack, Typography} from "@mui/material";
import {MiniAttributesCard} from "@/modules/students/components/MiniAttributesCard";
import {PreviousIcon} from "@/global/icons/PreviousIcon";
import {NextIcon} from "@/global/icons/NextIcon";
import {useTranslation} from "react-i18next";
import type {SchoolAttributesCountDto} from "@/global/api/gen/ts/SchoolAttributesCountDto";

interface Props {
    schoolAttributes:SchoolAttributesCountDto | undefined,
    schoolAttributesLoading:boolean,
    handleChangeSchoolIndex: (type: 'next' | 'previous') => void;
}

export function ManagerMainAttributesInfo({schoolAttributes, schoolAttributesLoading, handleChangeSchoolIndex}:Props) {
    const {t} = useTranslation();

    return (
        <Stack
            flexDirection={{xs:'column', lg:'row'}}
            sx={{
                mt: '51px',
                width: '100%'
            }}
        >
            <Stack
                flexDirection={{xs:'column', lg:'row'}}
                gap={'23px'}
            >
                <Stack
                    flexDirection={'column'}
                    gap={'10px'}
                >
                    <Typography
                        fontSize={'16px'}
                        lineHeight={'100%'}
                        color={'rgba(5,48,79,0.6)'}
                    >
                        {t('main-pages.manager.groups')}
                        :
                    </Typography>

                    <Stack
                        flexDirection={'row'}
                        gap={'10px'}
                    >
                        <MiniAttributesCard
                            count={schoolAttributes?.groups?.total ?? 'Н/Д'}
                            title={t('main-pages.manager.total')}
                            loading={schoolAttributesLoading}
                        />

                        <MiniAttributesCard
                            count={schoolAttributes?.groups?.active ?? 'Н/Д'}
                            title={t('main-pages.manager.current')}
                            loading={schoolAttributesLoading}
                        />
                    </Stack>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    gap={'10px'}
                >
                    <Typography
                        fontSize={'16px'}
                        lineHeight={'100%'}
                        color={'rgba(5,48,79,0.6)'}
                    >
                        {t('main-pages.manager.students')}
                        :
                    </Typography>

                    <Stack
                        flexDirection={'row'}
                        gap={'10px'}
                    >
                        <MiniAttributesCard
                            count={schoolAttributes?.students?.total ?? 'Н/Д'}
                            title={t('main-pages.manager.total')}
                            loading={schoolAttributesLoading}
                        />
                    </Stack>
                </Stack>
            </Stack>

            <Stack
                flexDirection={'row'}
                gap={'10px'}
                sx={{
                    height: "fit-content",
                    mt: {xs:'20px', lg: "auto"},
                    ml: "auto",
                }}
            >
                <Button
                    sx={{
                        width: 'fit-content',
                        height: 'fit-content',
                        borderRadius: '16px',
                        minWidth: 'fit-content',
                        p: 0
                    }}
                    onClick={() => {
                        handleChangeSchoolIndex('previous');
                    }}
                >
                    <PreviousIcon/>
                </Button>
                <Button
                    sx={{
                        width: 'fit-content',
                        height: 'fit-content',
                        borderRadius: '16px',
                        minWidth: 'fit-content',
                        p: 0
                    }}
                    onClick={() => {
                        handleChangeSchoolIndex('next');
                    }}
                >
                    <NextIcon/>
                </Button>
            </Stack>
        </Stack>
    );
}