import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  UsersControllerChangeUserInfoByIdMutationRequest,
  UsersControllerChangeUserInfoByIdMutationResponse,
} from '../../ts/usersDTO/UsersControllerChangeUserInfoById'

export function getUsersControllerChangeUserInfoByIdUrl() {
  return `/users/change-userinfo` as const
}

/**
 * @summary Сменить информацию о пользователе
 * {@link /users/change-userinfo}
 */
export async function usersControllerChangeUserInfoById(
  data: UsersControllerChangeUserInfoByIdMutationRequest,
  config: Partial<RequestConfig<UsersControllerChangeUserInfoByIdMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<UsersControllerChangeUserInfoByIdMutationResponse, ResponseErrorConfig<Error>, UsersControllerChangeUserInfoByIdMutationRequest>({
    method: 'POST',
    url: getUsersControllerChangeUserInfoByIdUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}