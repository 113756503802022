import type {ChangeEvent} from "react";
import {Box, Checkbox, FormControlLabel} from "@mui/material";

interface Props {
    categoriesArray: string[],
    changing: boolean,
    handleCategoriesChange: (e: ChangeEvent<HTMLInputElement>) => void,
}

export function ChangeSchoolMainCategoryCheckboxes({changing, categoriesArray, handleCategoriesChange}:Props) {
    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('A')}
                            disabled={!changing}
                            name={'A'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'А'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('B')}
                            disabled={!changing}
                            name={'B'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'B'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('BE')}
                            disabled={!changing}
                            name={'BE'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'BE'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('C')}
                            disabled={!changing}
                            name={'C'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'C'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('CE')}
                            disabled={!changing}
                            name={'CE'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'CE'}
                />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('D')}
                            disabled={!changing}
                            name={'D'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'D'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('DE')}
                            disabled={!changing}
                            name={'DE'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'DE'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('Tb')}
                            disabled={!changing}
                            name={'Tb'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'Tb'}
                />
                <FormControlLabel
                    className={'category-label'}
                    control={(
                        <Checkbox
                            checked={categoriesArray.includes('Tm')}
                            disabled={!changing}
                            name={'Tm'}
                            onChange={handleCategoriesChange}
                            className={'register-checkbox'}
                        />
                    )}
                    label={'Tm'}
                />
            </Box>
        </>
    );
}