import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerCertificatePDFForGraduationQueryResponse,
  CertificateControllerCertificatePDFForGraduationPathParams,
} from '../../ts/CertificateDTO/CertificateControllerCertificatePDFForGraduation'

export function getCertificateControllerCertificatePDFForGraduationUrl({
  number,
}: {
  number: CertificateControllerCertificatePDFForGraduationPathParams['number']
}) {
  return `/certificate/pdf/graduation/${number}` as const
}

/**
 * {@link /certificate/pdf/graduation/:number}
 */
export async function certificateControllerCertificatePDFForGraduation(
  { number }: { number: CertificateControllerCertificatePDFForGraduationPathParams['number'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerCertificatePDFForGraduationQueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerCertificatePDFForGraduationUrl({ number }).toString(),
    ...requestConfig,
  })
  return res
}