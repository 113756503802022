import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography} from '@mui/material';
import {useUserStore} from '@/global/store/UserStore';
import {useSnackbarStore} from '@/global/store/SnackbarStore';
import {useNavigate} from 'react-router-dom';
import FlexHeader from '../../global/components/FlexHeader';
import {DatePicker} from '@mui/x-date-pickers';
import dayjs, {Dayjs} from 'dayjs';
import PageHeader from '../../global/components/PageHeader';
import {PeriodType} from "@/modules/statistic/types/statistic.type";
import {OverviewInfo} from '@/modules/statistic/components/OverviewInfo';
import {ChartUsersCountByPeriod} from '@/modules/statistic/components/ChartUserCountByPeriod';
import {ChartSchoolCountByPeriod} from "@/modules/statistic/components/ChartSchoolCountByPeriod";
import {ChartBarRegisterUsers} from "@/modules/statistic/components/ChartBarRegisterUsers";
import {PieChartByCategory} from "@/modules/statistic/components/PieChartByCategory";
import {PieChartUsers} from "@/modules/statistic/components/PieChartUsers";
import {ChartBarDemographicsRegion} from "@/modules/statistic/components/ChartBarDemographicsRegion";
import {TableSchools} from "@/modules/statistic/components/TableSchools";
import {ChartGroupCountByPeriod} from "@/modules/statistic/components/ChartGroupCountByPeriod";

function getDefaultStartDate(): Dayjs {
    return dayjs(new Date('2024-05-20'));
}

function getDefaultEndDate(): Dayjs {
    return dayjs(new Date());
}

function StatisticsPage() {

    const userStore = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userStore.isAdmin && !userStore.isSuperAdmin && !userStore.isObserver) {
            snackbar.errorMessage('У вас нет прав доступа к данной странице');
            navigate('/');
        }
    }, []);

    const [inputStartDate, setInputStartDate] = useState(getDefaultStartDate());
    const [inputEndDate, setInputEndDate] = useState(getDefaultEndDate());
    const [sendPeriod, setSendPeriod] = useState<PeriodType>(PeriodType.Month);

    const sendStartDate = inputStartDate.format('YYYY-MM-DD');
    const sendEndDate = inputEndDate.format('YYYY-MM-DD');

    const refreshDate = () => {
        setInputStartDate(dayjs(getDefaultStartDate()));
        setInputEndDate(dayjs(getDefaultEndDate()));
        setSendPeriod(PeriodType.Month);
    };

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>
            <PageHeader/>

            <FlexHeader
                sx={{gap: 2, mb: 1.5}}
                alignItems="center"
            >
                <Stack gap={1}>
                    <Typography
                        variant="h3"
                        sx={{mt: 2}}
                    >
                        Страница статистики
                    </Typography>
                    <Typography>
                        Часовой пояс: Астана (UTC+5:00)
                    </Typography>
                </Stack>
                <Stack
                    direction={'row'}
                    gap={2}
                    justifyContent={'flex-start'}
                    sx={{mb: '1rem'}}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                >
                    <Box>
                        <DatePicker
                            maxDate={inputEndDate}
                            label={'С'}
                            onChange={(date) => setInputStartDate(date ? date : dayjs(getDefaultStartDate()))}
                            value={inputStartDate}
                        />
                    </Box>
                    <Box>
                        <DatePicker
                            minDate={inputStartDate}
                            maxDate={dayjs(getDefaultEndDate())}
                            label={'До'}
                            onChange={(date) => setInputEndDate(date ? date : dayjs(getDefaultEndDate()))}
                            value={inputEndDate}
                        />
                    </Box>
                    <FormControl
                        sx={{minWidth: '200px', '& .MuiInputBase-root': {border: 'none !important'}}}
                    >
                        <InputLabel>
                            Период
                        </InputLabel>
                        <Select
                            value={sendPeriod}
                            label={'Период'}
                            onChange={(event) => setSendPeriod(event.target.value as PeriodType)}
                        >
                            <MenuItem value={PeriodType.Month}>
                                Месяц
                            </MenuItem>
                            <MenuItem value={PeriodType.Year}>
                                Год
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Box>
                        <Button
                            variant={'contained'}
                            onClick={refreshDate}
                        >
                            Сбросить
                        </Button>
                    </Box>
                </Stack>
            </FlexHeader>

            <OverviewInfo/>

            <Grid
                container
                spacing={2}
                sx={{mb: 4.5}}
            >
                <Grid
                    item
                    xs={100}
                    sm={6}
                    md={6}
                    lg={6}
                >
                    <ChartUsersCountByPeriod
                        end_date={sendEndDate}
                        start_date={sendStartDate}
                        period_type={sendPeriod}
                    />
                </Grid>
                <Grid
                    item
                    xs={100}
                    sm={6}
                    md={6}
                    lg={6}
                >
                    <ChartGroupCountByPeriod
                        end_date={sendEndDate}
                        start_date={sendStartDate}
                        period_type={sendPeriod}
                    />
                </Grid>
                <Grid
                    item
                    xs={100}
                    sm={6}
                    md={6}
                    lg={6}
                >
                    <ChartSchoolCountByPeriod
                        end_date={sendEndDate}
                        start_date={sendStartDate}
                        period_type={sendPeriod}
                    />
                </Grid>
                {/*<Grid*/}
                {/*    item*/}
                {/*    xs={100}*/}
                {/*    sm={6}*/}
                {/*    md={6}*/}
                {/*    lg={6}*/}
                {/*>*/}
                {/*    <ChartBarDemographicsAge/>*/}
                {/*</Grid>*/}
                {/*<Grid*/}
                {/*    item*/}
                {/*    xs={100}*/}
                {/*    sm={6}*/}
                {/*    md={6}*/}
                {/*    lg={6}*/}
                {/*>*/}
                {/*    <ChartBarDemographicsGender/>*/}
                {/*</Grid>*/}
                <Grid
                    item
                    xs={100}
                    sm={6}
                    md={6}
                    lg={6}
                >
                    <ChartBarRegisterUsers/>
                </Grid>
                <Grid
                    item
                    xs={100}
                    sm={4}
                    md={4}
                    lg={4}
                >
                    <PieChartByCategory/>
                </Grid>
                <Grid
                    item
                    xs={100}
                    sm={4}
                    md={4}
                    lg={4}
                >
                    <PieChartUsers/>
                </Grid>
                <Grid
                    item
                    xs={100}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <ChartBarDemographicsRegion/>
                </Grid>
                {/*<Grid*/}
                {/*    item*/}
                {/*    xs={100}*/}
                {/*    sm={6}*/}
                {/*    md={6}*/}
                {/*    lg={6}*/}
                {/*>*/}
                {/*    <ChartBarDemographicsAgePassed/>*/}
                {/*</Grid>*/}
            </Grid>
            <Grid
                container
                sx={{mb: 4.5}}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <TableSchools
                        start_date={sendStartDate}
                        end_date={sendEndDate}
                    />
                </Grid>
            </Grid>
        </Box>
    );

}

export default StatisticsPage;