import {type ChangeEvent, useState} from 'react';
import {Dialog, DialogContent, DialogTitle, Stack, TextField, Typography} from '@mui/material';
import {InfoOutlined} from '@mui/icons-material';
import LoadingButton from '../../../global/components/LoadingButton';
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import type {StudentDto} from "@/global/api/gen/ts/StudentDto";
import {apiCreateCorrectionStudent} from "@/modules/students/services/student.service";
import type {StudentMedicalDto} from "@/global/api/gen/ts/StudentMedicalDto";
import {toast} from "react-toastify";

interface CorrectionDialogProps {
    student: StudentDto;
}

function CorrectionDialog({student, open, close} : ModalSystemProps<CorrectionDialogProps>) {
    const [studentForm, setStudentForm] = useState<StudentMedicalDto>({
        student_id: student.id,
        medical_certificate_number: '',
        medical_center_title: '',
        medical_center_bin: '',
        medical_examination_date: ''
    });

    const {mutate, isPending: loading} = apiCreateCorrectionStudent();

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setStudentForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleSubmit = () => {
        mutate(studentForm, {
            onSuccess: () => {
                toast.success(`Обновление информации успешно произведено. Для отображения информации - обновите страницу`);
                close(5);
            }
        });
    };

    return (
        <Dialog
            open={open}
            onClose={() => !loading ? close(5) : toast.info('Дождитесь окончания подачи')}
            maxWidth={'xl'}
        >
            <DialogTitle>
                Заявка на исправление информации курсанта 
                {student.last_name}
                {student.first_name}
                #
                {studentForm.student_id}
            </DialogTitle>
            <DialogContent sx={{py: 2}}>

                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    sx={{my: 2}}
                >
                    <InfoOutlined sx={{color: 'gray', mr: 2}}/>
                    <Stack>
                        <Typography variant={'subtitle2'}>
                            <b>
                                Внимание!
                            </b>
                            Посредством данной формы можно заполнить информацию по медицинскому осмотру студента, если это не было сделано при добавлении студента в группу.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Stack
                        flexDirection={'column'}
                        gap={2}
                    >
                        <TextField
                            name={'medical_certificate_number'}
                            value={studentForm.medical_certificate_number}
                            onChange={handleChange}
                            label={'Номер мед. справки'}
                        />
                        <TextField
                            name={'medical_center_title'}
                            value={studentForm.medical_center_title}
                            onChange={handleChange}
                            label={'Название мед. центра'}
                        />
                        <TextField
                            name={'medical_center_bin'}
                            value={studentForm.medical_center_bin}
                            onChange={handleChange}
                            label={'БИН мед. центра'}
                        />
                        <TextField
                            name={'medical_examination_date'}
                            type={'date'}
                            value={studentForm.medical_examination_date}
                            onChange={handleChange}
                            label={'Дата прохождения мед. осмотра'}
                            InputLabelProps={{
                              shrink: true,
                          }}
                        />
                    </Stack>
                </Stack>

                <Stack
                    gap={2}
                    sx={{my: 2}}
                >
                    <LoadingButton
                        variant={'contained'}
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        Обновить данные
                    </LoadingButton>

                    <LoadingButton
                        variant={'contained'}
                        color={'error'}
                        onClick={() => close(5)}
                        loading={loading}
                    >
                        Отмена
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default CorrectionDialog;