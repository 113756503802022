import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CertificateControllerCertificatePDFForA4QueryResponse,
  CertificateControllerCertificatePDFForA4PathParams,
} from '../../ts/CertificateDTO/CertificateControllerCertificatePDFForA4'

export function getCertificateControllerCertificatePDFForA4Url({ studentId }: { studentId: CertificateControllerCertificatePDFForA4PathParams['studentId'] }) {
  return `/certificate/a4/pdf/${studentId}` as const
}

/**
 * {@link /certificate/a4/pdf/:studentId}
 */
export async function certificateControllerCertificatePDFForA4(
  { studentId }: { studentId: CertificateControllerCertificatePDFForA4PathParams['studentId'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<CertificateControllerCertificatePDFForA4QueryResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'GET',
    url: getCertificateControllerCertificatePDFForA4Url({ studentId }).toString(),
    ...requestConfig,
  })
  return res
}