import React from 'react';
import {Avatar, Box, CircularProgress, Divider, Stack, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {red} from '@mui/material/colors';

import PageHeader from '../../global/components/PageHeader';
import {formatDateTime, getName} from '@/global/helpers/text';
import MessagesHistory from '../components/MessagesHistory';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {apiGetTicketInfo} from "@/modules/tickets/services/tickets.service";

function TicketMessages() {

    const {id} = useParams();

    const {data: ticketInfo, isLoading: loading} = apiGetTicketInfo(+(id ?? 0));

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Box sx={{...wrapperSx}}>

                {!ticketInfo && !loading && (
                    <Typography>
                        Ошибка. Нет данных.
                    </Typography>
                )}
                {!ticketInfo && loading && <CircularProgress/>}
                {ticketInfo && !loading &&
                    (
                        <Box sx={{display: 'flex', flexDirection: 'column', mt: 2}}>
                            <Typography
                                color={'gray'}
                                fontSize={'small'}
                            >
                                Обращение #
                                {ticketInfo?.id}
                            </Typography>
                            <Typography
                                color={'gray'}
                                fontSize={'small'}
                            >
                                от
                                {formatDateTime(ticketInfo?.created_at)}
                            </Typography>
                            <Stack
                                sx={{display: 'flex', gap: '50px', mt: '10px'}}
                                direction={'row'}
                            >
                                <Box>
                                    <Typography>
                                        Пользователь
                                    </Typography>
                                    <Stack sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <React.Fragment>
                                            <Avatar
                                                sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                            >
                                                {getName(ticketInfo?.user ?? {}).partialName}
                                            </Avatar>
                                            <Typography>
                                                {getName(ticketInfo?.user ?? {}).fullName}
                                            </Typography>
                                        </React.Fragment>
                                    </Stack>
                                </Box>

                                <Box>
                                    <Typography>
                                        Менеджер
                                    </Typography>
                                    <Stack sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <React.Fragment>
                                            <Avatar
                                                sx={{mr: 1.5, fontSize: 16, bgcolor: red[400]}}
                                            >
                                                {ticketInfo.manager ? getName(ticketInfo?.manager).partialName : 'Х'}
                                            </Avatar>
                                            <Typography>
                                                {ticketInfo.manager ? getName(ticketInfo?.manager).fullName : 'Не назначен'}
                                            </Typography>
                                        </React.Fragment>
                                    </Stack>
                                </Box>
                            </Stack>

                            <Divider style={{margin: '20px 0'}}/>

                            <Typography variant={'h4'}>
                                {ticketInfo?.title}
                            </Typography>

                            <MessagesHistory ticketInfo={ticketInfo}/>
                        </Box>
                    )
                }
            </Box>
        </Box>
    );
}

export default TicketMessages;