import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  AuthControllerReturnRegisterXMLMutationRequest,
  AuthControllerReturnRegisterXMLMutationResponse,
} from '../../ts/authDTO/AuthControllerReturnRegisterXML'

export function getAuthControllerReturnRegisterXMLUrl() {
  return `/auth/xml-register` as const
}

/**
 * @summary Возврат XML заявки на регистрацию
 * {@link /auth/xml-register}
 */
export async function authControllerReturnRegisterXML(
  data: AuthControllerReturnRegisterXMLMutationRequest,
  config: Partial<RequestConfig<AuthControllerReturnRegisterXMLMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<AuthControllerReturnRegisterXMLMutationResponse, ResponseErrorConfig<Error>, AuthControllerReturnRegisterXMLMutationRequest>({
    method: 'POST',
    url: getAuthControllerReturnRegisterXMLUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}