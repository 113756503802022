import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerDeleteStudentFromGroupMutationRequest,
  StudentsControllerDeleteStudentFromGroupMutationResponse,
} from '../../ts/StudentsDTO/StudentsControllerDeleteStudentFromGroup'

export function getStudentsControllerDeleteStudentFromGroupUrl() {
  return `/students/delete` as const
}

/**
 * @summary Удаление студента из группы
 * {@link /students/delete}
 */
export async function studentsControllerDeleteStudentFromGroup(
  data: StudentsControllerDeleteStudentFromGroupMutationRequest,
  config: Partial<RequestConfig<StudentsControllerDeleteStudentFromGroupMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    StudentsControllerDeleteStudentFromGroupMutationResponse,
    ResponseErrorConfig<Error>,
    StudentsControllerDeleteStudentFromGroupMutationRequest
  >({ method: 'POST', url: getStudentsControllerDeleteStudentFromGroupUrl().toString(), data, ...requestConfig })
  return res
}