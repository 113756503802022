import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  StudentsControllerSetPassedTrueForStudentMutationResponse,
  StudentsControllerSetPassedTrueForStudentPathParams,
} from '../../ts/StudentsDTO/StudentsControllerSetPassedTrueForStudent'

export function getStudentsControllerSetPassedTrueForStudentUrl({ id }: { id: StudentsControllerSetPassedTrueForStudentPathParams['id'] }) {
  return `/students/exam/success/${id}` as const
}

/**
 * {@link /students/exam/success/:id}
 */
export async function studentsControllerSetPassedTrueForStudent(
  { id }: { id: StudentsControllerSetPassedTrueForStudentPathParams['id'] },
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<StudentsControllerSetPassedTrueForStudentMutationResponse, ResponseErrorConfig<Error>, unknown>({
    method: 'POST',
    url: getStudentsControllerSetPassedTrueForStudentUrl({ id }).toString(),
    ...requestConfig,
  })
  return res
}