import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type { AuthControllerRegisterMutationRequest, AuthControllerRegisterMutationResponse } from '../../ts/authDTO/AuthControllerRegister'

export function getAuthControllerRegisterUrl() {
  return `/auth/register` as const
}

/**
 * @summary Регистрация
 * {@link /auth/register}
 */
export async function authControllerRegister(
  data: AuthControllerRegisterMutationRequest,
  config: Partial<RequestConfig<AuthControllerRegisterMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<AuthControllerRegisterMutationResponse, ResponseErrorConfig<Error>, AuthControllerRegisterMutationRequest>({
    method: 'POST',
    url: getAuthControllerRegisterUrl().toString(),
    data,
    ...requestConfig,
  })
  return res
}