import React, {useState} from 'react';
import {Box, Button, InputBase, Typography} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {registerInputStyle} from '@/global/helpers/globalstyles';
import LoadingButton from '../../global/components/LoadingButton';
import {useSnackbarStore} from '@/global/store/SnackbarStore';
import {apiAttachSchoolWorker} from "@/modules/users/services/user.service";

interface AddWorkerDialogProps {
    schoolId: number
    onClose: () => void
}

function AddWorkerDialog({schoolId, onClose}: AddWorkerDialogProps) {

    const snackbar = useSnackbarStore((state) => state);
    const [identifier, setIdentifier] = useState<string>('');
    const {mutate:executeRegistration, isPending: attachLoading} = apiAttachSchoolWorker();

    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setIdentifier(value);
    };

    const handleWorkerSubmit = () => {
        if (!identifier) {
            snackbar.errorMessage(`Необходимо заполнить поле "Идентификатор пользователя"`);
        }
        executeRegistration({
            user_identifier: identifier,
            school_id: Number(schoolId)
        }, {
            onSuccess: () => {
                cleanForm();
            }
        });
    };

    const cleanForm = () => {
        setIdentifier('');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                width: '773px',
                height: 'auto',
                p: '32px',
                background: '#fff',
                borderRadius: '16px',
                mt: '17px',
            }}
            flexDirection={'column'}
            gap={1}
        >
            <Button
                variant={'contained'}
                onClick={onClose}
                sx={{mb: 1}}
            >
                Скрыть окно 
                {' '}
                <KeyboardArrowUpIcon style={{marginLeft: '10px'}}/>
            </Button>
            <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} >
                <b>
                    Регистрация нового специалиста
                </b>
            </Typography>

            <InputBase
                type={'text'}
                placeholder={'Идентификатор пользователя (ID/Email/ИИН)*'}
                value={identifier}
                name={'iin'}
                onChange={handleUserChange}
                inputProps={{style: registerInputStyle}}
                required={true}
                disabled={attachLoading}
            />

            <LoadingButton
                variant={'outlined'}
                color={'primary'}
                onClick={handleWorkerSubmit}
                loading={attachLoading}
            >
                Зарегистрировать
            </LoadingButton>
        </Box>
    );
}

export default AddWorkerDialog;